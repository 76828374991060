import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');
class LightEngineGeometryBox extends Component {

	get defaultProps(){
		return {
			width:          { type: "int", value: 20 },
			height:         { type: "int", value: 20 },
			depth:          { type: "int", value: 20 },
			widthSegments:  { type: "int", value: 1 },
			heightSegments: { type: "int", value: 1 },
			depthSegments:  { type: "int", value: 1 },
			anchor:         { 'type': "array", value: [0.5, 0.5, 0.5] }
		}
	}

	ready(){

		this.geometry = new THREE.BufferGeometry().fromGeometry(
			new THREE.CubeGeometry(
				this.props.width,
				this.props.height,
				this.props.depth,
				this.props.widthSegments,
				this.props.heightSegments,
				this.props.depthSegments
			)
		);

		if(this.props.anchor){
            let translateX = (this.props.anchor[0] - 0.5) * 2 * (this.props.width/2) * -1; //(this.props.width * ) - halfWidth;
            let translateY = (this.props.anchor[1] - 0.5) * 2 * (this.props.height/2);
            let translateZ = (this.props.anchor[2] - 0.5) * 2 * (this.props.depth/2);
            this.geometry.applyMatrix( new THREE.Matrix4().makeTranslation( translateX, translateY, translateZ ) );
        }
        
		this.addToMesh();
		
	}

	addToMesh(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-MESH"){
				this.mesh = node
				node.mesh.geometry = this.geometry;
				break;
			}
			node = node.parentNode;
		}
	}

}

register('light-engine-geometry-box', LightEngineGeometryBox);
