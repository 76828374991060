import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class SearchOverlay extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown .js-close': '_onClose',
            'keyup': 'onKeyUp'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            query:   { type: 'string', value: '', change: '_onQueryChange' },
            posts:   { type: 'json', value: null, change:'_onPostsChange' },
            active:  { type: 'bool', value: false, change: '_onActiveChange' },
            onClose: { type: 'func', value: function(){ return; } },
            onSend:  { type: 'func', value: function(){ return; } },
            onInputChange: { type: 'func', value: function(){ return; } },
            showResult:    { type: 'bool', value: false },
        }
    }

    _onQueryChange() {
        clearTimeout( this._loaderTimer );
        this.props.showResult = false;
    }

    _onPostsChange() {
        clearTimeout( this._loaderTimer );
        this._loaderTimer = setTimeout(()=>{
            this.props.showResult = true;
        },300);
    }

    _onInputChange( value ){
        this.props.onInputChange( value );
    }

    _onActiveChange() {
        if(this.props.active) {
            setTimeout( () => {
                this.querySelector('.js-input').focus();
            }, 500);
        }
        else {
            this.querySelector('.js-input').blur();
        }
    }

    _onClose(){
        this.props.onClose();
    }

    onKeyUp(e){

        if (!this.props.active) {
            return;
        }

        if(e.keyCode == 27){
            this._onClose();
        }

        if(e.keyCode == 13){
            e.preventDefault();
            e.stopPropagation();
            this.props.onSend();
            this._onClose();
        }

    }

}

register('search-overlay', SearchOverlay);
