import {register} from "light";
import {Component} from "light";
import {support} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');


class LightEngineCameraPerspective extends Component {

	get defaultProps(){
		return {
			position:      { type: "array",  value: [0,0,0], change: 'onPositionChange' },
			rotation:      { type: "array",  value: [0,0,0], change: 'onRotationChange' },
			lookAt:        { type: "array",  value: [0,0,0] , change: 'onLookAtChange'  },
			quaternion:    { type: "array",  value: null        },
			up:        { type: "array", value: [0,1,0] },
			fov:       { type: "int",   value: 75 },
			near:      { type: "int",   value: 1 },
			far:       { type: "int",   value: 1000 },
			trackBall: { type: "bool",   value: false },
			accelerometer: { type: "bool", value: false },
			winWidth:       { type: 'int',   value: window.innerWidth  },
			winHeight:      { type: 'int',   value: window.innerHeight },
			aspect:    {
				type: "float",
				value: null,
				change: 'onAspectChange'
			},
			onReady: {type: 'func', value: function(){} },
		}
	}

	onAspectChange(){
		this.camera.aspect = this.props.aspect;
		this.camera.updateProjectionMatrix();
	}

	resize(){
		this.props.winWidth = window.innerWidth;
		this.props.winHeight = window.innerHeight;
		this.camera.aspect = this.props.aspect || (this.props.winWidth / this.props.winHeight);
		this.camera.updateProjectionMatrix();
	}

	detached(){
		if(this.parentScene){
			this.parentScene.camera = null;
		}
	}

	disable() {
		this.visible = false;
	}

	enable() {
		this.visible = true;
	}

	created(){

		this.pointerXMove    = 0;
		this.pointerYMove    = 0;
		this.pointerXRotate  = window.innerWidth/2;
		this.pointerYRotate  = 0;

		this.currpointerXRotateRatio    = 0;
		this.currpointerYRotateRatio    = 0;

		this.cameraDistance  = 100;

		this.pointerX = 0;
		this.pointerY = 0;
		this.pointerZ = 0;
		this.lastPointerX = 0;
		this.lastPointerY = 0
		this.lastPointerZ = 0;
		

		this._cache = {
			rotation: {x:0, y: 0, z: 0},
			position: {x:0, y: 0, z: 0},
		};

	}

	ready(){

		this.camera = new THREE.PerspectiveCamera(
			this.props.fov,
			this.props.aspect,
			this.props.near,
			this.props.far
		);

		this.camera._parentNode = this;

		this.camera.up    = new THREE.Vector3(0,1,0);
		this.cameraTarget = new THREE.Vector3(0,0,0);
		this.cameraDeltaPosition = new THREE.Vector3(0,0,0);

		var node = this.parentNode;


		while(node){
			if (node.tagName == "LIGHT-ENGINE-SCENE"
				|| node.tagName == "LIGHT-ENGINE-SCENE-CSS") {
				this.parentScene = node;
				node.addCamera( this.camera );
				break;
			}
			node = node.parentNode;
		}




		if (this.getAttribute('id') === 'health-scene-camera' ) {
			console.log('YOUHJOU', this.parentScene );
		}

		if (this.props.trackBall) {

			document.addEventListener( support.pointerdown, this.onPointerDown.bind(this), false );
			document.addEventListener( support.pointermove, this.onPointerMove.bind(this), false );
			document.addEventListener( support.pointerup,   this.onPointerUp.bind(this),   false );
		    document.addEventListener( 'DOMMouseScroll',    this.onMouseWheel.bind(this),  false );
	  		document.addEventListener( 'mousewheel',        this.onMouseWheel.bind(this),  false );
	  		document.addEventListener("contextmenu", this.onContextMenu.bind(this), false);

		}
		
		if (this.props.accelerometer) {
			this.euler = new THREE.Euler();
			this.xAxis = new THREE.Vector3( 1, 0, 0 );
			this.yAxis = new THREE.Vector3( 0, 1, 0 );
			this.zAxis = new THREE.Vector3( 0, 0, 1 );
			this.tempQuat  = new THREE.Quaternion();
			this.worldQuat = new THREE.Quaternion();
			this.worldQuat.setFromAxisAngle( this.xAxis, - Math.PI / 2 ); // - PI/2 around the x-axis
			this.screenOrientation = 0;
			this.onScreenOrientationChangeEvent();
			this.bindMethods(["onScreenOrientationChangeEvent", "onDeviceOrientationChangeEvent"]);
			window.addEventListener( 'orientationchange', this.onScreenOrientationChangeEvent, false );
			window.addEventListener( 'deviceorientation', this.onDeviceOrientationChangeEvent, false );
		}

		this.onPositionChange();
		this.onRotationChange();
		this.onLookAtChange();

  		this.props.onReady(this, this.camera);

	}

	onKeyUp() {

	}

	onScreenOrientationChangeEvent(){
		this.screenOrientation = THREE.Math.degToRad( window.orientation || 0 );
		if ( this.initialScreenOffset === undefined ) {
			this.initialScreenOffset = this.screenOrientation;
		}
	}

	onDeviceOrientationChangeEvent(event) {
		var alpha = THREE.Math.degToRad( event.alpha );
		var beta = THREE.Math.degToRad( event.beta );
		var gamma = THREE.Math.degToRad( event.gamma );
		// 'ZXY' for the device, but 'YXZ' for us
		this.euler.set( beta, alpha, - gamma, 'YXZ' );
	}

	onContextMenu(event){
		event.preventDefault();
	}

    onMouseWheel(e){

    	if (!this.visible) {
    		return;
    	}

        var wheelDeltaX, wheelDeltaY;

        if ( e.wheelDelta ){
            wheelDeltaX = wheelDeltaY = e.wheelDelta; //6 or 12
        } else if ( e.detail ){
            wheelDeltaX = wheelDeltaY = -e.detail * 40; // *3
        } else if ( e.wheelDeltaX ) {
            wheelDeltaY = e.wheelDeltaY/12;
            wheelDeltaX = -1 * e.wheelDeltaX/12; 
        } else if ( e.axis !== undefined && e.axis === e.HORIZONTAL_AXIS ) {
            wheelDeltaY = 0;
            wheelDeltaX = -1 * wheelDeltaY;
        } else {
            wheelDeltaY = 0;
            wheelDeltaX = 0;
        }

        this.pointerZ = wheelDeltaY * -0.5;

    }


	onPointerDown(event) {

    	if (!this.visible) {
    		return;
    	}

		if (event.which == 3) {
			this.isRightClick = true;
			event.preventDefault();
			event.stopPropagation();
		}
		else {
			
			if (this.camera._blockAxis) {
				return;
			}

		}

        this.isPointerDown = true;

        this.touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

		// this.touchEventPageX = this.touchEvent.pageX;
		// this.touchEventPageY = this.touchEvent.pageY;
		// this.touchEventPageX -= window.pageXOffset || document.documentElement.scrollLeft;
		// this.touchEventPageY -= window.pageYOffset || document.documentElement.scrollTop;

        this._touchEventPageX  = this._touchEvent.clientX;
        this._touchEventPageY  = this._touchEvent.clientY;

		if (this.isRightClick) {
	        this.pointerX = this.touchEventPageX;
			this.pointerY = this.touchEventPageY;
			this.lastPointerX = this.pointerX;
			this.lastPointerY = this.pointerY;
		}
		else {
	        this.pointerXDown  = this.touchEventPageX;
	        this.pointerYDown  = this.touchEventPageY;
			this.startPointerX = this.pointerXRotate;
	        this.startPointerY = this.pointerYRotate;
		}

    }

    onPointerUp(){
        this.isPointerDown = false;
        this.isRightClick = false;
    }

	onPointerMove(e) {

		if (!this.visible) {
    		return;
    	}

		if (this.camera._blockAxis) {
			return;
		}

        if( !this.isPointerDown ){
            return;
        }
        
       event.preventDefault();

        this.touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;
        this.touchEventPageX = this.touchEvent.pageX;
		this.touchEventPageY = this.touchEvent.pageY;
		this.touchEventPageX -= window.pageXOffset || document.documentElement.scrollLeft;
		this.touchEventPageY -= window.pageYOffset || document.documentElement.scrollTop;

		if (this.isRightClick) {
	        // this.pointerXMove = this.startPointerX + (this.touchEventPageX - this.pointerXDown);
	        // this.pointerYMove = this.startPointerY + (this.touchEventPageY - this.pointerYDown);
			this.pointerX = this.touchEventPageX;
			this.pointerY = this.touchEventPageY;
		}
		else {
	        this.pointerXRotate = this.startPointerX + (this.touchEventPageX - this.pointerXDown);
	        this.pointerYRotate = this.startPointerY + (this.touchEventPageY - this.pointerYDown);
		}

	}

	onPositionChange() {
		if (this.camera) {
			this.camera.position.x = this.props.position[0];
			this.camera.position.y = this.props.position[1];
			this.camera.position.z = this.props.position[2];
			this.camera.lookAt( this.cameraTarget );
			this.camera.rotation.x += this._cache.rotation.x;
			this.camera.rotation.y += this._cache.rotation.y;
			this.camera.rotation.z += this._cache.rotation.z;
		}
	}

	onRotationChange() {
		this._cache.rotation.x = -this.props.rotation[0] * Math.PI / 180;
		this._cache.rotation.y = -this.props.rotation[1] * Math.PI / 180;
		this._cache.rotation.z = -this.props.rotation[2] * Math.PI / 180;

		if (this.mesh !== void 0) {
			if (this.props.lookAt !== null) {
				this.cameraTarget.x = this.props.lookAt[0];
				this.cameraTarget.y = this.props.lookAt[1];
				this.cameraTarget.z = this.props.lookAt[2];
				this.camera.lookAt( this.cameraTarget );
				this.camera.rotation.x += this._cache.rotation.x;
				this.camera.rotation.y += this._cache.rotation.y;
				this.camera.rotation.z += this._cache.rotation.z;
			}
			else{
				this.camera.rotation.x = this._cache.rotation.x;
				this.camera.rotation.y = this._cache.rotation.y;
				this.camera.rotation.z = this._cache.rotation.z;
			}
		}
	}

	onLookAtChange() {
		if (this.camera !== void 0) {
			if (this.props.lookAt !== null) {
				this.cameraTarget.x = this.props.lookAt[0];
				this.cameraTarget.y = this.props.lookAt[1];
				this.cameraTarget.z = this.props.lookAt[2];
				this.camera.lookAt( this.cameraTarget );
				this.camera.rotation.x += this._cache.rotation.x;
				this.camera.rotation.y += this._cache.rotation.y;
				this.camera.rotation.z += this._cache.rotation.z;
			}
		}
	}

	update(){

		if (this.camera.fov !== this.props.fov) {
			this.camera.fov = this.props.fov;
			this.camera.updateProjectionMatrix();
		}

		if (this.camera.near !== this.props.near) {
			this.camera.near = this.props.near;
			this.camera.updateProjectionMatrix();
		}

		if (this.camera.far !== this.props.far) {
			this.camera.far  = this.props.far;
			this.camera.updateProjectionMatrix();
		}

		this.camera.up.x = this.props.up[0];
		this.camera.up.y = this.props.up[1];
		this.camera.up.z = this.props.up[2];


		if (this.props.trackBall)
		{

			this.pointerXMove = (this.pointerX - this.lastPointerX) * -1;
			this.pointerYMove = this.pointerY - this.lastPointerY;
			this.pointerZMove = this.pointerZ - this.lastPointerZ;

			this.lastPointerX = this.pointerX;
			this.lastPointerY = this.pointerY;

			this.cameraDeltaPosition.add( new THREE.Vector3().copy( new THREE.Vector3(1,0,0) ).applyQuaternion(this.camera.quaternion).multiplyScalar(this.pointerXMove) );
			this.cameraDeltaPosition.add( new THREE.Vector3().copy( new THREE.Vector3(0,1,0) ).applyQuaternion(this.camera.quaternion).multiplyScalar(this.pointerYMove) );
			this.cameraDeltaPosition.add( new THREE.Vector3().copy( new THREE.Vector3(0,0,1) ).applyQuaternion(this.camera.quaternion).multiplyScalar(this.pointerZMove) );


			this.camera.position.x = this.props.position[0] + this.cameraDeltaPosition.x;
			this.camera.position.y = this.props.position[1] + this.cameraDeltaPosition.y;
			this.camera.position.z = this.props.position[2] + this.cameraDeltaPosition.z;

			this.pointerXRotateRatio = (this.pointerXRotate / this.props.winWidth - .5)  * 2;
			this.pointerYRotateRatio = (this.pointerYRotate / this.props.winHeight - .5) * 2;
			this.currpointerXRotateRatio += ( this.pointerXRotateRatio - this.currpointerXRotateRatio ) * 0.1;
			this.currpointerYRotateRatio += ( this.pointerYRotateRatio - this.currpointerYRotateRatio ) * 0.1;
	
			this.cameraTarget.x = Math.cos( (this.currpointerXRotateRatio * 180 - 90) * Math.PI / 180 ) * this.cameraDistance + this.camera.position.x;
			this.cameraTarget.z = Math.sin( (this.currpointerXRotateRatio * 180 - 90) * Math.PI / 180 ) * this.cameraDistance + this.camera.position.z;
			this.cameraTarget.y = this.pointerYRotate * -1 + this.camera.position.y;

			this.camera.lookAt( this.cameraTarget );

		}
		else {


			if(this.props.accelerometer) {
				// orient the device
				this.camera.quaternion.setFromEuler( this.euler );
				// adjust by initial non-absolute device orientation angle offset
				this.camera.quaternion.multiplyQuaternions( this.tempQuat.setFromAxisAngle( this.yAxis, this.initialScreenOffset || 0 ), this.camera.quaternion );
				// camera looks out the back of the device, not the top
				this.camera.quaternion.multiply( this.worldQuat );
				// adjust for current screen orientation angle
				this.camera.quaternion.multiply( this.tempQuat.setFromAxisAngle( this.zAxis, - this.screenOrientation ) );

			}
			else {

				if (this.props.quaternion !== null) {
					this.camera.quaternion.copy( this.props.quaternion );
					this.camera.updateMatrixWorld();
				}

			}

		}
		
	}

}

register('light-engine-camera-perspective', LightEngineCameraPerspective);