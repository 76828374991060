import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class LightUiDialog extends Component {

    get events() {
        return {
            "pointerdown .js-dialog-confirm": '_onConfirmDown',
            "pointerdown .js-dialog-cancel": '_onCancelDown',
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps() {
        return {
            active:  { type: 'bool', value: false, change: 'togglePopin' },
            content: {type: 'json', value: null},
            onCancel:  { type: 'fn', value: function(){} },
            onConfirm:  { type: 'fn', value: function(){} },
        }
    }

    created() {
        this.bindMethods(["_onKeyUp"]);
    }

    _onConfirmDown() {
        this.props.onConfirm();
    }

    _onCancelDown() {
        this.props.onCancel();
    }

    detached() {
        document.removeEventListener('keyup', this._onKeyUp, false);
    }

    _onKeyUp(event) {
        if (event.keyCode == 13) {
            this._onConfirmDown();
        }
        if (event.keyCode == 27) {
            this._onCancelDown();
        }
    }

    togglePopin(){
        if(this.props.active){
            this.classList.add('is-active');
            document.addEventListener('keyup', this._onKeyUp, false);
        }
        else {
            this.classList.remove('is-active');
            document.removeEventListener('keyup', this._onKeyUp, false);
        }
    }
    
}

register('light-ui-dialog', LightUiDialog);