import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class assignEvents extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            "change .js-input-event": 'onInputChange'
        }
    }

    get defaultProps(){

        return {

            content: { type: 'json', value: null },

            params: {type: 'json', value: {
                events:[]
            }},

            forceReload: { type: 'bool', value: false },

        }
    }


    onInputChange(e){

        let name   = e.currentTarget.getAttribute('name');
        let checked = e.currentTarget.checked;
        let userid = e.currentTarget.getAttribute('user-id');

        let params = {
            userid: userid,
            eventid: name,
            assign: checked
        };

        this.props.params = params;

    }

    _onAssignSuccess( data ){
        if( data.body.success ){
            //commented because of inifinite loop
            /*this.props.forceReload = true;
            requestAnimationFrame(()=>{
                this.props.forceReload = false;
            });*/
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this._onSettingError(data);
        }
    }

    _onAssignError( data ){
        console.log('Error in ajax form');
        let inputs = this.props.content.form.inputs;
        for (let i=0; i< this._savedEvents.length; i++) {
            inputs[i].checked = this._savedEvents[i].checked;
            //TODO auto bind the checked attribute for inputs to avoid DOM manipulation...
            this.querySelector('#f-input-' + inputs[i].name).checked = inputs[i].checked;
        }
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

}

register('assign-events', assignEvents);
