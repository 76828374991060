import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

class CardAbout extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            isSticky: { type: 'bool', value: true },
            isFullWidth: { type:'bool', value:false},

            hasCoreSticky: {type:'bool', value: true},
        }
    }

    resize () {
        if( window.innerWidth < 1024 ){
            this.props.isSticky = false;
        }
        else {
            this.props.isSticky = true;
        }
    }


}

register('card-about', CardAbout);
