import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

class PageEvent extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    ready(){

        setTimeout( () => {

            this.$introEvent = this.querySelector('.js-intro-event');
            this.$cardContainer = this.querySelector('.js-card-container');

            if(this.$introEvent && this.$cardContainer){
                this.resize();

                EventDispatcher.fire('page:ready');
            }
            else {
                this.ready();
            }

        }, 100);

    }

    resize(){

        if(this.$introEvent
            && this.$cardContainer) {

            if ( window.innerWidth < 1024 ) {
                this.$cardContainer.style.top = '0px';
            }
            else {
                let height = this.$introEvent.offsetHeight;
                this.$cardContainer.style.top = -height + 'px';
            }

        }

    }

}

register('page-event', PageEvent);
