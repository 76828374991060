import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');

class LightEngineSphereGeometry extends Component {

	get defaultProps(){
		return {
			radius:              { type: "int",  value: 10     },
			widthSegments:       { type: "int",  value: 32    },
			heightSegments:      { type: "int",  value: 32    },
			faceNormalsHelper:    { type: "bool", value: false },
			vertexNormalsHelper: { type: "bool", value: false },
			wireframeHelper:     { type: "bool", value: false  },
			anchor:              { 'type': "array", value: [0.5, 0.5] }
		}
	}

	ready(){

		this.geometry = new THREE.SphereBufferGeometry(
			this.props.radius,
			this.props.widthSegments,
			this.props.heightSegments
		);

		if(this.props.anchor){
            let translateX = (this.props.anchor[0] - 0.5) * 2 * (this.props.radius) * -1; //(this.props.width * ) - halfWidth;
            let translateY = (this.props.anchor[1] - 0.5) * 2 * (this.props.radius);
            this.geometry.applyMatrix( new THREE.Matrix4().makeTranslation( translateX, translateY, 0 ) );
        }
        
		this.addToMesh();
		
	}

	addToMesh(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-MESH"){
				this.mesh = node.mesh
				node.mesh.geometry = this.geometry;
				// if (this.props.faceNormalsHelper) {
				// 	this.faceNormalsHelper = new THREE.FaceNormalsHelper( this.mesh, 10, 0xff0000, 1 );
				// 	node.scene.add(this.faceNormalsHelper);
				// }
				if (this.props.vertexNormalsHelper) {
					this.vertexNormalsHelper = new THREE.VertexNormalsHelper( this.mesh, 10, 0xffff00, 1 );
					node.scene.add(this.vertexNormalsHelper);
				}
				if (this.props.wireframeHelper) {
					this.wireframeHelper = new THREE.WireframeHelper( this.mesh, 0x00ffff );
					node.scene.add(this.wireframeHelper);
				}
				break;
			}
			node = node.parentNode;
		}
	}

	update () {
		if (this.vertexNormalsHelper) {
			this.vertexNormalsHelper.update();
		}
		// if (this.faceNormalsHelper) {
		// 	this.faceNormalsHelper.update();
		// }
	}

}

register('light-engine-geometry-sphere', LightEngineSphereGeometry);
