import {register} from 'light';
import {Component} from 'light';

class LightCoreSelectorItem extends Component {

    get defaultProps(){
        return {
            index: { type: 'int', value: null, change: 'onIndexChange' },
            selectedIndex: { type: 'int', value: null, change: 'onSelectedIndexChange' },
            onIndexChange: { type: 'func', value: function () { return; } },
            onSelectedIndexChange: { type: 'func', value: function () { return; } }
        }
    }

    ready(){
        this.$parent = this.parentNode;
        while(this.$parent){
            if(this.$parent.tagName == 'LIGHT-CORE-SELECTOR'){
                this.$parent.addItem(this, this.props.index);
                break
            }
            this.$parent = this.$parent.parentNode;
        }

        this.onIndexChange();
    }

    onIndexChange(){
        this.onSelectedIndexChange();
        this.props.onIndexChange(this.props.index, this.props.selectedIndex);
    }

    onSelectedIndexChange(){
        if( this.props.index == null || this.props.selectedIndex == null) return;
        this.props.onSelectedIndexChange(this.props.selectedIndex);
    }

    detached(){
        if (this.$parent) {
            this.$parent.removeItem(this, this.props.index);
        }
    }

}

register('light-core-selector-item', LightCoreSelectorItem);
