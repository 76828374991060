import {register} from "light";
import {Component} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');


class LightEngineRenderTarget extends Component {

	get defaultProps() {
		return {
			width:     { type: "float", value: null                },
			height:    { type: 'float', value: null                },
			minFilter: { type: 'int',   value: THREE.LinearFilter },
			magFilter: { type: 'int',   value: THREE.LinearFilter },
			format:    { type: 'int',   value: THREE.RGBAFormat    },
			onReady:   { type: 'fn',   value: null   },
			stencilBuffer:   { type: 'bool',   value: false   },
		}
	}

	detached() {
		if (this.renderTarget) {
			console.log('DISPOSE RENDER TARGET', this.renderTarget);
			this.renderTarget.dispose();
		}
	}

	ready(){
		
		this.renderTarget = new THREE.WebGLRenderTarget( this.props.width, this.props.height, {
			minFilter:     this.props.minFilter, 
			magFilter:     this.props.magFilter, 
			format:        this.props.format,
			stencilBuffer: this.props.stencilBuffer
		});

		if (this.props.onReady) {
			this.props.onReady( this, this.renderTarget );
		}

	}

	resize() {
		this.renderTarget.setSize( this.props.width, this.props.height );
	}

}

register('light-engine-render-target', LightEngineRenderTarget);