import {Component} from 'light';
import {register} from 'light';

class ScheduleTabs extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-schedule-tab': 'onTabChange',
            'click .js-prev-tab': '_onPrev',
            'click .js-next-tab': '_onNext',
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            activeTab: { type: 'int', value: 0 },
            theme: 'default',
            choosenDateLabel: '',
            choosenTimeLabel: '',
        }
    }


    _onDateChoosen(date) {
        this.props.choosenDateLabel = date;
    }

    _onTimeChoosen(time) {
        this.props.choosenTimeLabel = time;
    }

    _onSelectedIndexChange(index, total) {

    }

    _onItemsChange(items) {
        this.props.maxIndex = items.length - 1;
    }

    _onNext() {
        if (this.props.activeTab < this.props.maxIndex) {
            this.props.activeTab++;
        }
    }

    _onPrev() {
        if (this.props.activeTab > 0) {
            this.props.activeTab--;
        }
    }

    onTabChange(event){
        if(event){
            this.props.activeTab = Number(event.currentTarget.getAttribute('data-target'));
        }
    }

}

register('schedule-tabs', ScheduleTabs);
