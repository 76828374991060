

import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class ScrollBox extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown .js-scrollbar-y': 'onSidebarDown',
            'pointerdown .js-scrollbar-x': 'onSidebarDown'
        }
    }

    get defaultProps(){
        return {
            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },
            dragTarget: { type: 'array', value: [0,0] },
            direction: 'horizontal',
            useScrollbar: { type: 'bool', value: false },
        }
    }

    created(){

        this.bindMethods(['onSidebarMove', 'onSidebarUp']);

        this.componentReady = false;
        this._pointerNone = false;
        this._y = 0;
        this._oldDeltaY = 0;
        this._startDragY = 0;
        this._x = 0;
        this._oldDeltaX = 0;
        this._startDragX = 0;

        this._containerSidebarWidth = 0;
        this._containerSidebarHeight = 0;

    }

    ready(){

        this.timerReady = setTimeout( () => {

            this.$content = this.querySelector('.js-content');

            if (this.props.useScrollbar) {
                if (this.props.direction != 'horizontal') {
                    this.$sidebarY = this.querySelector('.js-scrollbar-y');
                }
                if (this.props.direction != 'vertical') {
                    this.$sidebarX = this.querySelector('.js-scrollbar-x');
                }
            }

            document.addEventListener(support.pointermove, this.onSidebarMove, false);
            document.addEventListener(support.pointerup, this.onSidebarUp, false);

            if(this.$content ){
                this.componentReady = true;
                this.initDrag();
            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){

        if(this.componentReady){
            this.initDrag();
        }

    }

    initDrag(){




        // container Size
        this.props.containerSize[0] = this.offsetWidth;
        this.props.containerSize[1] = this.offsetHeight;

        if( this.props.direction == "horizontal" ){

            this.props.contentSize[0] = this.$content.offsetWidth;
            this.props.contentSize[1] = this.$content.offsetHeight;

            if (this.props.useScrollbar) {
                this._containerSidebarWidth = this.$sidebarX.parentNode.offsetWidth;
                let pourcentX = this._containerSidebarWidth / this.props.containerSize[0];
                this._ratioSidebarX = this.props.containerSize[0] / this.props.contentSize[0] * pourcentX;
                this._sidebarWidth = (this.props.containerSize[0] * this._ratioSidebarX);
                this.$sidebarX.style.width = this._sidebarWidth + 'px';
            }
        }
        else {
            this.props.contentSize[0] = this.$content.offsetWidth;
            this.props.contentSize[1] = this.$content.offsetHeight;

            if (this.props.useScrollbar) {
                this._containerSidebarHeight = this.$sidebarY.parentNode.offsetHeight;
                let pourcentY = this._containerSidebarHeight / this.props.containerSize[1];
                this._ratioSidebarY = this.props.containerSize[1] / this.props.contentSize[1] * pourcentY;
                this._sidebarHeight = (this.props.containerSize[1] * this._ratioSidebarY);
                this.$sidebarY.style.height = this._sidebarHeight + 'px';
            }
        }

    }

    onMove(x, y){

        this.$content.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;

        if (!this._isPointerMove) {
            if( this.props.direction == "horizontal" ){
                this._x = x * this._ratioSidebarX;
                if (this.props.useScrollbar) {
                    this.$sidebarX.style[support.transform] = 'translate('+ ( (x * this._ratioSidebarX) ) +'px, 0px) '+ support.translateZ;
                }
            }
            else {
                this._y = y * this._ratioSidebarY;
                if (this.props.useScrollbar) {
                    this.$sidebarY.style[support.transform] = 'translate(0px,'+ ( (y * this._ratioSidebarY) ) +'px) '+ support.translateZ;
                }
            }
        }

    }

    onDrag(){
        if(!this._pointerNone){
            this._pointerNone = true;
            this.$content.classList.add('u-force-pointer-none');
            EventDispatcher.fire('pointer:none');
        }
    }

    onEnd(){
         if(this._pointerNone){
            this._pointerNone = false;
            this.$content.classList.remove('u-force-pointer-none');
            EventDispatcher.fire('pointer:auto');
        }
    }

    onSidebarDown(event){
        this._isPointerDown = true;

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        event.currentTarget.classList.add('is-active');

        if( this.props.direction == "horizontal" ){
            this._oldDeltaX = this._x;
            this._startDragX = this._pointerEvent.pageX;
        }
        else {
            this._oldDeltaY = this._y;
            this._startDragY = this._pointerEvent.pageY;
        }
    }

    onSidebarMove(event){

        if(!this._isPointerDown) return;

        if( !this._isPointerMove ){
            EventDispatcher.fire('pointer:none');
            this.classList.add('u-disable-selection');
            this._isPointerMove = true;
        }

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        if( this.props.direction == "horizontal" ){
            this._x = this._oldDeltaX + (this._pointerEvent.pageX - this._startDragX);

            if( this._x < 0 ){
                this._x = 0;
            }

            if( this._x > (this._containerSidebarWidth - this._sidebarWidth) ){
                this._x = (this._containerSidebarWidth - this._sidebarWidth);
            }

            if (this.props.useScrollbar) {
                this.$sidebarX.style[support.transform] = 'translate('+ ( (this._x) ) +'px, 0px) '+ support.translateZ;
            }

            this.props.dragTarget[0] = this._x / this._ratioSidebarX;
        }
        else {
            this._y = this._oldDeltaY + (this._pointerEvent.pageY - this._startDragY);

            if( this._y < 0 ){
                this._y = 0;
            }

            if( this._y > (this._containerSidebarHeight - this._sidebarHeight) ){
                this._y = (this._containerSidebarHeight - this._sidebarHeight);
            }

            if (this.props.useScrollbar) {
               this.$sidebarY.style[support.transform] = 'translate(0px, '+ ( (this._y) ) +'px) '+ support.translateZ;
            }

            this.props.dragTarget[1] = this._y / this._ratioSidebarY;
        }
    }

    onSidebarUp(){

        if(!this._isPointerDown) return;
        this._isPointerDown = false;

        if (this.props.useScrollbar) {
            if (this.props.direction != 'horizontal') {
                this.$sidebarY.classList.remove('is-active');
            }

            if (this.props.direction != 'vertical') {
                this.$sidebarX.classList.remove('is-active');
            }
        }

        this.classList.remove('u-disable-selection');
        EventDispatcher.fire('pointer:auto');

        if(!this._isPointerMove) return;

        this._isPointerMove = false;

    }

    detached(){
        clearTimeout(this.timerReady);
        document.removeEventListener(support.pointermove, this.onSidebarMove, false);
        document.removeEventListener(support.pointerup, this.onSidebarUp, false);
    }

}

register('light-ui-scrollbox', ScrollBox);
