import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import request from 'superagent';

/**
 * Efficiant Cubic method generator
 * @param  {[Number]} a cubic point A
 * @param  {[Number]} b cubic anchor A
 * @param  {[Number]} c cubic point B
 * @param  {[Number]} d ubic anchor B
 * @return {[Function]}
 */
function cubic(a, b, c, d) {
  if (a < 0 || a > 1 || c < 0 || c > 1) {
    return function(x) { return x; }
  }
  return function(x) {
    var start = 0, end = 1;
    while (1) {
      var mid = (start + end) / 2;
      function f(a, b, m) { return 3 * a * (1 - m) * (1 - m) * m + 3 * b * (1 - m) * m * m + m * m * m};
      var xEst = f(a, c, mid);
      if (Math.abs(x - xEst) < 0.001) {
        return f(b, d, mid);
      }
      if (xEst < x) {
        start = mid;
      } else {
        end = mid;
      }
    }
  }
}


class CoreAnimation extends Component {

    get defaultProps(){
        return {
            startAt: { type: 'int', value: 0 },

            duration: { type: 'int', value: 400 },
            delay: { type: 'int', value: 0 },
            easing: { type: 'array', value: [0.000, 0.000, 0.580, 1.000] },

            onStep: { type: 'function', value: function () { return; } },
            onComplete: { type: 'function', value: function() { return; } },

            autoPlay: { type: 'bool', value: false },

            restart: { type: 'bool', value: false, change: 'onRestartChange' }

            // TODO:
            // currentTime
            // Loop
            // active
        }
    }

    onRestartChange() {

        console.log('__ON RESTART CNAGE__');
        if (this.props.restart) {
            this.reStart();
        }
    }

    created(){
        this.ended = false;
        this.paused = true;
        this.animProgress  = 0;
    }

    attached(){
        this.cubicMethod = cubic.apply( this, this.props.easing );
        if( this.props.startAt != 0 ){
            this.animProgress = this.props.duration * this.props.startAt;
        }
        if(this.props.autoPlay){
            this.play();
        }
    }

    play(){
        this.playTimer = setTimeout( () => {
            this.animStartTime = Date.now();
            this.paused = false;
        }, this.props.delay );
    }

    pause(){
        this.paused = true;
        clearTimeout(this.playTimer);
    }

    stop( doComplete ){
        this.ended = true;
        clearTimeout(this.playTimer);

        if( doComplete ){
            this.props.onComplete();
        }
    }

    reStart(){
        this.ended = false;
        this.paused = true;
        this.animProgress  = 0;
        this.animRatio = 0;
        this.play();
    }

    update() {
        if( this.ended ) return;
        if(this.paused) return;

        if ( this.animProgress >= this.props.duration) {
            this.stop(true);
            return;
        }

        var timestamp = Date.now();
        var diff = timestamp - this.animStartTime;

        this.animRatio = (this.animProgress !== 0) ? this.cubicMethod( this.animProgress / this.props.duration ) : 0;
        this.animProgress = this.animProgress + diff;
        this.animStartTime = timestamp;

        this.props.onStep( this.animRatio );
    }

}

register('light-core-animation', CoreAnimation);
