import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';


class MyHeader extends Component {



    get template() {
        return require('./template');
    }

    get events(){
        return {
            'change .js-btn-social': 'onToggleCollapse',
            'click .js-open-nav': 'onOpenNav'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            collapseState: { type: 'bool', value: false },
            navOpen: { type: 'bool', value: false },
            hrefHome: '/',
            currentUrl: '',
            currentUrlForMenu: '',
            user: {type: 'json', value: null}
        }
    }

    attached(){
        EventDispatcher.on('state:change', this._onStateChange, this);
        EventDispatcher.on('menu:change', this._onMenuChange, this);
    }

    _onStateChange(state) {
        if (state == 'page') {
            this._onCloseNav();
        }
    }

    onToggleCollapse(event){
        this.props.collapseState = event.currentTarget.checked;
    }

    _onCloseNav(){
        this.props.navOpen = false;
    }

    onOpenNav( event ){
        if( this.isMobile ){
            this.props.navOpen = !this.props.navOpen;
        }
    }

    _onMenuChange( page ){                               
        this.props.currentUrlForMenu = page;
        if(this.querySelector('.js-top-menu') != null)        
            this.querySelector('.js-top-menu').classList.remove('is-active');
        //var anyPageActive = false;
        //this.$menuitemLinks = this.querySelectorAll('.js-menuitem');        
        //for ( let i = 0; i < this.$menuitemLinks.length; i++ ){                                    
        //    if(this.$menuitemLinks[i].getAttribute("pageid") == page){
        //        anyPageActive = true;
        //        this.$menuitemLinks[i].classList.add('is-active');
        //    }
        //    else{
        //        this.$menuitemLinks[i].classList.remove('is-active');
        //    }
        //}
        //if (this.$menuitemLinks.length>0){
        //    if (anyPageActive == false)
        //        this.$menuitemLinks[0].classList.add('is-active');
        //}
    }

    resize(){
        this.winWidth = window.innerWidth;
        if( support.touch && this.winWidth < 768 ){
            this.isMobile = true;
        }
        else {
            this.isMobile = false;

        }
    }
}

register('my-header', MyHeader);

