import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";

import PBRFragmentShader from './shaders/fs';
import PBRVertexShader from './shaders/vs';

// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');

var TEXTURE_CACHE = {}; 

// import DAT from "dat-gui";



var props = {
	chromaSensitivity: 0.35000001,
	chromaSmooth: 0.25000001
}


// var gui = new DAT.GUI({width: 300});
// gui.add( props, 'chromaSensitivity').min(0).max(1);
// gui.add( props, 'chromaSmooth').min(0).max(1);


THREE.ImageUtils.crossOrigin = 'anonymous';


class LightEngineMaterialPBR extends Component {

	get defaultProps(){

		return {

			type:        { type: "string", value: "basic"  },
			color:       { type: "array",  value: [1,1,1], change: 'onDiffusecolorChange'  },
			wireframe:   { type: "bool",   value: true    },
			shading:     { type: "bool",   value: THREE.SmoothShading },
			src:         { type: 'string', value: null     },
			side:        { type: "string", value: 'double' },
			transparent: { type: 'bool', value: false, change: 'onTransparentChange' },

			lights:      { type: 'bool', value: true, change: 'onLightsChange'  },

			//diffuse map
			map:              { type: "string", value: null, change: 'onMapChange'     },
			mapOffset:        { type: "array",  value: [0,0], change: 'onMapOffsetChange' },
			mapWrap:          { type: "array",  value: ["repeat","repeat"] },
			mapRepeat:        { type: "array",  value: [1,1] },
			mapOpacity:       { type: "float",  value: 1 ,change: 'onMapOpacityChange'    },

			//texture map
			texture:            { type: "json",   value: null, change: 'onTextureChange' },
			alphaTexture:       { type: "json",   value: null, change: 'onAlphaTextureChange' },

			//roughness map
			roughness:          { type: 'float', value: 0.5, change: 'onRoughnessChange' },
			roughnessMap:       { type: "string", value: null, change: 'onRoughnessMapChange' },
			roughnessMapOffset: { type: "array",  value: [0,0] },
			roughnessMapWrap:   { type: "array",  value: ["repeat","repeat"] },
			roughnessMapRepeat: { type: "array",  value: [1,1] },

			//metalness map
			metalness:   		{ type: 'float', value: 0.5, change: 'onMetalnessChange'    },
			metalnessMap:       { type: "string", value: null, change: 'onMetalnessMapChange' },
			metalnessMapOffset: { type: "array",  value: [0,0] },
			metalnessMapWrap:   { type: "array",  value: ["repeat","repeat"] },
			metalnessMapRepeat: { type: "array",  value: [1,1] },

			//alpha map
			alpha:           { type: "float",  value: 1, change: 'onAlphaChange' },
			alphaMap:        { type: "string", value: null, change: 'onAlphaMapChange' },
			alphaMapOffset:  { type: "array",  value: [0,0] },
			alphaMapWrap:    { type: "array",  value: ["repeat","repeat"] },
			alphaMapRepeat:  { type: "array",  value: [1,1] },

			//displacment map
			displacmentMap:       { type: "string", value: null, change: 'onDisplacmentMapChange' },
			displacmentMapWrap:   { type: "array",  value: ["repeat","repeat"] },
			displacmentMapRepeat: { type: "array",  value: [1,1] },
			displacmentScale:     { type: "array", value: [1,1] },

			//normal map
			normalMap:       { type: "string", value: null, change: 'onNormalMapChange' },
			normalMapOffset: { type: "array",  value: [0,0] },
			normalMapWrap:   { type: "array",  value: ["repeat","repeat"] },
			normalMapRepeat: { type: "array",  value: [1,1] },
			normalScale:     { type: "array", value: [1,1], change: 'onNormalScaleChange' },

			//reflection map
			reflectionMap:       { type: "string", value: null, change: 'onReflectionMapChange' },
			reflectionMapOffset: { type: "array",  value: [0,0] },
			reflectionMapWrap:   { type: "array",  value: ["repeat","repeat"] },
			reflectionMapRepeat: { type: "array",  value: [1,1] },
			reflectionScale:     { type: "array", value: [1,1] },

			viewVector: { type: "array", value: [0,0,0], change: 'onViewVectorChange' },
			useGlow:    { type: 'bool', value: false, change: 'onGlowChange'},

			blending: 'NormalBlending',
			blendSrc: 'SrcAlphaFactor',
			blendDst: 'OneMinusSrcAlphaFactor',


			glowC:   { type: "f", value: 1.0, change: 'onGlowCChange' },
			glowP:   { type: "f", value: 6 , change: 'onGlowPChange'  },//1.4

			screenMask:  { type: "json", value: null, change: 'onScreenMaskChange' },
			resolution:  { type: "arr", value: [0,0], change: 'onResolutionChange' },
			screenMaskKeyColor:  { type: "arr", value: [], change: 'onScreenMaskKeyColorChange'  },

		    depthTest:  { type: "bool",   value: true    },
		    depthWrite: { type: "bool",   value: true    },
		    	
		    separateTranslucency: { type: "bool", value: true, change: 'onSeparateTranslucencyChange' },

		    luminance: { type: "bool",   value: false, change:"onLuminanceChange" },

		    chroma: { type: "string", value: null, change:"onChromaChange" },

		    renderDepth: { type: "bool",   value: true    },

		}
		
	}

	detached () {

		EventDispatcher.off('scene:overridematerial', this.onOverrideMaterial);
		EventDispatcher.off('scene:restorematerial', this.onRestoreMaterial);

		if (this.mesh !== void 0) {
			this.mesh.material = null;
		}

		if (this.material !== void 0) {

			if (   this.uniforms
				&& this.uniforms.map
				&& this.uniforms.map.value
				&& this.uniforms.map.value._img) {

console.log('REMOVE MATERIAL')

				//image.src gives an absolute path. we want the path delcrae in src attribute.
				let src =  this.uniforms.map.value._img.getAttribute('src');

				if (src in TEXTURE_CACHE) {

					this.uniforms.map.value.image.onload  = null;
					this.uniforms.map.value.image.onerror = null;
					// this.uniforms.map.value.image         = null;

					TEXTURE_CACHE[src].count--;	

					if (TEXTURE_CACHE[src].count <= 0) {
						TEXTURE_CACHE[src].texture.dispose();
						delete TEXTURE_CACHE[src];
					}

				}

			}

			this.material.dispose();
			this.uniforms.map.value            = null;
			this.uniforms.normalMap.value      = null;
			this.uniforms.displacmentMap.value = null;
			this.uniforms.alphaMap.value       = null;
			this.uniforms.reflectionMap.value  = null;

		}

	}

	created() {

		this.defines = {
			// USE_PBR_DISPLACMENTMAP: "",
			// USE_PBR_LIGHT:          "",
			// USE_PBR_MAP:            "",
			// USE_PBR_NORMALMAP:      "",
			// USE_PBR_ALPHAMAP:       "",
			// USE_PBR_METALNESSMAP:   "",
			// USE_PBR_ROUGHNESSMAP:   "",
			// USE_PBR_REFLECTIONMAP:  "",
			// USE_GLOW: ""
			// USE_DEPTH: ""
		};

		this.uniforms = {

			separateTranslucency: { type: 'f', value: 1.0},
			useDepth:    { type: 'f', value: 0.0},
			renderDepth:    { type: 'f', value: 1.0},

			ambientLightColor: { value: new THREE.Color( 1,1,1 ), type: 'c' },

            diffuseColor:      { value: new THREE.Color( 1,1,1 ), type: 'c' },
           	globalAlpha:    { type: 'f', value: 1.0},

			metalness:    { type: 'f', value: this.props.metalness },
			metalnessMap: { type: "t", value: null },
			metalnessMapOffset:  { type: 'v2', value: new THREE.Vector2( 0, 0 ) },

			roughness:    { type: 'f', value: this.props.roughness },
			roughnessMap: { type: "t", value: null },
			roughnessMapOffset:  { type: 'v2', value: new THREE.Vector2( 0, 0 ) },

            map:          { type: "t", value: null },
            mapOffset:    { type: 'v2', value: new THREE.Vector2( 0, 0 ) },
            mapOpacity:   { type: 'f', value: 1.0},
			// "mapDiffuse" : "magnum/displacement.png",
			// "mapDiffuseWrap": ["repeat", "repeat"],
			// "mapDiffuseMinFilter": [1, 1],
			// "mapDiffuseMagFilter": [1, 1],

            normalMap:      { type: "t", value: null },
            normalMapOffset:  { type: 'v2', value: new THREE.Vector2( 0, 0 ) },
            normalScale:    { type: 'v2', value: new THREE.Vector2( 1, 1 ) },

            displacmentMap:        { type: "t", value: null },
            displacmentMapOffset:  { type: 'v2', value: new THREE.Vector2( 0, 0 ) },
            displacmentScale:      { type: 'v2', value: new THREE.Vector2( 1, 1 ) },

            alphaMap:       { type: "t", value: null },
            alphaMapOffset: { type: 'v2', value: new THREE.Vector2( 0, 0 ) },

			reflectionMap:       { type: "t", value: null },	
			reflectionMapOffset: { type: 'v2', value: new THREE.Vector2( 0, 0 ) },

			"c":   { type: "f", value: 1.0 },
			"p":   { type: "f", value: 6 },//1.4
			glowColor: { type: "c", value: new THREE.Color(0xffff00) },
			viewVector: { type: "v3", value: new THREE.Vector3( 0, 0, 0 ) },

			screenMask:  { type: "t", value: this.props.screenMask != null ? this.props.screenMask : null },
			resolution:  { type: 'v2', value: new THREE.Vector2( 0, 0 ) },
			screenMaskKeyColor: { type: 'c', value: new THREE.Color(0,0,0) },

			cameraNear: { type: "f", value: 1.0 },
			cameraFar:   { type: "f", value: 1000.0 },

			chroma:   { type: "f", value: null },
			chromaSensitivity:   { type: "f", value: null },
			chromaSmooth:   { type: "f", value: null },

        }

	}

	ready() {

		//TODO: lights added after this line will not be taken into account
		for (var k in THREE.UniformsLib['lights']) {
			this.uniforms[k] = THREE.UniformsLib['lights'][k];
		}

		for (var k in THREE.UniformsLib['shadowmap']) {
			this.uniforms[k] = THREE.UniformsLib['shadowmap'][k];
		}

		this.material = new THREE.ShaderMaterial({
			defines: 		this.defines,
			uniforms:       this.uniforms,
		    vertexShader:   PBRVertexShader,
		    fragmentShader: PBRFragmentShader,
		    side:           this.props.side == "double" ? THREE.DoubleSide : this.props.side == "front" ? THREE.FrontSide : THREE.BackSide,
		    lights:         this.props.lights,
		    transparent:    this.props.transparent,
		    blending:      THREE[this.props.blending],
		    blendSrc:      THREE[this.props.blendSrc],
		    blendDst:      THREE[this.props.blendDst],
		    // extensions:  {
		    // 	derivatives: true,
		    // },
		    depthTest:  this.props.depthTest,
		    depthWrite: this.props.depthWrite,
		});




		this.onMapChange();
		this.onMapOffsetChange();
		this.onMapOpacityChange();
		this.onTextureChange();
		this.onAlphaTextureChange();
		this.onRoughnessChange();
		this.onRoughnessMapChange();
		this.onMetalnessChange();
		this.onMetalnessMapChange();
		this.onAlphaChange();
		this.onAlphaMapChange();
		this.onDisplacmentMapChange();
		this.onNormalMapChange();
		this.onNormalScaleChange();
		this.onReflectionMapChange();
		this.onDiffusecolorChange();
		this.onResolutionChange();
		this.onTransparentChange();
		this.onLightsChange();
		this.onViewVectorChange();
		this.onGlowChange();
		this.onGlowCChange();
		this.onGlowPChange();
		this.onScreenMaskChange();
		this.onScreenMaskKeyColorChange();
		this.onSeparateTranslucencyChange();
		this.onLuminanceChange();
		this.onChromaChange();
		
		this.material.needsUpdate = true;

		this.addToMesh();

		EventDispatcher.on('scene:overridematerial', this.onOverrideMaterial, this);
		EventDispatcher.on('scene:restorematerial', this.onRestoreMaterial, this);

	}

	
	enable() {
		// if (this.material) {
		// 	this.onMapChange();
		// 	this.onMapOffsetChange();
		// 	this.onMapOpacityChange();
		// 	this.onTextureChange();
		// 	this.onAlphaTextureChange();
		// 	this.onRoughnessChange();
		// 	this.onRoughnessMapChange();
		// 	this.onMetalnessChange();
		// 	this.onMetalnessMapChange();
		// 	this.onAlphaChange();
		// 	this.onAlphaMapChange();
		// 	this.onDisplacmentMapChange();
		// 	this.onNormalMapChange();
		// 	this.onNormalScaleChange();
		// 	this.onReflectionMapChange();
		// 	this.onDiffusecolorChange();
		// 	this.onResolutionChange();
		// 	this.onTransparentChange();
		// 	this.onLightsChange();
		// 	this.onViewVectorChange();
		// 	this.onGlowChange();
		// 	this.onGlowCChange();
		// 	this.onGlowPChange();
		// 	this.onScreenMaskChange();
		// 	this.onScreenMaskKeyColorChange();
		// 	this.onSeparateTranslucencyChange();
		// 	this.onLuminanceChange();
		// }
	}

	disable(){
		// if (this.material) {
		// 	if (this.uniforms.map.value !== null && this.uniforms.map.value === TEXTURE_CACHE[this.props.map]) {
		// 		this.uniforms.map.value = null;
		// 		TEXTURE_CACHE[this.props.map].dispose();
		// 		TEXTURE_CACHE[this.props.map] = null;
		// 		delete TEXTURE_CACHE[this.props.map];
		// 	}
		// 	if (this.uniforms.alphaMap.value !== null && this.uniforms.alphaMap.value === TEXTURE_CACHE[this.props.alphaMap]) {
		// 		this.uniforms.alphaMap.value = null;
		// 		TEXTURE_CACHE[this.props.alphaMap].dispose();
		// 		TEXTURE_CACHE[this.props.alphaMap] = null;
		// 		delete TEXTURE_CACHE[this.props.alphaMap];
		// 	}
		// }
	}

	releaseTextures() {
		// if (this.props.roughnessMap != null) {
		// 	delete TEXTURE_CACHE[this.props.roughnessMap];
		// }
		// if (this.props.metalnessMap != null) {
		// 	delete TEXTURE_CACHE[this.props.metalnessMap];
		// }
		// if (this.props.displacmentMap != null) {
		// 	delete TEXTURE_CACHE[this.props.displacmentMap];
		// }
		// if (this.props.normalMap != null) {
		// 	delete TEXTURE_CACHE[this.props.normalMap];
		// }
		// if (this.props.reflectionMap != null) {
		// 	delete TEXTURE_CACHE[this.props.reflectionMap];
		// }
	}

	onChromaChange() {
		if (this.props.chroma) {
			this.defines.USE_CHROMA = "";
			this.uniforms.chroma.value = this.props.chroma == 'red' ? 0 : this.props.chroma == 'green' ? 1 : 2;
		}
		else {
			delete this.defines.USE_CHROMA;
		}
	}

	onLuminanceChange() {
		if (this.props.luminance) {
			this.defines.USE_LUMINANCE = "";
		}
		else {
			delete this.defines.USE_LUMINANCE;
		}
	}

	onScreenMaskChange() {
		if (this.props.screenMask !== null) {
			this.defines.USE_MASK = "";
			this.uniforms.screenMask.value = this.props.screenMask != null ? this.props.screenMask : null;
		}
		else {
			delete this.defines.USE_MASK;
		}
	}

	onScreenMaskKeyColorChange() {
		if (this.props.screenMaskKeyColor[0] !== void 0) {
			if (this.defines.USE_KEYCOLOR === void 0) {
				this.defines.USE_KEYCOLOR = "";
			}
			if (this.uniforms.screenMaskKeyColor.value.r !== this.props.screenMaskKeyColor[0])
				this.uniforms.screenMaskKeyColor.value.r = this.props.screenMaskKeyColor[0];
			if (this.uniforms.screenMaskKeyColor.value.g !== this.props.screenMaskKeyColor[1])
				this.uniforms.screenMaskKeyColor.value.g = this.props.screenMaskKeyColor[1];
			if (this.uniforms.screenMaskKeyColor.value.b !== this.props.screenMaskKeyColor[2])
				this.uniforms.screenMaskKeyColor.value.b = this.props.screenMaskKeyColor[2];
		}
		else{
			if (this.defines.USE_KEYCOLOR !== void 0) {
				delete this.defines.USE_KEYCOLOR;
			}
		}
	}
	
	onTransparentChange() {
		this.material.transparent = this.props.transparent;
	}
	
	onLightsChange() {
		this.material.lights = this.props.lights;
		if (this.props.lights) {
			this.defines.USE_PBR_LIGHT = "";
		}
		else {
			delete this.defines.USE_PBR_LIGHT;
		}
	}


	createTexture(src, wrap, minFilter, magFilter) {
		
		let texture;

		if (typeof TEXTURE_CACHE[src] !== "undefined") {

			texture = TEXTURE_CACHE[src].texture;
			TEXTURE_CACHE[src].count++;

			// console.log('REUSE TEXTURE', texture, src );

		}
		else {

			// console.log('CREATE TEXTURE', src );

			//texture = new THREE.TextureLoader().load(src);
			//texture = THREE.ImageUtils.loadTexture(src);

			var imageObj = new Image();
			imageObj.onload = function(){
				imageObj.onload = null;
				imageObj.onerror = null;
				texture.needsUpdate = true;
			}
			imageObj.onerror = function(){
				imageObj.onload = null;
				imageObj.onerror = null;
			}
			imageObj.crossOrigin = "anonymous"
			imageObj.src = src;


			texture = new THREE.Texture( imageObj );

			texture._img = imageObj;

			// console.log('New TExture', texture, src);

			texture.wrapS = texture.wrapT = wrap ? wrap : THREE.RepeatWrapping;
			// texture.magFilter = THREE.NearestFilter;
			// texture.minFilter = THREE.NearestFilter;
			TEXTURE_CACHE[src] = {
				texture: texture,
				count: 1
			};

		}

		return TEXTURE_CACHE[src].texture;
	}
	


	onMapChange() {


		if (   this.uniforms
			&& this.uniforms.map
			&& this.uniforms.map.value
			&& this.uniforms.map.value._img) {

			//image.src gives an absolute path. we want the path delcrae in src attribute.
			let src =  this.uniforms.map.value._img.getAttribute('src');

			if (src in TEXTURE_CACHE) {

				this.uniforms.map.value._img.onload  = null;
				this.uniforms.map.value._img.onerror = null;
				// this.uniforms.map.value.image         = null;

				TEXTURE_CACHE[src].count--;	

				if (TEXTURE_CACHE[src].count <= 0) {					
					TEXTURE_CACHE[src].texture.dispose();
					delete TEXTURE_CACHE[src];
				}

			}

		}


		if (this.props.map != null && this.props.map !== 'null') {

			this.uniforms.map.value = this.createTexture(this.props.map);
			this.defines.USE_PBR_MAP = "";

		}
		else if(this.props.texture === null || this.props.texture !== 'null') {

			this.uniforms.map.value = null;

			delete this.defines.USE_PBR_MAP;

			// if (this.props.map in TEXTURE_CACHE) {
			// 	console.log('CLEAR TEXTURE CACHE', this.props.map, TEXTURE_CACHE)
			// 	TEXTURE_CACHE[this.props.map].count--;
			// 	if (TEXTURE_CACHE[this.props.map].count <= 0) {
			// 		delete TEXTURE_CACHE[this.props.map];
			// 	}
			// }

		}
	}

	onMapOffsetChange() {
		// if (this.uniforms.mapOffset.value.x !==  this.props.mapOffset[0]);
			this.uniforms.mapOffset.value.x = this.props.mapOffset[0];
		// if (this.uniforms.mapOffset.value.y !==  this.props.mapOffset[1]);
			this.uniforms.mapOffset.value.y = this.props.mapOffset[1];
	}

	onMapOpacityChange() {
		if (this.uniforms.mapOpacity.value !== this.props.mapOpacity);
			this.uniforms.mapOpacity.value  = this.props.mapOpacity;
	}

	onTextureChange() {
		if (this.props.texture != null) {
			this.uniforms.map.value = this.props.texture;
			this.uniforms.map.value.needsUpdate = true;
			this.defines.USE_PBR_MAP = "";
			this.defines.USE_PBR_TEXTURE = "";
		}
		else if(this.props.map == null) {
			this.uniforms.map.value = null;
			delete this.defines.USE_PBR_MAP;
		}
	}

	onAlphaChange() {		
		if (this.uniforms.globalAlpha.value !== this.props.alpha);
			this.uniforms.globalAlpha.value = this.props.alpha;
	}

	onAlphaMapChange() {
		if (this.props.alphaMap != null) {
			this.uniforms.alphaMap.value = this.createTexture(this.props.alphaMap, THREE.ClampToEdgeWrapping);
			this.defines.USE_PBR_ALPHAMAP = "";
		}
		else if(this.props.alphaTexture == null) {
			this.uniforms.alphaMap.value = null;
			delete this.defines.USE_PBR_ALPHAMAP;
		}
	}
	onAlphaTextureChange() {
		if (this.props.alphaTexture != null) {
			this.uniforms.alphaMap.value = this.props.alphaTexture;
			this.defines.USE_PBR_ALPHAMAP = "";
		}
		else if(this.props.map == null) {
			this.uniforms.alphaMap.value = null;
			delete this.defines.USE_PBR_ALPHAMAP;
		}
	}

	onRoughnessChange() {
		this.uniforms.roughness.value = this.props.roughness;
	}

	onRoughnessMapChange() {
		this.uniforms.roughnessMap.value = this.props.roughnessMap != null ? this.createTexture(this.props.roughnessMap) : null;
		if (this.props.roughnessMap != null) {
			this.defines.USE_PBR_ROUGHNESSMAP = "";
		}
		else {
			delete this.defines.USE_PBR_ROUGHNESSMAP;
		}
	}

	onMetalnessChange() {
		this.uniforms.metalness.value = this.props.metalness;
	}

	onMetalnessMapChange() {
		this.uniforms.metalnessMap.value = this.props.metalnessMap != null ? this.createTexture(this.props.metalnessMap) : null;
		if (this.props.metalnessMap != null) {
			this.defines.USE_PBR_METALNESSMAP = "";
		}
		else {
			delete this.defines.USE_PBR_METALNESSMAP;
		}
	}

	onDisplacmentMapChange() {
		this.uniforms.displacmentMap.value = this.props.displacmentMap != null ? this.createTexture(this.props.displacmentMap) : null;
		if (this.props.displacmentMap != null) {
			this.defines.USE_PBR_DISPLACMENTMAP = "";
		}
		else {
			delete this.defines.USE_PBR_DISPLACMENTMAP;
		}
	}

	onNormalMapChange() {
		this.uniforms.normalMap.value = this.props.normalMap != null ? this.createTexture(this.props.normalMap) : null;
		if (this.props.normalMap != null) {
			this.defines.USE_PBR_NORMALMAP = "";
		}
		else {
			delete this.defines.USE_PBR_NORMALMAP;
		}
	}

	onNormalScaleChange() {
		if (this.uniforms.normalScale.value.x !== this.props.normalScale[0]);
			this.uniforms.normalScale.value.x = this.props.normalScale[0];
		if (this.uniforms.normalScale.value.y !== this.props.normalScale[1]);
			this.uniforms.normalScale.value.y = this.props.normalScale[1];
	}

	onReflectionMapChange() {
		this.uniforms.reflectionMap.value = this.props.reflectionMap != null ? this.createTexture(this.props.reflectionMap) : null;
		if (this.props.reflectionMap != null) {
			this.defines.USE_PBR_REFLECTIONMAP = "";
		}
		else {
			delete this.defines.USE_PBR_REFLECTIONMAP;
		}
	}

	onViewVectorChange() {
		if (this.uniforms.viewVector.value.x !== this.props.viewVector[0]);
			this.uniforms.viewVector.value.x = this.props.viewVector[0];
		if (this.uniforms.viewVector.value.y !== this.props.viewVector[1]);
			this.uniforms.viewVector.value.y = this.props.viewVector[1];
		if (this.uniforms.viewVector.value.z !== this.props.viewVector[2]);
			this.uniforms.viewVector.value.z = this.props.viewVector[2];
	}

	onGlowChange() {
		if (this.props.useGlow) {
			this.defines.USE_GLOW = "";
		}
		else{
			delete this.defines.USE_GLOW;
		}
	}

	onGlowCChange(){
		if (this.uniforms.c.value.z !== this.props.glowC);
			this.uniforms.c.value = this.props.glowC;
	}

	onGlowPChange(){
		if (this.uniforms.p.value.z !== this.props.glowP);
			this.uniforms.p.value = this.props.glowP;
	}

	onSeparateTranslucencyChange() {
		if (this.uniforms !== void 0) {
			this.uniforms.separateTranslucency.value = this.props.separateTranslucency ? 1 : 0;
		}
	}

	onOverrideMaterial( material, camera ) {
		if (this.uniforms !== void 0
			&& material == 'depth') {
			this.uniforms.useDepth.value = 1;
			this.uniforms.cameraNear.value = camera.near;
			this.uniforms.cameraFar.value  = camera.far;
			this.material.blending = THREE[ 'NormalBlending' ];
			this.material.blendSrc = THREE[ 'SrcAlphaFactor' ];
			this.material.blendDst = THREE[ 'OneMinusSrcAlphaFactor' ];
		}
	}




	onRestoreMaterial() {
		if (this.uniforms !== void 0) {
			this.uniforms.useDepth.value = 0;
			this.material.blending = THREE[ this.props.blending ];
			this.material.blendSrc = THREE[ this.props.blendSrc ];
			this.material.blendDst = THREE[ this.props.blendDst ];
		}
	}



	onDiffusecolorChange() {
		if (this.uniforms.diffuseColor.value.r !==  this.props.color[0]){
			this.uniforms.diffuseColor.value.r = this.props.color[0];
		}
		if (this.uniforms.diffuseColor.value.g !==  this.props.color[1]){
			this.uniforms.diffuseColor.value.g = this.props.color[1];
		}
		if (this.uniforms.diffuseColor.value.b !==  this.props.color[2]){
			this.uniforms.diffuseColor.value.b = this.props.color[2];
		}
	}

	onResolutionChange() {
		if (this.uniforms.resolution.value.x !== this.props.resolution[0])
			this.uniforms.resolution.value.x = this.props.resolution[0];
		if (this.uniforms.resolution.value.y !== this.props.resolution[1])
			this.uniforms.resolution.value.y = this.props.resolution[1];
	}


	update() {

		// if (this.uniforms.displacmentScale.value.x !== this.props.displacmentScale[0]);
		// 	this.uniforms.displacmentScale.value.x = this.props.displacmentScale[0];
		// if (this.uniforms.displacmentScale.value.y !== this.props.displacmentScale[1]);
		// 	this.uniforms.displacmentScale.value.y = this.props.displacmentScale[1];

		// if (this.uniforms.alphaMapOffset.value.x !== this.props.alphaMapOffset[0]);
		// 	this.uniforms.alphaMapOffset.value.x = this.props.alphaMapOffset[0];
		// if (this.uniforms.alphaMapOffset.value.y !== this.props.alphaMapOffset[1]);
		// 	this.uniforms.alphaMapOffset.value.y = this.props.alphaMapOffset[1];


		
		// for (var k in THREE.UniformsLib['lights']) {
		// 	if (this.uniforms[k] === void 0) {
		// 		this.uniforms[k] = THREE.UniformsLib['lights'][k];
		// 		console.log('<<< MISSING LIGHT >>>');
		// 	}
		// }
		

		if (this.props.chroma !== null ) {
			this.uniforms.chromaSensitivity.value = props.chromaSensitivity;
			this.uniforms.chromaSmooth.value      = props.chromaSmooth;
		}
		
		this.uniforms.renderDepth.value = this.props.renderDepth ? 1 : 0;


	}


	addToMesh(){
		var node = this.parentNode;
		while (node) {
			if(node.tagName == "LIGHT-ENGINE-MESH"){
				this.mesh = node.mesh;
				this.mesh.material = this.material;
				this.mesh.material.needsUpdate = true;
				break;
			}
			if(node.tagName == "LIGHT-ENGINE-MATERIAL-MESH"){
				node.material.materials.push( this.material );
				node.material.materials[0].needsUpdate = true;
				break;
			}
			node = node.parentNode;
		}
	}

}

register('light-engine-material', LightEngineMaterialPBR);
