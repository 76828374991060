import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';


class forgetpassword extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }


    onSuccess( data ){

        if(data.body.success){
            if( data.body.redirect.type == 'popin' ){
                EventDispatcher.fire('state:change', ['popin', { url: data.body.redirect.href, "save": true,"size":"small"}] );
            }
            else {
                EventDispatcher.fire('state:change', ['page', { url: data.body.redirect.href }] );
            }
           // EventDispatcher.fire('page:reload');

            //if (data.body.redirect) {
            //    EventDispatcher.fire('state:change', ['popin', {url: data.body.redirect}]);
            //}
            //this.props.errorMessage = '';
        }
        else {
            this.props.errorMessage = data.body.response;
        }
        
    }

    onError(data){
        console.log('error', data.body);
    }

}


register('forget-password', forgetpassword);