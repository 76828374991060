import {Component} from 'light';
import {register} from 'light';

class LeadEvent extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

}

register('lead-event', LeadEvent);
