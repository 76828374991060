import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListGallerySelect extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            addData: {
                type: 'json',
                value: null
            }
        }
    }

    ready() {
    	
    }

    _onSelect(post)  {
        EventDispatcher.fire('select:media', [post]);
        //EventDispatcher.fire('state:change', ['popin:close']);
    }


}

register('list-gallery-select', ListGallerySelect);
