import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class LightUiSelect extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {

        }
    }

    get defaultProps() {
        return {
            value: { type: 'string', value: '' },
            selected: { type: 'bool', value: false },
            index: {type: 'int', value : 0}
        }
    }

    created() {
        this._selectNode = null;
    }

    ready() {
        this.$option = this.querySelector('option');
        this._addToSelect();
    }

    _addToSelect(){
        var node = this.parentNode;
        while(node){
            if(node.tagName == "LIGHT-UI-SELECT"){
                this._selectNode = node;
                this._selectNode.addOption( this );
                //this._selectNode.$select.appendChild( this.$option );
                break;
            }
            node = node.parentNode;
        }
    }

    detached() {
        this._selectNode.removeOption( this );
        //this.$option.parentNode.removeChild( this.$option );
    }
    
}

register('light-ui-select-option', LightUiSelect);