import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';
import request from 'superagent';


const THREE = require('three');

require('./CSS3DRenderer.js');

class MyPanorama extends Component {

    get template(){
        return require('./template');
    }

    get events(){
        return {
            'pointerdown': 'onPointerDown',
         }
    }

    get defaultProps(){
        return {
            img: { type: 'string', value: null },
            autoplay: { type: 'bool', value: true },
            manualControl: { type: 'bool', value: false },
            latitude: 0,
            longitude: 0,

            index: { type: 'int', value: null },
            active: { type: 'bool', value: false, change: 'onActiveChange' }

        }
    }

    onActiveChange(){

        if(this.props.active){
            setTimeout( () => {
                this.resize();
                this.stop = true;
                // this.visible = true;
            }, 40);
        }
        else {
            this.stop = false;
            // this.visible = false;
        }

    }

    created(){
        this.componentReady = false;

        this.animLatitude = 0;
        this.animLongitude = 0;
        this.radius = 10;
        this.fov = 50;

        this.circumference = Math.PI * 100;

        this.bindMethods(['_onPointerMove', '_onPointerUp']);

    }

    attached(){
        this.bindMethods(['toggleVisible'])

        EventDispatcher.on('popin', this.toggleVisible);
        EventDispatcher.on('popin:close', this.toggleVisible);
    }

    toggleVisible() {
        this.stop = (this.stop) ? false : true;
    }

    detached() {
        EventDispatcher.off('popin', this.toggleVisible);
        EventDispatcher.off('popin:close', this.toggleVisible);
        document.removeEventListener(support.pointermove, this._onPointerMove, false);
        document.removeEventListener(support.pointerup, this._onPointerUp, false);
    }

    ready(){
        setTimeout( () => {


            // due to a probleme with content injection, je js-point and js-arc 
            // are added inside the Css3d renderer
            // fix the core
            this.$point = this.querySelectorAll('.js-point')[1];
            this.$arc   = this.querySelectorAll('.js-arc')[1];
            
            if(this.$point == undefined){
                this.$point = this.querySelectorAll('.js-point')[0];
                this.$arc   = this.querySelectorAll('.js-arc')[0];
            }
            
            if(this.$point && this.$arc){

                this.createWEBGLRenderer();
                this.createCSSRenderer();

                this.createCamera();
                this.createSphere();

                this.createCTA();

                this.componentReady = true;

                this.onActiveChange();
            }
            else {
                this.ready();
            }

        }, 100);

        document.addEventListener(support.pointermove, this._onPointerMove, false);
        document.addEventListener(support.pointerup, this._onPointerUp, false);

    }

    createWEBGLRenderer(){
        // WEBGL renderer
        this.renderer = new THREE.WebGLRenderer({
            alpha: false,
            antialias: false,
            canvas: this.querySelector('.js-canvas')
        });

        this.renderer.setSize(this.width, this.height);

        this.scene = new THREE.Scene();
    }

    createCSSRenderer(){
        // CSS3D renderer
        this.renderer2 = new THREE.CSS3DRenderer(this.querySelector('.js-css3DRenderer'), this.querySelector('.js-camera'));
        this.renderer2.domElement.style.position = 'absolute';
        this.renderer2.domElement.style.top = 0;
        this.renderer2.setSize(this.width, this.height);

        // this.appendChild( this.renderer2.domElement );

        this.scene2 = new THREE.Scene();
    }

    createCamera(){
        this.camera = new THREE.PerspectiveCamera(this.fov, this.width / this.height, 1, 10000);
        this.camera.aspect = this.width / this.height;
        this.camera.target = new THREE.Vector3(0, 0, 0);
    }

    createSphere(){
        this.sphere = new THREE.SphereGeometry(100, 100, 40);
        this.sphere.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1));

        this.sphereMaterial = new THREE.MeshBasicMaterial();

        var loader = new THREE.TextureLoader();
        loader.crossOrigin = '';
        this.sphereMaterial.map = loader.load(this.props.img);

        this.sphereMesh = new THREE.Mesh(this.sphere, this.sphereMaterial);
        this.scene.add(this.sphereMesh);
    }

    createCTA(){

        //HTML
        let childrens = this.querySelectorAll('.js-children');

        if(!childrens.length) return;

        this.CTAs = [];

        for( let i = 0, length = childrens.length; i < length; i++ ){

            let cta = new THREE.CSS3DObject(childrens[i]);

            let positions = JSON.parse(childrens[i].getAttribute('position'));

            cta.position.x = positions[0] * this.distToCamera;
            cta.position.y = positions[1] * this.distToCamera;
            cta.position.z = positions[2] * this.distToCamera;

            cta.originPosition = positions;

            cta.lookAt(new THREE.Vector3(0,0,0));

            this.CTAs.push(cta);
            this.scene2.add(cta);
        }

    }

    resize(){
        this.width = this.offsetWidth;
        this.height = this.offsetHeight;

        this.distToCamera = this.height / 2 / Math.tan(Math.PI * this.fov / 360);

        if(this.renderer){
            this.renderer.setSize(this.width, this.height);
        }

        if(this.renderer2){
            this.renderer2.setSize(this.width, this.height);
        }

        if(this.camera){
            this.camera.aspect = this.width / this.height;
        }

        if(this.CTAs){
            for( let i = 0, length = this.CTAs.length; i < length; i++){
                this.CTAs[i].position.x = this.CTAs[i].originPosition[0] * this.distToCamera;
                this.CTAs[i].position.y = this.CTAs[i].originPosition[1] * this.distToCamera;
                this.CTAs[i].position.z = this.CTAs[i].originPosition[2] * this.distToCamera;
            }
        }
    }

    update(){

        if(!this.stop) return;

        if(this.renderer && this.renderer2){

            if(!this.props.manualControl && this.props.autoplay){
                this.props.longitude += 0.05;
            }


            this.animLatitude += ( this.props.latitude - this.animLatitude ) * 0.1;
            this.animLongitude += ( this.props.longitude - this.animLongitude ) * 0.1;
            this.animLatitude = Math.max(-85, Math.min(85, this.animLatitude));

            this.camera.target.x = 500 * Math.sin( THREE.Math.degToRad(90 - this.animLatitude)) * Math.cos(THREE.Math.degToRad(this.animLongitude) );
            this.camera.target.y = 500 * Math.cos( THREE.Math.degToRad(90 - this.animLatitude) );
            this.camera.target.z = 500 * Math.sin( THREE.Math.degToRad(90 - this.animLatitude)) * Math.sin(THREE.Math.degToRad(this.animLongitude) );

            this.$arc.style[support.transform] = 'rotate('+this.animLongitude%360+'deg) '+ support.translateZ;
            this.$point.style[support.transform] = 'translate('+ Math.sin(THREE.Math.degToRad(180 - this.animLongitude))*this.radius +'px, '+ Math.cos(THREE.Math.degToRad(180 - this.animLongitude))*this.radius +'px) '+ support.translateZ;



            this.camera.lookAt(this.camera.target);

            this.renderer.render(this.scene, this.camera);
            this.renderer2.render(this.scene2, this.camera);

        }
    }

    onPointerDown( event ){
        event.preventDefault();

        if(this.timerPointerUp){
            clearTimeout(this.timerPointerUp);
        }

        this.props.manualControl = true;
        this._isPointerDown = true;

        this._touchEvent = support.touch && event.type != 'mousedown' ? (event.touches[0] || event.changedTouches[0]) : event;

        this.savedX = this._touchEvent.pageX;
        this.savedY = this._touchEvent.pageY;

        this.savedLongitude = this.props.longitude;
        this.savedLatitude = this.props.latitude;
    }

    _onPointerMove( event ){

        this._touchEvent = support.touch && event.type != 'mousmove' ? (event.touches[0] || event.changedTouches[0]) : event;

        if(this.props.manualControl && this._isPointerDown){
            this.props.longitude = (this.savedX - this._touchEvent.pageX) * 0.1 + this.savedLongitude;
            this.props.latitude = (this._touchEvent.pageY - this.savedY) * 0.1 + this.savedLatitude;
            if(this.props.latitude > 8.7){
                this.props.latitude = 8.7;
            }
            if(this.props.latitude < -8.7){
                this.props.latitude = -8.7;
            }
        }
    }

    _onPointerUp( event ){
        this._isPointerDown = false;

        this.timerPointerUp = setTimeout( () => {
            this.props.manualControl = false;
        }, 1500)
    }


}

register('my-panorama', MyPanorama);
