import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ScheduleTwo extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-date': 'onDateChange',
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            activeTab: {type: 'int', value: 0},
            errorMessage: {type: 'string', value: '' },

            dateArrowLeft:{type: 'bool', value: true},
            dateArrowRight:{type: 'bool', value: true},
        }
    }

    created() {
        this.componentReady = false;
        this._colSize = 100;
        this._dateScrollLeft = 0;
    }

    resize() {
        if (this.$dateContent) {
            let $cols = this.$dateContent.querySelectorAll('.js-col');
            this._dateColSize = $cols[0].clientWidth;
            this._dateContentScrollWidth = ($cols[0].clientWidth * $cols.length) - this.$dateContent.offsetWidth;
            this._onDateContentScroll()
        }
    }

    _onDateArrowPrevDown(e) {
        this._dateScrollLeft -= this._dateColSize;
        if (this._dateScrollLeft<0) {
            this._dateScrollLeft = 0;
        }
        this._onDateContentScroll();
    }

    _onDateArrowNextDown(e) {
        this._dateScrollLeft += this._dateColSize;
        if (this._dateScrollLeft>this._dateContentScrollWidth) {
            this._dateScrollLeft = this._dateContentScrollWidth;
        }
        this._onDateContentScroll();
    }

    _onDateContentScroll() {

console.log('DATE CONTENT SCROKLL', this._dateScrollLeft, this._dateContentScrollWidth)
        this.$dateInner.style.left = (this._dateScrollLeft * -1) + 'px';

        if (this._dateScrollLeft > 10) {
            this.props.dateArrowLeft = true;
        }
        else {
            this.props.dateArrowLeft = false;
        }

        if (this._dateScrollLeft < this._dateContentScrollWidth - 10 ) {
            this.props.dateArrowRight = true;
        }
        else {
            this.props.dateArrowRight = false;
        }

    }

    onSuccess( data ){
        if(data.body.success){

            if( data.body.redirect.type == 'popin' ){
                EventDispatcher.fire('state:change', ['popin', { url: data.body.redirect.href, "save": true,"size":"small"}] );
            }
            else {
                EventDispatcher.fire('state:change', ['page', { url: data.body.redirect.href }] );
            }

        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }

    onError(data){
        console.log('error', data.body);
    }

    ready(){
        setTimeout( () => {

            this.$date = this.querySelectorAll('.js-date');

            for (let i=0; i<this.props.content.tabs.length; i++) {
                if (this.props.content.tabs[i].selected) {
                    this.props.activeTab = i;
                } 
            }



            this.$dateContent = this.querySelector('.js-date-content');
            this.$dateInner = this.querySelector('.js-date-inner');
            let $cols = this.$dateContent.querySelectorAll('.js-col');
            this._dateColSize = $cols[0].clientWidth;
            this._dateContentScrollWidth = ($cols[0].clientWidth * $cols.length) - this.$dateContent.offsetWidth;
            this._onDateContentScroll();


            if(this.$date && this.$date.length){
                this.componentReady = true;
            }
            else {
                this.ready();
            }
        }, 100);
    }

    onDateChange(event){

        let index = Number(event.currentTarget.getAttribute('data-target'));

        this.props.activeTab   = index;

        // Array.prototype.forEach.call(this.$date, (el, i) => {

        //     let index = el.getAttribute('data-target');

        //     if( index == this.props.activeTab ) {
        //         el.classList.add('is-active');
        //     }
        //     else {
        //         el.classList.remove('is-active');
        //     }

        // });

    }


}

register('schedule-two', ScheduleTwo);
