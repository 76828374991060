import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

import detectWebgl from 'utils/detectWebgl';

var THREE = require('three');

const MIN_SCENE_WIDTH = 1920 / 2.5;
const MIN_SCENE_HEIGHT = 1200 / 2.5;

const MAX_SCENE_WIDTH = 1920 / 1.5;
const MAX_SCENE_HEIGHT = 1200 / 1.5;


class HubScene extends Component {

    get events() {
        return  {

            'pointerenter .js-nav': '_onNavEnter',
            'pointerleave .js-nav': '_onNavLeave',

            'click .js-zoom-in': '_zoomIn',
            'click .js-zoom-out': '_zoomOut',
            'click .js-list': '_showList',
            
            'pointerup .js-filter': '_onFilterDown',
            'pointerup .js-close': '_onCloseHub',
            'pointerup .js-clear-search': '_onClearSearch',
            'pointerup .js-toggle-nav': '_onToggleNav',


            'pointerup .js-scene': '_onScenePointerUp',
            'keyup': 'onKeyUp',
            'click .js-tag':        '_onTagDown',
            // 'click .js-release':        '_releaseAll',
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {

            src: '',
            
            content: { type: 'json', value: null },

            active: {type: 'bool', value: false},

            position: {type: 'arr', value: [0,0]},
            scale: {type: 'arr', value: [1,1]},
            subScale: {type: 'arr', value: [1,1]},
            alpha: {type: 'float', value: 0},
            winWidth: {type: 'float', value: window.innerWidth},
            winHeight: {type: 'float', value: window.innerHeight},
            cameraPosition: {type: 'arr', value: [0,0,1000]},
            cameraTarget: {type: 'arr', value: [0,0,-1000]},
            textureOffset: {type: 'arr', value: [0,0]},
            textureUvs: {type: 'arr', value: [0.1,-0.1,0.9,-0.1,0.1,-0.9,0.9,-0.9]},

            activePosts: {
                type: 'json',
                value: []
            },

            currentPost: {
                title: '',
                type: '',
                author: '',
                tags: []
            },

            posts:          { type: 'arr',    value: null, change: '_onPostChange' },
            data: {type: 'json', value: null},

            showCurrentPost: {type:'bool', value: false},

            showList: {type:'bool', value: false},
            viewMode: 'compact',

            listPosts: {type: 'arr', value: []},

            postTypes: {type: 'json', value: {
                photo:   0,
                live:    0,
                article: 0,
                video:   0
            }},

            selectedTags: {type: 'arr', value: []},
            query: '',
            currentType: 'all',

            isNavOpened: {type: 'bool', value: false},

            webgl: {type: 'bool', value: detectWebgl() },

            sceneZoom: {type: 'arr', value: [1,1]},
        }
    }

    _onTagDown(e) {
        let tagName  = e.currentTarget.getAttribute('name');
        for (let i=0; i<this.props.selectedTags.length; i++ ) {
            if (this.props.selectedTags[i] === tagName) {
                this.props.selectedTags.splice(i,1);
                break;
            }
        }
        this._fireSearch();
    }
    
    _onClearSearch() {
        this.props.query = '';
        this.props.selectedTags.splice(0, this.props.selectedTags.length);
        this.props.currentType = 'all';
        this._fireSearch();
    }

    // _onNewSearch() {
    //     // EventDispatcher.fire('search:new');
    //     // this._onCloseHub();
    // }

    _onToggleNav() {
        this.props.isNavOpened = !this.props.isNavOpened;
    }

    _onFilterDown(e) {
        let type = e.currentTarget.getAttribute('data-type');
        this.props.currentType = type == this.props.currentType ? 'all' : type;
        this._onPostChange();
    }


    // _releaseAll() {

    //     this._pool.splice(0, this._pool.length);
    //     this._tileMeshes.splice(0, this._tileMeshes.length);
    //     this.props.posts.splice(0, this.props.posts.length);
    //     this._validPosts.splice(0, this._validPosts.length);
    //     this.props.activePosts.splice(0, this.props.activePosts.length);

    // }


    _onPostChange() {

        //dont create tiles if hub is not opened
        if (!this.props.active) {
            return;
        }

        this._currentTileIndex = -1;
        this._dragX = 0;
        this._dragY = 0;

        for (let i=0; i<this.props.posts.length; i++) {
            this.props.posts[i].active = false;
        }

        this._pool.splice(0, this._pool.length);
        this._tileMeshes.splice(0, this._tileMeshes.length);
        this._validPosts.splice(0, this._validPosts.length);
        this.props.activePosts.splice(0, this.props.activePosts.length);



            var minPosX = 0;
            var maxPosX = 0;
            var minPosY = 0;
            var maxPosY = 0;

            for (let k in this.props.postTypes) {
                this.props.postTypes[k] = 0;
            }

            for (let i=0; i<this.props.posts.length; i++) {

                this.props.postTypes[ this.props.posts[i].type ]++;

                //if post not matching current filter type, skip it
                if (this.props.currentType !== 'all' && this.props.posts[i].type !== this.props.currentType ) {
                    continue;
                }

                //break reference
                this._validPosts.push( JSON.parse(JSON.stringify(this.props.posts[i])) );

            } 

            //UPDATE THE POST LIST
            this.props.listPosts.splice(0, this.props.listPosts.length);
            Array.prototype.push.apply(this.props.listPosts, this._validPosts);


            var maxVoxelPerLine = Math.sqrt( this._validPosts.length ) * 2;
            var lines = [];
            let posts = [];

            for (let i=0; i<this._validPosts.length; i++) {

                let post = this._validPosts[i];

                let rowIndex = 0;
                let colIndex = 0;
                var hasFound = false;

                for (let lineIndex=0; lineIndex<lines.length; lineIndex++) {

                    if (hasFound) {
                        break;
                    }

                    for (let lineVoxelIndex=0; lineVoxelIndex<maxVoxelPerLine; lineVoxelIndex++) {

                        if (lineVoxelIndex + post.width <= maxVoxelPerLine) {

                            let canFit = true;
                            /**
                             * we loop one all post voxel to check it can fit on the given index
                             * because lineVoxelIndex can be empty but not the follwing one...
                            */
                            for (let w=0;w<post.width;w++) {
                                if (lines[lineIndex][lineVoxelIndex+w] !== void 0 ) {
                                    canFit = false;
                                }
                            }

                            if (canFit) {
                                rowIndex = lineIndex;
                                colIndex = lineVoxelIndex;
                                hasFound = true;
                                break;
                            }

                        }
                    }
                }

                //if cannot fit anywhere, we create a new line
                if (!hasFound) {
                    rowIndex = lines.length;
                    colIndex = 0;
                }

                for (let y=0; y<post.height; y++) {
                    for (let x=0; x<post.width; x++) {
                        lines[rowIndex+y] = lines[rowIndex+y] || [];
                        lines[rowIndex+y][colIndex+x] = 1;
                    }
                }

                post.position[0] = (colIndex+(post.width/2)) * 93;
                post.position[1] = (rowIndex+(post.height/2)) * 93; 
            }


            for (let i=0; i<this._validPosts.length; i++) {
                this._validPosts[i].position[0] -= 93 * (maxVoxelPerLine*0.5);
                this._validPosts[i].position[1] -= 93 * (lines.length*0.5);
                if (this._validPosts[i].position[0] < minPosX) {
                    minPosX = this._validPosts[i].position[0] - (93*0.5*this._validPosts[i].scale[0]);
                }
                if (this._validPosts[i].position[0] > maxPosX) {
                    maxPosX = this._validPosts[i].position[0] + (93*0.5*this._validPosts[i].scale[0]);
                }
                if (this._validPosts[i].position[1] < minPosY) {
                    minPosY = this._validPosts[i].position[1] - (93*0.5*this._validPosts[i].scale[1]);
                }
                if (this._validPosts[i].position[1] > maxPosY) {
                    maxPosY = this._validPosts[i].position[1] + (93*0.5*this._validPosts[i].scale[1]);
                }
            }

            this._minPosX = minPosX;
            this._maxPosX = maxPosX;
            this._minPosY = minPosY;
            this._maxPosY = maxPosY;

    }
    
    _onNavEnter() {
        this._isHoverNav = true;
    }

    _onNavLeave() {
        this._isHoverNav = false;
    }

    _showList() {
        this.props.showList = true;
        this.props.viewMode = 'list';
    }

    _zoomIn() {

        this.props.viewMode = 'grid';
        this.props.showList = false;

        this._sceneHeight   = MIN_SCENE_HEIGHT;
        this._sceneWidth    = MIN_SCENE_WIDTH;

        // this._cameraPostion[2]  = this._sceneHeight / 2 / Math.tan(Math.PI * 75 / 360);

         if (this.props.winWidth/this.props.winHeight < (this._sceneWidth)/(this._sceneHeight)) {
            this._cameraPostion[2] =  (this._sceneHeight) / 2 / Math.tan(Math.PI * 75 / 360);
        } else {
            this._cameraPostion[2] =  ((this._sceneWidth) / (this.props.winWidth/this.props.winHeight)) / 2 / Math.tan(Math.PI * 75 / 360);
        }

        if (this.props.winWidth/this.props.winHeight < (this._sceneWidth)/(this._sceneHeight)) {
            this._sceneZoom = this.props.winHeight / this._sceneHeight;
        }
        else {
            this._sceneZoom = this.props.winWidth / this._sceneWidth;
        }

    }

    _zoomOut() {
        this.props.viewMode = 'compact';
        this.props.showList = false;

        this._sceneHeight   = MAX_SCENE_HEIGHT;
        this._sceneWidth    = MAX_SCENE_WIDTH;

        // this._cameraPostion[2]  = this._sceneHeight / 2 / Math.tan(Math.PI * 75 / 360);

        if (this.props.winWidth/this.props.winHeight < (this._sceneWidth)/(this._sceneHeight)) {
            this._cameraPostion[2] =  (this._sceneHeight) / 2 / Math.tan(Math.PI * 75 / 360);
        } else {
            this._cameraPostion[2] =  ((this._sceneWidth) / (this.props.winWidth/this.props.winHeight)) / 2 / Math.tan(Math.PI * 75 / 360);
        }

        if (this.props.winWidth/this.props.winHeight < (this._sceneWidth)/(this._sceneHeight)) {
            this._sceneZoom = this.props.winHeight / this._sceneHeight;
        }
        else {
            this._sceneZoom = this.props.winWidth / this._sceneWidth;
        }

    }

    created() {

        this._sceneHeight   = MAX_SCENE_HEIGHT;
        this._sceneWidth    = MAX_SCENE_WIDTH;

        this._cameraPostion = [0,0,0];

        this._position = [0,0];
        this._scale     = [1,1];
        this._subScale  = [2,2];
        this._alpha    = 0;

        this._touchEventPageX = 0;
        this._touchEventPageY = 0;
        this._touchEventPageX = 0;
        this._touchEventPageY = 0;
        this._screenRatioPos = {x:0,y:0}
        this._pointerXDown  = 0;
        this._pointerYDown  = 0;
        this._startPointerX = 0;
        this._startPointerY = 0;
        this._pointerX      = 0;
        this._pointerY      = 0;
        this._isPointerDown = false;

        this._dragX = 0;
        this._dragY = 0;
        this._currDragX = 0;
        this._currDragY = 0;
        this._dragXDown = 0;
        this._dragYDown = 0;

        this._currAutoX = 0;
        this._currAutoY = 0;
        this._currLookAtX  = 0
        this._currLookAtY  = 0
        
        this._currTextureX  = 0;
        this._currTextureY  = 0;


        this._pool  = [];
        // this._posts = [];

        this._validPosts = [];

        this._tileMeshes = [];
        this._screenRatioPos = new THREE.Vector2();
        this._raycaster = new THREE.Raycaster(); // create once
        this._currentTileIndex = -1;


        this._minPosX = 0;
        this._minPosY = 0;
        this._maxPosX = 0;
        this._maxPosY = 0;

        this.onPointerDown = this.onPointerDown.bind(this);
        this._onPointerUp = this._onPointerUp.bind(this);
        this.onPointerMove = this.onPointerMove.bind(this);
        this._onCloseHub = this._onCloseHub.bind(this);

        this._sceneZoom = 1;
    }

    ready() {


        this.querySelector('.js-close').addEventListener('click', this._onCloseHub, false);
        document.addEventListener( support.pointerdown, this.onPointerDown, false);
        document.addEventListener( support.pointerup, this._onPointerUp, false);
        document.addEventListener( support.pointermove, this.onPointerMove, false );

        this._zoomOut();

        EventDispatcher.request('hub:store', [(data)=>{

            this.props.posts = data.posts;
            this.props.data  = data;

            /**
             * Bug in Firefox
             */
            requestAnimationFrame(()=>{                
                this._fireSearch();
            });

        }]);

        EventDispatcher.on('hub:open', this._onOpenHub, this);
        EventDispatcher.on('hub:close', this._onCloseHub, this);
        EventDispatcher.on('search', this._onSearch, this);
        // EventDispatcher.on('state:change', this.onStateChange, this);

    }

    _onInputChange(value) {
        this.props.query = value;

        clearTimeout( this._searchTimer );
        this._searchTimer = setTimeout(()=>{
            this._fireSearch();
        },500);
        
    }

    _clearTags(e) {
        for (let i=0; i<this.props.content.tags.length; i++ ) {
            this.props.content.tags[i].active = false;
        }
        this.props.selectedTags.splice(0,this.props.selectedTags.length);
        this._fireSearch();
    }

    _purgeSelectedTags() {
        let tags = [];
        for (let i=0;i<this.props.selectedTags.length;i++) {
            tags.push( this.props.selectedTags[i] );
        }
        return tags;
    }

    _fireSearch() {

            EventDispatcher.fire('search', [{
                tags: this._purgeSelectedTags(),
                keywords: this.props.query,
            }]);

    }

    onKeyUp(e){

        if (!this.props.active) {
            return;
        }

        if(e.keyCode == 13){

            this.props.selectedTags.push(this.props.query);
            this.props.query = '';
            this._fireSearch();
        }

    }


    _onSearch(params) {
        if (params.tags !== void 0) {
            this.props.selectedTags.splice(0, this.props.selectedTags.length);
            Array.prototype.push.apply(this.props.selectedTags, params.tags);
        }
        if (params.keywords !== void 0) {
            this.props.query = params.keywords
        }
    }


    onPointerDown(event){

        if (!this.props.active) {
            return;
        }

        this._touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        // this._touchEventPageX  = this._touchEvent.pageX;
        // this._touchEventPageY  = this._touchEvent.pageY;
        // this._touchEventPageX -= window.pageXOffset || document.documentElement.scrollLeft;
        // this._touchEventPageY -= window.pageYOffset || document.documentElement.scrollTop;

        this._touchEventPageX  = this._touchEvent.clientX;
        this._touchEventPageY  = this._touchEvent.clientY;

        this._screenRatioPos.x = ( this._touchEventPageX  / this.props.winWidth ) * 2 - 1;
        this._screenRatioPos.y = ( (this._touchEventPageY) / this.props.winHeight ) * 2 - 1;

        this._pointerXDown  = this._touchEventPageX;
        this._pointerYDown  = this._touchEventPageY;

        this._startPointerX = 0;
        this._startPointerY = 0;
        this._pointerX      = 0;
        this._pointerY      = 0;
        this._isPointerDown = true;

        this._dragXDown = this._dragX;
        this._dragYDown = this._dragY;

    }

    _onPointerUp(){

        if (!this.props.active) {
            return;
        }

        this._isPointerDown = false;

        this._hasMoved = false;

    }

    _onScenePointerUp() {



        /**
         * Fake click on webgl tile
         */
        if (!this._hasMoved
            && !this._isHoverNav
            && this.props.viewMode !== 'list' ) {

            if (support.touch) {

                let tileIndex = this.getTileIndexFromRay();

                if (this._currentTileIndex !== tileIndex) {
                    this._currentTileIndex = tileIndex;
                    this.updateCurrentPostInfos();
                }
                else {

                    if (this._currentTileIndex !== -1) {
                        let postIndex = this.props.activePosts[ this._currentTileIndex ]._postIndex;
                        // EventDispatcher.fire('state:change', ['page', {url: this._validPosts[ postIndex ].href}]);
                        EventDispatcher.fire('state:change', ['popin', { url: this._validPosts[ postIndex ].href, "save": true, "size":"big"}] );
                    }
                }

            }

            /**
             * NON TOUCH DEVICES
             */
            else {
                if (this._currentTileIndex !== -1) {
                    let postIndex = this.props.activePosts[ this._currentTileIndex ]._postIndex;
                    // EventDispatcher.fire('state:change', ['page', {url: this._validPosts[ postIndex ].href}]);
                    EventDispatcher.fire('state:change', ['popin', { url: this._validPosts[ postIndex ].href, "save": true, "size":"big"}] );
                }
            }

        }

    }

    onPointerMove(event) {

        if (!this.props.active
            || this.props.viewMode == 'list') {
            return;
        }

        event.preventDefault();
        
        this._touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        this._touchEventPageX  = this._touchEvent.clientX;
        this._touchEventPageY  = this._touchEvent.clientY;
        // this._touchEventPageX -= window.pageXOffset || document.documentElement.scrollLeft;
        // this._touchEventPageY -= window.pageYOffset || document.documentElement.scrollTop;
        this._screenRatioPos.x = ( this._touchEventPageX  / this.props.winWidth ) * 2 - 1;
        this._screenRatioPos.y = ( (this._touchEventPageY) / this.props.winHeight ) * 2 - 1;

        this._pointerX = this._startPointerX + (this._pointerXDown - this._touchEventPageX);
        this._pointerY = this._startPointerY + (this._pointerYDown - this._touchEventPageY);

  
        if (this._isPointerDown) {


            this._dragX = this._dragXDown + (this._pointerXDown - this._touchEventPageX);
            this._dragY = this._dragYDown + (this._touchEventPageY - this._pointerYDown);

            //CLAMP TO EDGES
            if (this._dragX - this._XScreenSideWorldPosition < this._minPosX) {
                this._dragX = this._minPosX + this._XScreenSideWorldPosition;
            }
            if (this._dragX + this._XScreenSideWorldPosition > this._maxPosX) {
                this._dragX = this._maxPosX - this._XScreenSideWorldPosition;
            }
            if (this._dragY - this._YScreenSideWorldPosition < this._minPosY) {
                this._dragY = this._minPosY + this._YScreenSideWorldPosition;
            }
            if (this._dragY + this._YScreenSideWorldPosition > this._maxPosY) {
                this._dragY = this._maxPosY - this._YScreenSideWorldPosition;
            }

            if (  Math.abs(this._pointerXDown - this._touchEventPageX) > 3 ) {
                this._hasMoved = true;
                clearTimeout(this._currentPostTimer);
                this._currentTileIndex = -1;
                this.props.showCurrentPost = false;
            }

        }


        if ( this._camera
            && !support.touch
            && !this._isPointerDown
            && !this._isHoverNav) {

            let tileIndex = this.getTileIndexFromRay();

            if (tileIndex === null) {
                clearTimeout(this._currentPostTimer);
                this._currentTileIndex = -1;
                this.props.showCurrentPost = false;
            }
            else {
                if (this._currentTileIndex !== tileIndex) {
                    this._currentTileIndex = tileIndex;
                    this.updateCurrentPostInfos();
                }
            }

        }


    }

    updateCurrentPostInfos() {

        this.props.showCurrentPost = false;

        clearTimeout(this._currentPostTimer);
        this._currentPostTimer = setTimeout(()=>{
            
            let postIndex = this.props.activePosts[ this._currentTileIndex ]._postIndex;

            this.props.currentPost.title   = this._validPosts[ postIndex ].title;
            this.props.currentPost.type    = this._validPosts[ postIndex ].type;
            this.props.currentPost.author  = this._validPosts[ postIndex ].author;

            this.props.currentPost.tags.splice( 0, this.props.currentPost.tags.length );
            Array.prototype.push.apply( this.props.currentPost.tags, this._validPosts[ postIndex ].tags );

            requestAnimationFrame(()=>{
                this.props.showCurrentPost = true;
            });

        }, 300);
    }

    getTileIndexFromRay() {
        this._raycaster.setFromCamera( new THREE.Vector2(this._screenRatioPos.x, this._screenRatioPos.y * -1), this._camera );
        var intersects = this._raycaster.intersectObjects( this._tileMeshes, true );
        if (intersects.length > 0 ) {
            return intersects[0].object._tileIndex;
        }
        return null;
    }


    onCameraReady(el, camera){
        this._camera = camera;
    }


    onTileReady(el, mesh) {
        mesh._tileIndex = Number(el.getAttribute('index'));
        this._tileMeshes.push( mesh );
    }


    detached() {
        EventDispatcher.off('hub:open', this._onOpenHub, this);
        EventDispatcher.off('hub:close', this._onCloseHub, this);
        EventDispatcher.off('search', this._onSearch, this);

        this.querySelector('.js-close').removeEventListener('click', this._onCloseHub, false);
        document.removeEventListener( support.pointerdown, this.onPointerDown, false);
        document.removeEventListener( support.pointerup, this._onPointerUp, false);
        document.removeEventListener( support.pointermove, this.onPointerMove, false );
    }

    resize() {

        this.props.winWidth = window.innerWidth;
        this.props.winHeight = window.innerHeight;

        // this._cameraPostion[2] = this._sceneHeight / 2 / Math.tan(Math.PI * 75 / 360);
        
        if (this.props.winWidth/this.props.winHeight < (this._sceneWidth)/(this._sceneHeight)) {
            this._cameraPostion[2] =  (this._sceneHeight) / 2 / Math.tan(Math.PI * 75 / 360);
        } else {
            this._cameraPostion[2] =  ((this._sceneWidth) / (this.props.winWidth/this.props.winHeight)) / 2 / Math.tan(Math.PI * 75 / 360);
        }

        this.props.cameraPosition[2] = this._cameraPostion[2];

    }

    _onOpenHub(position) {

        this.resize();

        if (position !== void 0) {
            this._position[1]      = position.screenTop;
            this._scale[1]         = position.height / this.props.winHeight;
        }

        this.props.position[1] = this._position[1];
        this.props.scale[1]    = this._scale[1];
        this.props.active      = true;

        this._alpha = 1;

        setTimeout(()=>{
            this._position[1] = 0;
            this._scale[1]    = 1;
            setTimeout(()=>{
                EventDispatcher.fire('scroll:disable');
            }, 1000);
        }, 800);

        this._onPostChange();

    }


    _onCloseHub() {
        console.log("_onCloseHub")
        EventDispatcher.fire('scroll:enable');
        this._alpha = 0;
        setTimeout(()=>{
            this._zoomOut();
            this.props.active = false;
        }, 800);
    }

    update() {

        if (!this.props.active) {
            return;
        }

        this.props.sceneZoom[0] += (this._sceneZoom - this.props.sceneZoom[0]) * 0.1;
        this.props.sceneZoom[1] = this.props.sceneZoom[0];

        this._currDragX += (this._dragX - this._currDragX) * 0.1;
        this._currDragY += (this._dragY - this._currDragY) * 0.1;

        this._currAutoX += ((this._screenRatioPos.x*100)  - this._currAutoX) * 0.005;
        this._currAutoY += ((this._screenRatioPos.y*-100) - this._currAutoY) * 0.005;

        this._currLookAtX += ((this._screenRatioPos.x*50)  - this._currLookAtX) * 0.05;
        this._currLookAtY += ((this._screenRatioPos.y*50) - this._currLookAtY) * 0.05;
        this._currTextureX += ((this._screenRatioPos.x*-0.1)  - this._currTextureX) * 0.05;
        this._currTextureY += ((this._screenRatioPos.y*-0.1) - this._currTextureY) * 0.05;

        this.props.position[1] += (this._position[1] - this.props.position[1] ) * 0.1;

        this.props.scale[1]    += (this._scale[1] - this.props.scale[1] ) * 0.1;
        this.props.subScale[1] = this.props.winHeight / (this.props.winHeight*this.props.scale[1]);


        if (this.props.position[1] < 0.1) {
            this.props.position[1] = 0;
        }

        if (this.props.scale[1] > 0.99) {
            this.props.scale[1] = 1;
            this.props.subScale[1] = 1;
        }

        this.props.alpha += (this._alpha - this.props.alpha ) * 0.1;

        this.props.cameraPosition[0] += ( (this._currLookAtX + this._currDragX + this._currAutoX) - this.props.cameraPosition[0]) * 0.1;
        this.props.cameraPosition[1] += ( (this._currLookAtY + this._currDragY + this._currAutoY) - this.props.cameraPosition[1]) * 0.1;
        this.props.cameraPosition[2] += (this._cameraPostion[2] - this.props.cameraPosition[2]) * 0.1;
        
        this.props.cameraTarget[0] += ( (this._currDragX + this._currAutoX) - this.props.cameraTarget[0]) * 0.1;
        this.props.cameraTarget[1] += ( (this._currDragY + this._currAutoY) - this.props.cameraTarget[1]) * 0.1;

        this.props.textureOffset[0] += ( (this._currTextureX) - this.props.textureOffset[0]) *  0.1;
        this.props.textureOffset[1] += ( (this._currTextureY) - this.props.textureOffset[1]) *  0.1;



        if (this._camera !== void 0) {

            var camPos = new THREE.Vector3();
            camPos.x = this._camera.position.x;
            camPos.y = this._camera.position.y;
            camPos.z = this._camera.position.z;

            var vectorNegative = new THREE.Vector3();
            vectorNegative.set(-1, -1, 0.5);
            vectorNegative.unproject( this._camera );
            var dirNegative = vectorNegative.sub( camPos ).normalize();
            var distanceNegative = - camPos.z / dirNegative.z;
            var posNegative = camPos.clone().add( dirNegative.multiplyScalar( distanceNegative ) );


            var vectorCenter = new THREE.Vector3();
            vectorCenter.set(0, 0, 0.5);
            vectorCenter.unproject( this._camera );
            var dirCenter = vectorCenter.sub( camPos ).normalize();
            var distanceCenter = - camPos.z / dirCenter.z;
            var posCenter = camPos.clone().add( dirCenter.multiplyScalar( distanceCenter ) );

            var deltaX = posCenter.x - posNegative.x;
            var deltaY = posCenter.y - posNegative.y;

            this._XScreenSideWorldPosition = Math.sqrt(deltaX*deltaX);
            this._YScreenSideWorldPosition = Math.sqrt(deltaY*deltaY);

        }


        let numOfCompute = 0;

        if (this._validPosts !== null) {

            for (let i=0; i<this._validPosts.length; i++) {

                // this._validPosts[i].position[0] = this._validPosts[i].position[0] + this._currDragX;
                // this._validPosts[i].position[1] = this._validPosts[i].position[1] + this._currDragY;
                
                let posX = this._validPosts[i].position[0] - this._currDragX;
                let posY = this._validPosts[i].position[1] - this._currDragY;

                let dist = (posX*posX)+(posY*posY);
                
                if ( Math.sqrt(dist) < (this._sceneHeight*.7) ) {

                    if (!this._validPosts[i].active) {

                        this._validPosts[i].active = true;

                        if (this._pool.length == 0) {

                            this.props.activePosts.push({
                                position: [0,0,0],
                                scale: [1,1,1],
                                alpha: 0,
                                width: this._validPosts[i].width,
                                height: this._validPosts[i].height,
                                image: this._validPosts[i].image,
                                _tileIndex: this.props.activePosts.length,
                                _postIndex: i
                            });

                            this._validPosts[i]._tileIndex = this.props.activePosts.length - 1;
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].scale[0]    = this._validPosts[i].scale[0];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].scale[1]    = this._validPosts[i].scale[1];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].position[0] = this._validPosts[i].position[0];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].position[1] = this._validPosts[i].position[1];
                        }
                        else {

                            this._validPosts[i]._tileIndex = this._pool[ this._pool.length-1 ];
                            this._pool.pop();
                            this.props.activePosts[ this._validPosts[i]._tileIndex ]._postIndex  = i;
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].width  = this._validPosts[i].width;
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].height = this._validPosts[i].height;
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].image  = this._validPosts[i].image;
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].scale[0] = this._validPosts[i].scale[0];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].scale[1] = this._validPosts[i].scale[1];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].position[0] = this._validPosts[i].position[0];
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].position[1] = this._validPosts[i].position[1];

                        }

                    }
                    else {

                        if ( this.props.activePosts[ this._validPosts[i]._tileIndex ] !== void 0) {

                            numOfCompute++;

                            if (1-(Math.sqrt(dist) / (this._sceneHeight*.5)) > 0.3) {

                                if (this._currentTileIndex == -1) {
                                    this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha = 0.9;
                                }
                                else {
                                    if (this.props.activePosts[ this._validPosts[i]._tileIndex ]._tileIndex == this._currentTileIndex) {
                                        this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha = 1;
                                    }
                                    else {
                                        this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha = 0.4;
                                    }
                                }

                            }
                            else {
                                if (this.props.activePosts[ this._validPosts[i]._tileIndex ]._tileIndex == this._currentTileIndex) {
                                    this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha = 1;
                                }
                                else {
                                    this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha = 0.1;
                                }
                            }

                            
                            this.props.activePosts[ this._validPosts[i]._tileIndex ].alpha += ((this.props.activePosts[ this._validPosts[i]._tileIndex ]._alpha) - this.props.activePosts[ this._validPosts[i]._tileIndex ].alpha) * 0.1;
                            
                            // this.props.activePosts[ this._validPosts[i]._tileIndex ].position[0] = this._validPosts[i].position[0];
                            // this.props.activePosts[ this._validPosts[i]._tileIndex ].position[1] = this._validPosts[i].position[1];
                            // this.props.activePosts[ this._validPosts[i]._tileIndex ].alpha = this._validPosts[i].alpha;

                        }

                    }

                }
                else {
                    
                    if (this._validPosts[i].active 
                        && this.props.activePosts[ this._validPosts[i]._tileIndex ] !== void 0) {

                        this.props.activePosts[ this._validPosts[i]._tileIndex ].alpha       = 0;
                        this.props.activePosts[ this._validPosts[i]._tileIndex ].position[0] = -1000;
                        this.props.activePosts[ this._validPosts[i]._tileIndex ].position[1] = -1000;
                        this.props.activePosts[ this._validPosts[i]._tileIndex ].image       = null;
     
                        this._pool.push( this._validPosts[i]._tileIndex );
                        this._validPosts[i].active = false;
                        this._validPosts[i]._tileIndex = null;

                    }

                }

            }


        }



    }

    _onSuccess(data) {


    }

    _onError(data){

    }


}

register('hub-scene', HubScene);
