import {Component} from "light";
import {register} from "light";

class AppSlideshow extends Component {

    get events(){
        return{
            'pointerdown .js-next':  '_onNext',
            'pointerdown .js-prev':  '_onPrev',
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
        	active:        { type: "bool", value: false },
            currentIndex:  { type: "int", value: -1     },
            maxIndex:      { type: "int", value: 0      },
            controls:      { type: "bool", value: true  },
            onItemsChange: { type: "fn", value: function(){}  },
        }
    }

    ready(){

    }

    _onNext() {
        if (this.props.currentIndex < this.props.maxIndex) {
            this.props.currentIndex++;
        }
    }

    _onPrev() {
        if (this.props.currentIndex > 0) {
            this.props.currentIndex--;
        }
    }

    _onSelectedIndexChange(index, total) {

    }

    _onItemsChange(items) {
        this.props.maxIndex = items.length - 1;
        this.props.onItemsChange(items);
    }

}



register("app-slideshow", AppSlideshow);