import {Component} from 'light';
import {register} from 'light';

class TileExhibitor extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'mouseover .js-exhibitor': '_onTitleRollOver',
            'mouseout .js-exhibitor': '_onTitleRollOut'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    _onTitleRollOver(event){          
        this.querySelector('.js-title').classList.remove('u-hide');
    }

    _onTitleRollOut(event){                
        this.querySelector('.js-title').classList.add('u-hide');
    }

}

register('tile-exhibitor', TileExhibitor);
