import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminNavigation extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-burger': 'onToggleNav',
            'click .js-overlay': 'onCloseNav',
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
        }
    }

    created(){
        this._navOpen = false;
    }

    attached(){

    }

    ready(){

    }

    onToggleNav(){

        if( !this._navOpen ){
            this._navOpen = true;
            EventDispatcher.fire( 'scroll:disable');
            this.classList.add('is-menu-open');
        }
        else {
            this.onCloseNav();
        }
    }

    onCloseNav(){
        this._navOpen = false;
        EventDispatcher.fire( 'scroll:enable');
        this.classList.remove('is-menu-open');
    }
}

register('admin-navigation', AdminNavigation);
