import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class MyDropdown extends Component {

    get events(){
        return {
            'click .js-dropdown': 'onClick'
        }
    }

    get defaultProps(){
        return {
            active: { type: 'bool', value: false, chnage: 'onActiveChange' }
        }
    }

    created(){
        this._active = false;
    }

    ready(){              
        this.onActiveChange();
    }

    onActiveChange(){
        if(this.props.active){            
            this._active = true;
            this.classList.add('is-active');
        }
        else {            
            this._active = false;
            this.classList.remove('is-active');
        }
    }

    onClick(){            
       //if(!this._active){
       //     this._active = true;
       //     this.classList.add('is-active');
       // }
       // else {
       //     this._active = false;
       //     this.classList.remove('is-active');
        // }
        this.classList.toggle('is-active');
    }


}

register('my-dropdown', MyDropdown);
