import {Component} from 'light';
import {register} from 'light';

class BusinessCard extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .jsvideocall': '_onVideoCall',
            'click .jsaudiocall': '_onAudioCall',
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    _onVideoCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),button.currentTarget.getAttribute('scheduleid'),false);
    }

    _onAudioCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),button.currentTarget.getAttribute('scheduleid'),true);
    }
}

register('business-card', BusinessCard);
