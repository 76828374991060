import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';
import {utils} from 'light';

class CoreSlider extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown': 'onSliderDown',
            'pointermove': 'onPointerMove',
            'pointerup': 'onPointerUp'
        }
    }

    get defaultProps() {
        return {
            value: { value: 0, change: 'onUpdateValue' },
            min: 0,
            max: 1,
            invert: { type: 'bool',  value: false },
            orientation: 'horizontal',
            onSlide: { type: 'func', value: function(){} },
            onStop: { type: 'func', value: function(){} },
            active: { type: 'bool', value: false, change: 'onActiveChange' }
        }
    }

    attached() {

        this.range               = this.props.max - this.props.min;
        this.sizeProp            = this.props.orientation === "horizontal" ? 'width' : 'height';
        this.handlerSizeProp     = this.props.orientation === "horizontal" ? 'handlerWidth' : 'handlerHeight';
        this.offsetProp          = this.props.orientation === "horizontal" ? 'x' : 'y';
        this.handlerPositionProp = this.props.orientation === "horizontal" ? 'x' : 'y';
        this.offset              = { x: 0, y: 0 };
        this.handler             = { x: 0, y: 0 };
    }

    ready() {
        this.$handler = this.querySelector('.js-handler');
    }

    onActiveChange(){
        if(this.props.active){
            this.resize();
        }
    }

    onSliderDown( event ) {
        this.isPointerDown = true;


        var pageX = support.touch ? (event.touches[0].pageX || event.changedTouches[0].pageX ) : event.clientX;
        var pageY = support.touch ? (event.touches[0].pageY || event.changedTouches[0].pageY ) : event.clientY;

        this.track( pageX, pageY );
    }

    onPointerMove( event ) {

        if( !this.isPointerDown ){
            return;
        }
        if(event){
            event.preventDefault();
        }
        var pageX = support.touch ? (event.touches[0].pageX || event.changedTouches[0].pageX ) : event.clientX;
        var pageY = support.touch ? (event.touches[0].pageY || event.changedTouches[0].pageY ) : event.clientY;

        this.track( pageX, pageY );

    }

    onPointerUp(){

        if( !this.isPointerDown ){
            return;
        }

        this.isPointerDown = false;
        this.props.onStop( this.props.value );

    }

    track( pageX, pageY ) {

        var n = this.props.orientation === "horizontal" ? pageX : pageY;
        var q = this.props.invert ? ( this.offset[ this.offsetProp ] + this[ this.sizeProp ] - n ) : ( n - this.offset[ this.offsetProp ] );

        if( q >= 0 && q <= this[ this.sizeProp ] ){
            this.handler[ this.handlerPositionProp ] = q;
            this.props.value = this.props.min + (( this.range/ this[ this.sizeProp ] ) * q );

            this.props.onSlide( this.props.value );
        }

    }

    resize() {

        this.height = this.clientHeight;
        this.width  = this.clientWidth;

        this.hanlderWidth  = this.$handler.clientWidth;
        this.hanlderHeight = this.$handler.clientHeight;

        this.hanlderHalfWidth  = this.hanlderWidth >> 1;
        this.hanlderHalfHeight = this.hanlderHeight >> 1;

        // TODO : offset.top + document.body.scrollTop
        var offset = this.getBoundingClientRect();

        this.offset.x = 0;
        this.offset.y = 0;

        let el = this;

        while( el ){
            if(el.offsetLeft){
                this.offset.x += el.offsetLeft;
                this.offset.y += el.offsetTop;

            }

            el = el.parentNode;
        }

    }

    onUpdateValue() {
        this.range = this.props.max - this.props.min;
        this.handler[ this.handlerPositionProp ] = ( (this.props.value-this.props.min) / this.range) * this[this.sizeProp];
    }

    update() {
        if( this.$handler && !isNaN( this.handler.x - this.hanlderHalfWidth ) ){
            this.$handler.style[support.transform] = 'translate('+ this.handler.x +'px, '+ this.handler.y +'px) ' + support.translateZ;
        }
    }

}

register('core-slider', CoreSlider);
