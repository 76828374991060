import {Component} from 'light';
import {register} from 'light';

class ListNetwork extends Component {

     get events() {
        return {

        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            data:    { type: 'json',   value: null },

            onAdd: {type:'fn', value: function(){}},
            onDelete: {type:'fn', value: function(){}}
        }
    }

    _onListReady(data) {
        this.props.data = data;
    }

    _onAdd(post){
        this.props.onAdd(post);
    }

    _onDelete(post){
        this.props.onDelete(post.content);
        console.log('ON DELETE NETWORK', post.content)


    }

}

register('list-network', ListNetwork);
