import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminNotifications extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            "change .js-input-setting": 'onInputChange',
        }
    }

    get defaultProps(){
        return {

            content: { type: 'json', value: null },

            params: {type: 'json', value: {
                settings:[]
            }},

            forceReload: { type: 'bool', value: false },

        }
    }

    onInputChange(e){

        let index   = Number(e.currentTarget.getAttribute('index'));
        let checked = e.currentTarget.checked;
        let inputs  = this.props.content.sidebar.form.inputs;
        let newSetting = [];
            
        this._savedSettings = [];

        for (let i=0; i<inputs.length; i++) {
            
            this._savedSettings[i] = JSON.parse(JSON.stringify(inputs[i]) );

            if (i == index) {
                inputs[i].checked = checked;
            }

            if (inputs[i].checked) {
                newSetting.push( inputs[i].name );
            }
        }

        this.props.params.settings = newSetting;

    }

   _onSettingSuccess( data ){
        if( data.body.success ){
            this.props.forceReload = true;
            requestAnimationFrame(()=>{
                this.props.forceReload = false;
            });
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this._onSettingError(data);
        }
    }

    _onSettingError( data ){
        console.log('Error in ajax form');
        let inputs = this.props.content.sidebar.form.inputs;
        for (let i=0; i< this._savedSettings.length; i++) {
            inputs[i].checked = this._savedSettings[i].checked;
            //TODO auto bind the checked attribute for inputs to avoid DOM manipulation...
            this.querySelector('#f-input-' + inputs[i].name).checked = inputs[i].checked;
        }
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

}

register('admin-notifications', AdminNotifications);
