import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

import page from 'page';

class XApplication extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {

            width:  window.innerWidth,
            height: window.innerHeight,

            baseDataUrl: 'data',
            baseUrl: '',

            specialRoutes: {type:'arr', value: null},

            globalDataUrl: null,
            globalData: { type: 'json', value: null },
            reloadGlobalData: { type: 'bool', value: false },

            defaultRoute: '/home',
            routeOptions: { type: 'json', value: null },

            displayHeader: { type: 'bool', value: false },
            displayFooter: { type: 'bool', value: false },

            dataUrl: null,
            popinDataUrl: null,
            dataIsLoaded: { type: 'bool', value: false },

            componentId: null,
            hasComponentId: { type: 'bool', value: false },
            areas: { type: 'json', value: null },
            pageData: { type: 'json', value: null },

            popinSize: { type: 'bool', value: false },
            savePopinUrl: { type: 'bool', value: false },
            isPopinReady: { type: 'bool', value: false },
            showPopin: { type: 'bool', value: false },
            popinData: { type: 'json', value: null },
            popinComponentId: null,
            hasPopinComponentId: { type: 'bool', value: false },
            // popinResponseDisplay: { type: 'bool', value: false },
            // dataPopinResponse: { type: 'json', value: null },

            manifest: { type: 'json', value: null },
            loaderDisplay: { type: 'bool', value: true },
            resetLoader: { type: 'bool', value: true },

            headers: { type: 'json', value: {} },

            showNotification: { type: 'bool', value: false },
            notificationContent: { type: 'json', value: {
                title: null,
                body: null
            }},

            showConfirm: { type: 'bool', value: false },
            confirmContent: { type: 'json', value: {
                title: null,
                body: null,
                confirmLabel: 'Ok',
                cancelLabel: 'cancel'
            }},

            showGrid: { type: 'bool', value: false },

            state: {type: 'json', value: {
                url: '',
                queryParams: {},
                path: {}
            }},

            user: {type: 'json', value: {
                logged: false,
                admin: false,
                name: null,
                avatar: null
            }},
            reloadLoginStatus: { type: 'bool', value: false },

            token: null,

            isSearching: {type:'bool', value: false},


            bookmarkParams: { type: 'json', value: {
                id: null,
                typeid: null
            }},

            reloadBookmark: { type: 'json', value: false },

            routes: {
                "/": {
                    "componentId": "pageGenerator",
                },

                "/404": {
                    "componentId": "p404",
                },

                "/:page/*": {
                    "componentId": "pageGenerator",
                },

                "/:page": {
                    "componentId": "pageGenerator",
                }
            },


            pageParams: {type:'json', value: null},
            location: {type:'string', value:''}

        }
    }


    _onKeyUp(e){
        // if(e.keyCode == 72){
        //     this.props.showGrid = !this.props.showGrid;
        // }
    }

    created(){

        this._displayHeader       = false;
        this._displayFooter       = false;

        this._componentId         = null;
        this._pageData            = null;

        this._popinComponentId    = null;
        this._popinData           = null;

        this.$body = document.getElementsByTagName("body")[0];
        this.props.icons = require('./icons');

        this.props.routeOptions = {
            hashbang: false,
            click: true
        };

        if (window.environment == 'dev') {
            this.bindMethods(['_onKeyUp']);
            document.addEventListener('keyup', this._onKeyUp, false);
        }

        EventDispatcher.setHandler('token:get', ()=>{
            return this.props.token;
        });

    }

    attached(){

        EventDispatcher.on('loader:end', this.onLoaderEnd, this);
        EventDispatcher.on('page:ready', this.onPageReady, this);
        EventDispatcher.on('page:redirect', this.onPageRedirect, this);

        // EventDispatcher.on( 'popin', this.openPopin.bind(this) );
        // EventDispatcher.on( 'popin:close', this.closePopin, this );
        // EventDispatcher.on( 'popinResponse:open', this.openPopinResponse.bind(this) );
        // EventDispatcher.on( 'popinResponse:close', this.closePopinResponse.bind(this) );

        EventDispatcher.on('scroll:enable', this.enableScroll.bind(this) );
        EventDispatcher.on('scroll:disable', this.disableScroll.bind(this) );
        EventDispatcher.on('pointer:none', this.disablePointer.bind(this) );
        EventDispatcher.on('pointer:auto', this.enablePointer.bind(this) );
        EventDispatcher.on('token:set', this._onSetToken.bind(this));
        EventDispatcher.on('bookmark', this._onBookmark.bind(this));


        EventDispatcher.on('hub:open', this._onOpenHub, this);

        EventDispatcher.on('page:reload', this.onForceReload.bind(this));

        EventDispatcher.setHandler('popinstate', ()=>{
            return this.props.showPopin;
        });

        EventDispatcher.on('notify', this._onNotify.bind(this) );
        EventDispatcher.on('confirm', this._onConfirm, this );

        EventDispatcher.on('state:change', this.onStateChange, this);
    }


    _onBookmark(post) {
        console.log('_onBookmark',post);
        this._bookmarkCallback = post.callback;
        if (this.props.bookmarkParams.id == post.id) {
            this.props.reloadBookmark = true;
            requestAnimationFrame(()=>{
                this.props.reloadBookmark = false;
            });
        }
        else {
            this.props.bookmarkParams.id = post.id;
            this.props.bookmarkParams.typeid = post.typeid;
        }
    }
    _onBookmarkSuccess(resp) {
        if (this._bookmarkCallback !== void 0) {
            this._bookmarkCallback(resp);
        }
        EventDispatcher.fire('bookmark:success');
    }
    _onBookmarkError(resp) {
        if (this._bookmarkCallback !== void 0) {
            this._bookmarkCallback(resp);   
        }
    }



    onRouteChange( location, oldLocation ){

        console.log('-------------\nONROUTECHANGE\n-------------')
        
        this.props.location = location;

        if(this.props.showPopin || this.props.isSearching){
            return;
        }

EventDispatcher.fire('state:change', [location, oldLocation]);

if( (!this.forceReload && location.path == oldLocation.path) ){
    
            // TODO: do something when new location is egal to old location
            EventDispatcher.fire('query:change');
            return;
            
        }
        else {            

            // RESET
            this.forceReload = false;
            this.pageReady = false;
            this.props.dataIsLoaded = false;
            this.props.popinDisplay = false;

            if(this.props.manifest){
                this.props.manifest.splice(0, this.props.manifest.length);
            }

            if( !this.props.loaderDisplay ){
                this.showLoader();
            }

            requestAnimationFrame(()=>{

                let baseUrl = this.props.baseDataUrl;

                for (let i=0;i<this.props.specialRoutes.length; i++) {
                    if (location.path === this.props.specialRoutes[i] ) {
                        baseUrl = '';
                    }
                }

                this.props.dataUrl = (location.path == '/') ? baseUrl + 'home' : baseUrl + (location.completePath).replace(/^\//,'');

                if(this.getParameter('modal')){
                    let state = {};
                    state.url = this.getParameter('modal');
                    state.size = this.getParameter('size');
                    this.openPopin(state);
                }
            });            
        }
        
    }

    getParameter(param) { 
      const params = window.location.search.substr(1).split('&');
     
      for (let i = 0; i < params.length; i++) {
        let p=params[i].split('=');
        if (p[0] == param) {
          return decodeURIComponent(p[1]);
        }
      }
      return false;
    }

    onStateChange(state, params){

        if (state === 'popin') {
            this.openPopin(params); 
        }
        else if(state === 'popin:close'){
            this.closePopin();
        }
        else if(state === 'hub:open' || state === 'hub'){
            EventDispatcher.fire('hub:open');
        }
        else if(state === 'hub:close'){
            EventDispatcher.fire('hub:close');
        }
        else if(state === 'page'){
            this.onPageChange(params);
        }
        else {
            EventDispatcher.fire('hub:close');
        }
    }

    _onOpenHub() {
        this.$body.classList.add('is-hub-opened');
    }

    _onCloseHub() {
        this.$body.classList.remove('is-hub-opened');
    }

    _onNotify(content) {
        this.props.notificationContent.title = content.title;
        this.props.notificationContent.body = content.body;
        this.props.showNotification = true;
        clearTimeout(this._notifyTimer);
        this._notifyTimer = setTimeout(()=>{
            this._onNotifyClose();
        }, 3000);
    }

    _onNotifyClose() {
        clearTimeout(this._notifyTimer);
        this.props.showNotification = false;
    }

    _onConfirm(content) {
        this.props.confirmContent.title = content.title;
        this.props.confirmContent.body = content.body;
        this.props.confirmContent.confirmLabel = content.confirmLabel;
        this.props.confirmContent.cancelLabel = content.cancelLabel;
        this.props.showConfirm = true;
        this._confirmValidateCallback = content.onConfirm;
        this._confirmCancelCallback = content.onCancel;
    }

    _onConfirmValidate()  {
        this.props.showConfirm = false;
        this._confirmValidateCallback();
    }

    _onConfirmCancel() {
        this.props.showConfirm = false;
        this._confirmCancelCallback();
    }

    ready(){
        window.scrollTo(0,0);       
    }

    resize(){
        this.props.width  = window.innerWidth;
        this.props.height = window.innerHeight;
    }

    onGlobalDataLoaded( data ) {
        this.props.globalData = data.body;
        this._getLoginStatus();
    }

    onPopinDataLoaded( data ){

        if( data.body == null ){
            this.onPopinDataError();
            return;
        }

        if (data.body.redirect) {
            EventDispatcher.fire('state:change', ['page', {url: data.body.redirect}]);
            return;
        }

        this._getLoginStatus();

        this.props.popinData           = data.body.areas;
        this.props.popinComponentId    = data.body.componentId;
        this.props.hasPopinComponentId = true;
        this.props.isPopinReady        = true;

    }

    onPopinDataError( error ){
        EventDispatcher.fire('state:change', ['page', {
            url: "/404",
            type: "popin"
        }]);
    }

    _getLoginStatus(data) {
        this.props.reloadLoginStatus = true;
        requestAnimationFrame(()=>{
            this.props.reloadLoginStatus = false;
        });
    }

    _onLoginStatusSucess(data) {
        
        if (data.body.response.logged !== this.props.user.logged) {
            this.props.reloadGlobalData = true;
            requestAnimationFrame(()=>{
                this.props.reloadGlobalData = false;
            });
        }

        this.props.user.logged = data.body.response.logged;
        this.props.user.name   = data.body.response.name;
        this.props.user.avatar = data.body.response.avatar;

    }

    _onLoginStatusError() {

    }

    onDataLoaded( data ){

        if( data.body == null ){
            this.onDataError();
            return;
        }

        if (data.body.redirect) {
            EventDispatcher.fire('state:change', ['page', {url: data.body.redirect}]);
            return;
        }

        this._getLoginStatus();
     
        this.props.manifest     = data.body.manifest;
        this.props.dataIsLoaded = true;

        this._pageData          = data.body.content;
        this._areas             = data.body.areas;
        this._componentId       = data.body.componentId;
        this.createComponent();        
    }
    
    onDataError( error ){
        console.log('DATA EROR');
        EventDispatcher.fire('state:change', ['page', {url: "/404"}]);
    }

    /**
     * POPIN BEHAVIOUR
     */
    openPopin(state){

console.log("openPopin", state);

        this.props.showPopin   = true;
        this.props.isSearching = false;

        // if( oldState && state.url == oldState.url ){
        //     this.props.isPopinReady = true;
        //      this.props.hasPopinComponentId = false;
        // }
        // else {
            //this.props.popinDataUrl = this.props.baseDataUrl + state.url.replace(/^\//, '') + (state.query ? '?'+state.query : '');

       // }

        let baseUrl = this.props.baseDataUrl;

        for (let i=0;i<this.props.specialRoutes.length; i++) {
            if (state.url === this.props.specialRoutes[i] ) {
                baseUrl = '';
            }
        }

        this.props.popinDataUrl = baseUrl + (state.url).replace(/^\//,'') + (state.query ? '?'+state.query : '');

        this.props.popinSize = state.size !== void 0 ? state.size : 'large';

        if (state.save) {
            //EventDispatcher.fire('query:change', [{modal:state.url + (state.query ? '?'+state.query : '') , size: this.props.popinSize} ]);
            page(this.props.location.path + '?modal=' + state.url.replace(/^\//, '') + '&size='+state.size);
        }

        this.disableScroll();
    }

    closePopin(){

        console.log('CLOSE_POPIN')
        page(this.props.location.path);

        this.props.showPopin    = false;
        this.props.isPopinReady = false;

        this.props.hasPopinComponentId = false;
        this.props.popinDataUrl = null;

        this.enableScroll();
        //EventDispatcher.fire('query:change', [{modal: false,size:false}]);

        EventDispatcher.fire('popinclose');                       
    }

    onQueryChange(queryParams) {

    }

    onPageChange(state, oldState) {

        this.props.isSearching = false;

        if(this.props.showPopin){
            this.closePopin();
        }


        page(state.url);

        // if (state.queryParams && state.queryParams.modal) {
            
        //     EventDispatcher.fire('state:change', ['popin', {
        //         url: state.queryParams.modal,
        //         size: state.queryParams.size,
        //         query: state.query,
        //         state: state.queryParams
        //     }]);
        
        // }   
        // else {
        //     if (this.props.showPopin) {
        //         this.closePopin();
        //     }
        // }

        // if (state.url == '' || state.url == '/') {
        //     state.url = this.props.defaultRoute;
        // }

        // if( oldState 
        //     && state.url == oldState.url ){
        //     // TODO: do something when new location is egal to old location
        //     //EventDispatcher.fire('query:change');
        //     return;
        // }
        // else {

        //     // RESET
        //     this.forceReload        = false;
        //     this.pageReady          = false;
        //     this.props.dataIsLoaded = false;

        //     if(this.props.manifest){
        //         this.props.manifest.splice(0, this.props.manifest.length);
        //     }

        //     if( !this.props.loaderDisplay ){
        //         this.showLoader();
        //     }

        //     setTimeout( () => {
        //         this.props.dataUrl = this.props.baseDataUrl + state.url.replace(/^\//, '') + (state.query ? '?'+state.query : '');
        //     }, 0);

        // }

    }

    createComponent(){

        /* Create Component :
         * Only when the loader is display and the new data are loaded
         */
        if (this.props.loaderDisplay
            && this.props.dataIsLoaded) {

            this.props.displayHeader   = this._displayHeader;
            this.props.displayFooter   = this._displayFooter;

            this.props.pageData        = this._pageData;
            this.props.areas           = this._areas;
            this.props.componentId     = this._componentId;

            requestAnimationFrame( () => {
                this.props.hasComponentId = true;
                this.hideLoader();               
            });

        }
    }

    showLoader(){

        clearTimeout(this._hideLoaderTimer);

        this.loaderComplete = false;
        this.props.resetLoader = true;

        this.disableScroll();
        this.$body.classList.remove('is-loaded-end');
        this.$body.classList.remove('is-loaded');

        /* 800 = time of css transition
         * for show the loader
         */
       // setTimeout( () => {
            this.$body.classList.add('is-loading');
            this.props.loaderDisplay = true;
            this.props.hasComponentId = false;
            this.createComponent();
       // }, 800);

    }

    onPageReady(){
        //if( !this.props.showPopin){
            // page is ready
            this.pageReady = true;
            this.hideLoader();

            EventDispatcher.fire('menu:change', [this.props.location.path]);
        //}
    }

    onLoaderEnd(){
        //if( !this.props.showPopin ){
            // loader is complete
            this.loaderComplete = true;
            this.hideLoader();
        //}
    }

    hideLoader(){

        if(this.loaderComplete && this.pageReady){

            clearTimeout(this._hideLoaderTimer);
            this._hideLoaderTimer = setTimeout(()=>{

                this.enableScroll();
                this.$body.classList.remove('is-loading');
                this.$body.classList.add('is-loaded');
                this.props.loaderDisplay = false;
                EventDispatcher.fire('page:loaded');
                //setTimeout( () => {
                    this.props.resetLoader = false; // for data-binding
                    this.$body.classList.add('is-loaded-end');
                //}, 800);

            },0);

        }

    }

    // openPopinResponse( params ){
    //     this.props.popinResponseDisplay = true;
    //     this.props.dataPopinResponse = params.data;
    //     this.disableScroll();
    // }

    // closePopinResponse(){
    //     this.props.popinResponseDisplay = false;
    //     this.enableScroll();
    // }

    onPageRedirect( params ){
        page(params.url);
    }


    _onSetToken( token ){
        this.props.token = token;
        // this.props.headers = { 'Token':  token };
        // this.context.headers = this.props.headers;
    }


    onForceReload(){
        console.log('FORCE RELOAD')
        this.forceReload = true;
        this.props.dataUrl = '';
        requestAnimationFrame(()=>{
            this.onRouteChange(this.props.location, this.props.location)
        });
    }

    enableScroll(){
        this.$body.classList.remove('is-disable-scroll');
    }

    disableScroll(){
        this.$body.classList.add('is-disable-scroll');
    }

    disablePointer(){
        // console.log('disablePointer');
        this.$body.classList.add('u-force-pointer-none');
        this.$body.classList.add('u-disable-selection');
    }

    enablePointer(){
        // console.log('enablePointer');
        this.$body.classList.remove('u-force-pointer-none');
        this.$body.classList.remove('u-disable-selection');
    }

}

register('x-application', XApplication);
