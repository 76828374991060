import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListTags extends Component {


    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content:   { type: 'json', value: null },
            data:      { type: 'json', value: null },
            onAdd:     { type: "fn",   value: function(){} },
            onDelete:  { type: "fn",   value: function(){} },
        }
    }

    _onAdd(post){
        this.props.onAdd(post);
    }

    _onDelete(post){
        this.props.onDelete(post);
    }

    _onListReady(data) {
        this.props.data = data;
    }

}

register('list-tags', ListTags);