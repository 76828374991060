import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ScheduleError extends Component {

	get events() {
		return {
			'pointerdown .js-cancel': '_onCancelDown'
		}
	}

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },

            deleteParams: { type: 'json', value: {
                id: null,
            }},

            forceReload: { type: 'bool', value: false },

            errorMessage: {type: 'string', value: '' }

        }
    }

    created() {
    	this.bindMethods(["_onCancelDelete", "_onConfirmDelete"]);
    }

    ready(){
        EventDispatcher.on('popinclose', this.closePopin, this);        
    }

    closePopin(){        
        EventDispatcher.off('popinclose', this.closePopin, this);        
        EventDispatcher.fire('page:reload');
    }

	_onCancelDown(event) {
        this._idToDelete = event.currentTarget.getAttribute('data-id');
        EventDispatcher.fire('confirm', [{
            title: 'Are you sure?',
            body: 'This action is irreversible, your call will be canceled',
            confirmLabel: "cancel call",
            cancelLabel: "no",
            onConfirm: this._onConfirmDelete,
            onCancel:  this._onCancelDelete
        }])
    }

    _onCancelDelete() {
        this._idToDelete = null;
    }

    _onConfirmDelete() {
    	console.log('_onConfirmDelete', this._idToDelete);
        this.props.deleteParams.id = this._idToDelete;
        this._idToDelete = null;
    }

    _onDeleteSuccess() {
    	EventDispatcher.fire('state:change', ['popin:close']);
    	EventDispatcher.fire('notify', [{
			title: 'Success',
			body:'call canceled'
		}]);
    }

	_onDeleteError() {
		EventDispatcher.fire('notify', [{
			title: 'Error',
			body:'An error occured. Your call hasn\'t been canceled'
		}]);
    }
}

register('schedule-error', ScheduleError);
