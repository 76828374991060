import {Component} from 'light';
import {register} from 'light';

class ListExhibitors extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content:   { type: 'json', value: null },
            data:      { type: 'json', value: null },
        }
    }

    _onListReady(data) {
        this.props.data = data;
        console.log(data);
    }

    // Datas from <app-finder>
    _updateList(data) {
        console.log(data);
        // TODO
    }
}

register('list-exhibitors', ListExhibitors);
