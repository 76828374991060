import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class LightUiSelect extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            'change .js-select': 'onValueChange'
        }
    }

    get defaultProps() {
        return {
            name: { type: 'string', value: '' },
            selectClass: { type: 'string', value: '' },
            onValueChange: { type: 'fn', value: function() { return; } }
        }
    }

    created() {
        this._options = [];
    }

    ready() {
        this.$select = this.querySelector('.js-select');
        for (let i=0; i<this._options.length; i++) {
            if (!this._options[i]._isAppended) {
                this.$select.appendChild( this._options[i].$option );
                this._options[i]._isAppended = true;
            }
        }
    }

    onValueChange( event ){
        this.currentValue = event.currentTarget.value;
        this.props.onValueChange({currentTarget: this.$select}, this.currentValue );
    }

    addOption(node) {
        this._options.push( node );
        this._updateChildNodes();
        if (this.$select) {
            this.$select.appendChild( node.$option );
            node._isAppended = true;
        }
    }

    removeOption(node) {
        this._options.splice(node.props.index, 1);
        this._updateChildNodes();
        if (this.$select) {
            for (let i=0; i<this._options.length; i++) {
                if (this._options[i].$option.parentNode) {
                    this._options[i].$option.parentNode.removeChild( this._options[i].$option );
                }
            }
        }
    }

    _updateChildNodes() {
        for (let i=0; i<this._options.length; i++) {
            this._options[i].props.index = i;
        }
    }

}

register('light-ui-select', LightUiSelect);