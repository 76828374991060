import {register} from "light";
import {Component} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');
import './CSS3DRenderer';

class LightEngineSceneCSS3D extends Component {

	get template() {
		return require('./template');
	}
	
	get defaultProps(){
		return {
			width:  { type: 'float',  value: window.innerWidth , change: 'resize' },
			height: { type: 'float',  value: window.innerHeight, change: 'resize' },
			update: { type: 'bool',   value: true}
		}
	}

	created(){
		this.scene    = new THREE.Scene();
		this.frustum  = new THREE.Frustum();
	}

	ready(){
		this.$domElement    = this.querySelector('.js-dom-element');
		this.$cameraElement = this.querySelector('.js-camera-element');
		this.renderer       = new THREE.CSS3DRenderer(this.$domElement, this.$cameraElement);
	}

	resize(){
		this.renderer.setSize(this.props.width, this.props.height);
	}

	update(){

		if( !this.props.update ){
			return;
		}

		if (this.renderer
			&& this.camera) {
			this.renderer.render(this.scene, this.camera);

			this.frustum.setFromMatrix( new THREE.Matrix4().multiplyMatrices( this.camera.projectionMatrix, this.camera.matrixWorldInverse ) );
		}
	}

	inFrustrum(object){
		return this.frustum.intersectsObject(object);
	}
	
	add(node){
		this.scene.add(node);
	}

}

register('light-engine-scene-css', LightEngineSceneCSS3D);