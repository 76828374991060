import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

var UUID = 0;

class CoreVideo extends Component {

    get template() {
        return require('./template');
    }


    get events(){
        return {
            'click .js-video-big-play': 'togglePlay',
            'click .js-video-mute': 'toggleSound',
            'click .js-video-play': 'togglePlay',
            'click .js-video-fullscreen': 'toggleFullScreen',

            'pointermove': 'toggleControls'
        }
    }

    get defaultProps() {
        return {
            useNative: { type: 'bool', value: true },

            src : { type: 'string', value: null, change: 'onSrcChange' },
            poster: { type: 'string', value: '' },
            wmode: { type: 'string', value: 'transparent' },

            active: { type: 'bool', value: true, change: 'onActiveChange' },
            forceStop: { type: 'bool', value: false, change: 'onForceStop' },
            controls: { type: 'bool',  value: true },
            // autoHideControls: { type: 'bool', value: false },
            autoplay: { type: 'bool', value: 0 },
            mute: { type: 'bool', value: false },
            loop: { type: 'bool', value: false },
            showinfo: { type: 'bool', value: false },
            rel: { type: 'int', value: 0 },
            uuid:  { type: 'string', value: 'yt-' + UUID },
            forceQuality: { type: 'bool', value: false },
            callback: { type: 'func', value: function(){} },

            timelineValue: { type: 'float', value: null },
            onEnded: { type: 'fn', value: function(){ return; } }
        }
    }

    created(){

        UUID++;

        this.paused = null;
        this.enabled = true;

        this.classList.add('c-video-player');

        // init values

        this.YTplayer         = null;
        this.paused           = true;
        this.muted            = false;
        this.seeksliding      = false;
        this.duration         = 0;
        this.currentTime      = 0;
        this.isFullScreen     = false;
        this.progressTimer    = null;
        this.isFirstPlay      = true;
        this.mouseMoveTimer   = null;
        this.isControlsHidden = false;

    }

    disable(){
        this.enabled = false;

        this.pause();

        if( document.getElementById( this.props.uuid + '-video' ) ){
            document.getElementById( this.props.uuid + '-video' ).style.display = "none";
        }
    }

    enable(){
        this.enabled = true;

        if (this.props.autoplay) {
            this.play();
        }

        if (document.getElementById(this.props.uuid + '-video') ) {
            document.getElementById(this.props.uuid + '-video').style.display = "block";
        }
    }

    ready() {

        if (this.props.src === null) {
            console.warn("Sorry, props.src key must be specified");
            return;
        }

        if (support.requestFullScreen) {
            this.classList.add( 'is-support-fullscreen' );
        }

        setTimeout( () => {

            if(this.props.useNative){
                this.HTMLplayer = this.querySelector('.js-native-video');

                if(this.HTMLplayer){
                    this.onSrcChange();
                }
                else {
                    this.ready();
                }
            }
            else {
                this.onSrcChange();
            }

        }, 10);

    }

    resize(){
        if( support.ipad ){
            this.classList.add( 'is-ipad' );
        }

        if( support.iphone ){
            this.classList.add( 'is-iphone' );
        }

        if( support.touch ){
            this.classList.add( 'is-touch');
        }
    }

    onSrcChange(){

        if(this.props.src !== null ){
            if( !this.props.useNative ){
                this._createYTPlayer = this.createYTPlayer.bind(this);
                ready( this._createYTPlayer );
            }
            else {
                this.createHTML5Player();
                this.classList.add('is-native');
            }
        }

    }

    onSliderSlide( ratio ) {
        this.seeksliding = true;
        this.stopProgress();
    }

    onSliderStop( ratio ) {
        this.seeksliding = false;
        this.seek( ratio * this.duration );
        this.startProgress();
    }

    createYTPlayer() {

        this.YTplayer = new YT.Player( this.props.uuid + '-video', {
            height: '100%',
            width: '100%',
            videoId: this.props.src,
            playerVars: {
                wmode: this.props.wmode,
                controls: 0,
                showinfo: this.props.showinfo ? 1 : 0,
                autoplay: 0,
                loop: 0,
                rel: this.props.rel,
                playlist: this.props.src,
                modestbranding: 1,
                html5: 1
            },
            events: {
                onReady: this.onPlayerReady.bind(this),
                onStateChange: this.onPlayerStateChange.bind(this),
                onError: this.onPlayerError.bind(this)
            }
        });
    }

    createHTML5Player() {
        this.HTMLplayer.addEventListener('loadedmetadata', this.onPlayerReady.bind(this), false);
        this.HTMLplayer.addEventListener('play', this.onPlayerStateChange.bind(this), false);
        this.HTMLplayer.addEventListener('pause', this.onPlayerStateChange.bind(this), false);
        this.HTMLplayer.addEventListener('ended', this.onPlayerStateChange.bind(this), false);
        this.HTMLplayer.addEventListener('progress', this.onPlayerStateChange.bind(this), false);
        this.HTMLplayer.addEventListener('error', this.onPlayerError.bind(this), false);

    }

    onPlayerReady() {

        this.classList.add('is-ready');
        this.duration = this.getDuration();
        this.addListenners();
        this.props.callback(this);
        this.fire('ready');

        if( (support.touch && support.smallscreen) || support.ipad ){

        }
        else {
            if( this.props.autoplay && this.paused !== false && this.enabled ){
                this.play();
            }
        }

    }

    onPlayerError( e ) {
        console.warn('ERROR VIDEO:', e);
        this.fire('error');
    }

    onPlayerStateChange( event ) {

        if( this.ischangingQuality ){
            return
        }

        if( this.props.forceQuality && !this.props.useNative && event.target.getPlaybackQuality() !== this.props.forceQuality ){

            if ( event.data == YT.PlayerState.PLAYING ) {

                this.ischangingQuality = true;
                event.target.stopVideo();
                event.target.setPlaybackQuality( this.props.forceQuality );  // <-- WORKS!
                event.target.playVideo();
                this.ischangingQuality = false;
            }
        }

        if( this.props.mute == true ){
            this.mute();
        }

        if( event.type == "ended" ||  event.data == 0 ){ // ended
            this.onPlayerEnded();
        }
        if( event.type == "play" || event.data == 1 ){ // play
            this.classList.remove('is-loading');
            this.onPlayerPlay();
        }
        if( event.type == "pause" || event.data == 2 ){ // pause
            this.onPlayerPause();
        }
        if( event.type == "progress"){ //buffer
            this.onPlayerBuffer();
        }

    }

    onPlayerBuffer() {
        this.fire('buffer');
        this.classList.add('is-loading');
    }

    addListenners() {

        if( !this.props.controls ){
            return;
        }

        EventDispatcher.on('key:escape', this.cancelFullScreen, this);

        //show/hide controls on muser activity
        // if( this.props.autoHideControls ){
            // EventDispatcher.on('pointermove', this.toggleControls, this);
        // }

    }

    toggleControls() {
        clearTimeout(this.mouseMoveTimer);

        if( this.isControlsHidden ){
            this.isControlsHidden = false;
            this.classList.remove('is-no-controls');
        }

        this.mouseMoveTimer = setTimeout( () =>{
            this.isControlsHidden = true;
            this.classList.add('is-no-controls');
        }, 2000);
    }

    toggleSound( e ) {
        if( this.muted ){
            this.unmute();
        } else {
            this.mute();
        }
    }

    onPlayerPlay( node ){

        this.classList.add('is-playing');
        this.classList.remove('is-paused');

        this.paused = false;
        this.startProgress();

        if( this.props.controls ){
            this.toggleControls();
        }

        if( this.isFirstPlay ){
            this.classList.add('has-started');
            this.isFirstPlay = false;
            this.duration = this.getDuration();
        }

        this.fire('play');

    }

    onPlayerPause() {
        this.classList.remove('is-playing');
        this.classList.add('is-paused');
        this.paused = true;
        this.stopProgress();
        this.fire('pause');
    }

    onPlayerEnded() {
        this.fire('ended');
        this.props.onEnded();

        setTimeout( () => {
            this.onPlayerPause();
        }, 40)
    }

    togglePlay() {
        this[ this.paused ? 'play' : 'pause' ]();
    }

    toggleFullScreen() {
        if( !this.isFullScreen ){
            this.requestFullScreen();
        } else {
            this.cancelFullScreen();
        }
    }

    requestFullScreen () {

        this.isFullScreen = true;
        this.isPausedBeforeFullScreen = this.paused;

        this.pause();
        this.classList.add('is-fullscreen');

        EventDispatcher.fire('video:enterfullscreen');

        var node = this;
        while( node.parentNode && node.parentNode.getAttribute){
            node.parentNode.classList.add('is-branch')
            node = node.parentNode;
        }

        if( support.requestFullScreen ){
            this[ support.requestFullScreen ]();
            document.addEventListener(support.fullScreenChange, this.onFullScrenChange.bind(this) );
        } else {
            setTimeout( () => {
                if( this.isPausedBeforeFullScreen == true ){
                    this.pause();
                } else {
                    this.play();
                }
            }, 0);
        }

        document.getElementsByTagName('body')[0].classList.add( 'is-fullscreen' );

    }

    onFullScrenChange() {

        if( !document[ support.isFullScreen] ){
            document.removeEventListener(support.fullScreenChange , this.onFullScrenChange.bind(this) );

            if( this.isFullScreen ){
                this.cancelFullScreen();
            }
        }

        if( this.isPausedBeforeFullScreen == true ){
            this.pause();
        } else {
            this.play();
        }

    }

    cancelFullScreen(){

        this.isFullScreen = false;
        this.isPausedBeforeFullScreen = this.paused;

        this.pause();
        this.classList.remove('is-fullscreen');

        var node = this;
        while( node.parentNode && node.parentNode.getAttribute){
            node.parentNode.classList.remove( 'is-branch' );
            node = node.parentNode;
        }

        if( support.cancelFullScreen ){
            document[ support.cancelFullScreen ]();
        } else {
            setTimeout( () => {
                if( this.isPausedBeforeFullScreen == true ){
                    this.pause();
                } else {
                    this.play();
                }
            }, 0);
        }

        document.getElementsByTagName('body')[0].classList.remove( 'is-fullscreen' );

        EventDispatcher.fire('video:leavefullscreen');

    }

    play() {
        if( this.props.useNative ){
            if( this.HTMLplayer ){
                this.HTMLplayer.play();
            }
        } else {
            if( this.YTplayer && typeof this.YTplayer.playVideo != "undefined" ){
                this.YTplayer.playVideo();
            }
        }
    }

    pause(){
        if( this.paused ){
            return;
        }

        if( this.props.useNative ){
            if( this.HTMLplayer ){
                this.stopProgress();
                this.HTMLplayer.pause();
            }
        }
        else {
            if( this.YTplayer && typeof this.YTplayer.pauseVideo != "undefined" ){
                this.stopProgress();
                this.YTplayer.pauseVideo();
            }
        }
    }

    onActiveChange(){
        if(this.props.active){
            // this.play();
        }
        else {
            this.pause();
        }
    }

    onForceStop(){
        if(this.props.forceStop){
            this.seek(0, true);

            setTimeout( () => {
                this.pause();
            }, 4);
        }
    }

    onSeekChange(){
        this.seek(this.props.seekTo);
    }

    changeSrc( id ){
        if(typeof this.YTplayer !== 'undefined'){
            this.YTplayer.loadVideoById(id);

            this.YTplayer.playVideo();
            return false;
        }
    }

    changePoster( poster ) {
        this.props.poster = poster;
    }

    unmute() {
        if( this.props.useNative ){
            if( this.HTMLplayer ){
                this.HTMLplayer.muted = false;
                this.muted = false;
                this.classList.remove('is-muted');
            }
        }
        else {
            if( this.YTplayer && typeof this.YTplayer.unMute != "undefined" ){
                this.muted = false;
                this.classList.remove('is-muted');
                this.YTplayer.unMute();
            }
        }
    }

    mute() {
        if( this.props.useNative ){
            if( this.HTMLplayer ){
                this.HTMLplayer.muted = true;
                this.muted = true;
                this.classList.add('is-muted');
            }
        }
        else {
            if( this.YTplayer && typeof this.YTplayer.mute != "undefined" ){
                this.muted = true;
                this.YTplayer.mute();
                this.classList.add('is-muted');
            }
        }
    }

    seek( targetTime, pause ) {
        if( this.props.useNative ){
            if( this.HTMLplayer ){
                this.HTMLplayer.currentTime = targetTime;
            }
        }
        else {
            if( this.YTplayer && typeof this.YTplayer.seekTo != "undefined" ){
                this.YTplayer.pauseVideo();
                this.YTplayer.seekTo(targetTime, true);

                if(!pause){
                    this.YTplayer.playVideo();
                }
            }
        }
    }

    startProgress() {
        clearTimeout( this.progressTimer );
        this.updateTime();
    }

    stopProgress() {
        clearTimeout(this.progressTimer);
        this.progressTimer = null;
    }

    updateTime( e, time ) {

        this.currentTime = this.getCurrentTime();

        if( this.props.controls ){
            this.props.timelineValue = this.currentTime/this.duration;
        }

        this.progressTimer = setTimeout( () => {
            this.updateTime();
        }, 100);

        if( this.props.loop && this.currentTime >= this.duration - 0.5 ){
            this.seek(0);
        }

    }

    setTimeCount() {
        var curr = this.formatTime( this.currentTime * 1000 );
        var dur  = this.formatTime( this.duration * 1000 ) ;
        // TODO: data binding
        // this.$timecount.innerHTML = curr + '<span> / ' + dur + '</span>';
    }

    formatTime( ms ) {

        var min = (ms / 1000 / 60) << 0,
            sec = ((ms / 1000) % 60) << 0,
            ret = "";
        ret += min < 10 ? "0" : "";
        ret += min + ":";
        ret += sec < 10 ? "0" : "";
        ret += sec;

        return ret;
    }

    getCurrentTime () {
        if( this.props.useNative ){
            return this.HTMLplayer.currentTime;
        } else {
            return this.YTplayer && typeof this.YTplayer.getCurrentTime != "undefined" ? this.YTplayer.getCurrentTime() : 0;
        }
    }

    getDuration(){
        if( this.props.useNative ){
            return this.HTMLplayer.duration;
        } else {
            return this.YTplayer && typeof this.YTplayer.getDuration != "undefined" ? this.YTplayer.getDuration() : 0;
        }
    }

    destroy(){

        // EventDispatcher.off('pointermove', this.toggleControls );
        EventDispatcher.off('key:escape', this.cancelFullScreen, this );

        if( this.YTplayer ){
            this.pause();
            this.YTplayer.destroy();
            // delete YoutubeCustomPlayer.players[ this.props.uuid ];
        }
    }
}

var isReady = false;
var readyList = [];

function ready( callback ){
    if( isReady ){
        callback();
    } else {
        readyList.push( callback );
    }
};

function init(){
    isReady = true;

    for( var i=0,l=readyList.length; i<l; i++ ){
        readyList[i]();
        delete readyList[i];
    }
};


window.onYouTubeIframeAPIReady = function(){
    init();
};


/** load YT API */
var tag = document.createElement('script');
tag.src = "//youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


register('core-video', CoreVideo);

