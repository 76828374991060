import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class MySlide extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            move: { type: 'bool', value: true },
            culling: { type: 'bool', value: false },
            duration: { type: 'float', value: 1200 }
        }
    }

    detached() {
       // console.log('<my-slide> detached');
    }

    onIndexChange(index, slideshowIndex){

      //  console.log('<my-slide> onIndexChange', index, slideshowIndex, this);

        this._index = index;

        this.onSlideChange(slideshowIndex);

        setTimeout( () => {
            this.classList.add('is-ready');
        }, 100);
    }

    onSlideChange(slideshowIndex){

        // console.log('slide-'+this._index, (this.props.culling && ((this._index >= this._slideshowIndex && this._index <= slideshowIndex) || (this._index >= slideshowIndex && this._index <= this._slideshowIndex))) );

        // culling
        if( this.props.culling &&
            ((this._index >= this._slideshowIndex && this._index <= slideshowIndex)
            || (this._index >= slideshowIndex && this._index <= this._slideshowIndex)) ){
            this.style.display = 'block';
        }

        this._slideshowIndex = slideshowIndex;

        if(this._index == this._slideshowIndex){
            this.classList.remove('is-next');
            this.classList.remove('is-prev');
            this.classList.add('is-active');

            this.props.active = true;
        }
        else {
            this.classList.remove('is-active');

            this.props.active = false;

            if(this._slideshowIndex > this._index){
                this.classList.add('is-prev');
            }
            else {
                this.classList.add('is-next');
            }
        }

        if(this.props.move){

            setTimeout( () => {
                this.style[support.transform] = 'translateX('+ (this._index - (this._slideshowIndex)) * 100 +'%) ' + support.translateZ;
            }, 40);

            // reset culling
            if(this.props.culling){
                setTimeout( () => {
                    if(this._index != this._slideshowIndex){
                        this.style.display = 'none';
                    }
                }, this.props.duration + 20);
            }

        }
    }

}

register('my-slide', MySlide);

