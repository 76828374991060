import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class AppNotification extends Component {

    get events() {
        return {
            "pointerdown": '_close',
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps() {
        return {
            active:  { type: 'bool', value: false},
            content: { type: 'json', value: {
                title: null,
                body: null
            }},
            onClose: {type: 'fn', value: function(){} }
        }
    }

    _close() {
        clearTimeout( this.autoCloseTimer );
        this.props.onClose();

        console.log('NOTIF CLOSe');
    }

    ready() {
        
    }    
}


register('app-notification', AppNotification);