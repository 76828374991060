import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AddTags extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'change .js-input': 'onInputChange',
            'keydown .js-input': 'onInputChange',
            'keypress .js-input': 'onInputChange',
            'cut .js-input': 'onInputChange',
            'copy .js-input': 'onInputChange',
            'paste .js-input': 'onInputChange',
            'input .js-input': 'onInputChange',

            'click .js-tag-remove': 'onRemoveTag',
            'click .js-add-tag': 'onAddTag'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' },
            submitForm: { type: 'bool', value: false },
            results: { type: 'array', value: [] }
        }
    }

    onInputChange( event ){
        this.props.submitForm = false;
        clearTimeout(this.inputChangetimer);

        if( event.currentTarget.value.length > 2 ){
            this.inputChangetimer = setTimeout( () => {
                this.props.submitForm = true;
            }, 300);
        }
        else {
            this.props.results.splice(0, this.props.results.length);
        }

    }

    onInstantSearchSuccess( resp ){
        if(resp.body.success){
            this.props.results.splice(0, this.props.results.length);

            requestAnimationFrame( () => {
                Array.prototype.push.apply(this.props.results, resp.body.data.list);
            });

        }
    }

    onRemoveTag( event ){

        let value = event.currentTarget.getAttribute('data-value');
        let index = -1;

        for( let i = 0, l = this.props.content.form.tags.length; i < l; i++ ){
            if( value == this.props.content.form.tags[i].value ){
                index = i;
            }
        }

        if(index != -1){
            this.props.content.form.tags.splice(index, 1);
        }

    }

    onAddTag( event ){
         let value = event.currentTarget.getAttribute('data-value');
         let tag = null;

         for( let i = 0, l = this.props.results.length; i < l; i++ ){
            if( value == this.props.results[i].value ){
                tag = this.props.results[i];
            }
        }

        for( let i = 0, l = this.props.content.form.tags.length; i < l; i++ ){
            if( tag.value == this.props.content.form.tags[i].value ){
                return;
            }
        }

        this.props.content.form.tags.push(tag);
    }

    onInstantSearchError(){
        console.log('Error in ajax request');
    }

    onSuccess( data ){
         if(data.body.success){
            EventDispatcher.fire('reload:force');

            setTimeout( () => {
                console.log('reload notification page', data.body.url);
                //page(data.body.url);
            }, 40);
        }
        else {
            this.props.errorMessage = data.body.response.desc;
        }
    }

    onError(data){
        console.log('error', data.body);
    }

}

register('add-tags', AddTags);
