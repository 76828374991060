import {register} from "light";
import {Component} from "light";

class LightCoreSelector extends Component {

    get defaultProps(){
        return {
            activeIndex:   { type: 'int',  value: 0, change: 'onActiveIndexChange' },
            onSelectedIndexChange: { type: 'func', value: function(){ return; } },            
            onItemsChange: { type: 'func', value: function(){ return; } },
            items: { type: 'array', value: [] }
        }
    }

    ready(){
        this.onActiveIndexChange();
    }

    onActiveIndexChange(){

        if(!this.props.items){
            return;
        }

        for( let i = 0, l = this.props.items.length; i < l; i++){
            this.props.items[i].props.selectedIndex = this.props.activeIndex;
        }

        this.props.onSelectedIndexChange(this.props.activeIndex, this.props.items.length);

    }

    addItem( item, index ){

        item.props.selectedIndex = this.props.activeIndex;

        if( index ){
            this.props.items[index] = item;
        }
        else {
            item.props.index = this.props.items.length;
            this.props.items.push(item);
        }

        this.onActiveIndexChange();
        this.props.onItemsChange(this.props.items);

    }

    removeItem( item, index ){

        this.props.items.splice(index, 1);

        for( let i=0, l = this.props.items.length; i < l; i++){
            this.props.items[i].props.index = i;
        }

        this.onActiveIndexChange();
        this.props.onItemsChange(this.props.items);

    }


}

register('light-core-selector', LightCoreSelector);