import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";
import {support} from "light";

// import threejs from "three-js";
// const THREE = threejs();


console.log('import light engine scene');


var THREE = require('three');

import template from './template';

var renderer = new THREE.WebGLRenderer({
	antialias: true,
	alpha: true
});

var UUID = 0;

class LightEngineScene extends Component {

	get styles() {
		return require('./styles.css');
	}

	get events () {
		return {
			'pointerdown': '_onPointerDown',
			'pointermove': '_onPointerMove',
			'pointerup': '_onPointerUp'
		}
	}

	get template() {
		return template;
	}
	
	get defaultProps() {

		return {

			width:          { type: 'float',  value: window.innerWidth , change: 'resize' },
			height:         { type: 'float',  value: window.innerHeight, change: 'resize' },
			clearColor:     { type: "array",  value: [0,0,0]  },
			clearOpacity:   { type: 'float',  value: 1        },
			antialias:      { type: 'bool',   value: true     },
			shadowMap:      { type: 'bool',   value: false    },
			softShadow:     { type: 'bool',   value: false    },
			pixelRatio:     { type: 'float',  value: 1        },

			alpha: {type: 'bool',  value: false},

			cameraNear:     { type: 'float',  value: 0 },
			cameraFar:     { type: 'float',  value: 0 },

			renderToScreen: { type: 'bool', value: false },
			renderTarget:   { type: 'json', value: null, change: '_onRenderTargetChange' },

			update: {type: 'bool', value: true},

			preserveDrawingBuffer: {type: 'bool', value: false },

			editor: {type: 'bool', value: false},
			isToolBarOpened: {type: 'bool', value: true},
			selectedObjectPosition: {type: 'arr', value: [0,0,0]},
			selectedObjectScale: {type: 'arr', value: [0,0,0]},
			cameraPositionZ: { type: 'float',  value: 1 },

			cameras: {type:"arr", value: []}

		}
	}

	_onEditorCameraReady(node, camera) {
		console.log('_onEditorCameraReady', node);
	}


	addCamera(camera) {

		for (let i=0; i<this.props.cameras.length; i++) {
			this.props.cameras[i]._parentNode.disable();
		}

		this.props.cameras.push( camera );
		this.camera = camera;


		if (this.props.editor) {
			if (camera._parentNode.getAttribute('id') !== 'perspective') {
				camera._helper = new THREE.CameraHelper(camera);
				this.scene.add( camera._helper );
			}
		}

	}

	_setCamera(event) {

		for (let i=0; i<this.props.cameras.length; i++) {
			if (this.props.cameras[i]._parentNode.getAttribute('id') == 'perspective') {
				this.props.cameras[i]._parentNode.disable();
			}
		}

		let index = Number( event.currentTarget.getAttribute('index') );
		this.camera = this.props.cameras[index];
		this.camera._parentNode.enable();	

	}

	_onToolbarChange() {
		
	}

	_toggleToolbar() {
		this.props.isToolBarOpened = !this.props.isToolBarOpened;
	}

	_onTranslateDown() {
		this._editMode = 'translate';
		this._positionHelper.visible = true;
		this._scaleHelper.visible    = false;
	}
	_onRotateDown() {
		this._editMode = 'rotate';
	}
	_onScaleDown() {
		this._editMode = 'scale';
		this._positionHelper.visible = false;
		this._scaleHelper.visible    = true;
	}


	created(){

		this.scene = new THREE.Scene();
		this.uuid = UUID++;

		this._plane = new THREE.Plane();
		this._intersection = new THREE.Vector3();
		this._offset = new THREE.Vector3();
		this._offset2 = new THREE.Vector3();
		this._editMode = 'translate';
			
	}	


	ready(){

		if (this.props.renderToScreen) {
			console.log( new THREE.WebGLRenderer )
			
			this.renderer = new THREE.WebGLRenderer({
				antialias: this.props.antialias,
				alpha: this.props.alpha,
				//preserveDrawingBuffer : this.props.preserveDrawingBuffer
			});
			this.$container = this.querySelector('.js-renderer-dom-element');
			this.$container.appendChild(this.renderer.domElement);

			this.renderer.domElement.style.position = 'relative';
		}
		else {
			this.renderer = renderer;
		}

		this._onRenderTargetChange();
		//this.renderer.shadowMapBias = 0.0039;

		if (this.props.editor) {

			this._helpers = [];
			this._screenRatioPos = new THREE.Vector2();
			this._screenRatioPosDown= new THREE.Vector2();
	        this._raycaster = new THREE.Raycaster();

	        this._selectedEditAxis = null;

	       
			/**
			 * POSITION
			 */
			this._positionHelper = new THREE.Object3D();

			let xPositionLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0xff0000}) );
			xPositionLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 1, 0, 0 )
			);
			xPositionLine.material.linewidth = 2;
			xPositionLine._axis = 'x';
			xPositionLine._defaultColor = new THREE.Color(0xff0000);
			this._positionHelper.add( xPositionLine );

			let xPositionCone = new THREE.Mesh( new THREE.CylinderGeometry(0, 0.05, 0.15, 10, 1, false), new THREE.LineBasicMaterial({color: 0xff0000}) );
			xPositionCone.position.set(1,0,0);
			xPositionCone.rotation.z = -90 * Math.PI / 180;
			xPositionCone._axis = 'x';
			xPositionCone._defaultColor = new THREE.Color(0xff0000);
			this._positionHelper.add( xPositionCone );

			let yPositionLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0x00ff00}) );
			yPositionLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 0, 1, 0 )
			);
			yPositionLine.material.linewidth = 2;
			yPositionLine._axis = 'y';
			yPositionLine._defaultColor = new THREE.Color(0x00ff00);
			this._positionHelper.add( yPositionLine );

			let yPositionCone = new THREE.Mesh( new THREE.CylinderGeometry(0, 0.05, 0.15, 10, 1, false), new THREE.LineBasicMaterial({color: 0x00ff00}) );
			yPositionCone.position.set(0,1,0);
			yPositionCone._axis = 'y';
			yPositionCone._defaultColor = new THREE.Color(0x00ff00);
			this._positionHelper.add( yPositionCone );

			let zPositionLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0x0000ff}) );
			zPositionLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 0, 0, 1 )
			);
			zPositionLine.material.linewidth = 2;
			zPositionLine._axis = 'z';
			zPositionLine._defaultColor = new THREE.Color(0x0000ff);
			this._positionHelper.add( zPositionLine );

			let zPositionCone = new THREE.Mesh( new THREE.CylinderGeometry(0, 0.05, 0.15, 10, 1, false), new THREE.LineBasicMaterial({color: 0x0000ff}) );
			zPositionCone.position.set(0,0,1);
			zPositionCone._axis = 'z';
			zPositionCone.rotation.x = 90 * Math.PI / 180;
			zPositionCone._defaultColor = new THREE.Color(0x0000ff);
			this._positionHelper.add( zPositionCone );

			let xyPositionSquare = new THREE.Mesh(new THREE.PlaneGeometry( 0.25, 0.25 ), new THREE.MeshBasicMaterial({color: 0xffff00, side:THREE.DoubleSide, transparent: true, opacity: 0.25, depthTest:false, depthWrite: false}));
			xyPositionSquare.position.x = 0.125;
			xyPositionSquare.position.y = 0.125;
			xyPositionSquare._axis = 'xy';
			xyPositionSquare._defaultColor = new THREE.Color(0xffff00);
			this._positionHelper.add( xyPositionSquare );

			let xzPositionSquare = new THREE.Mesh(new THREE.PlaneGeometry( 0.25, 0.25 ), new THREE.MeshBasicMaterial({color: 0xff00ff, side:THREE.DoubleSide, transparent: true, opacity: 0.25, depthTest:false, depthWrite: false}));
			xzPositionSquare.position.x = 0.125;
			xzPositionSquare.position.z = 0.125;
			xzPositionSquare.rotation.x = 90 * Math.PI / 180
			xzPositionSquare._axis = 'xz';
			xzPositionSquare._defaultColor = new THREE.Color(0xff00ff);
			this._positionHelper.add( xzPositionSquare );

			let yzPositionSquare = new THREE.Mesh(new THREE.PlaneGeometry( 0.25, 0.25 ), new THREE.MeshBasicMaterial({color: 0x00ffff, side:THREE.DoubleSide, transparent: true, opacity: 0.25, depthTest:false, depthWrite: false}));
			yzPositionSquare.position.y = 0.125;
			yzPositionSquare.position.z = 0.125;
			yzPositionSquare.rotation.y = 90 * Math.PI / 180
			yzPositionSquare._axis = 'yz';
			yzPositionSquare._defaultColor = new THREE.Color(0x00ffff);
			this._positionHelper.add( yzPositionSquare );

			this.scene.add( this._positionHelper );





			/**
			 * SCALE
			 */
			this._scaleHelper = new THREE.Object3D();
			this._scaleHelper.visible = false;

			let xScaleLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0xff0000}) );
			xScaleLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 1, 0, 0 )
			);
			xScaleLine.material.linewidth = 2;
			xScaleLine._axis = 'x';
			xScaleLine._defaultColor = new THREE.Color(0xff0000);
			this._scaleHelper.add( xScaleLine );

			let xScaleBox = new THREE.Mesh( new THREE.BoxGeometry(0.1,0.1,0.1), new THREE.LineBasicMaterial({color: 0xff0000}) );
			xScaleBox.position.set(1,0,0);
			xScaleBox._axis = 'x';
			xScaleBox._defaultColor = new THREE.Color(0xff0000);
			this._scaleHelper.add( xScaleBox );

			let yScaleLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0x00ff00}) );
			yScaleLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 0, 1, 0 )
			);
			yScaleLine.material.linewidth = 2;
			yScaleLine._axis = 'y';
			yScaleLine._defaultColor = new THREE.Color(0x00ff00);
			this._scaleHelper.add( yScaleLine );

			let yScaleBox = new THREE.Mesh( new THREE.BoxGeometry(0.1,0.1,0.1), new THREE.LineBasicMaterial({color: 0x00ff00}) );
			yScaleBox.position.set(0,1,0);
			yScaleBox._axis = 'y';
			yScaleBox._defaultColor = new THREE.Color(0x00ff00);
			this._scaleHelper.add( yScaleBox );

			let zScaleLine = new THREE.Line( new THREE.Geometry(), new THREE.LineBasicMaterial({color: 0x0000ff}) );
			zScaleLine.geometry.vertices.push(
				new THREE.Vector3( 0, 0, 0 ),
				new THREE.Vector3( 0, 0, 1 )
			);
			zScaleLine.material.linewidth = 2;
			zScaleLine._axis = 'z';
			zScaleLine._defaultColor = new THREE.Color(0x0000ff);
			this._scaleHelper.add( zScaleLine );

			let zScaleBox = new THREE.Mesh( new THREE.BoxGeometry(0.1,0.1,0.1), new THREE.LineBasicMaterial({color: 0x0000ff}) );
			zScaleBox.position.set(0,0,1);
			zScaleBox._axis = 'z';
			zScaleBox._defaultColor = new THREE.Color(0x0000ff);
			this._scaleHelper.add( zScaleBox );


			let xyzScaleBox = new THREE.Mesh( new THREE.BoxGeometry(0.1,0.1,0.1), new THREE.LineBasicMaterial({color: 0xffffff}) );
			xyzScaleBox._axis = 'xyz';
			xyzScaleBox._defaultColor = new THREE.Color(0xffffff);
			this._scaleHelper.add( xyzScaleBox );

			this.scene.add( this._scaleHelper );



			var helper = new THREE.GridHelper( 200, 10 );
			this.scene.add( helper );


			// this._viewportHelper = new THREE.Mesh(
			// 	new THREE.PlaneGeometry(1920,1200),
			// 	new THREE.MeshBasicMaterial({color: 0xff00ff, wireframe: true})
			// 	);
			// this.scene.add( this._viewportHelper );


			// this._viewportHelper2 = new THREE.Mesh(
			// 	new THREE.PlaneGeometry(1920,1200),
			// 	new THREE.MeshBasicMaterial({color: 0xff00ff, wireframe: true})
			// 	);
			// this._viewportHelper2.position.set( 0,0, -300 );
			// this.scene.add( this._viewportHelper2 );



		}
		
	}


	_onPointerDown(event) {

		if (!this.props.editor) {
			return;
		}

		if (event.which == 3) {
			return;
		}

		this._touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;
		this._touchEventPageX  = this._touchEvent.pageX;
		this._touchEventPageY  = this._touchEvent.pageY;

		this._screenRatioPos.x = ( this._touchEventPageX  / window.innerWidth ) * 2 - 1;
		this._screenRatioPos.y = (1-(this._touchEventPageY / window.innerHeight) ) * 2 - 1;

		this._screenRatioPosDown.x = this._screenRatioPos.x
		this._screenRatioPosDown.y = this._screenRatioPos.y

		this._isPointerDown = true;

	
		this._raycaster.setFromCamera( this._screenRatioPos, this.camera );

		if (this._selectedEditAxis !== null
			|| this._selectedEditAxis !== null ) {

			this.camera._blockAxis = true;

			let axis;

			if (this._selectedEditAxis === 'x' ||
				this._selectedEditAxis === 'y' ||
				this._selectedEditAxis === 'xy' ||
				this._selectedEditAxis === 'x') {
				axis = new THREE.Vector3(0,0,1)
			}

			if (this._selectedEditAxis === 'y' ||
				this._selectedEditAxis === 'z' ||
				this._selectedEditAxis === 'yz' ||
				this._selectedEditAxis === 'y') {
				axis = new THREE.Vector3(1,0,0)
			}


			if (this._selectedEditAxis === 'z' ||
				this._selectedEditAxis === 'xz' ||
				this._selectedEditAxis === 'z') {
				axis = new THREE.Vector3(0,1,0)
			}

			if (this._selectedEditAxis === 'xyz') {
				axis = this.camera.getWorldDirection( this._plane.normal );
			}

			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);

			if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {

				this._offset.copy(  this._intersection ).sub( this._selectedObject.position );
				this._offset2.copy(  this._intersection );
				this._selectedObjectScaleDown = {
					x: this._selectedObject.scale.x,
					y: this._selectedObject.scale.y,
					z: this._selectedObject.scale.z
				}

			}

		}
		else {

			for (let i=0;i<this._helpers.length; i++) {
				this.scene.remove( this._helpers[i] );
			}

			this._helpers.splice(0, this._helpers.length);

			this._raycaster.setFromCamera( this._screenRatioPos, this.camera );

			var intersects = this._raycaster.intersectObjects( this.scene.children, true );

	        if (intersects.length > 0 ) {

				var helper = new THREE.BoxHelper( intersects[0].object );
				this.scene.add( helper );
	            this._helpers.push( helper );

	            this._selectedObject = intersects[0].object;

	            this._positionHelper.position.x = this._selectedObject.position.x;
	            this._positionHelper.position.y = this._selectedObject.position.y;
	            this._positionHelper.position.z = this._selectedObject.position.z;

	            this._scaleHelper.position.x = this._selectedObject.position.x;
	            this._scaleHelper.position.y = this._selectedObject.position.y;
	            this._scaleHelper.position.z = this._selectedObject.position.z;


	        }
	        else {
	     		this._selectedObject = null;
	        }
			
		}

	}

	_rotateObject(){

	}

	_scaleObject(){

		this._raycaster.setFromCamera( this._screenRatioPos, this.camera );

		let axis;

		if (this._selectedEditAxis === 'x') {
			axis = new THREE.Vector3(0,0,1)
			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);
        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {
				this._selectedObject.scale.x =  this._selectedObjectScaleDown.x + ( this._intersection.clone().sub( this._offset2 ).x / 1000 )
			}
		}

		if (this._selectedEditAxis === 'y') {
			axis = new THREE.Vector3(0,0,1)
			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);
        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {
				this._selectedObject.scale.y =  this._selectedObjectScaleDown.y + ( this._intersection.clone().sub( this._offset2 ).y / 1000 )
			}
		}

		if (this._selectedEditAxis === 'z') {
			axis = new THREE.Vector3(0,1,0)
			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);
        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {
				this._selectedObject.scale.z =  this._selectedObjectScaleDown.z + ( this._intersection.clone().sub( this._offset2 ).z / 1000 )
			}
		}

		if (this._selectedEditAxis === 'xyz') {
			axis = this.camera.getWorldDirection( this._plane.normal );
			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);
        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {

        		let scale = this._intersection.clone().sub( this._offset2 ).length() / 1000;

        		this._scaleDirection = this._intersection.x < this._offset2.x && this._intersection.y < this._offset2.y ? -1 : 1; 

        		this._selectedObject.scale.x =  this._selectedObjectScaleDown.x + scale * this._scaleDirection;
        		this._selectedObject.scale.y =  this._selectedObjectScaleDown.y + scale * this._scaleDirection;
				this._selectedObject.scale.z =  this._selectedObjectScaleDown.z + scale * this._scaleDirection;
			}
		}


	}

	_translateObject(){

		this._raycaster.setFromCamera( this._screenRatioPos, this.camera );

		let axis;

		if (this._selectedEditAxis === 'x' ||
			this._selectedEditAxis === 'y' ||
			this._selectedEditAxis === 'xy') {

			axis = new THREE.Vector3(0,0,1)

			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);

        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {
        		if (this._selectedEditAxis === 'x' || this._selectedEditAxis === 'xy') {
        			this._selectedObject.position.x = this._intersection.sub( this._offset ).x;
        		}
        		if (this._selectedEditAxis === 'y' || this._selectedEditAxis === 'xy') {
        			this._selectedObject.position.y = this._intersection.sub( this._offset ).y;
        		}
			}

		}

		if (this._selectedEditAxis === 'y' ||
			this._selectedEditAxis === 'z' ||
			this._selectedEditAxis === 'yz') {

			axis = new THREE.Vector3(1,0,0)

			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);

        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {

        		if (this._selectedEditAxis === 'y' || this._selectedEditAxis === 'yz') {
        			this._selectedObject.position.y = this._intersection.sub( this._offset ).y;
        		}

        		if (this._selectedEditAxis === 'z' || this._selectedEditAxis === 'yz') {
        			this._selectedObject.position.z = this._intersection.sub( this._offset ).z;
        		}
			}

		}


		if (this._selectedEditAxis === 'z' ||
			this._selectedEditAxis === 'xz') {

			axis = new THREE.Vector3(0,1,0)

			this._plane.setFromNormalAndCoplanarPoint(
				axis,
				this._selectedObject.position
			);

        	if ( this._raycaster.ray.intersectPlane( this._plane, this._intersection ) ) {
        		if (this._selectedEditAxis === 'x' || this._selectedEditAxis === 'xz') {
        			this._selectedObject.position.x = this._intersection.sub( this._offset ).x;
        		}
        		if (this._selectedEditAxis === 'z' || this._selectedEditAxis === 'xz') {
        			this._selectedObject.position.z = this._intersection.sub( this._offset ).z;
        		}
			}

		}

		this._positionHelper.position.x = this._selectedObject.position.x;
		this._positionHelper.position.y = this._selectedObject.position.y;
		this._positionHelper.position.z = this._selectedObject.position.z;

	}


	_onPointerMove(event) {

		if (!this.props.editor) {
			return;
		}

		this._touchEvent  = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        this._touchEventPageX = this._touchEvent.pageX;
        this._touchEventPageY = this._touchEvent.pageY;

        this._screenRatioPos.x = ( this._touchEventPageX  / window.innerWidth ) * 2 - 1;
        this._screenRatioPos.y = (1-(this._touchEventPageY / window.innerHeight) ) * 2 - 1;

        this._screenRatioPos.x = ( this._touchEventPageX  / window.innerWidth ) * 2 - 1;
        this._screenRatioPos.y = (1-(this._touchEventPageY / window.innerHeight) ) * 2 - 1;

        if (this._selectedObject ){

        	if (this._isPointerDown) {

        		if (this._selectedEditAxis !== null) {

        			if (this._editMode == 'translate') {
        				this._translateObject();
        			}
        			else if (this._editMode == "rotate") {
        				this._rotateObject();
        			}
        			else {
        				this._scaleObject();
        			}

        		}

        	}
        	else {

				this._raycaster.setFromCamera( this._screenRatioPos, this.camera );

				if (this._editMode == 'translate') {

					var intersects = this._raycaster.intersectObjects( this._positionHelper.children, true );
					var target = null;

			        if (intersects.length > 0 ) {
			        	target = intersects[0].object;
			        }
			        else {
			        	this._selectedEditAxis = null;
			        }

		        	for (let i=0; i<this._positionHelper.children.length; i++) {
		        		if (target !== this._positionHelper.children[i]) {
	        				this._positionHelper.children[i].material.color.r = this._positionHelper.children[i]._defaultColor.r;
		        			this._positionHelper.children[i].material.color.g = this._positionHelper.children[i]._defaultColor.g;
		        			this._positionHelper.children[i].material.color.b = this._positionHelper.children[i]._defaultColor.b;
		        			this._positionHelper.children[i].material.opacity = 0.25;
		        		}
		        		else {
		        			this._selectedEditAxis = this._positionHelper.children[i]._axis;
	        				this._positionHelper.children[i].material.color.r = 1;
		        			this._positionHelper.children[i].material.color.g = 1;
		        			this._positionHelper.children[i].material.color.b = 0;
		        			this._positionHelper.children[i].material.opacity = 1;
		        		}
		        	}

				}
				else if (this._editMode == 'rotate') {

				}
				else {

					var intersects = this._raycaster.intersectObjects( this._scaleHelper.children, true );
					var target = null;

			        if (intersects.length > 0 ) {
			        	target = intersects[0].object;
			        }
			        else {
			        	this._selectedEditAxis = null;
			        }

		        	for (let i=0; i<this._scaleHelper.children.length; i++) {
		        		if (target !== this._scaleHelper.children[i]) {
	        				this._scaleHelper.children[i].material.color.r = this._scaleHelper.children[i]._defaultColor.r;
		        			this._scaleHelper.children[i].material.color.g = this._scaleHelper.children[i]._defaultColor.g;
		        			this._scaleHelper.children[i].material.color.b = this._scaleHelper.children[i]._defaultColor.b;
		        		}
		        		else {
		        			this._selectedEditAxis = this._scaleHelper.children[i]._axis;
	        				this._scaleHelper.children[i].material.color.r = 1;
		        			this._scaleHelper.children[i].material.color.g = 1;
		        			this._scaleHelper.children[i].material.color.b = 0;
		        		}
		        	}

				}

        	}

        }

	}



	_onPointerUp() {
		if (!this.props.editor) {
			return;
		}
		this._selectedEditAxis = null;
    	this.camera._blockAxis = false;
    	this._isPointerDown = false;
	}


	_onRenderTargetChange() {
		if (this.props.renderTarget !== null
			&& this.props.renderTarget.renderer !== null) {
			this.renderer = this.props.renderTarget.renderer;
		}
	}



	resize(){

		if (!this.renderer) {
			return;
		}
		
		this.renderer.setSize( this.props.width, this.props.height );

		// if (this.composer) {

		// 	for (let i=0; i<this.composer.passes.length; i++ ) {
		// 		// this this.composer.passes[0] == renderPass so no uniforms
  //               if ( typeof this.composer.passes[i].uniforms !== 'undefined' && this.composer.passes[i].uniforms['resolution']) {
		// 			this.composer.passes[i].uniforms['resolution'].value = new THREE.Vector2(this.props.width, this.props.height)
		// 		}
		// 	}

		// 	this.composer.reset( this.props.renderTarget );
		// }

	}

	update(){




		if (this.camera) {
			if (this.props.editor) {

				let scale =  this._positionHelper.position.clone().sub( this.camera.position ).length() / 4;
		
				this._positionHelper.scale.x = scale;
				this._positionHelper.scale.y = scale;
				this._positionHelper.scale.z = scale;

				this._scaleHelper.scale.x = scale;
				this._scaleHelper.scale.y = scale;
				this._scaleHelper.scale.z = scale;

				if (this._selectedObject) {
					this.props.selectedObjectPosition[0] = this._selectedObject.position.x;
					this.props.selectedObjectPosition[1] = this._selectedObject.position.y;
					this.props.selectedObjectPosition[2] = this._selectedObject.position.z;

					this.props.selectedObjectScale[0] = this._selectedObject.scale.x;
					this.props.selectedObjectScale[1] = this._selectedObject.scale.y;
					this.props.selectedObjectScale[2] = this._selectedObject.scale.z;

				}

				this.props.cameraPositionZ = this.camera.position.z;



				for (let i=0; i<this.props.cameras.length; i++) {
					if (this.props.cameras[i]._helper !== void 0) {
						this.props.cameras[i]._helper.update();
					}
				}

	


			}
		}




		if( !this.props.update || !this.renderer ){
			return;
		}

		this.renderer.antialias = this.props.antialias;
		this.renderer.alpha = this.props.alpha;
		this.renderer.setClearColor( new THREE.Color(this.props.clearColor[0], this.props.clearColor[1], this.props.clearColor[2]), this.props.clearOpacity );
		this.renderer.setPixelRatio( this.props.pixelRatio );
		this.renderer.shadowMap.enabled = this.props.shadowMap;

		if (this.props.softShadow) {
			this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		}

		if (this.renderer
			&& this.camera
			&& !this.composer) {

			//EventDispatcher.fire('scene:overridematerial', [false]);

			
			if (this.props.renderToScreen) {
				this.renderer.render( this.scene, this.camera );
			}
			else{
				if (this.props.renderTarget !== null
					&& this.props.renderTarget instanceof THREE.WebGLRenderTarget) {
					this.renderer.render( this.scene, this.camera, this.props.renderTarget );
				}
			}

		}

	}

	add(node){
		//flag to detect the three.js instance for this node
		this.scene.add(node);
	}

}

register('light-engine-scene', LightEngineScene);