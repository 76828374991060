import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminEventsUpdateDescription extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            forceSubmit: {type:'bool', value: false},
            formActionType: {type:'string', value:''}
        }
    }

    // get events() {
    //     return {
    //         'input .js-input-handler' : '_onChange'
    //     }
    // }

    // ready() {
    //     this._onChanged = false;
    // }


    // _onChange(evt) {
    //     this._onChanged = true;
    // }


    //-------------------------------
    //   CONNECT WITH admin-event

    // attached(){

    //     this.$parent = this.parentNode;
    //     while(this.$parent){
    //         if(this.$parent.tagName === 'ADMIN-EVENT'){
    //             this._success = this.$parent._addRequest(this._send.bind(this));
    //             break
    //         }
    //         this.$parent = this.$parent.parentNode;
    //     }
    // }


    //-------------------------------------
    //   COMMUNICATION WITH admin-event

    // _send(type){

    //     if (this._onChanged) {
    //         this.props.forceSubmit = true;
    //         this._onChanged = false;
    //         this.props.formActionType = type;
    //         return 1;
    //     }

    //     return 0;
    // }

    // onSuccess(){
    //     this.props.forceSubmit = false;
    //     this._onChanged = false;

    //     // dispatch to admin-event
    //     this._success();
    // }

}

register('admin-events-update-description', AdminEventsUpdateDescription);