import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

import {support} from 'light';

class AdminSchedule extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goToTab': 'onTabChange'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            activeTab: { type: 'int', value: 0 },

            containerWidth: {type:'float', value:0},
            containerHeight: {type:'float', value:0},

            contentWidth: {type:'float', value:0},
            contentHeight: {type:'float', value: 0},

            containerArr: {type:'array', value: []},
            contentArr:   {type:'array', value: []},

            dateArrowLeft:{type: 'bool', value: true},
            dateArrowRight:{type: 'bool', value: true},

        }
    }

    created(){
        this._pointerNone   = false;
        this._tabIndex = 0;

        this._onKeyUp = this._onKeyUp.bind(this);
        this._onContentScroll = this._onContentScroll.bind(this);

        this._colSize = 100;
        this._dateScrollLeft = 0;
    
    }

    _onDateArrowPrevDown(e) {
        this._dateScrollLeft -= this._dateColSize;
        if (this._dateScrollLeft<0) {
            this._dateScrollLeft = 0;
        }
        this._onDateContentScroll();
    }

    _onDateArrowNextDown(e) {
        this._dateScrollLeft += this._dateColSize;
        if (this._dateScrollLeft>this._dateContentScrollWidth) {
            this._dateScrollLeft = this._dateContentScrollWidth;
        }
        this._onDateContentScroll();
    }

    _onDateContentScroll() {

        this.$dateInner.style.left = (this._dateScrollLeft * -1) + 'px';

        if (this._dateScrollLeft > 10) {
            this.props.dateArrowLeft = true;
        }
        else {
            this.props.dateArrowLeft = false;
        }

        if (this._dateScrollLeft < this._dateContentScrollWidth - 10 ) {
            this.props.dateArrowRight = true;
        }
        else {
            this.props.dateArrowRight = false;
        }

    }




    

    attached() {
        document.addEventListener('keyup', this._onKeyUp, false);
    }

    detached() {
        document.removeEventListener('keyup', this._onKeyUp, false);
        if (this.$content) {
            for (let i=0; i<this.$content.length; i++) {
                this.$content[i].removeEventListener('scroll', this._onContentScroll, false);
            }
        }
    }

    _onKeyUp(e) {
        if (e.which == 37) {
            this.$content[this.props.activeTab].scrollLeft -= this._colSize;
        }
        if (e.which == 39) {
            this.$content[this.props.activeTab].scrollLeft += this._colSize;
        }
    }

    _onArrowPrevDown(e) {
        const index = Number( e.currentTarget.getAttribute('data-index') );
        this.$content[index].scrollLeft -= this._colSize;
    }

    _onArrowNextDown(e) {
        const index = Number( e.currentTarget.getAttribute('data-index') );
        this.$content[index].scrollLeft += this._colSize;
    }



    _onContentScroll(e) {

        let index = Number( e.currentTarget.getAttribute('data-index') ); 

        if (e.currentTarget.scrollLeft > 10) {
            this.props.contentArr[index].arrowLeft = true;
        }
        else {
            this.props.contentArr[index].arrowLeft = false;
        }

        if (e.currentTarget.scrollLeft < this.props.contentArr[index].scrollWidth - 10 ) {
            this.props.contentArr[index].arrowRight = true;
        }
        else {
            this.props.contentArr[index].arrowRight = false;
        }

    }



    ready(){
        setTimeout( () => {

            this.$container = this.querySelectorAll('.js-container');
            this.$content   = this.querySelectorAll('.js-content');
            this.$goToLinks = this.querySelectorAll('.js-goToTab');
            this.$tab       = this.querySelector('.js-tab');
            this.$col       = this.querySelector('.js-col');

            this.$dateContent = this.querySelector('.js-date-content');
            this.$dateInner = this.querySelector('.js-date-inner');
            let $cols = this.$dateContent.querySelectorAll('.js-col');
            this._dateColSize = $cols[0].clientWidth;
            this._dateContentScrollWidth = ($cols[0].clientWidth * $cols.length) - this.$dateContent.offsetWidth;
            this._onDateContentScroll();

            if (this.$content) {
                for (let i=0; i<this.$content.length; i++) {

                    this.props.contentArr.push({
                        width:  this.$content[i].offsetWidth,
                        height: this.$content[i].offsetHeight,
                        scrollWidth:  this.$content[i].scrollWidth - this.$content[i].offsetWidth,
                        scrollHeight: this.$content[i].scrollHeight - this.$content[i].offsetHeight,
                        arrowLeft:  false,
                        arrowRight: false,
                    });

                    this.$content[i].addEventListener('scroll', this._onContentScroll, false);
                    this._onContentScroll({
                        currentTarget: this.$content[i] 
                    });
                }
            }

        }, 100);
    }

    resize(){

        if (this.$col) {
            this._colSize = this.$content[this.props.activeTab].querySelector('.js-col').clientWidth;
        }
        
        if (this.$content) {
            for (let i=0; i<this.props.contentArr.length; i++) {
                this.props.contentArr[i].width = this.$content[i].offsetWidth;
                this.props.contentArr[i].height = this.$content[i].offsetHeight;
                this.props.contentArr[i].scrollWidth  = this.$content[i].scrollWidth - this.$content[i].offsetWidth;
                this.props.contentArr[i].scrollHeight = this.$content[i].scrollHeight - this.$content[i].offsetHeight;
            }
        }

        if (this.$dateContent) {
            let $cols = this.$dateContent.querySelectorAll('.js-col');
            this._dateColSize = $cols[0].clientWidth;
            this._dateContentScrollWidth = ($cols[0].clientWidth * $cols.length) - this.$dateContent.offsetWidth;
            this._onDateContentScroll()
        }

    }

    onTabChange(event){

        if(event){
            this.props.activeTab = Number(event.currentTarget.getAttribute('data-target'));

            

        }

        //TODO use light-core-selector !
        Array.prototype.forEach.call(this.$goToLinks, (el, i) => {
            let index = el.getAttribute('data-target');
            if( index == this.props.activeTab ) {
                el.classList.add('is-active');
            }
            else {
                el.classList.remove('is-active');
            }
        });


            this.resize();

            setTimeout(()=>{
                requestAnimationFrame(()=>{

                    this._onContentScroll({
                        currentTarget: this.$content[this.props.activeTab] 
                    });
                
                });

            }, 50)


    }

    // _onMove(x,y){
    //     this.$content[this.props.activeTab].style[support.transform] = 'translateX(' + -x + 'px)' + support.translateZ;
    // }

    // _onDrag(){
    //     if(!this._pointerNone){
    //         this._pointerNone = true;
    //         this.$container.classList.add('u-force-pointer-none');

    //         EventDispatcher.fire('pointer:none');
    //     }
    // }

    // _onEnd(){
    //      if(this._pointerNone){
    //         this._pointerNone = false;
    //         this.$container.classList.remove('u-force-pointer-none');

    //         EventDispatcher.fire('pointer:auto');
    //     }
    // }

}

register('admin-schedule', AdminSchedule);