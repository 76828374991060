import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListTransactions extends Component {


    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content:   { type: 'json', value: null },
            data:      { type: 'json',   value: null },
        }
    }

    _onListReady(data) {
        this.props.data = data;
        console.log('IS LIST READY', data, this.props.content);
    }

}

register('list-transactions', ListTransactions);