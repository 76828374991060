import {register} from "light";
import {Component} from "light";
import jstz from 'jstz';

import "core/light-core-ajax/light-core-ajax";

class LightCoreAjaxForm extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'submit': 'onSubmit'
        }
    }


    get defaultProps(){
        return {
            onSuccess: { type: 'func', value: function(){} },
            onError: { type: 'func', value: function(){} },
            action: window.location.href,
            method: "post",
            enctype: "multipart/form-data",
            formData: { value: null },
            classForm: '',
            headers: { type: 'json', value: {}  },
            onCustomCheck: { type: 'fn', value: function(){ return true; }},
            forceSubmit: { type: 'bool', value: false, change: '_onForceSubmitChange' },
            reset: { type: 'bool', value: true },
            sendOnSrcChange: {type:'bool', value: true}
        }
    }

    _onForceSubmitChange() {
        if (this.props.forceSubmit) {
            this.onSubmit();
        }
    }

    checkFields(){

        this.$requiredField = this.querySelectorAll('.js-required');
        this.$emailFields   = this.querySelectorAll('.js-email');
        this.$regFields     = this.querySelectorAll('[data-regex]');
        this.$passwordFields   = this.querySelectorAll('.js-strongpassword');
        this.$passwordTextFields   = this.querySelectorAll('.js-strongpasswordtext');
        
        this.$OldPassword = this.querySelectorAll(".js-oldpassword");
        this.$NewPassword = this.querySelectorAll(".js-newpassword");
        this.oldpasstext = this.querySelectorAll(".js-oldpasswordtext");
        

        var isValid = this.props.onCustomCheck();
        var isValidPassword = true;

        for (let i=0,l= this.$requiredField.length; i<l; i++) {

            if (this.$requiredField[i].type === 'radio') {

                let isChecked = false;
                for (let j=0,ll= this.$requiredField.length; j<ll; j++) {

                    if (this.$requiredField[j].type == 'radio'
                        && this.$requiredField[i].name == this.$requiredField[j].name
                        && this.$requiredField[j].checked) {
                        isChecked = true;
                        break;
                    }
                }
                if (isChecked) {
                    this.$requiredField[i].classList.remove('is-error');
                    this.$requiredField[i].classList.add('is-success');
                }
                else {
                    isValid = false;
                    this.$requiredField[i].classList.add('is-error');
                    this.$requiredField[i].classList.remove('is-success');
                }

            }

            else if(    (!this.$requiredField[i].value && this.$requiredField[i].type !== 'checkbox')
                || (this.$requiredField[i].value == '' && this.$requiredField[i].type !== 'checkbox')
                || (this.$requiredField[i].type == 'checkbox' && !this.$requiredField[i].checked)) {

                if (this.$requiredField[i].type == 'file' && 
                    this.$requiredField[i].getAttribute('value') && 
                    this.$requiredField[i].getAttribute('value') !== '' ) {
                    // isValid = true;
                    this.$requiredField[i].classList.remove('is-error');
                    this.$requiredField[i].classList.add('is-success');
                }
                else {
                    isValid = false;
                    this.$requiredField[i].classList.add('is-error');
                    this.$requiredField[i].classList.remove('is-success');
                }

            }
            else{
                this.$requiredField[i].classList.remove('is-error');
                this.$requiredField[i].classList.add('is-success');
            }


            
        }

        for (let i=0,l= this.$OldPassword.length; i<l; i++) {
            if (!this.$OldPassword[i].value || this.$OldPassword[i].value == ''){
                isValid = false;
                isValidPassword = false;
                this.$OldPassword[i].classList.add('is-error');
                this.$OldPassword[i].classList.remove('is-success');
            }
            else
            { 
                if(this.$OldPassword[i].value!=='' && this.$NewPassword[i].value!=='' && (this.$OldPassword[i].value==this.$NewPassword[i].value))
                {
                    this.$OldPassword[i].classList.add('is-error');
                    this.$OldPassword[i].classList.remove('is-success');
                    this.oldpasstext[i].innerHTML="<span class='u-color--red'>The old password and new password must be different.</span>";
                    isValid = false;
                    isValidPassword = false;
                }
                else
                {
                    this.$OldPassword[i].classList.remove('is-error');
                    this.$OldPassword[i].classList.add('is-success');
                    this.oldpasstext[i].innerHTML="";                
                }
            }
        }

        for (let i=0,l= this.$passwordFields.length; i<l; i++) {
            if (!this.$passwordFields[i].value || this.$passwordFields[i].value == ''){
                isValid = false;
                isValidPassword = false;
                this.$passwordFields[i].classList.add('is-error');
                this.$passwordFields[i].classList.remove('is-success');
            }
            else{
                var regex = new Array();
                regex.push("[A-Z]"); //Uppercase Alphabet.
                regex.push("[a-z]"); //Lowercase Alphabet.
                regex.push("[0-9]"); //Digit.
                regex.push("[$@$!%*#?&]"); //Special Character.

                var passed = 0;

                //Validate for each Regular Expression.
                for (var j = 0; j < regex.length; j++) {
                    if (new RegExp(regex[j]).test(this.$passwordFields[i].value)) {
                        passed++;
                    }
                }


                //Validate for length of Password.
                if (passed > 2 && this.$passwordFields[i].value.length > 8) {
                    passed++;
                }

                //Display status.
                var color = "";
                var strength = "";                
                switch (passed) {
                    case 0:
                    case 1:
                        strength = "Short";
                        color = "red";
                        break;
                    case 2:
                        strength = "Weak";
                        color = "brand-video";
                        break;
                    case 3:
                    case 4:
                        strength = "Good";
                        color = "brand-all";
                        break;
                    case 5:
                        strength = "Strong";
                        color = "brand-article";
                        break;
                }
                if (strength == "Weak" || strength == "Good" || strength == "Short") {
                    isValid = false;
                    isValidPassword = false;
                    this.$passwordTextFields[i].innerHTML="<span class='u-color--" + color+"'>" + strength + "</span>";
                    this.$passwordTextFields[i].classList.remove('u-marg-b-sm');
                    this.$passwordFields[i].classList.add('is-error');
                    this.$passwordFields[i].classList.remove('is-success');
                }
                else{
                    this.$passwordTextFields[i].innerHTML="<span class='u-color--" + color+"'>" + strength + "</span>";
                    this.$passwordTextFields[i].classList.remove('u-marg-b-sm');
                    this.$passwordFields[i].classList.remove('is-error');
                    this.$passwordFields[i].classList.add('is-success');                    
                }
            }
            
        }
        if(isValidPassword){
            for (let i=0,l= this.$passwordFields.length; i<l; i++) {
                if (this.$passwordFields[i].value != this.$passwordFields[i+1].value){
                    isValid = false;
                    //this.$passwordFields[i].classList.add('is-error');
                    //this.$passwordFields[i].classList.remove('is-success');
                    this.$passwordFields[i+1].classList.add('is-error');
                    this.$passwordFields[i+1].classList.remove('is-success');
                    this.$passwordTextFields[i+1].innerHTML="<span class='u-color--red'>The password and confirm password do not match.</span>";
                }
                else{
                    //this.$passwordFields[i].classList.remove('is-error');
                    //this.$passwordFields[i].classList.add('is-success');
                    this.$passwordTextFields[i+1].innerHTML="";
                    this.$passwordFields[i+1].classList.remove('is-error');
                    this.$passwordFields[i+1].classList.add('is-success');
                }
                break;
            }
        }

        for (let i=0,l= this.$emailFields.length; i<l; i++) {
            if (!this.$emailFields[i].value
                || this.$emailFields[i].value == ''
                || ! /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( this.$emailFields[i].value ) ){
                isValid = false;
                this.$emailFields[i].classList.add('is-error');
                this.$emailFields[i].classList.remove('is-success');
            }
            else{
                this.$emailFields[i].classList.remove('is-error');
                this.$emailFields[i].classList.add('is-success');
            }
        }

        for (let i=0,l= this.$regFields.length; i<l; i++) {
            if( !new RegExp(this.$regFields[i].getAttribute('data-regex'), 'ig').test(this.$regFields[i].value) ){
                isValid = false;
                this.$regFields[i].classList.add('is-error');
                this.$regFields[i].classList.remove('is-success');
            }
            else{
                this.$regFields[i].classList.remove('is-error');
                this.$regFields[i].classList.add('is-success');
            }
        }

        return isValid;

    }

    onSubmit( event ){

        if(event){
            event.preventDefault();
        }

        if( this.checkFields() ){            
            this.querySelector('.js-loading').classList.remove('u-hide');

            this.props.headers.timezoneoffset = new Date().getTimezoneOffset();

            //ON IE10, the .pop() call fails
            let timezoneOffsetArr = new Date().toString().match(/\((.*)\)/);
            this.props.headers.windowstimezoneoffsetname = timezoneOffsetArr === void 0 || timezoneOffsetArr === null ? '' : timezoneOffsetArr.pop(); 
                
            this.props.headers.timezoneoffsetname = jstz.determine().name();
            this.props.formData = this.serialize( this.querySelector('.js-form') );
            console.log('FORMDATA', this.props.formData);
        }
    }

    serialize( form ) {

        let field, s = {};
        if (typeof form == 'object' && form.nodeName == "FORM") {
            var len = form.elements.length;
            for (let i=0; i<len; i++) {

                field = form.elements[i];

                if (    field.name
                     && !field.disable
                     && field.type != 'file'
                     && field.type != 'reset'
                     && field.type != 'submit'
                     && field.type != 'button') {

                    if (field.type == 'select-multiple') {

                        for (j=form.elements[i].options.length-1; j>=0; j--) {
                            if(field.options[j].selected){
                                s[field.name] = encodeURIComponent(field.options[j].value);
                            }
                        }

                    } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {


                        if ( s[field.name] !== void 0 ){

                            if (Object.prototype.toString.call(s[field.name]) !== '[object Array]') {
                                s[field.name] = new Array( s[field.name] );
                            }
                            
                            s[field.name].push( encodeURIComponent(field.value) );

                        }
                        else {
                            s[field.name] = encodeURIComponent(field.value);
                        }
                            
                     
                    
                    }
                }

                if (field.type == 'file') {
                    for (var k in field.files) {
                        if (field.files.hasOwnProperty(k) && field.files[k] instanceof File) {
                            s[field.name] = field.files[k];
                        }
                    }
                }

            }
        }

        return s;
    
    }

    onAjaxSuccess( resp ) {
        
        if( resp && typeof resp !== 'object' ){
            resp =  JSON.parse( resp );
        }
        
        this.props.onSuccess( resp );

        if (this.props.reset) {
            let form =  this.querySelector('.js-form');
            form.reset();
            var len = form.elements.length;
            for (let i=len-1; i>=0; i--) {
                if (form.elements[i].type == 'file') {
                    if (document.createEventObject){
                        var evt = document.createEventObject();
                        form.elements[i].fireEvent('onchange',evt);
                    }
                    else {
                        var evt = document.createEvent("HTMLEvents");
                        evt.initEvent("change", true, true );
                        form.elements[i].dispatchEvent(evt);
                    }
                }
            }
            
        }        
        this.querySelector('.js-loading').classList.add('u-hide');
    }

    onAjaxError( err ) {        
        this.querySelector('.js-loading').classList.add('u-hide');
        console.log('error in ajax form');
        this.props.onError( err );
    }
}

register('light-core-ajax-form', LightCoreAjaxForm);
