import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AppAutocomplete extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            "keyup .js-input": '_onInputChange',
            "copy .js-input": '_onInputChange',
            "cut .js-input": '_onInputChange',
            "paste .js-input": '_onInputChange',
            "pointerdown .js-result": "_onResultDown"
        }
    }

    get defaultProps(){
        return {
            border: {type: 'bool', value: true},
            active: {type: 'bool', value: false},
            action: null,
            placeholder: 'find a product',
            max: {type: 'int', value: 5},
            params: {type: 'json', value: {
                query: ''
            }},
            results: {type: "arr", value: []},
            onSelect: {type: 'fn', value: function(){ } },

            excludeList: {type: 'array', value: null}
        }
    }

    detached() {
        if (this.querySelector(".js-input") !== null) {
            this.querySelector(".js-input").removeEventListener('focus', this._onInputFocus, false);
            this.querySelector(".js-input").removeEventListener('blur', this._onInputBlur, false);
        }
    }

    /*created(){
        this._deleteIdArr = new Array();
    }*/

    ready() {
        this.bindMethods(['_onInputFocus', "_onInputBlur"]);
        this.querySelector(".js-input").addEventListener('focus', this._onInputFocus, false);
        this.querySelector(".js-input").addEventListener('blur', this._onInputBlur, false);
    }

    _onInputChange(event) {
        clearTimeout(this._inputTimer);
        this._inputTimer = setTimeout(()=>{
            this.props.params.query = event.currentTarget.value;
        }, 300);
    }

    _onSuccess(data) {
        var posts = data.body.posts;


console.log('Autocomplete success', posts, this.props.excludeList)
        /**
         * dont list already choosen post in autocomplete
         */
        if (this.props.excludeList !== null) {
            for (var i = posts.length-1; i>=0; i--) {
                for (var j = 0; j<this.props.excludeList.length; j++) {
                    if (posts[i].id == this.props.excludeList[j].id) {
                        posts.splice(i, 1);
                        break;
                    }
                }
            }        
        }

        this.props.results.splice(0, this.props.results.length);
        Array.prototype.push.apply(this.props.results, data.body.posts);

    }

    _onError () {

    }

    _onResultDown(e) {

        let el = e.currentTarget;
        el.style.display = 'none';

        //case for links in reuslt list:
        // has the click on a list will trigger the blur on the input and thus hide the list (cf:_onInputBlur),
        // we cannot simply add state btns in the list because they are binded on 'click' event, and as the list is hidden, the click is not triggered...
        // thus we need to recreate the state btn behaviour
        //
        let hasHref = el.getAttribute('has-href');

        if (hasHref == "true") {
            let href = el.getAttribute('href');
            EventDispatcher.fire('state:change', ['page', {url: href}]);
        }
        else {


            let index = Number(e.currentTarget.getAttribute('index') );
            
            console.log('ON-RESULT-DOWN', index, this.props.results)
            
            this.props.onSelect( this.props.results[ index ] );

            this.props.params.query = "";
            this.querySelector('.js-input').value = "";
            this.props.results.splice(0, this.props.results.length);
        }

    }

    _onInputFocus() {
        this.props.active = true;
    }

    _onInputBlur() {
        this.props.active = false;
    }

}

register('app-auto-complete', AppAutocomplete);