import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class CoreTwitterConnect extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {

            requestOauthTokenUrl: {type:'string', value: ''},
            sendOauthVerifierUrl: {type:'string', value: ''},

            //trigger the call to requestoAuthToekn
            requestOauthToken: {type: 'bool', value: false},

            OauthVerifier: {type:'string', value: ''},
            OauthToken: {type:'string', value: ''},

            isConnected: {type: 'bool', value: false},

            disconnectUrl: {type:'string', value: ''},
            disconnect: {type:'bool', value: false },

            connectLabel : 'connect',
            disconnectLabel: 'disconnect',
            errorMessage: ''

        }
    }

    get events(){
        return {
            'click .js-connect': 'onConnect',
            'click .js-disconnect': 'onDisconnect',
        }
    }

    ready(){

        if (!this.props.isConnected) {

            if(this.getParameter('oauth_token')){
                this.props.OauthToken = this.getParameter('oauth_token');     
            }

            if(this.getParameter('oauth_verifier')){
                this.props.OauthVerifier = this.getParameter('oauth_verifier');     
            }

        }

    }

    onConnect() {
        //when use rclick the connect button, we trigger a call to the requestTokenUrl
        //after that the user will we redirected to twitter to grant permissions.
        this.props.requestOauthToken = true;
        requestAnimationFrame(()=>{
            this.props.requestOauthToken = false;
        });
    }

    onDisconnect() {
        this.props.disconnect = true;
        requestAnimationFrame(()=>{
            this.props.disconnect = false;
        });
    }

    onRequestOAuthTokenSuccess(data) {
        console.log("onRequestOAuthTokenSuccess", data.body.success);
        
        if (!data.body.success) {
            this.onRequestOAuthError(data.body.response.body);
            return;
        }

        this.props.errorMessage = '';
        this.props.OauthToken = data.body.oauth_token;
        window.location = 'https://api.twitter.com/oauth/authenticate?oauth_token=' + data.body.oauth_token;

    }

    onRequestOAuthError(data){
        console.log('onRequestTokenError');
        this.props.errorMessage = data;
    }

    onSendOauthVerifierSuccess(data) {
        if (!data.body.success) {
            this.onSendOauthVerifierError(data.body.response.body);
            return;
        }
        this.props.isConnected = true;
        this.props.errorMessage = '';
    }

    onSendOauthVerifierError(data) {
        console.log('onSendOauthVerifierError');
        this.props.errorMessage = data;
    }

    onDisconnectSuccess(data) {
        if (!data.body.success) {
            this.onDisconnectError(data.body.response.body);
            return;
        }
        this.props.isConnected = false;
        this.props.errorMessage = '';
    }

    onDisconnectError(data) {
        console.log('onDisconnectError');
        this.props.errorMessage = data;
    }

    getParameter(param) { 
      const params = window.location.search.substr(1).split('&');
     
      for (let i = 0; i < params.length; i++) {
        let p=params[i].split('=');
        if (p[0] == param) {
          return decodeURIComponent(p[1]);
        }
      }
      return false;
    }


}

register('core-twitter-connect', CoreTwitterConnect);
