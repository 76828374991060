import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class CoreBtnState extends Component {

    get defaultProps(){
        return {
            state: null,
            params: { type: 'json', value: null },
            href: {type: 'string', value: '', change: '_onHrefChange'}
        }
    }

    created(){
        this.bindMethods(['triggerBtn'])
    }


    detached() {
        this.removeEventListener( "click", this.triggerBtn, false);
    }

    _onHrefChange() {
        let href = this.getAttribute('href');
        this.setAttribute('_href', href);
        this.setAttribute('href', 'javascript:void(0);');
    }

    ready() {
        this._onHrefChange();
        this.addEventListener( "click", this.triggerBtn, false);
    }


    triggerBtn( event ){
        event.stopPropagation();
        EventDispatcher.fire( 'state:change', [this.props.state, this.props.params] );
    }


}

register('core-btn-state', CoreBtnState);
register('core-btn-state-link', CoreBtnState, 'a');
register('core-btn-state-button', CoreBtnState, 'button');
