export default function getParameter(param) { 
  const params = window.location.search.substr(1).split('&');

  for (let i = 0; i < params.length; i++) {
    let p=params[i].split('=');
    if (p[0] == param) {
      return decodeURIComponent(p[1]);
    }
  }
  return false;
};