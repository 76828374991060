import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class myLoader extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            'active': { type: 'bool', value: true, change: '_init' },
            'manifest': { type: 'json', value: null },
            'pourcent': { type: 'string', value: '00' },
            'loaded': { type: 'bool', value: false, change: '_onDataLoaded' },
        }
    }

    created(){
        this._init();
    }

    ready(){
        this.gaugeCircle = this.querySelector('.js-circle');
        this.gaugeCircle.style.strokeDashoffset = 150;
    }

    _init(){
        if (this.props.active) {
            if (this.props.manifest === null) {
                this._onAssetsLoaded();
            }
            else {
                this._isAnimated = false;
                this._progress = 0;
                this._realProgress = 0;
                this._assetsLoaded = false;
            }
        }
    }

    _onStart(){
        this._isAnimated = true;
    }

    _onProgress( event ){
        this._realProgress = event._progress;
    }

    _onComplete(){
        this._realProgress = 100;
    }

    _onAssetsLoaded(){
        if( !this._assetsLoaded ){
            this._assetsLoaded = true;
            this._allAreLoaded();
        }
    }

    _onDataLoaded(){
        if( this.props.loaded ){
            this._allAreLoaded();
        }
    }

    _allAreLoaded(){
        if(this.props.loaded && this._assetsLoaded){
            EventDispatcher.fire('loader:end');
        }
    }

    update(){
        if(this._progress < 99.99){
            this._progress += ( this._realProgress - this._progress ) * 0.1; // 0.1
            this.gaugeCircle.style.strokeDashoffset = 150 - ( (this._progress/100) * 130 );
            EventDispatcher.fire('loader:progress', [this._progress]);
        }
        else {
            this._onAssetsLoaded();
        }

    }

}

register('my-loader', myLoader);
