import {Component} from 'light';
import {register} from 'light';

class SlideshowHome extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown .js-goto': 'onGoToSlide'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            activeSlide: { type: 'int', value: 0 }
        }
    }

    attached() {
        this._autoTimer = setTimeout(()=>{
            this.autoSlide();
        }, 4000);
    }

    detached() {
        clearTimeout(this._autoTimer);
    }

    autoSlide() {
        this._autoTimer = setTimeout(()=>{
            this.autoSlide();
        }, 6000);
        this.props.activeSlide++;
        if (this.props.activeSlide > this.props.content.slides.length-1) {
            this.props.activeSlide = 0;
        }
        for( let i = 0, l = this.props.content.slides.length; i < l; i++){
            this.props.content.slides[i].active = false;
        }

        this.props.content.slides[this.props.activeSlide].active = true;
    }

    onGoToSlide( event ){
        if(event){
            this.props.activeSlide = Number(event.currentTarget.getAttribute('data-target'));
        }

        for( let i = 0, l = this.props.content.slides.length; i < l; i++){
            this.props.content.slides[i].active = false;
        }

        this.props.content.slides[this.props.activeSlide].active = true;
    }

}

register('slideshow-home', SlideshowHome);
