import {register} from "light";
import {Component} from "light";
//import {StateProvider} from "light";
import {EventDispatcher} from "light";

import {utils} from 'light';

var optionalParam = /\((.*?)\)/g;
var namedParam = /(\(\?)?:\w+/g;
var splatParam = /\*\w+/g;
var escapeRegExp = /[\-{}\[\]+?.,\\\^$|#\s]/g;


class LightCoreStateMachine extends Component {

	get defaultProps() {
		return {
			base: '',
			onQueryChange: {type: 'fn', value: function(){}},
			useHashbang: {type: 'bool', value: true},
		}
	}

	_routeToRegExp (route) {
	    route = route.replace(escapeRegExp, '\\$&')
	      .replace(optionalParam, '(?:$1)?')
	      .replace(namedParam, function (match, optional) {
	        return optional ? match : '([^/?]+)';
	      })
	      .replace(splatParam, '([^?]*?)');
	    return new RegExp('^' + route + '(?:\\?([\\s\\S]*))?$');
	}

	created() {

		this.states = {};

		this._history = {};

		//StateProvider.initialize( this.props.base );
		this.lastState = null;
	    this.base = window.location.protocol + '//' + window.location.host + window.location.pathname + window.location.search;
	    EventDispatcher.on('state:change', this.onStateChange, this);
	    EventDispatcher.on('state:pop', this.onStatePop, this);
	    EventDispatcher.on('query:change', this.onQueryChange, this);
	    window.addEventListener('hashchange', this._onHashChange.bind(this));

	}

	onQueryChange(queryParams) {

		let lastKnowRouteStateName = this._lastRouteState;
		let lastKnowRouteState = this._history[ this._lastRouteState ];
		let lastKnowStateValue = lastKnowRouteState.states[ lastKnowRouteState.index ];

		lastKnowStateValue.queryParams = lastKnowStateValue.queryParams || {};

		for (let k in queryParams) {
			if (queryParams.hasOwnProperty(k)) {
				if (queryParams[k] === false) {
					delete lastKnowStateValue.queryParams[k];
				}
				else {
					lastKnowStateValue.queryParams[k] = queryParams[k];
				}
			}
		}

		//console.log('onQueryChange', lastKnowRouteStateName);

		this.onStateChange(lastKnowRouteStateName, lastKnowStateValue);
		this.props.onQueryChange(lastKnowStateValue.queryParams);

	}

	ready() {
		this._getHashState();
	}

	add(state, route, callback) {

		this._add( state, {
			route: route,
			controller: callback
		});

		this._getHashState();

	}

	_getHashState() {
		this._onHashChange();
	}

	_add( stateName, options ){

	    var options = utils.extend({
	      controller: null,
	      route: null,
	      //TODO:
	      // deepState
	      // stickyState: function(){},
	      // deepStateRedirect: {
	      //        default: { state: "foo.bar.baz.defaultSubState", params: { defaultStateParam1: "99" } },
	      //        fn: function($dsr$) {}
	      // }
	    }, options || {});

	    if( typeof options.controller == "object" ){
	      var controller = options.controller;
	      var method =  options.method || 'index';
	      options.controller = controller[method].bind( controller );
	    }

	    //generate regular expression from route
	    //extract namesParameters from route
	    if( options.route ){
	      options.regExp = this._routeToRegExp( options.route );
	      options.paramList = this._extractParamsFromRoute( options.route );
	    }

	    this.states[ stateName ] = options;

	}

	//state chnage is trigger as internal events.
    //if statechnage is triggered, ignore following hashchnage to avoid infinite loops
    onStateChange (state, stateParams) {


		//console.log(':::::onStateChange', state);

		if (state in this.states) {



			if (this._history[state] === void 0 ) {
				this._history[state] = {
					index: 0,
					states: []
				};
			} else {
				this._history[state].index += 1;
			}

			/**
			 * If a state is publish containing a query string, extract it and explode in 
			 * to params inside queryParams
			 */
			for (let k in stateParams) {
				if (stateParams.hasOwnProperty(k) && k !== 'queryParams' && k !== 'path') {
					if (typeof stateParams[k] === 'string'
						&& /\?.*/.test(stateParams[k]) ) {

						let query;
						stateParams[k] = stateParams[k].replace(/\?(.*)/, function(a, b, c){ query = b; return ''; });
						
						if (query) {
							stateParams.query = query;
						}

						stateParams.queryParams = this._queryStringToQueryParams( query, stateParams.queryParams || {} );

					}
				}
			}

			//if the state as a route, save it to the url
			if( this.states[state].route ){
				clearTimeout(this.bypassTimer);
				this.bypass = true;
				let path = this.hrefFromParams( state, stateParams );
				if (stateParams.queryParams !== void 0) {
					path += this._buildQueryFromParams( stateParams.queryParams );
				}
				stateParams.path = path;
				window.location.hash = (this.props.useHashbang?'#!':'#') + path;
				this.bypassTimer = setTimeout(()=>{ this.bypass = false; }, 50, this);
				this._lastRouteState = state;
			}


			this._history[state].states.push( stateParams );

			let prevState = this._history[state].index > 0 ? this._history[state].states[ this._history[state].index - 1 ] : null;

			if( this.states[state].controller ){
				this.states[state].controller( stateParams, prevState );
			}

		}
	
    }

    _buildQueryFromParams(params) {

    	let query = '';

    	for (let k in params) {
    		if (params.hasOwnProperty(k)) {
				if (Object.prototype.toString.call( params[k] ) === '[object Array]') {
	    			for (let i=0;i<params[k].length; i++) {
	    				query += encodeURIComponent(k) + '[]' +'=' + encodeURIComponent( params[k][i] ) + '&';
	    			}
				}
	  			else {
	  				query += k + '=' + encodeURIComponent(params[k]) + '&';
	  			}
    		}
    	}

    	query = query.replace(/\&$/,'');
    	
    	if (query !== '') {
    		query = '?' + query;
    	}
    	
    	return query;
    } 



    /**
     * remove last state entry
     * fire new state if given. else roll back to last know state
     */
    onStatePop(state, stateParams) {

		//console.log("onStatePop");

    	if (this._history[state] !== void 0) {
    		this._history[state].index -= 1;
    		if (this._history[state].index < 0) {
    			this._history[state].index = 0;
    		}
    		this.onStateChange(state, this._history[state].states[ this._history[state].index ] );
    	}
    }


	_extractParamsFromRoute (route) {

		var params = [];

		var namesArgs = route.match(namedParam);

		if (namesArgs != null) {
			for (var i = 0; i < namesArgs.length; i++) {
				params.push( namesArgs[i].replace(':', '') );
			}
		}

		var splatArgs = route.match(splatParam);
		if (splatArgs != null) {
			for (var i = 0; i < splatArgs.length; i++) {
				params.push( splatArgs[i].replace('*', '') );
			}
		}

		return params;

	}


	hrefFromParams (state, params) {

		if( !state in this.states ){
			return 'no state found :: ' + state;
		}

		if( !this.states[state].route ){
			return 'no route found';
		}

		var route = this.states[state].route;
		var originalRoute = route;

		for (var p in params) {

			if (p !== 'path' && p !== 'queryParams') {

				var namedParamRegExp = new RegExp('(\\(\\?)?:' + p, 'g');
				var splatParamRegExp = new RegExp('\\*' + p, 'g');
				var optionalParamRegExp = new RegExp('\\([]\\)', 'g');

				route = route.replace(namedParamRegExp, params[p])
								.replace(splatParamRegExp, params[p])
								  .replace(/(\(|\))/g, '')
								  	.replace('//', '/');
			}

		}

		return route;

	}

	_onHashChange() {

		//console.log('_onHashChange');

		if (this.bypass) {
		  return;
		}

		var self = this,
		  state = this.getState(),
		  args = [];

		for (var s in this.states) {

		  if (this.states[s].regExp && this.states[s].regExp.test(state.path)) {
		   
		    state.path.replace(this.states[s].regExp, function () {

		      for (var a = 1; a < arguments.length - 3; a++) {
		        if (arguments[a] === void 0) {
		          args.push("");
		        }
		        else {
		          args.push(arguments[a]);
		        }
		      }

		    });
		    
		    var stateParams = {};
		    
		    for (var i = 0; i < args.length; i++) {
		      stateParams[self.states[s].paramList[i]] = args[i];
		    }

		    stateParams.queryParams = {};

		    if (state.query !== void 0
		    	&& state.query !== '') {
		    	stateParams.query = state.query;
		    	stateParams.queryParams = this._queryStringToQueryParams( state.query );
		    }

		    // if (s in this._history && this._history[s].index > 0) {
		    // 	EventDispatcher.fire('state:change', [s, this._history[s].states[ this._history[s].index - 1 ] ]);
		    // }
		    // else {
		    	
		    	//console.log('fire state:change', s);
		    	EventDispatcher.fire('state:change', [s, stateParams]);
		    //}

		    break;

		  }

		}

	}

	_queryStringToQueryParams(query, queryParams) {

		queryParams = queryParams !== void 0 ? queryParams : {};

		let args = decodeURIComponent(query).split('&');

    	for (let i=0;i<args.length; i++) {
    		let argKeyVal = args[i].split('=');
    		let key   = argKeyVal[0].replace(/\[([^\]])*\]/g,'');
    		let value = argKeyVal[1];

    		if ( key in queryParams ) {
    			if (Object.prototype.toString.call( queryParams[key] ) !== '[object Array]') {
	    			let savedValue = queryParams[key];
	    			queryParams[key] = [];
	    			queryParams[key].push( savedValue );
    			}
	    		queryParams[key].push( value );
    		}
    		else{
    			queryParams[key] = value;
    		}
    	}

    	return queryParams;

	}


	getState () {

		var query = '';

		var path = document.location.hash.replace(/^#\!?/, '').replace(/\?(.*)/, function(a, b, c) {
			query = b;
			return '';
		});

		return {
			path: path,
			query: query
		}
	}

}

register('light-core-state-machine', LightCoreStateMachine);