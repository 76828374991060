import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AddTeamMember extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            'click .js-add-email': 'addEmail'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }

    created(){
        this.currentId = 0;
        this.componentReady = false;
    }

    ready(){
        setTimeout( () => {
            this.$email = this.querySelector('.js-new-email');

            if(this.$email){
                this.componentReady = true;
            }
            else {
                this.ready();
            }
        }, 100);
    }

    onSuccess( data ){
        if(data.body.success){
            EventDispatcher.fire('state:change', ['popin:close']);
            EventDispatcher.fire('notify', [{title: data.body.response.title,body: data.body.response.body }]);
        }
        else {
            this.props.errorMessage = data.body.response.body;
            EventDispatcher.fire('notify', [{title: data.body.response.title,body: data.body.response.body }]);
        }
    }

    onError(data){
        console.log('error', data.body);
    }

    checkEmail(){
         if (!this.$email.value
            || this.$email.value == ''
            || ! /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( this.$email.value ) ){

            this.$email.classList.add('is-error');
            this.$email.classList.remove('is-success');

            return false;
        }
        else{
            this.$email.classList.remove('is-error');
            this.$email.classList.add('is-success');

            return true;
        }
    }

    addEmail(){
        if(this.checkEmail()){
            this.currentId++;

            this.props.content.form.inputs.push({
                "value": this.$email.value,
            });

            this.$email.value = '';
        }
    }

    _removeEmail(e) {
        let index = Number(e.currentTarget.getAttribute('index'));
        this.props.content.form.inputs.splice( index, 1);
    }


}

register('add-team-member', AddTeamMember);
