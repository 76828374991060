import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class PastEvent extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {           
            'pointerdown .js-more':       '_onMoreDown'
        }
    }

    get defaultProps(){
        return {
            content:        { type: 'json',   value: null  },
            isLoading:      { type: 'bool',   value: false },
            currCategory: 'events',
            more:  '',
            page: 0,
            posts:          { type: 'arr',    value: null, change: '_onPostChange' },
            data: {type: 'json', value: null}
        }
    }

    _onPostChange() {
        clearTimeout( this._loaderTimer );
        this._loaderTimer = setTimeout(()=>{
            this.props.isLoading = false;
        },300);
    }

    ready() {

        EventDispatcher.fire('page:ready');
      
        EventDispatcher.request('search:store', [(data)=>{

            this.props.posts = data.posts;

            this.props.data = data;

            requestAnimationFrame(()=>{                
                EventDispatcher.fire('search', [{
                    category: this.props.currCategory,
                    page: 0
                }]);

            });

        }]);

    }

    _onMoreDown() {
        
        this.props.page++;
        console.log(this.props.page);
        EventDispatcher.fire('search', [{
            category: this.props.currCategory,
            page: this.props.page,
        }]);

        EventDispatcher.fire('state:change', ['page', {
            url: '/PastEvent',
            queryParams: {
                category: this.props.currCategory,
                page: this.props.page,
            }
        }]);

    }

}

register('past-event', PastEvent);
