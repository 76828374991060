import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

function getAbsoluteBoundingRect(el) {
    var doc  = document,
        win  = window,
        body = doc.body,

        // pageXOffset and pageYOffset work everywhere except IE <9.
        offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
            (doc.documentElement || body.parentNode || body).scrollLeft,
        offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
            (doc.documentElement || body.parentNode || body).scrollTop,

        rect = el.getBoundingClientRect();

    if (el !== body) {
        var parent = el.parentNode;

        // The element's rect will be affected by the scroll positions of
        // *all* of its scrollable parents, not just the window, so we have
        // to walk up the tree and collect every scroll offset. Good times.
        while (parent !== body) {
            offsetX += parent.scrollLeft;
            offsetY += parent.scrollTop;
            parent   = parent.parentNode;
        }
    }

    return {
        bottom: rect.bottom + offsetY,
        height: rect.height,
        left  : rect.left + offsetX,
        right : rect.right + offsetX,
        top   : rect.top + offsetY,
        width : rect.width,
        screenTop: rect.top,
        screenLeft: rect.left,
        screenBottom: rect.bottom,
        screenRight: rect.right,
    };

}


class HowItWorks extends Component {

    get events() {
        return {
            "click .js-feature-btn": "_onFeatureBtnDown"
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            scroll: { type: 'arr', value: [0,0] },
            sectionBoundings: {type: 'arr', value: []},
            winHeight: { type: 'float', value: window.innerHeight },
            winWidth: { type: 'float', value: window.innerWidth },
            featuresIndex: {type: 'int', value: 0},
            currentSection: {type: 'int', value: 0},
            scrollDuration: {type: 'float', value: 1000},
            scrollEasing: { type: 'array', value: [0.300, 0.100, 0.050, 1.000] },
            restart: { type: 'bool', value: false },
            allSectionsHeight: {type: 'float', value: 1000},
            pageOffsetY: {type: 'float', value: 0},

            features: {
                type: 'json',
                value: [
                    { title: "360° view" },
                    { title: "Video chat" },
                    { title: "Webcasts" },
                    { title: "The Hub" },
                    { title: "Product Showcase" },
                    { title: "Network contact" },
                    { title: "Social Buzz" },
                ]
            }
        }
    }

    _onFeatureBtnDown(event) {
        console.log('_onFeatureBtnDown', Number(event.currentTarget.getAttribute('index')) );
        this.props.featuresIndex = Number(event.currentTarget.getAttribute('index'));
    }

    _onFeaturesItemsChange(items) {
        this._numFeatures = items.length;
    }

    created() {
        this.bindMethods(['_onScroll', '_onWheel']);
        this.PIXEL_STEP  = 10;
        this.LINE_HEIGHT = 40;
        this.PAGE_HEIGHT = 800;
        this._sections = [];
        this._scrollTop = 0;
        this._scrollLeft = 0;
        this._numFeatures = 1;
        this._releaseScroll = false;
    }

    resize() {

        this.$sections = this.querySelectorAll('.js-section');

        let totalHeight = 0;

        for (let i=0; i<this.$sections.length; i++) {

            let bounding = getAbsoluteBoundingRect( this.$sections[i] );
            this._sections[i] = bounding;
        
            if (this.props.sectionBoundings[i] === void 0) {
                this.props.sectionBoundings.push({
                    top: 0,
                    bottom: 0,
                    height: 0,
                    screenTop: 0,
                    screenBottom: 0
                });
            }
            
            this.props.sectionBoundings[i].top = bounding.top;
            this.props.sectionBoundings[i].bottom = bounding.bottom;
            this.props.sectionBoundings[i].height = bounding.height;
            this.props.sectionBoundings[i].screenTop = bounding.screenTop;
            this.props.sectionBoundings[i].screenBottom = bounding.screenBottom;

            totalHeight += this.props.sectionBoundings[i].height;

        }

        this.props.winHeight = window.innerHeight;
        this.props.winWidth = window.innerWidth;
        this.props.allSectionsHeight = totalHeight;


        this.props.pageOffsetY = getAbsoluteBoundingRect( this ).top;

        
    }

    ready() {
        document.addEventListener('scroll', this._onScroll);
        document.addEventListener('wheel', this._onWheel);
        
        setTimeout(()=>{
            this.resize();            
        },100);
        setTimeout(()=>{
            this.resize();            
        },1000);
        setTimeout(()=>{
            this.resize();            
        },2000);
        setTimeout(()=>{
            this.resize();            
        },3000);
    }

    _onScroll( event ) {
        
        this.$howitworkssections = this.querySelectorAll('.js-howitworks');        
        if(this.$howitworkssections.length <=0) return;

        if(this._isScrollFromWheel || this._scrollIsAnimated){
            this._isScrollFromWheel = false;
            return;
        }

        this._scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        if (  this.props.winWidth > 767 &&
            this._scrollTop < this._sections[this._sections.length-1].top ){
            if (this._releaseScroll) {
                window.scrollTo(0, this._sections[this._sections.length-1].top );
                this._releaseScroll = false;
            }
        }
        else {
            this._releaseScroll = true;
        }

    }

    _onWheel( event ) {
        this.$howitworkssections = this.querySelectorAll('.js-howitworks');        
        if(this.$howitworkssections.length <=0) return;

        if (this._releaseScroll || this.props.winWidth <= 767) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        
        clearTimeout(this.wheelTimer);
        this.wheelTimer = setTimeout(()=>{
            this._isWheeling = false;
        }, 100);


        if (Math.abs(-this.normalizeWheel(event).pixelY) < 20 ) {
            clearTimeout(this.wheelTimer);
            this._isWheeling = false;
        }

        if (this._isWheeling
            || Math.abs(-this.normalizeWheel(event).pixelY) < 20
            || Date.now() - this._lastWheelTime < 1000 ) {
            return;
        }

        this._lastWheelTime = Date.now();

        this._wheelEndTimer = setTimeout(()=>{
            clearTimeout(this.wheelTimer);
            this._isWheeling = false;
        }, 2000);

        this._isWheeling = true;

        this._isScrollFromWheel = true;

        let normalizeWheel = this.normalizeWheel(event);
        var wheelDeltaY = -normalizeWheel.pixelY;
        var delta = wheelDeltaY;


        if (wheelDeltaY < 0) {
            if (this.props.currentSection >= this._sections.length-1) {
                if (this.props.featuresIndex < (this._numFeatures/2) - 1) {
                    this.props.featuresIndex += 1;
                }
                else {
                    this._isScrollFromWheel = false;
                    window.scrollTo(0, this._sections[this._sections.length-1].top + 1 );
                }
            }
            else {
                this.goToScroll(this.props.currentSection+1, false, 800);
            }
        }
        else if( wheelDeltaY > 0 ){
            if (this.props.featuresIndex >= 0) {
                this.props.featuresIndex -= 1;
            }
            else {
                this.goToScroll(this.props.currentSection-1, false, 800);
            }
        }

    }

    normalizeWheel(event) {

        this._sX = 0;
        this._sY = 0; // spinX, spinY

        this._pX = 0;
        this._pY = 0; // pixelX, pixelY

        // Legacy
        if ('detail'      in event) { this._sY = event.detail; }
        if ('wheelDelta'  in event) { this._sY = -event.wheelDelta / 120; }
        if ('wheelDeltaY' in event) { this._sY = -event.wheelDeltaY / 120; }
        if ('wheelDeltaX' in event) { this._sX = -event.wheelDeltaX / 120; }

        // side scrolling on FF with DOMMouseScroll
        if ( 'axis' in event && event.axis === event.HORIZONTAL_AXIS ) {
            this._sX = this._sY;
            this._sY = 0;
        }

        this._pX = this._sX * this.PIXEL_STEP;
        this._pY = this._sY * this.PIXEL_STEP;

        if ('deltaY' in event) { this._pY = event.deltaY; }
        if ('deltaX' in event) { this._pX = event.deltaX; }

        if ((this._pX || this._pY) && event.deltaMode) {
            if (event.deltaMode == 1) {          // delta in LINE units
                this._pX *= this.LINE_HEIGHT;
                this._pY *= this.LINE_HEIGHT;
            } else {                             // delta in PAGE units
                this._pX *= this.PAGE_HEIGHT;
                this._pY *= this.PAGE_HEIGHT;
            }
        }

        // Fall-back if spin cannot be determined
        if (this._pX && !this._sX) { this._sX = (this._pX < 1) ? -1 : 1; }
        if (this._pY && !this._sY) { this._sY = (this._pY < 1) ? -1 : 1; }

        return { spinX  : this._sX,
            spinY  : this._sY,
            pixelX : this._pX,
            pixelY : this._pY };
    }


    goToScroll(target, immediately, duration){

        if(!this._sections) return;

        target = Number(target);

        if( target < 0){
            target = 0;
        }
        else if (target > this._sections.length-1){
            target = this._sections.length-1;
        }

        this.props.currentSection = target;
        window.scrollTo(0, this.props.scroll[1]);

        this.props.isAnimated = true;
        this.props.scrollDuration = (duration) ? duration : 1200;
        this.onAnimStart(target == 0 ? 0 : this._sections[target].top);
        
    }

    onAnimStart( targetScroll ) {
        // init var
        this._scrollTarget  = targetScroll;
        this._saveScrollVal = this._scrollTop;        
        this._scrollIsAnimated = true;
        this.props.restart = true;
        requestAnimationFrame(()=>{
            this.props.restart = false;
        });
    }

    onAnimStep( ratio ){
        this._scrollTop = this._saveScrollVal + (this._scrollTarget - this._saveScrollVal) * ratio;
        window.scrollTo(0, this._scrollTop);

    }

    onAnimComplete(){
        this._scrollTop = this._scrollTarget;
        this._scrollIsAnimated = false;
    }

    update(){
        //this.props.scroll[1] += ( this._scrollTop - this.props.scroll[1] ) * 0.1;
        this.props.scroll[1] = this._scrollTop;
    }


   
}

register('how-it-works', HowItWorks);
