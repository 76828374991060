import {register} from "light";
import {Component} from "light";
import {support} from "light";

function calculateAspectRatio(srcWidth, srcHeight, maxWidth, maxHeight, fit) {

    var ratio = [maxWidth / srcWidth, maxHeight / srcHeight ];
    ratio = (fit) ? Math.min(ratio[0], ratio[1]) : Math.max(ratio[0], ratio[1]);

    return { width: srcWidth*ratio, height: srcHeight*ratio };
    
}

class LightUiObjectFit extends Component {

    get defaultProps(){
        return {
            width:  { type: 'float', value: 0, change: "resize" },
            height: { type: 'float', value: 0, change: "resize" },
            type:   { type: 'str',   value: 'cover' }
        }
    }

    attached(){
        this.aspect = (this.props.type == 'cover') ? false : true;
        this.resize();
    }

    detached() {
        if (this.$img && this.$img !== null) {
            this.$img.onload = null;
        }
    }

    ready(){

        this.$img = this.querySelector('.js-img');

        if (this.$img !== null) {
            this.bindMethods(['forceImageLoad']);
            this.$img.onload = this.forceImageLoad;
            this.forceImageLoad();
        }

    }

    //use another image object to clear cache/style
    //to get real width/height of image
    forceImageLoad() {
        let self = this;
        let image = new Image();
        image.onload = function () {
            this.onload = null;
            this.onerror = null;
            self.props.width = this.width;
            self.props.height = this.height;
            self.resize();
        };
        image.onerror = function () {
            this.onload = null;
            this.onerror = null;
        };
        image.src = this.$img.src;
    }

    resize(){

        if (this.parentNode === void 0) {
            return;
        }
        
        this.parentWidth  = this.parentNode.clientWidth;
        this.parentHeight = this.parentNode.clientHeight;

        this.computedSize = calculateAspectRatio(this.props.width, this.props.height, this.parentWidth, this.parentHeight, this.aspect);

        this.offsetX = Math.round(this.parentWidth - this.computedSize.width) * .5;
        this.offsetY = Math.round(this.parentHeight - this.computedSize.height) * .5;

        this.style.position = 'absolute';

        this.style.width  = Math.round(this.computedSize.width) + 'px';
        this.style.height = Math.round(this.computedSize.height) + 'px';

        if (this.offsetX || this.offsetY) {

            //this.style[support.transform] = 'translate('+ (this.offsetX) +'px, '+ (this.offsetY) +'px) '+ support.translateZ;
            this.style.left = this.offsetX + 'px';
            this.style.top  = this.offsetY + 'px';

        }

    }

}

register('light-ui-object-fit', LightUiObjectFit);