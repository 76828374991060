import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

class CardStaff extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown .js-scrollbar-y': 'onSidebarDown',

            'mouseover .js-staff-roll-ref': '_onStaffRollRefOver',
            'mouseout .js-staff-roll-ref': '_onStaffRollRefOut',

            'mouseover .js-staff-roll': '_onStaffRollOver',
            'mouseout .js-staff-roll': '_onStaffRollOut',
            'click .jsvideocall': '_onVideoCall',
            'click .jsaudiocall': '_onAudioCall',
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            isSticky: { type: 'bool', value: true },
            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },

            dragTarget: { type: 'array', value: [0,0] }
        }
    }

    _onVideoCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),button.currentTarget.getAttribute('scheduleid'),false);
    }

    _onAudioCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),button.currentTarget.getAttribute('scheduleid'),true);
    }

    created(){
        this.componentReady = false;
        this._pointerNone   = false;

        this.horizontal = false;

        this._y = 0;
        this._oldDeltaY = 0;
        this._startDragY = 0;

        this._isStaffRollOver = false

        this._staffRollIndex     = 0;
        this._staffRollRefIndex  = 0;
    }

    ready(){

        this.timerReady = setTimeout( () => {
            this.$container = this.querySelector('.js-container');
            this.$content = this.querySelector('.js-content');
            this.$sidebarY = this.querySelector('.js-scrollbar-y');

            this.$staffRoll    = this.querySelectorAll('.js-staff-roll');
            this.$staffRollRef = this.querySelectorAll('.js-staff-roll-ref');

            if(this.$container && this.$content && this.$sidebarY && this.$staffRoll.length === this.props.content.list.length && this.$staffRollRef.length === this.props.content.list.length){
                this.componentReady = true;
                this.initDrag();

                this.bindMethods(['onSidebarMove', 'onSidebarUp']);
                document.addEventListener(support.pointermove, this.onSidebarMove, false);
                document.addEventListener(support.pointerup, this.onSidebarUp, false);
            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){

        if( window.innerWidth < 1024 ){
            this.props.isSticky = false;
        }
        else {
            this.props.isSticky = true;
        }
        
        if(this.componentReady){
            this.initDrag();
        }

        if(this.$staffRollRef){
            for(let i = 0; i<this.$staffRollRef.length; i++){
                this.$staffRoll[i].style.top = (this.$staffRollRef[i].offsetTop - this.$staffRollRef[i].offsetHeight*.5 - this._y) + 'px';
            }
        }
    }

    _onStaffRollRefOver(event){
        //this._staffRollRefIndex = event.currentTarget.getAttribute('data-index');
        //this.$staffRoll[this._staffRollRefIndex].classList.add('is-active');
    }

    _onStaffRollRefOut(event){
        //if(this._isStaffRollOver){
        //    return;
        //}
        //this.$staffRoll[this._staffRollRefIndex].classList.remove('is-active');
    }

    _onStaffRollOver(event){
        this._staffRollIndex = event.currentTarget.getAttribute('data-index');
        this.$staffRoll[this._staffRollRefIndex].classList.add('is-active');

        if(this._staffRollRefIndex === this._staffRollIndex){
            this._isStaffRollOver = true;
        }
    }

    _onStaffRollOut(){
        this.$staffRoll[this._staffRollRefIndex].classList.remove('is-active');
        this._isStaffRollOver = false;
    }

    initDrag(){
        // container Size
        // container Size
        this.props.containerSize[0] = this.$container.offsetWidth;
        this.props.containerSize[1] = this.$container.offsetHeight;

        if( this.horizontal ){

            // content Size
            this.maxTop = 0;
            this.maxLeft = 0;

            for ( let i = 0, length = this.$childrens.length; i < length; i++ ){

                let el = this.$childrens[i];
                let offsetLeft = 0;
                let offsetTop  = 0;

                do {
                    offsetLeft += el.offsetLeft;
                    offsetTop  += el.offsetTop;

                    el = el.offsetParent;
                } while( el );

                let computedTop = this.$childrens[i].offsetHeight;
                let computedLeft = offsetLeft + this.$childrens[i].offsetWidth;

                if (this.maxTop < computedTop ) {
                    this.maxTop = computedTop;
                }

                if( this.maxLeft < computedLeft ) {
                    this.maxLeft = computedLeft;
                }
            }

            this.props.contentSize[0] = this.maxLeft;
            this.props.contentSize[1] = this.maxTop;

            this._containerSidebarWidth = this.$sidebarX.parentNode.offsetWidth;
            let pourcentX = this._containerSidebarWidth / this.props.containerSize[0];
            this._ratioSidebarX = this.props.containerSize[0] / this.props.contentSize[0] * pourcentX;

            this._sidebarWidth = (this.props.containerSize[0] * this._ratioSidebarX);
            this.$sidebarX.style.width = this._sidebarWidth + 'px';
        }
        else {
            this.props.contentSize[0] = this.$content.offsetWidth;
            this.props.contentSize[1] = this.$content.offsetHeight;

            this._containerSidebarHeight = this.$sidebarY.parentNode.offsetHeight;
            let pourcentY = this._containerSidebarHeight / this.props.containerSize[1];
            this._ratioSidebarY = this.props.containerSize[1] / this.props.contentSize[1] * pourcentY;

            this._sidebarHeight = (this.props.containerSize[1] * this._ratioSidebarY);
            this.$sidebarY.style.height = this._sidebarHeight + 'px';
        }
    }

    onMove(x, y){
        // console.log('onMove', x* this._ratioSidebar);
        //this._isGrabbing = true;

        this.$container.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;

        if(!this._isPointerMove){
            this._y = y * this._ratioSidebarY;
            this.$sidebarY.style[support.transform] = 'translate(0px,'+ ( (y * this._ratioSidebarY) ) +'px) '+ support.translateZ;
        }

        for(let i = 0; i<this.$staffRollRef.length; i++){
            this.$staffRoll[i].style[support.transform] = 'translate(0px,'+ (-y) +'px) '+ support.translateZ;
        }
    }

    onDrag(){

        if(!this._pointerNone){
            this._pointerNone = true;
            this.$container.classList.add('u-force-pointer-none');

            EventDispatcher.fire('pointer:none');
        }
    }

    onEnd(){
        if(this._pointerNone){
            this._pointerNone = false;
            this.$container.classList.remove('u-force-pointer-none');

            EventDispatcher.fire('pointer:auto');
        }
    }

    onScrollEnd(){
        //this._isGrabbing = false;
    }

    onSidebarDown(event){
        this._isPointerDown = true;

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        event.currentTarget.classList.add('is-active');

        if( this.horizontal ){
            this._oldDeltaX = this._x;
            this._startDragX = this._pointerEvent.pageX;
        }
        else {
            this._oldDeltaY = this._y;
            this._startDragY = this._pointerEvent.pageY;
        }
    }

    onSidebarMove(event){
        if(!this._isPointerDown) return;

        if( !this._isPointerMove ){
            EventDispatcher.fire('pointer:none');
            this.classList.add('u-disable-selection');

            this._isPointerMove = true;
        }

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        if( this.horizontal ){
            this._x = this._oldDeltaX + (this._pointerEvent.pageX - this._startDragX);

            if( this._x < 0 ){
                this._x = 0;
            }

            if( this._x > (this._containerSidebarWidth - this._sidebarWidth) ){
                this._x = (this._containerSidebarWidth - this._sidebarWidth);
            }

            this.$sidebarX.style[support.transform] = 'translate('+ ( (this._x) ) +'px, 0px) '+ support.translateZ;

            this.props.dragTarget[0] = this._x / this._ratioSidebarX;
        }
        else {
            this._y = this._oldDeltaY + (this._pointerEvent.pageY - this._startDragY);

            if( this._y < 0 ){
                this._y = 0;
            }

            if( this._y > (this._containerSidebarHeight - this._sidebarHeight) ){
                this._y = (this._containerSidebarHeight - this._sidebarHeight);
            }

            this.$sidebarY.style[support.transform] = 'translate(0px, '+ ( (this._y) ) +'px) '+ support.translateZ;

            this.props.dragTarget[1] = this._y / this._ratioSidebarY;
        }
    }

    onSidebarUp(){
        if(!this._isPointerDown) return;
        this._isPointerDown = false;

        this.$sidebarY.classList.remove('is-active');
        this.classList.remove('u-disable-selection');
        EventDispatcher.fire('pointer:auto');

        if(!this._isPointerMove) return;

        this._isPointerMove = false;

    }

    detached(){
        clearTimeout(this.timerReady);

        document.removeEventListener(support.pointermove, this.onSidebarMove, false);
        document.removeEventListener(support.pointerup, this.onSidebarUp, false);
    }

}

register('card-staff', CardStaff);
