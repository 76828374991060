import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListBookmarks extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            data:    { type: 'json',   value: null },
            forceReload:    { type: 'bool',   value: false },
        }
    }

    _onListReady(data) {
        this.props.data = data;
    }


    ready() {
        EventDispatcher.on('bookmark:success', this._onBookmark, this);
    }

    _onBookmark() {
        this.props.forceReload = true;
        requestAnimationFrame(()=>{
            this.props.forceReload = false;
        });
    }

    detached() {
        EventDispatcher.off('bookmark:success', this._onBookmark, this);
    }

}

register('list-bookmarks', ListBookmarks);
