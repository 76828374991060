import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {utils} from 'light';

class StoreFeed extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
        	src: null,
        	data: { type: 'json', value: {
                'posts':  [],
                'total':  0,
                'rest':   0,
            }},
            params: { type: 'json',  value: {
                page: 0,
                num: 10,
            }}
        }
    }

    detached() {
        EventDispatcher.off('feed', this._onSearch, this);
    }

    _onFeed(params) {
        console.log('_onFeed', params);
        for (let k in params) {
            if (this.props.params[k] !== void 0) {
                this.props.params[k] = params[k];
            }
        }
    }

    ready() {
        EventDispatcher.on('feed', this._onFeed, this);
        EventDispatcher.setHandler('feed:store', (callback)=>{
            if (callback !== void 0) {
                callback( this.props.data );
            }
            return this.props.data;
        }, this);

    }

    _onSuccess(data) {
        if (this.props.params.page == 0) {
            this.props.data.posts.splice(0, this.props.data.posts.length);
        }
        this.props.data.posts.push.apply( this.props.data.posts, data.body.posts );
        this.props.data.rest  = data.body.rest;
        this.props.data.total = data.body.total;
    }

    _onError() {
        this.props.data = data.body;
    }

}

register('store-feed', StoreFeed);