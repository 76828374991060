import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class MyListItems extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-more': 'onLoadMore',
            'click .js-delete': '_openPopin'
        }
    }

    get defaultProps(){
        return {
            moreUrl: { type: 'string', value: '' },
            loadItems: { type: 'bool', value: false },
            onMoreSuccess: { type: 'func', value: function(){} },

            delete: { type: 'json', value: null },
            submitDelete: { type: 'bool', value: false },
            onDeleteSuccess: { type: 'func', value: function(){} },

            deleteParams: {type:'json', value: null}
        }
    }


    // Load more items
    // ----------------

    onLoadMore( event ){
        this.props.loadItems = true;
    }

    onMoreSuccess( data ){
        this.props.loadItems = false;
        this.props.onMoreSuccess( data.body );
    }

    onMoreError(){
        console.log('Error in load more request');
    }


    // Delete Item
    // ----------------

    _openPopin( event ){

        this.props.deleteParams = {
            id : Number(event.currentTarget.getAttribute('data-id'))
        };

        // EventDispatcher.fire('popinResponse:open', [ {'data': this.props.delete.popin} ]);
        // EventDispatcher.on(this.props.delete.popin.submit.state, this.onDeleteItem, this);

        const data = this.props.delete.popin;
        const params = [{
            title: data.title,
            body: data.desc,
            confirmLabel: data.submit.label,
            cancelLabel: data.cancel.label,
            onConfirm: this._deleteItem.bind(this),
            onCancel:  () => { console.log('delete item canceled'); }
        }];

        this.props.submitDelete = false;
        EventDispatcher.fire('confirm', params, this);
    }

    _deleteItem(){
        this.props.submitDelete = true;
        requestAnimationFrame( () => {
            this.props.submitDelete = false;
        });
    }

    _onDeleteSuccess( data ){

        if( data.body.success ){
            data.body.id = this.props.deleteParams.id;
            this.props.onDeleteSuccess( data.body );
        }
        else {
            EventDispatcher.fire('popinResponse:open', [ {'data': data.body.response} ]);
        }
    }

    _onDeleteError(){
        console.log('Error in delete item form');
    }


}

register('my-list-items', MyListItems);
