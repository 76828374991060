import {register} from "light";
import {Component} from "light";

// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');

class LightEngineMesh extends Component {

	get defaultProps(){
		return {
			position:      { type: "array",  value: [0,0,0], change: 'onPositionChange' },
			rotation:      { type: "array",  value: [0,0,0], change: 'onRotationChange' },
			scale:         { type: "array",  value: [1,1,1], change: 'onScaleChange' },
			lookAt:        { type: "array",  value: null , change: 'onLookAtChange'  },
			quaternion:    { type: "array",  value: null        },
			castShadow:    { type: "bool",   value: false       },
			receiveShadow: { type: "bool",   value: false       },
			frustumCulled: { type: "bool",   value: true        },
			onReady: { type: "fn",   value: function(){}        },
		}
	}

	enable() {
		this.mesh.visible = true;
		if (this.mesh) {
			// this.onPositionChange();
			// this.onRotationChange();
			// this.onLookAtChange();
			// this.onScaleChange();
		}
	}

	disable() {
		if (this.mesh) {
			this.mesh.visible = false;
		}
	}

	created(){
		this._cache = {
			rotation: {x:0, y: 0, z: 0},
			scale:    {x:0, y: 0, z: 0},
			position: {x:0, y: 0, z: 0},
		};
	}	

	detached(){
		if (this.scene !== void 0
			&& this.mesh !== void 0) {

			console.log('<light-engine-mesh> Dispose', this.scene.children.length)
			this.scene.remove( this.mesh );

			this.mesh.geometry = null;
			this.mesh.material = null;
			// this.mesh.geometry.dispose();
			// this.mesh.material.dispose();
			console.log('    <light-engine-mesh> Dispose', this.scene.children.length)
		}
	}

	ready(){

		//this.transform = mat4.create();
		
		this.mesh = new THREE.Mesh();
		this.addToScene();
		this.mesh.castShadow    = this.props.castShadow;
		this.mesh.receiveShadow = this.props.receiveShadow;

		this.mesh.frustumCulled = this.props.frustumCulled;

		this.meshTarget = new THREE.Vector3(0,0,0);

		this.onPositionChange();
		this.onRotationChange();
		this.onLookAtChange();
		this.onScaleChange();
		
		this.props.onReady(this, this.mesh);

	}

	addToScene(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-SCENE"){
				this.sceneNode = node;
				this.scene = this.sceneNode.scene;
				this.scene.add( this.mesh );
				break;
			}
			node = node.parentNode;
		}
	}
	
	onPositionChange() {

		console.log('<mesh> position chnage')
		if (this.mesh) {
			this.mesh.position.x = this.props.position[0];
			this.mesh.position.y = this.props.position[1];
			this.mesh.position.z = this.props.position[2];
			this.onLookAtChange();
		}
	}

	onRotationChange() {
		this._cache.rotation.x = -this.props.rotation[0] * Math.PI / 180;
		this._cache.rotation.y = -this.props.rotation[1] * Math.PI / 180;
		this._cache.rotation.z = -this.props.rotation[2] * Math.PI / 180;

		if (this.mesh !== void 0) {
			if (this.props.lookAt !== null) {
				this.meshTarget.x = this.props.lookAt[0];
				this.meshTarget.y = this.props.lookAt[1];
				this.meshTarget.z = this.props.lookAt[2];
				this.mesh.lookAt( this.meshTarget );
				this.mesh.rotation.x += this._cache.rotation.x;
				this.mesh.rotation.y += this._cache.rotation.y;
				this.mesh.rotation.z += this._cache.rotation.z;
			}
			else{
				this.mesh.rotation.x = this._cache.rotation.x;
				this.mesh.rotation.y = this._cache.rotation.y;
				this.mesh.rotation.z = this._cache.rotation.z;
			}
		}
	}

	onLookAtChange() {
		if (this.mesh !== void 0) {
			if (this.props.lookAt !== null) {
				this.meshTarget.x = this.props.lookAt[0];
				this.meshTarget.y = this.props.lookAt[1];
				this.meshTarget.z = this.props.lookAt[2];
				this.mesh.lookAt( this.meshTarget );
				this.mesh.rotation.x += this._cache.rotation.x;
				this.mesh.rotation.y += this._cache.rotation.y;
				this.mesh.rotation.z += this._cache.rotation.z;
			}
		}
	}

	onScaleChange() {
		if (this.mesh !== void 0) {
			this.mesh.scale.x = (this.props.scale[0] < 0.001) ? 0.001 : this.props.scale[0];
	        this.mesh.scale.y = (this.props.scale[1] < 0.001) ? 0.001 : this.props.scale[1];
	        this.mesh.scale.z = (this.props.scale[2] < 0.001) ? 0.001 : this.props.scale[2];
		}
	}

	update(){

		// if (this.transform) {
		// 	mat4.identity(this.transform);
		// 	mat4.translate(this.transform, this.transform, [
		// 		this.props.position[0],
		// 		this.props.position[1],
		// 		this.props.position[2]
		// 	]);
		// 	mat4.rotateX(this.transform, this.transform, this.props.rotation[0]);
		// 	mat4.rotateY(this.transform, this.transform, this.props.rotation[1]);
		// 	mat4.rotateZ(this.transform, this.transform, this.props.rotation[2]);
		// }

		if (this.mesh) {
            // debug scale, if it is egual to 0, webgl error : Matrix3.getInverse(): can't invert matrix, determinant is 0
			if (this.props.quaternion !== null) {
				this.mesh.quaternion.copy( this.props.quaternion );
			}
		}
		
	}

}

register('light-engine-mesh', LightEngineMesh);
