import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

import Pikaday from 'pikaday';

class CoreDatepicker extends Component {

    get defaultProps(){
        return {
            format: { type: 'string', value: 'DD/MM/YYYY' },
            onValueChange: { type: 'fn', value: function(){} },
        }
    }

    ready(){
        this.picker = new Pikaday({
            field: this,
            format: this.props.format,
            onSelect: (date) => {
                this.setAttribute('value', this.picker.toString(this.props.format));
                this.value = this.picker.toString(this.props.format);
                this.props.onValueChange({currentTarget: this});
            }
        });
    }

}

register('core-datepicker', CoreDatepicker);
register('light-ui-input-date', CoreDatepicker, 'input');

