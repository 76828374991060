import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class XTest extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            src: null,
            data: null,
            componentId: null,
            hasComponent: { type: 'bool', value: false },
            content: { type: 'json', value: null }
        }
    }
    
    ready() {
        if (this.props.content !== null) {
            this.props.hasComponent = true;
        }
    }

    _onDataLoaded( data ){
        this.props.content = data.body;
        this.props.hasComponent = true;
    }

    _onDataError(){
        console.log('error in json for ', this.props.componentId);
    }

}

register('x-test', XTest);

