import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ScheduleThree extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }

    onSuccess( data ){
        if(data.body.success){

            if( data.body.redirect.type == 'popin' ){
                EventDispatcher.fire('state:change', ['popin', { url: data.body.redirect.href, "save": true,"size":"small"}] );
            }
            else {
                EventDispatcher.fire('state:change', ['page', { url: data.body.redirect.href }] );
            }

        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }

    onError(data){
        console.log('error', data.body);
    }

}

register('schedule-three', ScheduleThree);
