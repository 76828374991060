import {Component} from 'light';
import {register} from 'light';

class AdminBookmarks extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    onMoreSuccess( data ){

        Array.prototype.push.apply(this.props.content.content.items, data.items);

        if( !data.more ){
            this.querySelector('.js-more').classList.add('u-hide');
        }
        else {
            this.props.content.content.more.label = data.more.label;
            this.props.content.content.more.href = data.more.href;
        }

        setTimeout( () => {
            let evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }, 100);

    }

}

register('admin-bookmarks', AdminBookmarks);
