import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

class CoreCollapse extends Component {

    get events() {
        return {
            'pointerdown .js-btn-collapse': 'onToggle'
        }
    }

    get defaultProps(){
        return {
            active: { type: 'bool', value: false, change: 'collapse' },
            minHeight: 0,
            height: null
        }
    }

    created(){
        this.componentReady = false;
    }

    ready(){
        setTimeout( () => {
            this.$container = this.querySelector('.js-container');
            this.$collapse = this.$container.children[0];
            //console.log(this.$collapse);
            if(this.$container && this.$collapse){
                this.componentReady = true;
                this.resize();
            }

        }, 100);
    }

    resize(){

        if(this.componentReady){
            //console.log(this.$collapse.clientHeight);
            this.props.height = this.$collapse.clientHeight;
            this.props.minHeight = getComputedStyle(this.$collapse)['min-height'];

        
            if( this.props.active ){
                this.$container.style.height = this.props.height + 'px';
            }
            else {
                this.$container.style.height = this.props.minHeight;
            }


            this._minHeight = Number( this.props.minHeight.replace('px','') );
            if (this.props.height > this._minHeight ) {
                this.classList.add('is-collapsable');
            }
            else {
                this.classList.remove('is-collapsable');
            }


        }

    }

    onToggle( event ){
        this.props.active = (this.props.active) ? false : true;
        event.currentTarget.classList.toggle('is-active');
    }

    collapse(){

        if( this.props.active ){
            this.$container.style.height = this.props.height + 'px';
            this.$container.classList.add('is-collapse');
        }
        else {
            this.$container.style.height = this.props.minHeight;
            this.$container.classList.remove('is-collapse');
        }

        setTimeout( () => {
            let evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }, 800);

    }
}

register('core-collapse', CoreCollapse);
