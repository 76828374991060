import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class IntroTimeline extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
        }
    }

}

register('intro-products', IntroTimeline);
