import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class ImageZoom extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            'pointermove': 'onPointerMove',
        }
    }

    get defaultProps(){
        return {
            active: { type: 'bool', value: false },
            src: { type: 'string', value: '' },

            zoomX: { type: 'float', value: 0 },
            zoomY: { type: 'float', value: 0 },

            imageX: { type: 'float', value: 0 },
            imageY: { type: 'float', value: 0 },

            lensSize: { type: 'float', value: 200 }
        }
    }

    created(){
        this.componentReady = false;

        this.ratioX = 0;
        this.ratioY = 0;

        this._zoomX = 0;
        this._zoomY = 0;

        this._imageX = 0;
        this._imageY = 0;

        this._scrollTop = 0;
    }

    attached(){
        this.$popin = document.querySelector('.c-popin');

        if(this.$popin){
            this.$popin.addEventListener('scroll', () => {
                this._scrollTop = this.$popin.scrollTop;
            });
        }

    }

    ready(){

        setTimeout( () => {
            this.$img = this.querySelector('.js-img');

            if(this.$img){
                this.componentReady = true;
                this.resize();
            }
            else {
                this.ready();
            }

        }, 100);

    }


    resize(){
        if(!this.componentReady) return;

        this._width = this.offsetWidth;
        this._height = this.offsetHeight;

        let el = this;
        let offsetLeft = 0;
        let offsetTop  = 0;

        do {
            offsetLeft += el.offsetLeft;
            offsetTop  += el.offsetTop;

            el = el.offsetParent;
        } while( el );


        this._offsetTop = offsetTop;
        this._offsetLeft = offsetLeft;

        this._imgWidth = this.$img.offsetWidth;
        this._imgHeight = this.$img.offsetHeight;

        this.ratioImgX = this._imgWidth / this._width ;
        this.ratioImgY = this._imgHeight / this._height;

    }

    onPointerMove(e){
        if(!this.props.active) return;

        this._touchEvent = support.touch && e.type != 'mousemove' ? (e.touches[0] || e.changedTouches[0]) : e;

        this._zoomX = (this._touchEvent.clientX) - this._offsetLeft;
        this._zoomY = (this._touchEvent.clientY) - this._offsetTop + this._scrollTop;

        this._imageX = -(this._zoomX * this.ratioImgX);
        this._imageY = -(this._zoomY * this.ratioImgY);
    }

    update(){
        this.props.zoomX += (this._zoomX - this.props.zoomX) * 0.3;
        this.props.zoomY += (this._zoomY - this.props.zoomY) * 0.3;

        this.props.imageX += (this._imageX - this.props.imageX) * 0.3;
        this.props.imageY += (this._imageY - this.props.imageY) * 0.3;
    }

}

register('image-zoom', ImageZoom);

