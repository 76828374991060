import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class MyTab extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            duration: { type: 'float', value: 40 }
        }
    }

    onIndexChange(index, slideshowIndex){
        this._index = index;

        this.onSlideChange(slideshowIndex);
    }

    onSlideChange(slideshowIndex){

        this._slideshowIndex = slideshowIndex;

        if(this._index == this._slideshowIndex){
            // this.classList.add('is-active');
            this.style.display = 'block';
            this.props.active = true;

            setTimeout( () => {
                let evt = window.document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);

            }, this.duration);
        }
        else {
            // this.classList.remove('is-active');
            this.style.display = 'none';
            this.props.active = false;
        }

    }

}

register('my-tab', MyTab);

