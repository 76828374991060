import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListDraft extends Component {


    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            
            content:   { type: 'json', value: null },

            inputAddHidden: {type: 'arr', value: []},
            inputDeleteHidden: {type: 'arr', value: []},

            // inputAddChanged: {type:'bool', value:false},
            // inputDeleteChanged: {type:'bool', value:false},
            // forceSubmit: {type:'bool', value: false},
            // formActionType: {type:'string', value:''},
        }
    }

    //-------------------------------
    //   CONNECT WITH admin-event

    _onClearDraft() {

        this.props.inputAddHidden     = [];
        this.props.inputDeleteHidden  = [];

    }


    attached(){
        EventDispatcher.on('admin:event:clear', this._onClearDraft, this);
        if (this.props.content.name === 'media') {
            //EventDispatcher.fire('select:media', [id]);
        }
    }

    _onAdd(post){

        for (var i=0; i<this.props.inputAddHidden.length; i++) {
            if (this.props.inputAddHidden[i].id == post.id) {
                console.log('POST ALREADY EXIST IN ADDED POST !');
                return;
                break;
            }
        }

        for (var i=0; i<this.props.inputDeleteHidden.length; i++) {
            if (this.props.inputDeleteHidden[i].id == post.id) {
                this.props.inputDeleteHidden.splice( i, 1 );
                console.log('POST WAS REMOVED, CANCEL IT');
                return;
                break;
            }
        }

        this.props.inputAddHidden.push({
            'id': post.id,
            'value': 'add-' + this.props.content.name
        });

    
    }

    _onDelete(post){

        for (var i=0; i<this.props.inputDeleteHidden.length; i++) {
            if (this.props.inputDeleteHidden[i].id == post.id) {
                console.log('POST ALREADY EXIST IN DELETED POST !');
                return;
                break;
            }
        }

        for (var i=0; i<this.props.inputAddHidden.length; i++) {
            if (this.props.inputAddHidden[i].id == post.id) {
                this.props.inputAddHidden.splice( i, 1 );
                console.log('POST WAS ADDED, CANCEL IT');
                return;
                break;
            }
        }

        this.props.inputDeleteHidden.push({
            'id': post.id,
            'value': 'delete-' + this.props.content.name
        });


    }
}

register('list-draft', ListDraft);