import {register} from "light";
import {Component} from "light";
import {support} from "light";
import {EventDispatcher} from "light";

class LightCoreOverlay extends Component {

    get defaultProps() {
        return {
            active:   { type: "bool",   value: false, change: 'onActiveChange' },
            easing:   { type: "string", value: "cubic-bezier(0.230, 1.000, 0.320, 1.000)" },
            duration: { type: "float", value: "1" },
        }
    }

    ready() {
        this.onActiveChange();
        setTimeout(()=>{
            this.onActiveChange();
        },500);
    }

    onActiveChange(){

        clearTimeout(this.timer);

        if (this.props.active) {

            this.classList.remove('u-hide'); //force hide all children
            //EventDispatcher.fire('resize');

            requestAnimationFrame( () => {
                this.style[support.transition] = 'opacity '+this.props.duration+'s '+this.props.easing+', visibility 0s 0s';
                this.style.visibility = "visible";
                this.style.opacity = 1;

                this.addOptimizeOnNode(this);

                // this.timer = setTimeout( () => {
                //     EventDispatcher.fire('resizeend');
                //     console.log('RSIZE END!');
                // }, 2000);

            }); // delay for keep transition

        }
        else {

            this.style[support.transition] = 'opacity '+this.props.duration+'s '+this.props.easing+', visibility 0s ' +this.props.duration+'s';
            this.style.visibility = "hidden";
            this.style.opacity = 0;

            this.timer = setTimeout( () => {
                this.classList.add('u-hide'); //force hide all children
                this.removeOptimizeOnNode(this);
            }, this.props.duration*1000);

        }
    }

    addOptimizeOnNode( node ){
        let childrens = node.childNodes;

        for( let i = 0, l = childrens.length; i < l; i++ ){
            if(childrens[i].nodeType == 1 && childrens[i].tagName !== 'LIGHT-CORE-OVERLAY'){

                // classList for ie11
                if (childrens[i].classList) {
                    childrens[i].classList.add('is-optimize');
                }
                else {
                    var cur = ' ' + (childrens[i].getAttribute('class') || '') + ' ';

                    if (cur.indexOf(' ' + 'is-optimize' + ' ') < 0) {
                        childrens[i].setAttribute('class', (cur + 'is-optimize').trim());
                    }
                }

                if (childrens[i].resize !== void 0) {
                    childrens[i].resize();
                }

                if (childrens[i].resizeEnd !== void 0) {
                    childrens[i].resizeEnd();
                }

                this.addOptimizeOnNode(childrens[i]);
            }
        }
    }

    removeOptimizeOnNode(node){
        let childrens = node.childNodes;

        for( let i = 0, l = childrens.length; i < l; i++ ){
            if(childrens[i].nodeType == 1 && childrens[i].tagName !== 'LIGHT-CORE-OVERLAY'){

                // classList for ie11
                if (childrens[i].classList) {
                    childrens[i].classList.remove('is-optimize');
                }
                else {
                    var cur = ' ' + (childrens[i].getAttribute('class') || '') + ' ';
                    var tar = ' ' + 'is-optimize' + ' ';

                    while (cur.indexOf(tar) >= 0) {
                        cur = cur.replace(tar, ' ');
                    }
                    childrens[i].setAttribute('class', cur.trim());
                }

                this.removeOptimizeOnNode(childrens[i]);
            }
        }
    }

}

register('light-core-overlay', LightCoreOverlay);
