import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class PageAdmin extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            // 'click .js-burger': 'onToggleNav',
            // 'click .js-overlay': 'onCloseNav'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            // title: '',
            // logout: { type: 'json', value: null }
        }
    }

    // created(){
    //     this.navOpen = false;
    // }

    // attached(){
    //     if(this.props.areas.header[0].componentId == 'admin-navigation'){

    //         // title page
    //         for (let i = 0, l = this.props.areas.header[0].content.links.length; i < l; i++ ){

    //             if(this.props.areas.header[0].content.links[i].active){
    //                 this.props.title = this.props.areas.header[0].content.links[i].label;
    //             }
    //         }

    //         // logout data
    //         this.props.logout = this.props.areas.header[0].content.logout;

    //     }
    // }

    ready(){
        EventDispatcher.fire('page:ready');
    }

    // onToggleNav(){

    //     if( !this.navOpen ){
    //         this.navOpen = true;
    //         EventDispatcher.fire( 'scroll:disable');
    //         this.classList.add('is-menu-open');
    //     }
    //     else {
    //         this.onCloseNav();
    //     }
    // }

    // onCloseNav(){
    //     this.navOpen = false;
    //     EventDispatcher.fire( 'scroll:enable');
    //     this.classList.remove('is-menu-open');
    //}

}

register('page-admin', PageAdmin);
