import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class MyAdminHeader extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-burger': '_onToggleNav',
            'click .js-overlay': '_onCloseNav',
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            title: '',
            logout: { type: 'json', value: null },
            panelX: { type: 'float', value: 0 },
            panelOverlayOpacity: { type: 'float', value: 0 },
            active: {type: 'bool', value: false, change: 'onActiveChange' },
            overflow: {type: 'bool', value: true },
        }
    }

    created(){
        this.bindMethods(['_onPointerDown','_onPointerMove','_onPointerUp']);
        this._navOpen = false;
        this._isPointerdown = false;

        this._pointerXDown = 0;
        this._pointerYDown = 0;
        this._pointerX = 0;
        this._pointerY = 0;

        this._panelXDown = 0;
        this._panelX = 0;
        this._panelDeltaX = 0;
        this._cumulatedXScroll = 0;
        this._cumulatedYScroll = 0;
        this._showNav = false;
    }

    attached(){
        EventDispatcher.on('state:change', this._onStateChange, this);
        document.addEventListener(support.pointerdown, this._onPointerDown, false);
        document.addEventListener(support.pointermove, this._onPointerMove, false);
        document.addEventListener(support.pointerup, this._onPointerUp, false);


        this._onPopState = this._onPopState.bind(this);
        window.addEventListener('popstate', this._onPopState, false);
        console.log('ATTACH POP STATE')

    }


    _onPopState() {
        console.log('POP STATE')
        this._onCloseNav();
    }

    detached(){
        EventDispatcher.off('state:change', this._onStateChange, this);
        document.removeEventListener(support.pointermove, this._onPointerDown, false);
        document.removeEventListener(support.pointermove, this._onPointerMove, false);
        document.removeEventListener(support.pointermove, this._onPointerUp, false);


        window.removeEventListener('popstate', this._onPopState, false);
    }

    resize() {
        this.props.winWidth = window.innerWidth;
        this._panelWidth = this.querySelector('.js-panel').clientWidth;
        if (this.props.winWidth < 1280) {
            this._panelDeltaX = -this._panelWidth;
        }
        else {
            this._panelDeltaX = 0;
            this._panelX = 0
        }
    }

    _onPointerDown(event) {
        this._isPointerdown = true;
        this._touchEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;
        this._pointerXDown = this._touchEvent.clientX;
        this._pointerYDown = this._touchEvent.clientY;
        this._panelXDown = this.props.panelX - this._panelDeltaX;
        this._panelWidth = this.querySelector('.js-panel').clientWidth;
    }

    _onPointerMove(event) {

        if (!this._isPointerdown || this._panelDeltaX ===0) {
            return;
        }

        this._touchEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        this._cumulatedXScroll = Math.abs(this._touchEvent.clientX - this._pointerXDown);
        this._cumulatedYScroll = Math.abs(this._touchEvent.clientY - this._pointerYDown);

        if ( this._pointerXDown < this._panelWidth) {
            
            if (this._cumulatedXScroll > 10 && this._cumulatedYScroll < 20 ) {
                EventDispatcher.fire( 'scroll:disable');
                this.props.overflow = false;
                this._showNav = true;
            }

            if (this._showNav) {
                event.preventDefault();
                this._panelX = this._panelXDown + (this._touchEvent.clientX - this._pointerXDown);
                if (this._panelX > this._panelWidth) {
                    this._panelX = this._panelWidth;
                }
                if (this._panelX < 0) {
                    this._panelX = 0;
                }
            }
        }

    }

    _onPointerUp(event) {

        this._showNav = false;
        this._cumulatedXScroll = 0;
        this._cumulatedYScroll = 0;

        if (!this._isPointerdown || this._panelDeltaX ===0) {
            return;
        }

        this._isPointerdown = false;
        this._touchEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        if (this._panelX > this._panelWidth * .5) {
           this.props.active = true;
        }
        else {
            this.props.active = false;
        }
        this.props.overflow = true;
        this.onActiveChange();
    }

    onActiveChange() {
        clearTimeout(this._overflowtimer);
        if (this.props.active) {
            this._panelX = this._panelWidth;
            EventDispatcher.fire( 'scroll:disable');
            // this.classList.add('is-menu-open');
        }
        else {
            this._panelX = 0;
            EventDispatcher.fire( 'scroll:enable');
            // this.classList.remove('is-menu-open');
        }
    }

    update() {
        this.props.panelX += ((this._panelX+this._panelDeltaX) - this.props.panelX) * 0.2;
        if (this._panelWidth !== 0) {
            this.props.panelOverlayOpacity += ((this._panelX/this._panelWidth) - this.props.panelOverlayOpacity) * 0.2;
        }
    }


    _onStateChange(state) {
        //if (state == 'page') {
            this._onCloseNav();
        //}
    }

    ready(){
        this.resize();
        setTimeout(()=>{
            this.resize();
        },1000);
    }

    _onToggleNav(){
        this.props.active = !this.props.active;
    }

    _onCloseNav(){
        this.props.active = false;
    }

}

register('my-admin-header', MyAdminHeader);



