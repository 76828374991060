import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class MyPopinResponse extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            active: { type: 'bool', value: false, change: 'togglePopin' },
            content: null,
        }
    }

    onClose(){
        this.props.active = false;
    }

    togglePopin(){
        if(this.props.active){
            this.classList.add('is-active');
            this.scrollTop = 0; // reset scroll

            requestAnimationFrame( () => {
                this.classList.add('is-loaded');
            });
        }
        else {
            this.classList.remove('is-active');
            this.classList.remove('is-loaded');
        }
    }

}

register('my-popin-response', MyPopinResponse);
