import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class signinverification extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }

    onSuccess( data ){
         if(data.body.success){
            EventDispatcher.fire('state:change',  ['popin:close']);			 
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }

    onError(data){
        console.log('error', data.body);
    }

}

register('sign-in-verification', signinverification);
