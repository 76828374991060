import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ChangePassword extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'change .js-strongpassword': 'onInputChange',
            'keydown .js-strongpassword': 'onInputChange',
            'keyup .js-strongpassword': 'onInputChange',
            'keypress .js-strongpassword': 'onInputChange'

        }
    }
    
    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }

    onSuccess( data ){
        if(data.body.success){
            EventDispatcher.fire('state:change',  ['popin:close']);			 
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }
    onInputChange()
    {
        this.$passwordFields   = this.querySelectorAll('.js-strongpassword');
        this.$passwordTextFields   = this.querySelectorAll('.js-strongpasswordtext');

        for (let i=0,l= this.$passwordFields.length; i<l; i++) {
            if (!this.$passwordFields[i].value || this.$passwordFields[i].value == ''){
                //isValid = false;
                //isValidPassword = false;
                this.$passwordFields[i].classList.add('is-error');
                this.$passwordFields[i].classList.remove('is-success');
            }
            else{
                var regex = new Array();
                regex.push("[A-Z]"); //Uppercase Alphabet.
                regex.push("[a-z]"); //Lowercase Alphabet.
                regex.push("[0-9]"); //Digit.
                regex.push("[$@$!%*#?&]"); //Special Character.

                var passed = 0;

                //Validate for each Regular Expression.
                for (var j = 0; j < regex.length; j++) {
                    if (new RegExp(regex[j]).test(this.$passwordFields[i].value)) {
                        passed++;
                    }
                }


                //Validate for length of Password.
                if (passed > 2 && this.$passwordFields[i].value.length > 8) {
                    passed++;
                }

                //Display status.
                var color = "";
                var strength = "";                
                switch (passed) {
                    case 0:
                    case 1:
                        strength = "Short";
                        color = "red";
                        break;
                    case 2:
                        strength = "Weak";
                        color = "brand-video";
                        break;
                    case 3:
                    case 4:
                        strength = "Good";
                        color = "brand-all";
                        break;
                    case 5:
                        strength = "Strong";
                        color = "brand-article";
                        break;
                }
                if (strength == "Weak" || strength == "Good" || strength == "Short") {
                    //isValid = false;
                    //isValidPassword = false;
                    this.$passwordTextFields[i].innerHTML="<span class='u-color--" + color+"'>" + strength + "</span>";
                    this.$passwordTextFields[i].classList.remove('u-marg-b-sm');
                    this.$passwordFields[i].classList.add('is-error');
                    this.$passwordFields[i].classList.remove('is-success');
                }
                else{
                    this.$passwordTextFields[i].innerHTML="<span class='u-color--" + color+"'>" + strength + "</span>";
                    this.$passwordTextFields[i].classList.remove('u-marg-b-sm');
                    this.$passwordFields[i].classList.remove('is-error');
                    this.$passwordFields[i].classList.add('is-success');                    
                }
            }
            
        }
    }
    onError(data){
        console.log('error', data.body);
    }
}

register('change-password', ChangePassword);
