import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminAccount extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-delete': 'onDelete'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            submitDelete: { type: 'bool', value: false },
            isPasswordActive: { type: 'bool', value: false },
            errorMessage: null,

            inputTagAdded: { type: 'array', value: [] },
            inputTagDeleted: { type: 'array', value: [] },
            forceTagSubmit: { type: 'boolean', value: false }
        }
    }

    created() {
        this.bindMethods(['_onConfirmDelete', '_onCancelDelete']);
    }

    attached(){
      //  EventDispatcher.on('user:delete', this.onDeleteUser, this);
    }

    _onTagAdd(data) {
        console.log('AdminAccount._onTagAdd()', data)
        this.props.inputTagAdded.push({
            'id': data.id,
            'value': 'add'
        });
    }

    _onTagDelete(data) {
        console.log('AdminAccount._onTagDelete()', data)
        if (data.added) {
            for (let i = 0, l = this.props.inputTagAdded.length; i < l; i++) {
                if (this.props.inputTagAdded[i].id === data.id ) {
                    this.props.inputTagAdded.splice(i, 1);
                }
            }
        }
        else {
            this.props.inputTagDeleted.push({
                'id': data.id,
                'value': 'delete'
            });
        }
    }

    _submitTags() {
        this.props.forceTagSubmit = true;
        requestAnimationFrame(() => {
            this.props.forceTagSubmit = false;
        });
    }

    _onTagError( data ){
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    _onTagSuccess( data ){
        this.props.inputTagAdded = [];
        this.props.inputTagDeleted = [];
    }



    //---------------------------
    //

    // Edit account
    onEditSuccess( data ){
        if (data.body.success) {
            this.props.errorMessage = null;
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
            EventDispatcher.fire('page:reload');
        }
        else {
            this.onEditError(data);
        }

        this._submitTags();
    }

    onEditError( data ){
        this.props.errorMessage = data.body.response.body;
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);

        this._submitTags();
    }

    // Delete account
    onDelete(){
        EventDispatcher.fire('confirm', [{
            title: 'Are you sure?',
            body: 'This action is irreversible, your staff member will be deleted',
            confirmLabel: "delete",
            cancelLabel: "cancel",
            onConfirm: this._onConfirmDelete,
            onCancel:  this._onCancelDelete
        }]);
    }

    _onConfirmDelete() {
        this.props.submitDelete = false;
        requestAnimationFrame( () => {
            this.props.submitDelete = true;
        });
    }

    _onCancelDelete() {

    }

    onDeleteSuccess( data ){
        if (data.body.success) {
            EventDispatcher.fire('state:change', [ 'page', {'url': data.body.redirect} ]);
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.onDeleteError(data);
        }
    }

    onDeleteError( data ){
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    _onFileError() {
        console.log('ON FILE ERROR');
        this.props.errorMessage = 'file format error';
    }

}

register('admin-account', AdminAccount);
