import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class RelatedEvents extends Component {

    get events(){
        return {
            'pointerdown .js-scrollbar': 'onSidebarDown'
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },

            dragTarget: { type: 'array', value: [0,0] },

            tileSize: {type:'string', value:'default'}
        }
    }

    created(){
        this.componentReady = false;

        this._pointerNone = false;

        this._x = 0;
        this._oldDelta = 0;
        this._startDrag = 0;
    }

    ready(){
        this.timerReady = setTimeout( () => {
            this.$container = this.querySelector('.js-container');
            this.$sidebar = this.querySelector('.js-scrollbar');
            this.$childrens = this.$container.querySelectorAll(".js-drag-children");

            if(this.$container && this.$sidebar && this.$childrens && this.$childrens.length == this.props.content.events.length+1){
                this.componentReady = true;
                this.initDrag();

                this.bindMethods(['onSidebarMove', 'onSidebarUp']);
                document.addEventListener(support.pointermove, this.onSidebarMove, false);
                document.addEventListener(support.pointerup, this.onSidebarUp, false);
            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){
        if(this.componentReady){
            this.initDrag();
        }
    }

    initDrag(){
        // container Size
        this.props.containerSize[0] = this.$container.offsetWidth;
        this.props.containerSize[1] = this.$container.offsetHeight;

        // content Size
        this.maxTop = 0;
        this.maxLeft = 0;

        for ( let i = 0, length = this.$childrens.length; i < length; i++ ){

            let el = this.$childrens[i];
            let offsetLeft = 0;
            let offsetTop  = 0;

            do {
                offsetLeft += el.offsetLeft;
                offsetTop  += el.offsetTop;

                el = el.offsetParent;
            } while( el );

            let computedTop = this.$childrens[i].offsetHeight;
            let computedLeft = offsetLeft + this.$childrens[i].offsetWidth;

            if (this.maxTop < computedTop ) {
                this.maxTop = computedTop;
            }

            if( this.maxLeft < computedLeft ) {
                this.maxLeft = computedLeft;
            }
        }

        this.props.contentSize[0] = this.maxLeft;
        this.props.contentSize[1] = this.maxTop;

        this._containerSidebarWidth = this.$sidebar.parentNode.offsetWidth;

        let pourcent = this._containerSidebarWidth / this.props.containerSize[0];

        this._ratioSidebar = this.props.containerSize[0] / this.props.contentSize[0] * pourcent;
        this._sidebarWidth = (this.props.containerSize[0] * this._ratioSidebar)
        this.$sidebar.style.width = this._sidebarWidth + 'px';
    }

    onMove(x, y){
        // console.log('onMove', x* this._ratioSidebar);

        this.$container.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;

        if(!this._isPointerMove){
            this._x = x * this._ratioSidebar;
            this.$sidebar.style[support.transform] = 'translate('+ ( (x * this._ratioSidebar) ) +'px,'+ (-y) +'px) '+ support.translateZ;
        }

        if(x < 40){
            if( this.classList.contains('is-hide-title')){
                this.classList.remove('is-hide-title');
            }
        }
        else {
            if( !this.classList.contains('is-hide-title')){
                this.classList.add('is-hide-title');
            }
        }
    }

    onDrag(){
        if(!this._pointerNone){
            this._pointerNone = true;
            this.$container.classList.add('u-force-pointer-none');

            EventDispatcher.fire('pointer:none');
        }
    }

    onEnd(){
         if(this._pointerNone){
            this._pointerNone = false;
            this.$container.classList.remove('u-force-pointer-none');

            EventDispatcher.fire('pointer:auto');
        }
    }


    onSidebarDown(event){
        this._isPointerDown = true;

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        this.$sidebar.classList.add('is-hide-title');

        this._oldDelta = this._x;
        this._startDrag = this._pointerEvent.pageX;
    }

    onSidebarMove(event){
        if(!this._isPointerDown) return;

        if( !this.isPointerMove ){
            EventDispatcher.fire('pointer:none');
            this.classList.add('u-disable-selection');

            this.isPointerMove = true;
        }

        this._pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;
        this._x = this._oldDelta + (this._pointerEvent.pageX - this._startDrag);

        if( this._x < 0 ){
            this._x = 0;
        }

        if( this._x > (this._containerSidebarWidth - this._sidebarWidth) ){
            this._x = (this._containerSidebarWidth - this._sidebarWidth);
        }

        this.$sidebar.style[support.transform] = 'translate('+ ( (this._x) ) +'px, 0px) '+ support.translateZ;

        this.props.dragTarget[0] = this._x / this._ratioSidebar;
    }

    onSidebarUp(){
        if(!this._isPointerDown) return;

        this._isPointerDown = false;

        this.$sidebar.classList.remove('is-hide-title');
        this.classList.remove('u-disable-selection');
        EventDispatcher.fire('pointer:auto');

        if(!this._isPointerMove) return;

        this._isPointerMove = false;

    }

    detached(){
        clearTimeout(this.timerReady);

        document.removeEventListener(support.pointermove, this.onSidebarMove, false);
        document.removeEventListener(support.pointerup, this.onSidebarUp, false);
    }


}

register('related-events', RelatedEvents);
