import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class StripeForm extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'submit .js-payment-form': 'onSubmit',
            //'change .js-remember': '_onRememberChange'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' },
            token: {type: 'string', value: '' },
            submitDisable: { type: 'bool', value: false },
            forceSubmit: { type: 'bool', value: false },
            //remember: { type: 'bool', value: false },
        }
    }

    created(){
        this.componentReady = false;
    }

    _onRememberChange(e) {
       // this.props.remember = e.currentTarget.checked;
    }


    ready(){
        setTimeout( () => {

            this.$form = this.querySelector('.js-payment-form');
            if(this.$form){

                this.componentReady = true;
                this.bindMethods(['onSubmit']);
                this.$form.addEventListener('submit', this.onSubmit, false);

               // this.props.remember = this.querySelector('.js-remember').checked;

            }
            else {
                this.ready();
            }
        }, 100);
    }

    onSubmit(event){
        event.preventDefault();
        event.stopPropagation();
        this.props.submitDisable = true;
        Stripe.card.createToken( this.$form, this.stripeResponseHandler.bind(this) );
        return false;
    }

    stripeResponseHandler( status, response ){
        if (response.error) {

            // Show the errors on the form:
            this.props.errorMessage = response.error.message;
            this.props.submitDisable = false; // Re-enable submission

        } else {
            // Get the token ID:
            this.props.token = response.id;
            // console.log('token', this.props.token);

            // Submit the ajax form:
            this.props.forceSubmit = true;
        }
    }

    onSuccess( data ){
        if(data.body.success){

            if( data.body.redirect.type == 'popin' ){
                EventDispatcher.fire('state:change', ['popin', { url: data.body.redirect.href, "save": true,"size":"small"}] );
            }
            else {
                EventDispatcher.fire('state:change', ['page', { url: data.body.redirect.href }] );
            }
            EventDispatcher.fire('page:reload');
        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }

    //onSuccess( data ){
    //    if(data.body.success){
    //        //EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    //        if (data.body.redirect) {
    //            EventDispatcher.fire('state:change', ['popin', {url: data.body.redirect}])
    //        }
        
    //        EventDispatcher.fire('page:reload');
            
    //    }
    //}

    onError(data){
        console.log('error', data.body);

    }

    detached(){
        this.$form.removeEventListener('submit', this.onSubmit);
    }

}

register('stripe-form', StripeForm);
