import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListSchedule extends Component {

    get events() {
        return {
            'click .js-disable': '_onDisableDown',
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {

            content:   { type: 'json', value: null },
            data:      { type: 'json',   value: null },

            disableParams:  { type: 'json', value: {
                id: null,
                name: null
            }},

            reload: {type:'bool', value: false}

        }
    }

    _onListReady(data) {
        this.props.data = data;
    }

    _onDisableDown(event) {
        let index = Number(event.currentTarget.getAttribute('data-index'));
        let id    = event.currentTarget.getAttribute('data-id');
        let name  = event.currentTarget.getAttribute('data-name');
        this.props.disableParams.id   = id;
        this.props.disableParams.name = name;
        this.props.data.posts[index].disabled = !this.props.data.posts[index].disabled;

        this.props.reload = true;
    }

    _onDisableSuccess(data) {
        if (this.props.disableParams.id !== null
            && this.props.disableParams.name !== null) {
            requestAnimationFrame(()=>{
                this.props.disableParams.id   = null;
                this.props.disableParams.name = null;
            });
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        this.props.reload = false;
    }

    _onDisableError(data) {
        if (this.props.cancelParams.id !== null
            && this.props.cancelParams.name !== null) {
            requestAnimationFrame(()=>{
                this.props.cancelParams.id   = null;
                this.props.cancelParams.name = null;
            });
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        this.props.reload = false;
    }

}

register('list-schedule', ListSchedule);