import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListMedias extends Component {


    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content:   { type: 'json', value: null },
            data:      { type: 'json',   value: null },
            onSelect: {type: 'fn', value: function(){}},
            type: 'remove',

            delta: {type:'int', value: 0},

            onAdd: {type:'fn', value: function(){}},
            onDelete: {type:'fn', value: function(){}},

            editable: {type: 'bool', value: false}

        }
    }

    created() {
        this._delta = 0;
    }
    
    _onMediaSelectedFromLibraryOrUploaded(post) {

        console.log('ADD A NEW MEDIA', this.props.content.type, post.type);

       if (this.props.content.type ===  post.type) {


            let postAlreadyExists = false;
            for (let i=0; i<this.props.data.posts.length; i++) {
                if (post.id === this.props.data.posts[i].id) {
                    console.log("OVERRIDE select:media | add:media", i);
                    postAlreadyExists = true;
                    // this.props.data.posts.splice(i, 1, post);

                    if (post.link && this.props.data.posts[i].link) {
                        this.props.data.posts[i].link.href = post.link.href;
                    }
                    if (post.edit && this.props.data.posts[i].edit) {
                        this.props.data.posts[i].edit.href = post.edit.href;
                    }
                    
                    this.props.data.posts[i].img.src = post.img.src;
                    break;
                } 
            }

            if (!postAlreadyExists) {

                console.log("select:media | add:media", post);
                post.editable  = this.props.content.editable ? post.editable : false;
                post.remove    = true;
                post.select    = false;
                this.props.data.posts.push( post );
                this._onAdd( post );

            }


        }


    }

    attached() {
        //if (this.props.content.waitForSelect) {
            EventDispatcher.on('select:media', this._onMediaSelectedFromLibraryOrUploaded, this);
            EventDispatcher.on('add:media', this._onMediaSelectedFromLibraryOrUploaded, this);
        //}
    }

    detached() {
        EventDispatcher.off('select:media', this._onMediaSelectedFromLibraryOrUploaded, this);
        EventDispatcher.off('add:media', this._onMediaSelectedFromLibraryOrUploaded, this);
    }

    _onListReady(data) {
        this.props.data = data;
    }

    _onSelect(post) {
        this.props.onSelect(post, this.props.content.type);
    }

    _onAdd(post){
        this.props.onAdd(post);
    }

    _onDelete(post){
        this.props.onDelete(post);
    }

}

register('list-medias', ListMedias);