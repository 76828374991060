import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class MyPopin extends Component {


    get events() {
        return {
            'pointerdown .js-close': '_close'
        }
    }
    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            active:  { type: 'bool', value: false, change: 'togglePopin' },
            loaded:   { type: 'bool', value: false },
            size: { type: 'string', value: 'large' },
            save: { type: 'string', value: null },
        }
    }

    created() {
        this.bindMethods(['_onKeyUp']);
    }

    attached(){

    }

    ready() {
        this.togglePopin();
    }

    onClose(){

    }

    onOpen() {
        
    }

    _close() {
        EventDispatcher.fire('state:change', ['popin:close']);
    }

    _onKeyUp(e){
        if(e.keyCode == 27){
            console.log('CLOSE POPIN FROM KEY')
            this._close();
        }
    }

    togglePopin(){
        if(this.props.active){
            this.classList.add('is-active');
            document.addEventListener('keyup', this._onKeyUp, false);
        }
        else {
            this.classList.remove('is-active');
            document.removeEventListener('keyup', this._onKeyUp, false);
        }
    }

}

register('my-popin', MyPopin);
