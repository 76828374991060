import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class CoreLoadMore extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-more': 'onLoadMore',
            'click .js-delete': 'onDelete'
        }
    }

    get defaultProps(){
        return {
            moreUrl: { type: 'string', value: '' },
            loadItems: { type: 'bool', value: false },
            onMoreSuccess: { type: 'func', value: function(){} },

            delete: { type: 'json', value: null },
            submitDelete: { type: 'bool', value: false },
            onDeleteSuccess: { type: 'func', value: function(){} },
        }
    }


    // Load more items
    // ----------------

    onLoadMore( event ){
        this.props.loadItems = true;
    }

    onMoreSuccess( data ){
        this.props.loadItems = false;
        this.props.onMoreSuccess( data.body );
    }

    onMoreError(){
        console.log('Error in load more request');
    }


    // Delete Item
    // ----------------

    onDelete( event ){
        this.deleteId = Number(event.currentTarget.getAttribute('data-id'));

        EventDispatcher.fire('popinResponse:open', [ {'data': this.props.delete.popin} ]);
        EventDispatcher.on('media:delete', this.onDeleteItem, this);
    }

    onDeleteItem(){
        EventDispatcher.fire('popinResponse:close');

        this.props.submitDelete = false;

        requestAnimationFrame( () => {
            this.props.submitDelete = true;
        });
    }

    onDeleteSuccess( data ){
        console.log('onDeleteSuccess', data.body);
        this.props.onDeleteSuccess( data.body );
    }

    onDeleteError(){
        console.log('Error in delete item form');
    }


}

register('core-load-more', CoreLoadMore);
