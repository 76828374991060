import {register} from "light";
import {Component} from "light";
import  {EventDispatcher} from "light";

class LightUiIcon extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            name: null,
            iconClass: '',
            base: '',
            additionalcss: ''
        }
    }

    attached(){
        this.bindMethods(['onChangeUrl']);

        this.onChangeUrl();

        EventDispatcher.on('base:change', this.onChangeUrl, this);
    }

    onChangeUrl( path ){

        // console.log('<light-ui-icon name="'+this.props.name+'"> onChangeUrl', path)

        if(/firefox/i.test(navigator.userAgent) || /edge/i.test(navigator.userAgent) || /msie/i.test(navigator.userAgent)){
            if(path){
                this.props.base = window.location.origin + path;
            }
            else {
                this.props.base = window.location.href.replace(window.location.hash, "");
            }
        }

         if(/chrome/i.test(navigator.userAgent)){
            if (!path) {
                this.props.base = window.location.href.replace(window.location.hash, "");
            }
        }
        
    }

    detached(){
        EventDispatcher.off('base:change', this.onChangeUrl);
    }

}

register('light-ui-icon', LightUiIcon);
