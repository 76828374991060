import {Component} from 'light';
import {register} from 'light';

class AppFinder extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            "keyup .js-input": '_onInputChange',
            "copy .js-input": '_onInputChange',
            "cut .js-input": '_onInputChange',
            "paste .js-input": '_onInputChange'
        }
    }

    get defaultProps(){
        return {
            border: {type: 'bool', value: true},
            action: null,
            placeholder: 'find a product',
            max: {type: 'int', value: 5},
            params: {type: 'json', value: {
                query: ''
            }},
            onFilter: {type: 'fn', value: function(){ } },
            // excludeList: {type: 'array', value: null}
        }
    }


    _onInputChange(event) {
        clearTimeout(this._inputTimer);
        this._inputTimer = setTimeout(()=>{
            this.props.onFilter( event.currentTarget.value );
        }, 300);
    }

}

register('app-finder', AppFinder);