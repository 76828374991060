import {Component} from 'light';
import {register} from 'light';
import page from 'page';

class CoreRoute extends Component {

    get defaultProps(){
        return {
            routes: { type: 'json', value: null },
            options: { type: 'json', value: null },
            base: '',

            location: null,
            oldLocation: null,

            onRouteChange: { type: 'func', value: function(){} },
            onOpenPopin: { type: 'func', value: function(){} }
        }
    }

    attached(){

        page.base(this.props.base);

        for(var route in this.props.routes) {
            this.addRoute(route);
        }

        page('*', this.notFound.bind(this));

        page.start(this.props.options);

    }

    addRoute( route ){
        var routeFn = function(context, next) {
            this.onRoute(route, context, next);
        };

        page(route, routeFn.bind(this));
    }

    onRoute(path, context, next ){
        this.props.oldLocation = (this.props.location == null) ? { path: null, componentId: null } : this.props.location;

        this.props.location = {
            regexp: path,
            path: context.pathname,
            componentId: this.props.routes[path].componentId,
            params: context.params,
            query: context.querystring,
            completePath: context.path
        };

        // console.log('----------\nCORE ROUTE\n----------');
        // console.log('location', this.props.location);

        // let modalValue = {};
        // modalValue.url = this.getParameter('modal');

        
        // if(modalValue){
        //     this.props.onOpenPopin(modalValue);
        // }
        
        this.props.onRouteChange(this.props.location, this.props.oldLocation);

    }

    notFound(){

        setTimeout( () => {
            page('/404');
        }, 10);
    }

}

register('core-route', CoreRoute);