import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class AdminEvents extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    get events(){
        return {
            'click .jsvideocall': '_onVideoCall',
            'click .jsaudiocall': '_onAudioCall',
        }
    }

    _onVideoCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),0,false);
    }

    _onAudioCall(button){        
        BeginCall(button.currentTarget.getAttribute('id'),0,true);
    }

    /*get events(){
        return {
            'click .js-delete' : 'onFormSubmit'
        }
    }*/


    // -------------------------
    //      DATA MANAGING
    // -------------------------

    onMoreSuccess( data ){

        Array.prototype.push.apply(this.props.content.content.items, data.items);

        if( !data.more ){
            this.querySelector('.js-more').classList.add('u-hide');
        }
        else {
            this.props.content.content.more.label = data.more.label;
            this.props.content.content.more.href = data.more.href;
        }

        setTimeout( () => {
            let evt = window.document.createEvent('UIEvents');
            evt.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(evt);
        }, 100);

    }

    onDeleteSuccess( resp ){

        this.props.content.content.items.splice(0, this.props.content.content.items.length);
        Array.prototype.push.apply(this.props.content.content.items, resp.data.items);

        if( !resp.data.more ){
            this.querySelector('.js-more').classList.add('u-hide');
        }
        else {
            this.querySelector('.js-more').classList.remove('u-hide');
            this.props.content.content.more.label = resp.data.more.label;
            this.props.content.content.more.href = resp.data.more.href;
        }

        // console.log('onDeleteSuccess', resp);
    }

    _onDeleteSchedulePopin( resp ){

        let id = -1;
        const schedules = this.props.content.sidebar.schedules;
        // console.log('==>', schedules);
        for(let i = 0, l = schedules.length; i < l; i++) {
            const schedule = schedules[i];
            // console.log(i, resp);
            if (schedule.id == resp.id) {
                schedules.splice(i, 1);
                return;
            }
        }

        /*this.props.content.sidebar.schedules.splice(0, this.props.content.sidebar.schedules.length);
        Array.prototype.push.apply(this.props.content.sidebar.schedules, resp.data.items);*/
    }

}

register('admin-events', AdminEvents);
