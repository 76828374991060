import {Component} from 'light';
import {register} from 'light';

class CoreFilePreview extends Component {


    get template() {
        return require('./template');
    }

    get events(){
        return {
            'change .js-file': 'onFileChange',
            'click .js-delete-file': 'onRemoveFile'
        }
    }

    get defaultProps(){
        return {
            onPreviewChange: {type: 'fn', value: function(){}},
            onError: {type: 'fn', value: function(){}},
            defaultMediaImage: './assets/images/default-media.jpg',
            name: "file",
            placeholder: "Drop it like it's hot",
            accept: {type: 'string', value: "image/*,video/mp4,video/x-m4v,video/*", change: '_onAcceptChange'},
            imagePreviewSrc: '',
            videoPreviewSrc: '',
            value: '',
            defaultPreview: '',
            defaultVideoPreview: '',
            fileName: {type:'string', value:''},
            isFileNameShown: {type:'bool', value: false},
            hasRemoveBtn: {type:'bool', value: false},
            isRequired: {type:'bool', value: false},
            isDeleted: {type:'bool', value: false},
            forceDelete: {type:'bool', value: false},
        }
    }


    created(){
        this.reader = new FileReader();
        this._acceptFormats = [];
    }

    ready(){
        this.$inputFile = this.querySelector('.js-file');
        this._onAcceptChange();
    }

    _onAcceptChange() {


        let accept =  this.props.accept.replace(/\s*/, '').split(',');

        this._acceptFormats = [];

        for (let i=0; i<accept.length; i++ ) {
            if (accept[i] == 'image/*') {
                this._acceptFormats.push('.gif','.jpg','.jpeg','.png','.bmp','.tga');
            }
            else if (accept[i] == 'video/*') {
                this._acceptFormats.push('.mov','.avi','.mkv','.mpeg','.mpg','.mp4','.ogv','.webm');
            }
            else if (accept[i] == 'audio/*') {
                this._acceptFormats.push('.mp3','.wav','.ogg');
            }
            else if (accept[i] == 'application/pdf'){
                this._acceptFormats.push('.pdf');   
            }
            else {
                this._acceptFormats.push( accept[i] );
            }
        }

    }


    // onDragHover( event ){
    //     event.stopPropagation();
    //     event.preventDefault();
    // }

    // onDrop( event ){

    //     event.stopPropagation();
    //     event.preventDefault();

    //     let files = event.dataTransfer.files;

    //     let extension = files[0].name.split('.').pop().toLowerCase();
    //     let isAcceptedFormat = this._acceptFormats.indexOf('.'+extension) > -1;

    //     if (isAcceptedFormat) {
    //         this.$input.files = files;
    //     }
    //     else {
    //         this.props.onError();
    //     }

    // }

    onFileChange( event ){

        let input = event.currentTarget;

        console.log('onFileChange');

        if (input.files
            && input.files[0]) {

            let extension = input.files[0].name.split('.').pop().toLowerCase();
            let isAcceptedFormat = this._acceptFormats.indexOf('.'+extension) > -1;

            if (!isAcceptedFormat) {
                this.props.onError();
            }
            else {


                this.reader = new FileReader();
                this.props.fileName = input.files[0].name;


                if ( /video/.test( input.files[0].type ) ) {
                    
                    this.reader.onload = (e)=>{
                        var blob = new Blob([e.target.result], {type: input.files[0].type}),
                            url = (window.URL || window.webkitURL).createObjectURL(blob);
                        this.props.videoPreviewSrc = url;
                        this.props.onPreviewChange( url, this, "1" );
                        if (this.props.value !== '') {
                            this.props.isDeleted = true;
                        }
                    };
                    this.reader.readAsArrayBuffer(input.files[0]);    
                }
                else if ( /image/.test( input.files[0].type ) ) {
                    
                    this.reader.onload = (e) => {
                        this.props.videoPreviewSrc = "";
                        this.props.imagePreviewSrc = e.target.result;
                        this.props.onPreviewChange( e.target.result, this ,"2");
                        if (this.props.value !== '') {
                            this.props.isDeleted = true;
                        }
                    }
                    this.reader.readAsDataURL(input.files[0]);
                }
                else {
                    this.props.imagePreviewSrc = this.props.defaultMediaImage;
                    this.props.videoPreviewSrc = "";
                    this.props.onPreviewChange( this.props.defaultMediaImage, this );
                    console.log('here');
                    if (this.props.value !== '') {
                        this.props.isDeleted = true;
                    }
                }

            }

        }
        else {
            // this.props.onPreviewChange( null, this );
            // this.props.videoPreviewSrc = "";
            // this.props.imagePreviewSrc = "";
        }

    }

    onRemoveFile () {
        this.props.onPreviewChange( null, this );
        this.props.videoPreviewSrc = "";
        this.props.imagePreviewSrc = "";
        this.props.fileName = "";
        this.$inputFile.value = null;
        this.props.defaultPreview = '';
        this.props.defaultVideoPreview = '';
        //this.props.value = '';
        
        this.props.isDeleted = true;
        
        console.log('remove file', this.props.imagePreviewSrc, this.props.value);
    }

    detached(){
        // this.removeEventListener("dragover", this._onDragHover);
        // this.removeEventListener("dragleave", this._onDragHover);
        // this.removeEventListener("drop", this._onDrop);
    }
}

register('core-file-preview', CoreFilePreview);
