import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class ProductDetails extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goto': 'onGoTo',
            'click .js-prev': 'onPrev',
            'click .js-next': 'onNext',
             'pointerdown .js-scrollbar': 'onSidebarDown',
            'pointerdown .js-nextSlide': 'onNextDrag',
            'pointerdown .js-prevSlide': 'onPrevDrag'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            activeSlide: { type: 'int', value: 0 },
            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },
            dragToVal: { type: 'array', value: [0,0] },
            dragTarget: { type: 'array', value: [0,0] }
        }
    }

    onPrev(){
        this.goToSlide( this.props.activeSlide - 1 );
    }

    onNext(){
        this.goToSlide( this.props.activeSlide + 1 );
    }

    onGoTo( event ){
        if(event){
            let target = Number(event.currentTarget.getAttribute('data-target'));
            this.goToSlide( target );
        }
    }

    goToSlide( target ){
        if(target < 0 || target >= this.props.content.slides.length) return;

        this.props.activeSlide = target;
    }

    _onError() {

    }
    
    created(){
        this.componentReady = false;
        this._x = 0;
        this.sidebarX = 0;
        this.oldDelta = 0;
        this.startDrag = 0;
        this.moreSizeDrag = 100;
    }
    ready(){

        this.$container = this.querySelector('.js-container');
        this.$sidebar = this.querySelector('.js-scrollbar');
        this.$childrens = this.$container.querySelectorAll(".js-drag-children");

        this.$next = this.querySelector('.js-nextSlide');
        this.$prev = this.querySelector('.js-prevSlide');

        //this.$next.classList.add('is-disabled');

        if(this.$container && this.$sidebar && this.$childrens && this.$childrens.length && this.$next && this.$prev){
            this.componentReady = true;
            this.initDrag();
            this.bindMethods(['onSidebarMove', 'onSidebarUp']);
            document.addEventListener(support.pointermove, this.onSidebarMove, false);
            document.addEventListener(support.pointerup, this.onSidebarUp, false);
        }
        else {
            setTimeout(()=>{
                this.ready();
            },100);
        }

    }

    resize(){
        if(this.componentReady){
            // this.initDrag();
        }
    }

    initDrag(){        
        // container Size
        this.props.containerSize[0] = this.$container.offsetWidth;
        this.props.containerSize[1] = this.$container.offsetHeight;

        // content Size
        this.maxTop = 0;
        this.maxLeft = 0;

        for ( let i = 0, length = this.$childrens.length; i < length; i++ ){

            let offset = this.$childrens[i].getBoundingClientRect();

            let computedTop = this.$childrens[i].clientHeight;
            let computedLeft = offset.left + this.$childrens[i].clientWidth;

            if (this.maxTop < computedTop ) {
                this.maxTop = computedTop;
            }

            if( this.maxLeft < computedLeft ) {
                this.maxLeft = computedLeft;
            }
        }        
        this.props.contentSize[0] = this.maxLeft - this.$container.getBoundingClientRect().left;
        this.props.contentSize[1] = this.maxTop;

        // console.log(this.props.containerSize, this.props.contentSize);


        // sidebar =

        this.containerSidebarWidth = this.$sidebar.parentNode.offsetWidth;

        let pourcent = this.containerSidebarWidth / this.props.containerSize[0];

        this.ratioSidebar = this.props.containerSize[0] / this.props.contentSize[0] * pourcent;
        this.sidebarWidth = (this.props.containerSize[0] * this.ratioSidebar)
        this.$sidebar.style.width = this.sidebarWidth + 'px';

        
    }

    onMove(x, y){                
        if( x > (this.props.contentSize[0] - this.props.containerSize[0]) - this.moreSizeDrag/2 ){
            if(this.$next){
                this.$next.classList.add('is-disabled');
            }
        }
        else {
            if(this.$next){
                this.$next.classList.remove('is-disabled');
            }
        }

        if( x < this.moreSizeDrag/2 ){
            if(this.$prev){
                this.$prev.classList.add('is-disabled');
            }
        }
        else {
            if(this.$prev){
                this.$prev.classList.remove('is-disabled');
            }
        }

        if( x < ( this.props.contentSize[0] - this.props.containerSize[0] ) ){
            this.$container.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;
        }

        this._x = x;

        if(!this._isPointerMove){
            this.sidebarX = x * this.ratioSidebar;
            this.$sidebar.style[support.transform] = 'translate('+ ( (x * this.ratioSidebar) ) +'px,'+ (-y) +'px) '+ support.translateZ;
        }
    }

    onDrag(){
        if(!this._pointerNone){
            this._pointerNone = true;
            this.$container.classList.add('u-force-pointer-none');

            EventDispatcher.fire('pointer:none');
        }
    }

    onEnd(){
        if(this._pointerNone){
            this._pointerNone = false;
            this.$container.classList.remove('u-force-pointer-none');

            EventDispatcher.fire('pointer:auto');
        }
    }

    onNextDrag() {
        let target = this._x + this.moreSizeDrag;

        if( target > ( this.props.contentSize[0] - this.props.containerSize[0] ) ){
            target = this.props.contentSize[0] - this.props.containerSize[0];
        }

        // console.log(target);
        this.props.dragToVal[0] = target;
    }

    onPrevDrag(){

        let target = this._x - this.moreSizeDrag;

        if( target < 0){
            target = 0;
        }

        // console.log(target);
        this.props.dragToVal[0] = target;
    }

    onSidebarDown(event){
        this._isPointerDown = true;

        this.pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;

        this.$sidebar.classList.add('is-active');

        this.oldDelta = this.sidebarX;
        this.startDrag = this.pointerEvent.pageX;
    }

    onSidebarMove(event){
        if(!this._isPointerDown) return;

        if( !this._isPointerMove ){
            EventDispatcher.fire('pointer:none');
            this.classList.add('u-disable-selection');

            this._isPointerMove = true;
        }

        this.pointerEvent = support.touch ? (event.touches[0] || event.changedTouches[0]) : event;
        this.sidebarX = this.oldDelta + (this.pointerEvent.pageX - this.startDrag);

        if( this.sidebarX < 0 ){
            this.sidebarX = 0;
        }

        if( this.sidebarX > (this.containerSidebarWidth - this.sidebarWidth) ){
            this.sidebarX = (this.containerSidebarWidth - this.sidebarWidth);
        }

        this.$sidebar.style[support.transform] = 'translate('+ ( (this.sidebarX) ) +'px, 0px) '+ support.translateZ;

        this.props.dragTarget[0] = this.sidebarX / this.ratioSidebar;
    }

    onSidebarUp(){
        if(!this._isPointerDown) return;

        this._isPointerDown = false;

        this.$sidebar.classList.remove('is-active');
        this.classList.remove('u-disable-selection');
        EventDispatcher.fire('pointer:auto');

        if(!this._isPointerMove) return;

        this._isPointerMove = false;

    }

    detached(){
        document.removeEventListener(support.pointermove, this.onSidebarMove, false);
        document.removeEventListener(support.pointerup, this.onSidebarUp, false);
    }


}

register('product-details', ProductDetails);
