import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AddMedia extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: '',
            instructions: '',
            accept: ''
        }
    }
    ready(){
        this.props.instructions = this.props.content.form.instructions[0].label;
        this.props.accept = this.props.content.form.instructions[0].accept;
    }
    attached(){


        // this.props.instructions = this.props.content.form.instructions[0].label;
        // this.props.accept       = this.props.content.form.instructions[0].accept;
        this.$selectTag   = this.querySelectorAll('.js-select');
        for (let i=0,l= this.$selectTag.length; i<l; i++) {
            this.onSelectChange(this.$selectTag[i], this.$selectTag[i].value);
        }
      //  this.$selectTag = this.querySelector('.js-select');
       


    }

    onSuccess( data ){

        if(data.body.success){

            console.log('MEDIA ADDED', data.body.post)
            EventDispatcher.fire('add:media', [data.body.post]);
           // EventDispatcher.fire('reload:force');
            EventDispatcher.fire('state:change', ['popin:close']);
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            // console.log("errorMessage", data.body.response.body)
            this.props.errorMessage = data.body.response.body;
        }

    }

    onError (data) {
        // console.log('SEVER ERROR',data.body.response.body)
        this.props.errorMessage = data;
    }

    _onPreviewChange() {
        //console.log('PREVIEW CHNAGED')
        this.props.errorMessage = '';
    }

    _onFileError() {
        this.props.errorMessage = 'Format Error. Please read instructions';
    }

    onSelectChange( el, value ){
        for (let i = 0, l = this.props.content.form.instructions.length; i < l; i++ ){
            if( this.props.content.form.instructions[i].type == value ){
                this.props.instructions = this.props.content.form.instructions[i].label;
                this.props.accept = this.props.content.form.instructions[i].accept;
                console.log('    accept',this.props.accept );
            }
        }
    }
}

register('add-media', AddMedia);
