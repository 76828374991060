import {Component} from 'light';
import {register} from 'light';

class TileEvent extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            theme: { type: 'string', value: 'light' }
        }
    }

}

register('tile-event', TileEvent);
