//
// [Xporium - 2015] Javascript
// Version: 1.0
// Date:    22/12/2015
// Author:  [Florent BIFFI, Quentin LECLERCQ, Nicolas Riciotti]
//          [florent@immersive-g.com, quentin@immersive-g.com, nicolas@immersive-g.com]
// --------------------------------------------------

// polyfill IE9
if( typeof window.Float32Array == 'undefined' ){
    window.Float32Array = Array;
}

// CORE
import 'core/light-core-overlay/light-core-overlay';
import 'core/light-ui-input-text/light-ui-input-text';
import 'core/light-ui-input-date/light-ui-input-date';
import 'core/light-ui-select/light-ui-select';
import 'core/light-ui-select-option/light-ui-select-option';
import 'core/light-core-ajax/light-core-ajax';
import 'core/light-core-ajax-form/light-core-ajax-form';
import 'core/light-ui-dialog/light-ui-dialog';
import "core/light-core-state/light-core-state";
import "core/light-core-state-button/light-core-state-button";
import "core/light-core-state-machine/light-core-state-machine";
import "core/light-core-selector/light-core-selector";
import "core/light-core-selector-item/light-core-selector-item";
import "core/light-ui-icon/light-ui-icon";
import "core/light-ui-object-fit/light-ui-object-fit";
import 'core/core-btn-state';
import 'core/core-loader';
import 'core/core-collapse';
import 'core/core-video';
import 'core/core-video-native';
import 'core/core-slider';
import 'core/core-route';
import 'core/core-load-more';
import 'core/core-sticky';
import 'core/core-file-preview';
import "core/light-ui-scrollbox/light-ui-scrollbox";
import 'core/light-ui-drag/light-ui-drag';
import 'core/light-core-animation/light-core-animation';

import 'core/core-instagram-connect';
import 'core/core-twitter-connect';
// import 'core/pixi-components';


// ENGINE
import "core/light-engine-scene-css/light-engine-scene-css";
// import "core/light-engine-light/light-engine-light";
import "core/light-engine-geometry-box/light-engine-geometry-box";
import "core/light-engine-geometry-plane/light-engine-geometry-plane";
import "core/light-engine-geometry-sphere/light-engine-geometry-sphere";
import "core/light-engine-mesh-css/light-engine-mesh-css";
import "core/light-engine-render-target/light-engine-render-target";

// import 'core/light-engine-particle-system/light-engine-particle-system';
// import 'core/light-engine-shader-pass-blur/light-engine-shader-pass-blur';
// import 'core/light-engine-shader-pass-rain/light-engine-shader-pass-rain';
// import 'core/light-engine-shader-pass-noise/light-engine-shader-pass-noise';
// import 'core/light-engine-shader-pass-fxaa/light-engine-shader-pass-fxaa';
// import 'core/light-engine-texture-video/light-engine-texture-video';
// import "core/light-engine-mesh-curve/light-engine-mesh-curve";
import 'core/light-engine-material/light-engine-material';
import "core/light-engine-geometry/light-engine-geometry";
import "core/light-engine-mesh/light-engine-mesh";
import 'core/light-engine-scene/light-engine-scene';
import 'core/light-engine-camera-perspective/light-engine-camera-perspective';
// import 'core/light-engine-texture-mirror/index';
// import 'core/light-engine-texture-water/index';
// import 'core/light-engine-composer/light-engine-composer';
import 'core/core-input-repeater/';







// MAIN APP COMPONENTS
import 'components/my-header';
import 'components/my-admin-header';


// PAGES
import 'pages/page-generator';
import 'pages/page-event';
import 'pages/page-404';
import 'pages/page-admin';
import 'pages/page-unsubscribe';
import 'pages/page-error';

// COMPONENTS: TILES
import 'components/tile-tweet';
import 'components/tile-article';
import 'components/tile-event';
import 'components/tile-event-small';
import 'components/tile-exhibitor';
import 'components/tile-filter';
import 'components/tile-instagram';
import 'components/tile-list-social';
import 'components/tile-list-videos';
import 'components/tile-video';

// COMPONENTS: LISTS
import 'components/list-exhibitors';
import 'components/list-products';
import 'components/my-list-items';
import 'components/list-timeline';
import 'components/list-gallery';
import 'components/list-gallery-select';
import 'components/list-medias';
import 'components/list-tags';
import 'components/list-staff';
import 'components/list-network';
import 'components/list-events';
import 'components/list-bookmarks';
import 'components/list-schedule';


// COMPONENTS: SCHEDULE
import 'components/schedule-all';
import 'components/schedule-one';
import 'components/schedule-two';
import 'components/schedule-three';
import 'components/schedule-end';
import 'components/schedule-error';
import 'components/schedule-tabs';


// COMPONENTS: ADMIN
import 'components/admin-navigation';
import 'components/admin-notifications';
import 'components/admin-account';
import 'components/admin-medias';
import 'components/admin-products';
import 'components/admin-staff';
import 'components/admin-network';
import 'components/admin-bookmarks';
import 'components/admin-events';
import 'components/admin-event';
import 'components/admin-events-update-description';
import 'components/assign-events';
import 'components/admin-informations';
import 'components/admin-transactions';
import 'components/admin-schedule';

//OTHER COMPONENTS
import 'components/my-loader';
import 'components/my-popin';
import 'components/my-slide';
import 'components/my-tab';
import 'components/my-panorama';
import 'components/my-live-timeline';
import 'components/search-overlay';
import 'components/image-zoom';
import 'components/my-dropdown';
import 'components/my-popin-response';
import 'components/my-fav-form';
import 'components/app-bookmark-btn';
import 'components/my-footer';
import 'components/slideshow-home';
import 'components/related-events';
import 'components/hub-xpo';
import 'components/hub-scene';
import 'components/slideshow-event';
import 'components/lead-event';
import 'components/intro-event';
import 'components/intro-title';
import 'components/card-about';
import 'components/card-about-live';
import 'components/card-staff';
import 'components/nav-tabs';
import 'components/search-home';
import 'components/product-item';
import 'components/live-timeline';
//import 'components/btn-join';
import 'components/business-card';
import 'components/social-buzz';
import 'components/sign-in';
import 'components/sign-up';
import 'components/stripe-form';
import 'components/product-details';
import 'components/add-team-member';
import 'components/add-media';
import 'components/add-product';
import 'components/add-tags';
import 'components/app-notification';
import 'components/slideshow-video';
import 'components/app-breadcrumb'
import 'components/app-multi-upload'
import 'components/app-single-upload'
import 'components/intro-products';
import 'components/app-share-tweet';
import 'components/app-auto-complete';
import 'components/nav-tabs-small';
import 'components/app-list';
import 'components/article-details';
import 'components/list-transactions';
import 'components/preview-media';
import 'components/how-it-works';
import 'components/scroll-object';
import 'components/app-slideshow';
import 'components/app-slide';
import 'components/app-finder';

import 'components/list-draft';

// STORES
import 'stores/store-search';
import 'stores/store-hub';
import 'stores/store-feed';



// TEST
import 'components/x-test';

import 'components/intro-staffs';
import 'components/change-password';
import 'components/stripe-confirmation';
import 'components/sign-up-confirmation';
import 'components/sign-in-verification';
import 'components/tile-image';
import 'components/forget-password';
import 'components/unsubscribe-mail';
import 'components/custom-error';
import 'components/past-event'
import 'components/admin-reports'
 

 
// MAIN
import 'x-application';