import {register} from "light";
import {Component} from "light";

class LightCoreState extends Component {

	get defaultProps(){
		return {
			state:  { type: "string", value: null },
			onStateChange: { type: "fn", value: function(){} },
			route: { type: "string", value: null },
		}
	}

	ready(){

		this.addToStateMachine();

	}

	addToStateMachine(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-CORE-STATE-MACHINE"){
				node.add( this.props.state, this.props.route, this.props.onStateChange );
				break;
			}
			node = node.parentNode;
		}
	}

}

register('light-core-state', LightCoreState);