import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListTimeline extends Component {


    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content:   { type: 'json', value: null },
            data:      { type: 'json',   value: null },
            reload:   { type: 'bool', value: false },

            onDelete: {type:'fn', value: function(){}},
        }
    }

    _onListReady(data) {
        this.props.data = data;
    }

    _onDelete(post){
        this.props.onDelete(post);
    }

}

register('list-timeline', ListTimeline);