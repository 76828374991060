import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class Page404 extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    attached(){
        console.log(this.props.content);
    }

    ready(){
        EventDispatcher.fire('page:ready');
    }

}

register('page-404', Page404);
