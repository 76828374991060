import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class myFavForm extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            id: '',
            url: '',
            submitForm: { type: 'bool', value: false }
        }
    }

    ready(){
        EventDispatcher.on('post:fav', this.onFav.bind(this));
    }

    onFav( params ){
        this.props.id = params.id;
        this.props.url = params.url;

        this.props.submitForm = false;

        requestAnimationFrame( () => {
            this.props.submitForm = true;
        });
    }

    onSuccess( resp ){
        // console.log('onSuccess', resp.body);

        if( resp.body.success ){
            if(resp.body.data.fav){
                EventDispatcher.fire('fav:callback', [ true ]);
            }
            else {
                EventDispatcher.fire('fav:callback', [ false ]);
            }
        }
        else {
            EventDispatcher.fire('fav:callback', [ false ]);
            EventDispatcher.fire('popinResponse:open', [ {'data': resp.body.response} ]);

        }

    }

    onError( data ){
        console.log('Error in ajax fav form', data);
    }


}

register('my-fav-form', myFavForm);

