import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';


class AppList extends Component {

    get events() {
        return {
            //'pointerdown .js-more': '_onMoreDown',
            'pointerdown .js-delete': '_onDeleteMediaDown',
            'pointerdown .js-select': '_onSelectItem'
        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            reload: { type: 'bool', value: false, change: '_onReload' },
            forceReload: { type: 'bool', value: false },
            content: { type: 'json', value: null },
            data:     { type: 'json',   value: {
                posts: [],
                total: 0,
                rest: 0,
                norecordfoundmessage: '',
                hashtagmessage: '',
            }},
            params: { type: 'json', value: {
                query: '',
                page: 0,
                num: 5
            }},
            deleteParams: { type: 'json', value: {
                id: null,
            }},
            addParams: {type: 'json', value: {
                id: null,
                name: null
            }},
            onReady: {type: 'fn', value: function(){}},
            moreLabel: 'text',
            moreBtnWrapperClass: 't-list__item u-pad-r-xs u-pad-b-xs u-w1of4 u-w1of2@sm',
            listClass: '',

            deleteTitle:        'Are you sure?',
            deleteBody:         'This action is irreversible, your staff member will be deleted',
            deleteConfirmLabel: 'delete',
            deleteCancelLabel:  'cancel',

            onUpdate: {type: 'fn', value: function(){}},
            onSelect: {type: 'fn', value: function(){}},
            onDelete: {type: 'fn', value: function(){}},
            onAdd: {type: 'fn', value: function(){}},

            type: 'remove',

            excludeList: {type: 'array', value: null},

            isFilter: { type: 'bool', value: false },
        }
    }


    detached() {
       EventDispatcher.off('reload:force', this._onForceReload, this);
    }

    _onReload() {
        if (this.props.reload) {
            requestAnimationFrame(()=>{
                this._onForceReload();
            })
        }
    }


    _onFinderFilter(query) {
        console.log('FILTER RESULTS!', query);
        this.props.params.page  = 0;
        this.props.params.num   = 5;
        this.props.params.query = query;
    }

    attached() {
        EventDispatcher.on('reload:force', this._onForceReload, this);
    }

    ready() {
        this.props.onReady(this.props.data);
    }

    created() {
        this._mediaToDelete = null;
        this._mediaToDeleteIndex = 0;
        this.bindMethods(['_onConfirmDelete', '_onCancelDelete']);
        this._post = null;
    }

    _onMoreDown(e) {
        e.stopPropagation();//avoid triggering more in nested list
        this.props.params.num   = 10;
        this.props.params.page += 1;
    }

    _onSuccess(data) {
        this.props.data.norecordfoundmessage   = data.body.norecordfoundmessage;
        this.props.data.hashtagmessage   = data.body.hashtagmessage;
        this.props.data.total  = data.body.total;
        this.props.data.rest   = data.body.rest;
        this.props.params.page = data.body.page;

        if (data.body.page == 0) {
            this.props.data.posts.splice(0, this.props.data.posts.length);
        }

        this.props.data.posts.push.apply(this.props.data.posts, data.body.posts);

        clearTimeout(this._resizeTimer);
        this._resizeTimer = setTimeout(()=>{
            EventDispatcher.fire('resize');
        }, 2000);

    }

    _onError() {

    }

    _onSelectItem(event) {
        var index = Number( event.currentTarget.getAttribute('data-index') )
        var post = this.props.data.posts[ index ];
        this.props.onSelect( post );
    }

    _onCancelDelete() {
        this._mediaToDelete = null;
    }

    _onConfirmDelete() {
        // console.log('_onConfirmDelete', this._mediaToDelete, this.props);
        this.props.deleteParams.id = this._mediaToDelete;
        this._mediaToDelete = null;
    }

    _onForceReload() {
        this.props.params.page = 0;
        this.props.forceReload = true;
        requestAnimationFrame(()=>{
            this.props.forceReload = false;
        });
    }

    _onDeleteSuccess( data ){
        this._onForceReload();
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    _onDeleteError(data) {
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    _onAutoCompleteSelect(post) {

        if (this.props.content.deleteUrl && this.props.content.deleteUrl !== ''){
            this.props.addParams.id = post.id;
            this.props.addParams.name = post.name;
        }
        else {

            console.log('autocomplete select', post);

            var p = JSON.parse( JSON.stringify(post) );
            p.added = true;
            this.props.data.posts.push(p);
            this.props.onAdd( p );
            // console.log('data.posts', this.props.data.posts);
        }

    }


     _onDeleteMediaDown(event) {




//  fix for nested list as stoppropgation doesn'tseem to work properly
//TODO: fix light and stopPropagation
let parentNode = event.currentTarget.parentNode;
let isInSubList = false;

while (parentNode) {
    if (parentNode.tagName == 'APP-LIST' && parentNode !== this) {
        isInSubList = true;
    } 
    if (parentNode == this) {
        break;
    }
    parentNode = parentNode.parentNode;
}

if (isInSubList) {
    return;
}






        this._mediaToDelete      = event.currentTarget.getAttribute('data-id');
        this._mediaToDeleteIndex = event.currentTarget.getAttribute('data-index');

        if (this.props.content.deleteUrl && this.props.content.deleteUrl !== ''){

            EventDispatcher.fire('confirm', [{
                title: this.props.content.deleteTitle || this.props.deleteTitle,
                body:  this.props.content.deleteBody || this.props.deleteBody,
                confirmLabel: this.props.content.deleteConfirmLabel ||  this.props.deleteConfirmLabel,
                cancelLabel: this.props.content.deleteCancelLabel ||  this.props.deleteCancelLabel,
                onConfirm: this._onConfirmDelete,
                onCancel:  this._onCancelDelete
            }]);

        }
        else {

            var p = JSON.parse( JSON.stringify( this.props.data.posts[this._mediaToDeleteIndex] ) );

            // console.log('<app-list> delete()', this._mediaToDeleteIndex, p);

            this.props.data.posts.splice(this._mediaToDeleteIndex, 1);
            this.props.onDelete( p );
        }
    }

    _onTagAddSuccess(data) {
        // console.log('_onTagAddSuccess', data);
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        this._onForceReload();
    }

    _onTagAddError() {
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }


}

register('app-list', AppList);