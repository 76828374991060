import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';

class myBtnFav extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            url: '',
            postId: '',
            postType: '',
            state: {type: 'bool', value: false, change: '_onStateChange'},
            // params: {
            //     id: null,
            //     typeid: null
            // },
            reload: {type: 'bool', value: false},
            isActive: {type:'bool', value:false}
        }
    }

    _onStateChange() {
         if( this.props.state ){
            this.classList.add('is-active');
            this.props.isActive = true;
        }
        else {
            this.classList.remove('is-active');
            this.props.isActive = false;
        }
    }

    created(){
        this.bindMethods(['onFav','onSuccess'])
    }

    detached() {
        this.removeEventListener( "click", this.onFav, false);
    }

    ready() {
        this.addEventListener( "click", this.onFav, false);
        this._onStateChange();
    }

    onFav( event ){

        event.preventDefault();
        event.stopPropagation();

        EventDispatcher.fire('bookmark', [{
            'id': this.props.postId,
            'typeid': this.props.postType,
            callback: this.onSuccess
        }]);

        return false;

    }

    onSuccess( resp ){
        this.props.state = resp.body.response.bookmarked;
    }


}

register('app-bookmark-btn', myBtnFav);
