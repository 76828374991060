import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AppShareTweet extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            url: {type:'string', value: window.location.href },
            shareUrl: {type:'string', value: null},
        }
    }

    get events(){
        return {
            'click .js-share-link' : 'onShareClick'
        }
    }


    ready(){

        if( this.props.content.componentId === 'tile-tweet' ){
            this.props.shareUrl = 'https://twitter.com/intent/retweet?tweet_id=' + this.props.content.content.uuid;
        }
        else {
            this.props.shareUrl = 'https://twitter.com/home?status=' + this.props.content.name + ' ' + this.props.content.label + ' on @Xporium' + ' ' + this.props.url;
        }


    }

    onShareClick(){
        window.open(this.props.shareUrl, "nom_popup","menubar=no, status=no, scrollbars=no, menubar=no, width=550, height=300");
    }

}

register('app-share-tweet', AppShareTweet);
