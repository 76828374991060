import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';
import {utils} from 'light';


class CoreSticky extends Component {

    get template(){
        return require('./template');
    }

    get defaultProps(){
        return {

            active: { type: 'bool', value: true, change: '_onActiveChange' },

            gapTop: { type: 'float', value: 0    },
            gapBottom: { type: 'float', value: 0 },

            width: { type: 'float', value: 0 },
            height: { type: 'float', value: 0 },
            top: { type: 'float', value: 0 },
            left: { type: 'float', value: 0 },
            offsetTop: { type: 'float', value: 0 },
            offsetLeft: { type: 'float', value: 0 },

            parentOffsetTop: { type: 'float', value: 9999 },
            parentOffsetLeft: { type: 'float', value: 0 },
            parentHeight: { type: 'float', value: 0 },
            parentWidth: { type: 'float', value: 0 },

            fakeScroll: { type: 'bool', value: false },
            scroll: { type: 'float', value: 0 }

        }
    }

    created(){
        this.componentReady = false;
        this._minSticky = 0;
        this._maxSticky = 0;
        this._sticky = false;
    }

    attached(){
        this.classList.add('is-sticky');

        if(!this.props.fakeScroll){
            this.bindMethods(['onScroll']);
            document.addEventListener('scroll', this.onScroll);
        }

    }

    onScroll( event ) {
        this._scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    }

    getStickyParent( name ){
        let element = this.parentNode;
        while( element && !element.classList.contains(name) ){
            element = element.parentNode;
        }
        return element;
    }

    otherStickyElem(){
        let stickys = this.$parent.querySelectorAll('.is-sticky');
        let nbSticky = stickys.length;

        let otherSticky = true;

        for( let i = 0; i < nbSticky; i++ ){

            if(this == stickys[i] && i == (nbSticky-1)){
                otherSticky = false;
            }
            else {
                otherSticky = true;
            }

        }

        return otherSticky;
    }

    ready(){
        setTimeout( () => {
            this.$sticky = this.querySelector('.js-sticky');
            this.$inner  = this.querySelector('.js-sticky-inner');
            this.$placeholder  = this.querySelector('.js-sticky-placeholder');
            this.$parent = this.getStickyParent('js-sticky-parent');

            if(this.$sticky && this.$inner && this.$parent){

                this.componentReady = true;

                if( this.otherStickyElem() ){
                    this.visible = false; // stop update function
                }

                this.initSticky();
            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){
        this.initSticky();
    }

    resizeEnd(){
        this.initSticky();
    }

    initSticky(){

        if(this.componentReady){

            let el = this;
            let offsetLeft = 0;
            let offsetTop  = 0;

            this.$sticky.style.width =  '100%';
            this.$sticky.style.height =  'auto';


            //compute height/width and apply it to sticky
            this.props.width               = this.clientWidth;
            this.props.height              = this.clientHeight;
            this.$sticky.style.width       = this.props.width  + 'px';
            this.$sticky.style.height      = this.props.height + 'px';
            this.$placeholder.style.width  = this.props.width  + 'px';
            this.$placeholder.style.height = this.props.height + 'px';


            this.props.parentHeight  = this.$parent.clientHeight;
            this.props.parentWidth   = this.$parent.clientWidth;


            //compute element top/left
            while( el ) {
                if (el == this.$parent) {
                    break;
                }
                offsetLeft += el.offsetLeft;
                offsetTop  += el.offsetTop;
                el = el.offsetParent;
            };
            this.props.top         = offsetTop;
            this.props.left        = offsetLeft;


            // compute parent offset
            // compute element offset
            el = this.$parent;
            offsetLeft = 0;
            offsetTop  = 0;
            while( el ) {
                offsetLeft += el.offsetLeft;
                offsetTop  += el.offsetTop;
                el = el.offsetParent;
            };

            this.props.parentOffsetTop  = offsetTop;
            this.props.parentOffsetLeft = offsetLeft;
            
            this.props.offsetLeft   = this.props.left + this.props.parentOffsetLeft;
            this.props.offsetTop    = this.props.top  + this.props.parentOffsetTop;

            this._minSticky = this.props.parentOffsetTop + this.props.top - this.props.gapTop;
            this._maxSticky = this.props.parentOffsetTop + this.props.parentHeight - (this.props.height + this.props.gapTop + this.props.gapBottom);

            this._sticky = false;

        }
    }


    _onActiveChange() {
        if (!this.props.active) {
            this._sticky = false;
            if (this.$sticky) {
                this.$sticky.classList.remove('u-fixed');
                this.$sticky.classList.remove('u-relative');
                this.$sticky.style.top  = 'auto';
                this.$sticky.style.left = 'auto';
                this.$placeholder.classList.add('u-hide');
            }
        }
    }


    update(){

        if(!this.componentReady || !this.props.active)
            return;

        if(this.props.fakeScroll){
            this.scrollVal = this.props.scroll;
        }
        else {
            this.scrollVal = this._scrollTop;
        }


        if( this.scrollVal >= this._minSticky && this.scrollVal < this._maxSticky ){
            if(this.props.fakeScroll){
                this.$sticky.style[support.transform] = 'translateY('+(this.scrollVal - this._minSticky)+'px) '+ support.translateZ;
            }
            else {
                if(!this._sticky){
                    this._sticky = true;
                    this.$sticky.classList.add('u-fixed');
                    this.$sticky.classList.remove('u-relative');
                    this.$sticky.style.top  = this.props.gapTop + 'px';
                    this.$sticky.style.left = (this.props.offsetLeft )+ 'px';
                    this.$placeholder.classList.remove('u-hide');
                }
            }
        }
        else if ( this.scrollVal >= this._maxSticky ) {
            this._sticky = false;
            this.$sticky.classList.add('u-relative');
            this.$sticky.classList.remove('u-fixed');
            this.$placeholder.classList.add('u-hide');
            this.$sticky.style.top =  (this._maxSticky - this._minSticky) + 'px'; 
            this.$sticky.style.left = 0;
        }
        else {
            if(this.props.fakeScroll){
                this.$sticky.style[support.transform] = support.translateZ;
            }
            else {
                if(this._sticky){
                    this._sticky = false;
                    this.$sticky.classList.remove('u-fixed');
                    this.$sticky.classList.remove('u-relative');
                    this.$sticky.style.top  = 'auto';
                    this.$sticky.style.left = 'auto';
                    this.$placeholder.classList.add('u-hide');
                }
            }
        }


        // if( this.scrollVal >= this.triggerTop && this.scrollVal < this.triggerBottom ){

        //     if(this.props.fakeScroll){
        //         this.$sticky.style[support.transform] = 'translateY('+(this.props.scroll-this.triggerTop)+'px) '+ support.translateZ;
        //     }
        //     else {
        //         if(!this._sticky){
        //             this._sticky = true;

        //             this.$sticky.classList.add('u-fixed');
        //             this.$sticky.classList.remove('u-absolute');
        //             this.$sticky.classList.remove('u-relative');
        //             this.$sticky.style.top  = 0 + 'px';
        //             this.$sticky.style.left = this.props.left + 'px';

        //         }
        //     }

        // }
        // else if ( this.scrollVal >= this.triggerBottom ) {

        //     if(this._sticky && !this.props.fakeScroll){
        //         this._sticky = false;
        //         this.$sticky.classList.add('u-relative');
        //         this.$sticky.classList.remove('u-absolute');
        //         this.$sticky.classList.remove('u-fixed');
        //         this.$sticky.style.top =  (this.triggerBottom - this.triggerTop) + 'px'; 
        //         this.$sticky.style.left = 0//(this.props.left) + 'px';
        //     }

        // }
        // else {

        //     if(this.props.fakeScroll){
        //         this.$sticky.style[support.transform] = 'translateY('+this.props.scroll-this.triggerTop+'px) '+ support.translateZ;
        //     }
        //     else {
        //         if(this._sticky){
        //             this._sticky = false;
        //             this.$sticky.classList.remove('u-fixed');
        //             this.$sticky.style.top  = 'auto';
        //             this.$sticky.style.left = 'auto';
        //         }
        //     }

        // }

    }

    detached(){
        if(!this.props.fakeScroll){
            document.removeEventListener('scroll', this.onScroll);
        }
    }

}

register('core-sticky', CoreSticky);
