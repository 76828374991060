import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

class SlideshowEvent extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goto': 'onGoToSlide',
            'click .js-next': 'onNextThumb',
            'click .js-prev': 'onPrevThumb',

            'mouseover .js-goto': '_onTitleRollOver',
            'mouseout .js-goto': '_onTitleRollOut'
        }
    }

     created(){        
         this._titleRollIndex = 0;
    }
    
    _onTitleRollOver(event){
        this._titleRollIndex = event.currentTarget.getAttribute('data-target');
        this.querySelector('.js-title'+this._titleRollIndex).classList.remove('u-hide');
    }

    _onTitleRollOut(event){        
        this.querySelector('.js-title'+this._titleRollIndex).classList.add('u-hide');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },

            activeSlide: { type: 'int', value: 0 },

            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },
            dragToVal: { type: 'array', value: [0,0] },

            disableNext:  { type: 'bool', value: false },
            disablePrev:  { type: 'bool', value: true }
        }
    }

    created(){
        this.componentReady = false;
        this._pointerNone = false;
        this._x = 0;
        this._thumbWidth = 0;
    }

    ready(){
        setTimeout( () => {
            this.$goToLinks = this.querySelectorAll('.js-goto');
            this.$container = this.querySelector('.js-container');

            if(this.$goToLinks && (this.$goToLinks.length == this.props.content.slides.length) && this.$container){
                this.componentReady = true;
                this.resize();

            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){
        if(this.componentReady){
            this._thumbWidth = this.$goToLinks[0].offsetWidth;
            this.initDrag();
        }
    }

    initDrag(){

        // container Size
        this.props.containerSize[0] = this.$container.offsetWidth;
        this.props.containerSize[1] = this.$container.offsetHeight;


        // content Size
        this.maxTop = 0;
        this.maxLeft = 0;

        for ( let i = 0, length = this.$goToLinks.length; i < length; i++ ){

            let offset = this.$goToLinks[i].getBoundingClientRect();

            let computedTop = this.$goToLinks[i].clientHeight;
            let computedLeft = offset.left + this.$goToLinks[i].clientWidth;

            if (this.maxTop < computedTop ) {
                this.maxTop = computedTop;
            }

            if( this.maxLeft < computedLeft ) {
                this.maxLeft = computedLeft;
            }
        }

        this.props.contentSize[0] = this.maxLeft;
        this.props.contentSize[1] = this.maxTop;

        // console.log(this.props.containerSize, this.props.contentSize);
    }

    onMove(x, y){
        // console.log('onMove', x, y);
        this._x = x;

        this.props.disableNext = ( this._x > (this.props.contentSize[0] - this.props.containerSize[0]) - this._thumbWidth/2 ) ? true : false;
        this.props.disablePrev = ( this._x < this._thumbWidth/2 ) ? true : false;


        if( this._x < ( this.props.contentSize[0] - this.props.containerSize[0] ) ){
            this.$container.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;
        }
    }

    onDrag(){
        if(!this._pointerNone){
            this._pointerNone = true;
            this.$container.classList.add('u-force-pointer-none');
        }
    }

    onEnd(){
         if(this._pointerNone){
            this._pointerNone = false;
            this.$container.classList.remove('u-force-pointer-none');
        }
    }

    onNextThumb() {
        // console.log('onNextThumb');

        let target = this._x + this._thumbWidth;

        if( target > ( this.props.contentSize[0] - this.props.containerSize[0] ) ){
            target = this.props.contentSize[0] - this.props.containerSize[0];
        }

        this.props.dragToVal[0] = target;
    }

    onPrevThumb(){ 
        // console.log('onPrevThumb');

        let target = this._x - this._thumbWidth;

        if( target < 0){
            target = 0;
        }

        this.props.dragToVal[0] = target;
    }

    onGoToSlide( event ){
        var contenttype = "";
        var liveid = "";
        if(event){
            this.props.activeSlide = Number(event.currentTarget.getAttribute('data-target'));
            contenttype = event.currentTarget.getAttribute('type');
            liveid = event.currentTarget.getAttribute('connectionid');
        }
        if (contenttype == "live") {
            subcribebroadcast(liveid);
        }

        Array.prototype.forEach.call(this.$goToLinks, (el, i) => {

            let index = el.getAttribute('data-target');

            if( index == this.props.activeSlide ) {
                el.classList.add('is-active');
            }
            else {
                el.classList.remove('is-active');
            }

        });
    }
}

register('slideshow-event', SlideshowEvent);
