import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');

class LightEngineGeometryPlane extends Component {

	get defaultProps(){
		return {
			height:         { type: "float", value: 100 },
			width:          { type: "float", value: 100 },
			widthSegments:  { type: "int", value: 1 },
			heightSegments: { type: "int", value: 1 },
            anchor:         { 'type': "array", value: [0.5, 0.5] },
            uv:             {type: 'array', value: null}
		}
	}

	detached(){
		this._release()
	}
	
	_release() {
		console.log('<light-engine-geometry-plane> dispose()', THREE)
		// console.log('       before', 'textures: ' + THREE.TextureIdCount, 'geometries: ' + THREE.GeometryIdCount() )
		
		if (this.mesh !== void 0) {
			if (this.mesh.geometry) {
				this.mesh.geometry.dispose();
			}
			this.mesh.geometry = null;
			this.mesh = null
			// console.log('<light-engine-geometry-plane> dispose() -> break ref');
		}
		
		if (this.geometry !== void 0) {
			this.geometry.dispose();
			this.geometry = null;
		}

		// console.log('        after', 'textures: ' + THREE.TextureIdCount, 'geometries: ' + THREE.GeometryIdCount() )
	}

	resizeEnd() {
		this.createGeometry();
	}

	attached() {
		this.createGeometry();
	}

	createGeometry() {

		this._release()

		console.log('<light-engine-geometry-plane> create()')
		console.log('       before', 'textures: ' + THREE.TextureIdCount, 'geometries: ' + THREE.GeometryIdCount() )

        this.geometry = new THREE.PlaneBufferGeometry(
            this.props.width,
            this.props.height,
            this.props.widthSegments,
            this.props.heightSegments
        );

        if (this.props.uv !== null) {
			for(let i=0; i<this.props.uv.length; i++){
				this.geometry.attributes.uv.array[i] = this.props.uv[i];
			}
			this.geometry.attributes.uv.needsUpdate = true;
		}

        if(this.props.anchor){
            let translateX = (this.props.anchor[0] - 0.5) * 2 * (this.props.width/2) * -1; //(this.props.width  ) - halfWidth;
            let translateY = (this.props.anchor[1] - 0.5) * 2 * (this.props.height/2);
            this.geometry.applyMatrix( new THREE.Matrix4().makeTranslation( translateX, translateY, 0 ) );
        }

        this.addToMesh();

        console.log('        after', 'textures: ' + THREE.TextureIdCount, 'geometries: ' + THREE.GeometryIdCount() )

    }

	addToMesh(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-MESH"){
				this.mesh = node.mesh
				if( this.mesh ){
					this.mesh.geometry = this.geometry;
				}
				break;
			}
			node = node.parentNode;
		}
	}

}

register('light-engine-geometry-plane', LightEngineGeometryPlane);
