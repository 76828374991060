import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class CoreInstagramConnect extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            //callback url after successfull instagral connection
            redirectUri: {type:'string', value:''},

            //visible in the instagram application
            clientId: {type:'string', value: ''},

            //generated after successfull instagram connection
            code: {type:'string', value:''},

            //form 
            url: {type:'string', value: ''},
            forceSubmit: {type:'bool', value: false},


            isConnected: {type:'bool', value: false},

            disconnect: {type:'bool', value: false},

            connectLabel : 'connect',
            disconnectLabel: 'disconnect',

            disconnectUrl: '',
            errorMessage: ''
        }
    }


    get events(){
        return {
            'click .js-connect': 'onConnect',
            'click .js-disconnect': 'onDisconnect'
        }
    }

    ready(){

        if (!this.props.isConnected) {
            if(this.getParameter('code')){
                this.props.code = this.getParameter('code');     
                this.props.forceSubmit = true;
            }
        }

    }

    onDisconnect() {
        this.props.disconnect = true;
        requestAnimationFrame(()=>{
            this.props.disconnect = false;
        });
    }

    onDisconnectSuccess(data) {
        if (!data.body.success) {
            this.onDisconnectError(data.body.response.body);
            return;
        }
        this.props.isConnected = false;
        this.props.errorMessage = '';
    }

    onDisconnectError(data) {
        this.props.errorMessage = data;
        console.log('<core-instargram-connect> onDisconnectErorr()');
    }

    onConnect(){
        window.open('https://api.instagram.com/oauth/authorize/?client_id=' + this.props.clientId + '&redirect_uri=' + window.location.origin + this.props.redirectUri + '&response_type=code', '_self');
    }

    onConnectSuccess(data) {
        if (!data.body.success) {
            this.onConnectError(data.body.response.body);
            return;
        }
        this.props.isConnected = true;
        this.props.errorMessage = '';
    }

    onConnectError(err) {
        this.props.isConnected = false;
        this.props.errorMessage = err;
    }

    getParameter(param) { 
      const params = window.location.search.substr(1).split('&');
     
      for (let i = 0; i < params.length; i++) {
        let p=params[i].split('=');
        if (p[0] == param) {
          return decodeURIComponent(p[1]);
        }
      }
      return false;
    }


}

register('core-instagram-connect', CoreInstagramConnect);
