import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AddProduct extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' },
            instructions: '',
           // clear: {type: 'bool', value: false },
        }
    }


    attached(){
       
    }

    onSuccess( data ){

         if(data.body.success){
            EventDispatcher.fire('reload:force');
            EventDispatcher.fire('state:change', ['popin:close']);
            EventDispatcher.fire('notify', [{title: 'Product', body: 'product added successfully' }]);
        }
        else {
            this.props.errorMessage = data.body.response.desc;
        }

        // this.props.clear = true;
        // requestAnimationFrame(()=>{
        //     this.props.clear = false;
        // });
    }

    onError(data){
        console.log('error', data.body);
    }

    onSelectChange( value ){

        for (let i = 0, l = this.props.content.form.instructions.length; i < l; i++ ){
            if( this.props.content.form.instructions[i].type == value ){
                this.props.instructions = this.props.content.form.instructions[i].label;
            }

        }

    }
}

register('add-product', AddProduct);
