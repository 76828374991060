import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class signUp extends Component {

    get template() {
        return require('./template');
    }

    //get events(){
    //    return {
    //        'click .js-facebook-signin':  'onFacebookSignin',
    //        'click .js-linkedin-signin':  'onLinkedinSignin'
    //    }
    //}

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' },
            firstName: '',
            lastName: '',
            email: '',
            //apiName: null,
            //apiUserId: null,
            identificationtoken:'',
            passwordValue: {type: 'string', value: null}
        }
    }

    //attached(){
    //    this.bindMethods(['onPasswordBlur']);
    //}

    //detached(){
    //    this.removeInputsPasswordListener();
    //}

    //ready(){
    //    setTimeout( () => {
    //       // this.$btnSignInGoogle = this.querySelector('.js-google-signin');
    //        this.$inputPassword = this.querySelector('.js-input-password');
    //        this.$inputPasswordConfirm = this.querySelector('.js-input-password-confirm');

    //        if(this.$inputPassword &&
    //            this.$inputPasswordConfirm){

    //          //  this.initGoogleSignIn();
    //          //  this.initFacebookSignIn();
    //          //  this.initLinkedinSignIn();
    //            this.addInputsPasswordListener();
    //        }
    //        else {
    //            this.ready();
    //        }
    //    }, 100);
    //}

    // PASSWORD
    // -------------------

    //addInputsPasswordListener(){
    //    this.$inputPassword.addEventListener('blur', this.onPasswordBlur);
    //    this.$inputPasswordConfirm.addEventListener('blur', this.onPasswordBlur);
    //}

    //removeInputsPasswordListener(){
    //    this.$inputPassword.removeEventListener('blur', this.onPasswordBlur);
    //    this.$inputPasswordConfirm.removeEventListener('blur', this.onPasswordBlur);
    //}

    //onPasswordBlur(evt){
       
    //    const valPassword = this.$inputPassword.value,
    //          valPasswordConfirm = this.$inputPasswordConfirm.value;
    //   // this.props.passwordValue = valPassword.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");


    //    if(this.$inputPasswordConfirm.value != '' &&
    //       valPassword != valPasswordConfirm){
    //        this.$inputPasswordConfirm.classList.add('is-error');
    //    }
    //    else if(valPasswordConfirm != '' &&
    //       valPassword == valPasswordConfirm){
    //        this.$inputPasswordConfirm.classList.remove('is-error');
    //    }
    //}

    // GOOGLE
    // -------------------

    //initGoogleSignIn(){
    //    if(gapi){
    //        gapi.load('auth2', () => {
    //            this.googleAuth2 = gapi.auth2.init({
    //                client_id: '847281066638-lc9hkccvnkg8rkesdasqpn2fn5oh166h.apps.googleusercontent.com',
    //                cookiepolicy: 'single_host_origin',
    //            });
    //            this.attachGoogleSignin();
    //        });
    //    }
    //}

    //attachGoogleSignin(){
    //    this.googleAuth2.attachClickHandler( this.$btnSignInGoogle, {},
    //        (googleUser) => {
    //            this.onGoogleSuccess(googleUser);
    //        },
    //        (error) => {
    //            alert(JSON.stringify(error, undefined, 2));
    //        }
    //    );
    //}

    //onGoogleSuccess(googleUser){

    //    let profile = googleUser.getBasicProfile();
    //    // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    //    this.props.firstName = profile.getGivenName();
    //    this.props.lastName = profile.getFamilyName();
    //    this.props.email = profile.getEmail();
    //    this.props.apiName = 'google';
    //    this.props.apiUserId = googleUser.getAuthResponse().id_token;
    //}

    // FACEBOOK
    // -------------------

    //initFacebookSignIn(){
    //    if(FB){
    //        FB.init({
    //            appId      : '590475057796806',
    //            xfbml      : true,
    //            version    : 'v2.6'
    //        });
    //    }
    //}

    //onFacebookSignin() {

    //    FB.login( (response) => {
    //        // handle the response
    //        if (response.status === 'connected') {
    //            // Logged into your app and Facebook.
    //            this.onFacebookSuccess();
    //        }
    //        else if (response.status === 'not_authorized') {
    //            // The person is logged into Facebook, but not your app.
    //        }
    //        else {
    //            // The person is not logged into Facebook, so we're not sure if
    //            // they are logged into this app or not.
    //        }
    //    }, {scope: 'public_profile, email'});

    //}

    //onFacebookSuccess() {

    //    FB.api('/me?fields=id,email,first_name,last_name,gender', (response) => {

    //        this.props.firstName = response.first_name;
    //        this.props.lastName = response.last_name;
    //        this.props.email = response.email;

    //        if(response.gender){
    //            for( let i = 0, l = this.props.content.form.gender.radio.length; i < l; i++){
    //                this.props.content.form.gender.radio[i].active = false;

    //                if(this.props.content.form.gender.radio[i].value == response.gender){
    //                    let el = this.querySelector('#f-'+this.props.content.form.gender.radio[i].value);
    //                    if( el ){
    //                        el.checked = true;
    //                    }
    //                    this.props.content.form.gender.radio[i].active = true;
    //                }
    //            }
    //        }
    //        this.props.apiName = 'facebook';
    //        this.props.apiUserId = response.id;
    //    });

    //}

    // LINKEDIN
    // -------------------

    //initLinkedinSignIn() {
    //    if(IN){
    //        IN.Event.on(IN, "auth", this.onLinkedinSuccess.bind(this));
    //    }
    //    else {
    //        console('linkedin API is not defined', IN);
    //    }
    //}

    //onLinkedinSignin(){
    //    if(IN){
    //        IN.User.authorize();
    //    }
    //}

    //onLinkedinSuccess(data){

    //    IN.API.Profile("me").fields("id", "first-name", "last-name", "email-address")
    //        .result( (data) => {

    //            console.log("LINKED IN", data);

    //            this.props.firstName = data.values[0].firstName;
    //            this.props.lastName  = data.values[0].lastName;
    //            this.props.email     = data.values[0].emailAddress;

    //            this.props.apiName    = 'linkedin';
    //            this.props.apiUserId = data.values[0].id;

    //        })
    //        .error( (error) => {
    //            console.log('linkedin sign in error', error);
    //        }
    //    );

    //}

    // -------------------

   
    onSuccess( data ){
         if(data.body.success){
            EventDispatcher.fire('state:change', ['popin', {url:data.body.redirect, save: true, "size":"small"}]);
        }
        else {
            this.props.errorMessage = data.body.response;
        }
    }

    onError(data){
        console.log('error', data.body);
    }

}

register('sign-up', signUp);
