import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class SearchHome extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            'click .js-tag':        '_onTagDown',
            'click .js-clear-tags': '_clearTags',
            'click .js-category':   '_onCategoryDown',
            'pointerdown .js-more':       '_onMoreDown',
            "pointerdown .js-input-search": '_onInputFocus'
        }
    }

    get defaultProps(){
        return {
            content:        { type: 'json',   value: null  },
            isSearchActive: { type: 'bool',   value: false },
            isLoading:      { type: 'bool',   value: false },
            selectedTags:   { type: 'arr',    value: []    },
            currCategory: 'all',
            place: 'anywhere',
            date:  '',
            todate:'',
            more:  '',
            page: 0,
            posts:          { type: 'arr',    value: null, change: '_onPostChange' },
            query:          { type: 'string', value: '',   change:'_onQueryChange' },
            data: {type: 'json', value: null},
            norecordfoundmessage: { type: 'string',    value: '' },
        }
    }

    _onFilterPlaceChange(e) {
        this.props.place = e.currentTarget.value;
        this._fireSearch();
    }

    _onFilterDateChange(e) {
        this.props.date = e.currentTarget.value;
        this._fireSearch();
    }

    _onFilterToDateChange(e) {
        this.props.todate = e.currentTarget.value;
        this._fireSearch();
    }

    _onQueryChange() {        
        this._fireResearch();
    }

    _onOverlayInputChange(value) {
        this.props.query = value;
    }

    _onInputChange(value) {        
        this.props.query = value;
        if (value !== ''
            && !this.props.isSearchActive ) {
            this._openSearch();
        }
    }

    _onInputFocus() {        
        this._openSearch();
    }

    _onPostChange() {
        clearTimeout( this._loaderTimer );
        this._loaderTimer = setTimeout(()=>{
            this.props.isLoading = false;
        },300);
    }

    _getCurrentCategory() {
        for (let i=0; i<this.props.content.categories.length; i++) {
            if ( this.props.content.categories[i].active ) {
                this.props.currCategory = this.props.content.categories[i].name;
            }
        }
    }

    ready() {

        EventDispatcher.fire('page:ready');

        this._getSelectedTags();
        this._getCurrentCategory();

        EventDispatcher.request('search:store', [(data)=>{

            this.props.posts = data.posts;
            this.props.data = data;

            /**
             * Bug in Firefox
             */
            requestAnimationFrame(()=>{                
                EventDispatcher.fire('search', [{
                    tags: this._purgeSelectedTags(),
                    category: this.props.currCategory,
                    date: this.props.date,
                    todate: this.props.todate,
                    place: this.props.place,
                    keywords: this.props.query,
                    page: 0,
                }]);
            });

        }]);

        EventDispatcher.on('searchcompleted', this._onSearchCompleted, this);
        EventDispatcher.on('search:clear', this._clearTags, this);
        EventDispatcher.on('search:new', this._openSearch, this);
        EventDispatcher.on('search', this._onSearch, this);

    }


    detached() {
        EventDispatcher.off('searchcompleted', this._onSearchCompleted, this);
        EventDispatcher.off('search:clear', this._clearTags, this);
        EventDispatcher.off('search:new', this._openSearch, this);
        EventDispatcher.off('search', this._onSearch, this);
    }


    _onSearchCompleted(norecordfoundmessage){        
        this.props.norecordfoundmessage = norecordfoundmessage;
    }

    _onOverlaySend() {
        this._closeSearch();
        this._extractTagsFromQuery();
        this.props.query = '';
    }

    _extractTagsFromQuery() {
        let keywords = this.props.query.split(' ');
        for (let j=0; j<keywords.length; j++ ) {
            let hasKeyword = false;
            for (let i=0; i<this.props.selectedTags.length; i++ ) {
                if (this.props.selectedTags[i].label === keywords[j]) {
                    hasKeyword = true;
                    break;
                }
            }
            if (!hasKeyword && keywords[j] !== ' ') {
                this.props.selectedTags.push({
                    label: keywords[j],
                    name: keywords[j]
                })
            }
        } 
    }  

    _openSearch() {
        this.props.isSearchActive = true;
        EventDispatcher.fire('scroll:disable');
    }

    _closeSearch() {
        this.props.isSearchActive = false;
        EventDispatcher.fire('scroll:enable');
    }

    _getSelectedTags() {
        for (let i=0; i<this.props.content.choosenTags.length; i++ ) {
            this.props.selectedTags.push({
                label: this.props.content.choosenTags[i].label,
                name: this.props.content.choosenTags[i].name,
            });
        }
    }

    _purgeSelectedTags() {
        let tags = [];
        for (let i=0;i<this.props.selectedTags.length;i++) {
            tags.push(this.props.selectedTags[i].name);
        }
        return tags
    }

    _fireResearch() {

        this.props.page = 0;
        for (let i=0; i<this.props.content.categories.length; i++) {  
            this.props.content.categories[i].active = false;
        }
        this.props.content.categories[0].active = true;
        this.props.currCategory = this.props.content.categories[0].name;
        this.props.isLoading = true;

        clearTimeout( this._loaderTimer );

        this._loaderTimer = setTimeout(()=>{

            EventDispatcher.fire('search', [{
                tags: this._purgeSelectedTags(),
                category: this.props.currCategory,
                date: this.props.date,
                todate: this.props.todate,
                place: this.props.place,
                keywords: this.props.query,
                page: this.props.page,
                num:-1,
            }]);

        }, 300);
    }


    _fireSearch() {

        this.props.page = 0;
        this.props.isLoading = true;
        
        clearTimeout( this._loaderTimer );

        this._loaderTimer = setTimeout(()=>{

            EventDispatcher.fire('search', [{
                tags: this._purgeSelectedTags(),
                category: this.props.currCategory,
                date: this.props.date,
                todate: this.props.todate,
                place: this.props.place,
                keywords: this.props.query,
                page: this.props.page,
            }]);

        }, 300);
    }

    _onTagDown(e) {

        let tagName  = e.currentTarget.getAttribute('name');
        let tagLabel = e.currentTarget.getAttribute('label');

        let isTagAlreadySelected = false;
        for (let i=0; i<this.props.selectedTags.length; i++ ) {
            if (this.props.selectedTags[i].name === tagName) {
                isTagAlreadySelected = true;
                this.props.selectedTags.splice(i,1);
                break;
            }
        }
        if (!isTagAlreadySelected) {
            this.props.selectedTags.push({
                label: tagLabel,
                name: tagName
            });
        }
        for (let i=0; i<this.props.content.tags.length; i++ ) {
            if (this.props.content.tags[i].name === tagName) {
                this.props.content.tags[i].active = !this.props.content.tags[i].active; 
                break;
            }
        }
        this._fireSearch();
    }

    _clearTags(e) {
        for (let i=0; i<this.props.content.tags.length; i++ ) {
            this.props.content.tags[i].active = false;
        }
        this.props.selectedTags.splice(0,this.props.selectedTags.length);
        this._fireSearch();
    }

    _onCategoryDown(e) {

        let categoryIndex = Number(e.currentTarget.getAttribute('index'));
        for (let i=0; i<this.props.content.categories.length; i++) {
            if (i === categoryIndex) {
                this.props.content.categories[i].active = true;
            }
            else {
                this.props.content.categories[i].active = false;
            }
        }
        this.props.currCategory = this.props.content.categories[categoryIndex].name;
        this._fireSearch();
    }

    _onMoreDown() {
        
        this.props.page++;

        EventDispatcher.fire('search', [{
            tags: this._purgeSelectedTags(),
            category: this.props.currCategory,
            date: this.props.date,
            todate: this.props.todate,
            place: this.props.place,
            keywords: this.props.query,
            page: this.props.page,
        }]);

        //save params in the url
        EventDispatcher.fire('state:change', ['page', {
            url: '/',
            queryParams: {
                tags: this._purgeSelectedTags(),
                category: this.props.currCategory,
                date: this.props.date,
                todate: this.props.todate,
                place: this.props.place,
                keywords: this.props.query,
                page: this.props.page,
            }
        }]);

    }

    _onSearch(params) {

        if (params.tags !== void 0) {
            this.props.selectedTags.splice(0, this.props.selectedTags.length);
            let newTags = []
            for (let i=0; i<params.tags.length; i++) {
                newTags.push({
                    label: params.tags[i],
                    name: params.tags[i],
                })
            }
            Array.prototype.push.apply(this.props.selectedTags, newTags);
        }

        if (params.keywords !== void 0) {
            this.props.query = params.keywords
        }

    }


}

register('search-home', SearchHome);
