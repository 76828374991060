import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class customerror extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    ready(){
        EventDispatcher.fire('page:ready');
    } 
}

register('custom-error', customerror);
