import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminEvent extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            action: 'draft',
            reload: {type: 'bool', value: false}
        }
    }

    get events(){
    	return {
    		'click .js-form-submit' : 'onFormSubmit'
    	}
    }

    created() {
        // this._sendList = [];
        // this._requestNum = 0;
    }


    //---------------------------
    //    CONEXIONS WITH FORMS

    // _addRequest(sendCallback){
    //     this._sendList.push(sendCallback);
    //     return this._onRequestLoaded.bind(this);
    // }

    // _onRequestLoaded() {
    //     this._requestNum--;
    //     if (this._requestNum<1) {

    //         if (this._actionType == 'publish') {
    //             EventDispatcher.fire('reload:force');
    //         }
    //     }
    // }


    //---------------------------
    //    DISPATCH ALL LOADS

    onFormSubmit(event){

        // const type = event.currentTarget.getAttribute('data-action');
        // for(let i = 0, l = this._sendList.length; i < l; i++) {
        //     this._requestNum += this._sendList[i](type);
        // }
        // this._actionType = type;
        this.$required = this.querySelectorAll('.js-required');
        this.$goToTabLinks = this.querySelectorAll('.js-goToTab');
        for (let i=0,l= this.$required.length; i<l; i++) {            
            if(    (!this.$required[i].value && this.$required[i].type !== 'checkbox')
                || (this.$required[i].value == '' && this.$required[i].type !== 'checkbox')
                || (this.$required[i].type == 'checkbox' && !this.$required[i].checked)) {

                    this.$required[i].classList.add('is-error');
                    this.$required[i].classList.remove('is-success');
                    
                    Array.prototype.forEach.call(this.$goToTabLinks, (el, i) => {

                        let index = el.getAttribute('data-target');

                        if( index == 0 ) {
                            el.click();
                            el.classList.add('is-active');
                        }
                        else {
                            el.classList.remove('is-active');
                        }

                    });
            }
            else{
                this.$required[i].classList.remove('is-error');
                this.$required[i].classList.add('is-success');
            }

        }



        this.props.action = event.currentTarget.getAttribute('data-action');
        console.log(this.props.action);

        this.props.reload = true;
        requestAnimationFrame(()=>{
            this.props.reload = false;
        });

        EventDispatcher.fire('admin:event:clear');

    }

    _onFormSucess(data) {
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        EventDispatcher.fire('page:reload');
    }
    
    _onFormError() {

    }

}

register('admin-event', AdminEvent);