import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

class LiveTimeline extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goToTab': 'onTabChange',
            'click .js-drag-container': 'onToggleCollapse'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            activeTab: { type: 'int', value: 0 },
            collapseState: { type: 'bool', value: false }
        }
    }

    created(){
        this.componentReady = false;
    }

    ready(){

        setTimeout( () => {
            this.$goToLinks = this.querySelectorAll('.js-goToTab');

            if(this.$goToLinks && this.$goToLinks.length){
                this.componentReady = true;
            }
            else {
                this.ready();
            }

        }, 100);

    }

    onTabChange(event){

        if(event){
            let targetTab = Number(event.currentTarget.getAttribute('data-target'));

            if(targetTab != this.props.activeTab){
                this.props.activeTab = targetTab;
                this.props.collapseState = true;
            }
            else {
                this.props.collapseState = !this.props.collapseState;
            }
        }

        Array.prototype.forEach.call(this.$goToLinks, (el, i) => {

            let index = el.getAttribute('data-target');

            if( index == this.props.activeTab ) {
                el.classList.add('is-active');
            }
            else {
                el.classList.remove('is-active');
            }

        });

    }

    onToggleCollapse(){
        if(!this.props.collapseState){
            this.props.collapseState = true;
        }
        else {
            this.props.collapseState = false;
        }
    }

}

register('live-timeline', LiveTimeline);
