import {EventDispatcherMixin} from "light";
import {support} from "light";

import extend from './extend';
import CubicBezier from './CubicBezier';


/**
 * throttle the touchmove event no to be fired moire than 60times/seconds
 * in milliseconds
 */
const TOUCH_MOVE_THROTTLE = 10;//10;

/**
 * Minimum movement of touch required to be considered a drag.
 */
const MIN_TRACKING_FOR_DRAG = 10;

/**
 * Time delay before canceling momentum.
 */
const MAX_DELAY_FOR_DRAG = 300;

var isMobile = false;

if(support.touch && window.innerWidth < 768){
    isMobile = true;
}

class ScrollBehaviour {

    constructor( options ){

        this._options = extend({

            friction: (isMobile) ? 0.3 : 0.05,//0.1, //higher = faster more friction mobile = 1.
            deccelerationFriction: (isMobile) ? 0.003 : 0.0001, // 0.0006 //higher = more friction, mobile = 0.003
            minSpeed: 0.15,// minimum speed needed before the animation stop (px/ms)
            usetransition: false, //use css transition for css touch deceleration
            cssEasing: 'cubic-bezier(0, 0.33, 0.66, 1)',//easing used for css touch deceleration
            touchThrottle: TOUCH_MOVE_THROTTLE,//min delay between two touchmove event
            minDragDistance: MIN_TRACKING_FOR_DRAG, //min touch distance before trigger scrollevent
            maxDragDelay: MAX_DELAY_FOR_DRAG, //delay to consider scroll is static
            bypassNativeScroll: true, //use native scroll or smooth scroll
            useHtmlScroll: false, //use the html scroll bar to scroll the view ?
            autoFocus: false, //focus htmlscroll bar on view creation ?
            useCustomScrollBar: false,
            contentWrapper: null,
            contentWrapClass: 'scrollview-content-wrapper', //the class added to the content wrapper
            direction: 'both',//  || horizontal || both
            moveContent: false,
            momentum: false, // move content to emulate scroll or only publish scroll value
            useDrag: true,
            useWheel: false,
            target: document

        }, options || {});

        if( support.touch ){
            this._options.useHtmlScroll = false;
        }

        this.scrollToBinded  = this.scrollTo.bind(this);
        this.jumpToBinded  = this.jumpTo.bind(this);
        this.disableBinded = this._disableScroll.bind(this);
        this.enableBinded = this._enableScroll.bind(this);

        this._scrollHeight    = 0;
        this._contentHeight   = 0;
        this._maxScrollHeight = 0;
        this._scrollTop       = 0;
        this._savedScrollTop  = null; //to restore scrollpos on _focusTarget
        this._realScrollTop   = 0;
        this._scrollWidth     = 0;
        this._contentWidth    = 0;
        this._maxScrollWidth  = 0;
        this._scrollLeft      = 0;
        this._savedScrollLeft = null; //to restore scrollpos on _focusTarget
        this._realScrollLeft  = 0;
        this._touchDeltaX     = 0;
        this._touchDeltaY     = 0;
        this._totalTouchMoveX = 0;
        this._totalTouchMoveY = 0;
        this._textPosX        = 0;
        this._textPosY        = 0;
        this._scrollPosX      = 0;
        this._scrollPosY      = 0;
        this._touchEvents     = [];
        this._enabled         = true; // is the scroll active ?
        this._focused         = false;// is the view active/focused?
        this._hasMoved        = false;
        this._preventClick    = false;
        this._momentumProps   = false;
        this.hasJumpedOnce   = false;// on window load, the browser will try to autoScroll. to avoid this, we add a setTimeout only on the first jump
        this._onScrollBinded = this._onScroll.bind(this);
        this._onPointerDown  = this.onPointerDown.bind(this);
        this._onPointerMove  = this.onPointerMove.bind(this);
        this._onPointerUp    = this.onPointerUp.bind(this);

        //save the 2 last touchmove timestamp and delta in order to use it on touchend.
        //sometimes, touchend and the last touchmove will be the same, so we will have to use the previous touchmove event
        this._touchTimestampArr = [];
        this.touchScrollArr    = [];

        this._bindEvents();
        this._enterFrame();

    }

    destroy(){

        this._options.target.removeEventListener( support.pointerdown, this._focus);
        this._options.target.removeEventListener( 'DOMMouseScroll', this._focus);
        this._options.target.removeEventListener( 'mousewheel', this._focus);


        for( let i = 0, l = support.multipointerdown.length; i < l; i++){
            this._options.target.removeEventListener(support.multipointerdown[i], this._onPointerDown);
            this._options.target.removeEventListener(support.multipointerdown[i], this._focus);
        }

        for( let i = 0, l = support.multipointermove.length; i < l; i++){
            document.removeEventListener(support.multipointermove[i], this._onPointerMove);
        }

        for( let i = 0, l = support.multipointerup.length; i < l; i++){
            document.removeEventListener(support.multipointerup[i], this._onPointerUp);
        }

    }

    focus(){

    }

    _bindEvents(){

        if( this._options.autoFocus ){
            this._focus = this.focus.bind(this);

            for( let i = 0, l = support.multipointerdown.length; i < l; i++){
                this._options.target.addEventListener(support.multipointerdown[i], this._focus);
            }

            this._options.target.addEventListener( 'DOMMouseScroll', this._focus);
            this._options.target.addEventListener( 'mousewheel', this._focus);
        }

        if( this._options.useDrag ){

            for( let i = 0, l = support.multipointerdown.length; i < l; i++){
                this._options.target.addEventListener(support.multipointerdown[i], this._onPointerDown, false);
            }

            for( let i = 0, l = support.multipointermove.length; i < l; i++){
                document.addEventListener(support.multipointermove[i], this._onPointerMove, false);
            }

            for( let i = 0, l = support.multipointerup.length; i < l; i++){
                document.addEventListener(support.multipointerup[i], this._onPointerUp, false);
            }
        }

        this._options.target.addEventListener ("mousewheel", this._wheelBinded, false);
        this._options.target.addEventListener ("DOMMouseScroll", this._wheelBinded, false);

    }

    _computeMomentum(direction, distance, time) {

        var scrollProp    = direction == 'vertical' ? '_scrollPosY' : '_scrollPosX';
        var maxScrollProp = direction == 'vertical' ? '_scrollHeight' : '_scrollWidth';

        var speed = Math.abs(distance) / time;
        var maxDistLower = this[maxScrollProp] - this[scrollProp];
        var momentumLength, momentumDuration;

        /** momentum */
        momentumLength = ( speed * speed ) / ( this._options.deccelerationFriction * 2);
        momentumDuration = speed / this._options.deccelerationFriction;

        if (distance < 0 && momentumLength > this[scrollProp] ) { //would get over top

            momentumDuration = momentumDuration * this[scrollProp] / momentumLength;
            momentumLength = this[scrollProp];

        } else if (distance > 0 && momentumLength > maxDistLower) { //would get over bottom

            momentumDuration = momentumDuration * maxDistLower / momentumLength;
            momentumLength = maxDistLower;

        }

        momentumLength = momentumLength * (distance > 0 ? 1 : -1);

        return {
            length: momentumLength >> 0,
            duration: momentumDuration >> 0
        };

    }

    $deccelerate() {

        if (this.momentumProgress >= this.momentumDuration) {
            return;
        }

        var timestamp = Date.now();
        var diff = timestamp - this.momentumStartTime;
        var e = this.momentumProgress !== 0 ? CubicBezier(this._momentumEasing[0], this._momentumEasing[1], this._momentumEasing[2], this._momentumEasing[3], this.momentumProgress/ this.momentumDuration, this.momentumDuration) : 0;

        this.momentumProgress = this.momentumProgress + diff;
        this.momentumStartTime = timestamp;

        /**
        * less pecise but a lot fatser than the technic used on scroll end
        */
        this._scrollPosX  = (this._scrollPosXBeforeMomentum + (e * this._momentumdistanceLeft ) ) >> 0;
        this._scrollPosY  = (this._scrollPosYBeforeMomentum + (e * this._momentumdistanceTop  ) ) >> 0;

        // if( !support.touch ){
        //     this._isAutoScroll = true;
        //     window.scrollTo( this._scrollPosX, this._scrollPosY );
        // }

    }

    _computeFakeMomentum( distX, distY, duration, easing, callback, forceCallback ) {

        this.momentumStartTime        = Date.now();
        this.momentumProgress         = 0;
        this.momentumDuration         = duration;
        this._scrollPosXBeforeMomentum = this._scrollPosX;
        this._scrollPosYBeforeMomentum = this._scrollPosY;
        this._momentumdistanceLeft     = distX;
        this._momentumdistanceTop      = distY;
        this._momentumEasing           = easing || [ 0, 0.33, 0.66, 1 ];
        this._momentumCallback         = callback || null;
        this.forceMomentumCallback    = forceCallback || false;

    }

    _setMomentum(momentumDistanceLeft, momentumDistanceTop, momentumDuration, momentumEasing, callback) {

        /**
        * launch requestanimframe loop
        * sync with the css transition to update this._scrollTop
        */
        this._computeFakeMomentum( momentumDistanceLeft, momentumDistanceTop, momentumDuration, momentumEasing, callback );

        /** launch the css transition */
        this._scrollBy( momentumDistanceLeft, momentumDistanceTop, momentumDuration, momentumEasing );

    }

    onPointerDown(e) {

        if (!this._enabled ){
            return;
        }

        this._isPointerDown = true;
        //document.addEventListener(support.pointermove, this._onPointerMove, false);
        //document.addEventListener(support.pointerup, this._onPointerUp, false);

        this._totalTouchMoveX    = 0;
        this._totalTouchMoveY    = 0;
        this._preventClick       = false;
        this._hasMoved           = false;
        this._touchEvent         = support.touch && e.type != 'mousedown' ? (e.touches[0] || e.changedTouches[0]) : e;
        this._touchTimestamp     = e.timeStamp || Date.now();
        this._touchPositionX     = this._touchEvent.pageX;
        this._touchPositionY     = this._touchEvent.pageY;
        this._touchEvents.length = 0;

        this._stopDecceleration();

    }

    onPointerMove(e) {

        if (!this._enabled || !this._isPointerDown ){
            return;
        }

        // e.preventDefault();

        this.newTouchTimestamp = e.timeStamp || Date.now();

        //throttle the touchmove function no to trigger it too often
        if( this.newTouchTimestamp - this._touchTimestamp < TOUCH_MOVE_THROTTLE )
        return;


        this._touchEvent       = support.touch && e.type != 'mousemove' ? (e.touches[0] || e.changedTouches[0]) : e;
        this._touchDeltaX      = this._touchEvent.pageX - this._touchPositionX;
        this._touchPositionX   = this._touchEvent.pageX;
        this._totalTouchMoveX += Math.abs(this._touchDeltaX);
        this._touchDeltaY      = this._touchEvent.pageY - this._touchPositionY;
        this._touchPositionY   = this._touchEvent.pageY;
        this._totalTouchMoveY += Math.abs(this._touchDeltaY);

        // We need to move at least n pixels for the scrolling to initiate
        // where n = MIN_TRACKING_FOR_DRAG


        if (this._totalTouchMoveX > MIN_TRACKING_FOR_DRAG
        || this._totalTouchMoveY > MIN_TRACKING_FOR_DRAG )
        {
            this._hasMoved = true;
            this._preventClick = true;

            this.fire( "move" );
        }


        //TODO DRY this part
        if (this._scrollPosY - this._touchDeltaY <= 0) {
            this._scrollPosY = 0;
        } else if (this._scrollPosY - this._touchDeltaY > this._maxScrollHeight) {
            this._scrollPosY = this._maxScrollHeight;
        } else {
            this._scrollPosY -= this._touchDeltaY;
        }

        if (this._scrollPosX - this._touchDeltaX <= 0) {
            this._scrollPosX = 0;
        } else if (this._scrollPosX - this._touchDeltaX > this._maxScrollWidth) {
            this._scrollPosX = this._maxScrollWidth;
        } else {
            this._scrollPosX -= this._touchDeltaX;
        }

        this._activateWatch();

        this._touchTimestamp = this.newTouchTimestamp;

    }

    $watch(timestamp) {

        this._scrollLeft += ( this._scrollPosX - this._scrollLeft ) * this._options.friction;
        this._scrollTop  += ( this._scrollPosY - this._scrollTop  ) * this._options.friction;

        //  ~n+1 === n*-1
        this._animate( ~this._scrollLeft+1,  ~this._scrollTop+1  );

        //Keep list from growing infinitely (holding min 10, max 20 measure points)
        if (this._touchEvents.length > 60) { //{left,top,time} * 20
            this._touchEvents.splice(0, 30);   //{left,top,time} * 10
        }

        this._touchEvents.push( this._scrollLeft, this._scrollTop, Date.now() );

        //if value unchnaged, consider scroll has stopped
        //so prepar for scroll end after a small delay
        if( Math.round(this._scrollLeft) == this._lastScrollLeft && Math.round(this._scrollTop) == this._lastScrollTop ){
            this._lastScrollLeft = Math.round(this._scrollLeft);
            this._lastScrollTop  = Math.round(this._scrollTop);
            return;
        }

        clearTimeout( this._scrollendTimer );
            this._scrollendTimer = setTimeout(()=>{
            this._onScrollEnd();
        }, 100, this);

        this._lastScrollLeft = Math.round(this._scrollLeft);
        this._lastScrollTop  = Math.round(this._scrollTop);

    }

    onPointerUp(e) {

        if (!this._enabled || !this._isPointerDown/*|| !this._focused */ ) return;

        this._isPointerDown = false;

        var self = this,
        sameEvent, lastTouchTimestamp, lastTouchDeltaY,
        duration,
        distanceLeft, distanceTop,
        momentumLeft, momentumTop;

        this.newTouchTimestamp = e.timeStamp || Date.now();

        // Then figure out what the scroll position was about 100ms ago
        var endPos = this._touchEvents.length - 1;
        var startPos;

        // Move pointer to position measured 100ms ago
        for (var i = endPos; i > 0 && this._touchEvents[i] > (this.newTouchTimestamp - 100); i -= 3) { //{left,top,time}
            startPos = i;
        }

        // If start and stop position are identical in a 100ms timeframe,
        // we cannot compute any useful decceleration.

        if (startPos == endPos) {
            this._hasMoved = false;
        }

        this.fire( 'end', this._hasMoved );

        // Compute relative movement between these two points
        duration = this._touchEvents[endPos] - this._touchEvents[startPos];
        distanceLeft = this._scrollLeft - this._touchEvents[startPos - 2];
        distanceTop  = this._scrollTop - this._touchEvents[startPos - 1];


        if ( !this._hasMoved ) {
            return;
        }

        // e.preventDefault();
        e.stopPropagation();

        /** compute momentum based on touch distance and duration  */
        momentumLeft = this._computeMomentum( 'horizontal', distanceLeft, duration );
        momentumTop  = this._computeMomentum( 'vertical', distanceTop, duration );

        if( !this._options.momentum
        || (momentumLeft.duration == 0 && momentumTop.duration == 0) )
        {
            return;
        }

        // this.scrolling = false;
        this._setMomentum(momentumLeft.length, momentumTop.length, Math.max( momentumLeft.duration, momentumTop.duration ) );

    }

    _onScroll(x, y) {
        this._stopDecceleration();
        this._scrollPosX =  x;
        this._scrollPosY =  y;
        this._activateWatch();
    }

    _activateWatch(){
        if( !this._isWatching ){
            this._isWatching = true;
        }
    }

    _stopWatch(){
        if( this._isWatching ){
            this._isWatching = false;
            this._scrollPosX = this._scrollLeft;
            this._scrollPosY  = this._scrollTop;
        }
    }

    activateDecceleration(){
        if( !this.isDeccelerating ){;
            this.isDeccelerating = true;
        }
    }

    _stopDecceleration(){
        if( this.isDeccelerating ){
            this.isDeccelerating = false;
        }

        if( this._momentumCallback ){
            if( this.forceMomentumCallback ){
                this._momentumCallback();
            }
            this._momentumCallback = null;
        }
    }

    _onScrollEnd() {

        if( this._momentumCallback ){
            this._momentumCallback();
            this._momentumCallback = null;
        }

        this._stopWatch();
        this._stopDecceleration();

        this.fire( "scrollend", this._scrollLeft, this._scrollTop );

    }

    _scrollBy(distX, distY, duration, easing) {

        if (!this._enabled){
            return;
        }

        this._hasMoved = false;

        this._activateWatch();
        this.activateDecceleration();

    }

    _setTransition(destinationX, destinationY, duration) {
        this._momentumProps = this._momentumProps ? this._momentumProps : {};
        this._momentumProps.property = typeof duration != 'undefined' ? support.transformCss : '';
        this._momentumProps.duration = typeof duration != 'undefined' ? duration : 0;
        this._momentumProps.easing = typeof duration != 'undefined' ? this._options.cssEasing : '';
        this._momentumProps.left = typeof duration != 'undefined' ? destinationX : this._scrollLeft;
        this._momentumProps.top = typeof duration != 'undefined' ? destinationY : this._scrollTop;
    }

    _animate(scrollLeft, scrollTop) {

        if ( !this._enabled ){
            return;
        }

        if( this._momentumProps  ){
            scrollLeft = this._scrollLeft;
            scrollTop = this._scrollTop;
        }

        this.fire( 'scroll', this._scrollLeft, this._scrollTop, this._momentumProps, this._contentHeight );

    }


    _enterFrame(){

        requestAnimationFrame( this._enterFrame.bind(this) );

        if( this._isWatching ){
            this.$watch();
        }

        if( this.isDeccelerating ){
            this.$deccelerate();
        }

    }

    /**
    * PUBLIC API
    */

    _enableScroll(){
        this._enabled = true;
    }

    _disableScroll(){
        this._enabled = false;
    }

    updateScrollSize( wrapperWidth, wrapperHeight, contentWidth, contentHeight ){

        this._contentWidth = contentWidth;
        this._contentHeight = contentHeight;

        //set fake scroll size
        this.wrapperWidth = wrapperWidth;
        this.wrapperHeight = wrapperHeight;

        //maxScroll is the diff between the content size and the wrapper size
        //it is only used by touch devices with bypassed native scroll
        //we don't need the ratio as it is only use by no touch devices with htmlScroll option _enabled

        this._scrollWidth    = (this._contentWidth - this.wrapperWidth);
        this._scrollHeight    = (this._contentHeight - this.wrapperHeight);

        this._maxScrollWidth = this._contentWidth >= this.wrapperWidth ? this._scrollWidth : 0;
        this._maxScrollHeight = this._contentHeight >= this.wrapperHeight ? this._scrollHeight : 0;

        // if( this._focused && this._options.useHtmlScroll ){
        //   EventDispatcher.fire('page:updatescroll', [
        //     this.wrapperWidth,
        //     this.wrapperHeight,
        //     this._contentWidth,
        //     this._contentHeight
        //   ]);
        // } else {
        //   EventDispatcher.fire('page:updatescroll', [
        //     this.wrapperWidth,
        //     this.wrapperHeight,
        //     this.wrapperWidth,
        //     this.wrapperHeight
        //   ]);
        // }

        this.onScroll( this._scrollLeft, this._scrollTop, this._momentumProps, this._contentHeight );

    }

    jumpTo(newScrollLeft, newScrollTop) {

        if ( !this._enabled ){
            return;
        }

        this._stopDecceleration();
        this._activateWatch();

        this._scrollLeft = this._scrollPosX = newScrollLeft;
        this._scrollTop  = this._scrollPosY = newScrollTop;

    }

    scrollTo( newScrollLeft, newScrollTop, duration, easing, callback) {

        if (!this._enabled ){
            return;
        }

        duration = duration || 500;

        var distanceLeft = newScrollLeft - this._scrollPosX;
        var distanceTop = newScrollTop - this._scrollPosY;

        this._setMomentum(distanceLeft, distanceTop, duration, easing, callback);

    }

    onScroll(x, y){

    }

    onScrollEnd(x, y){

    }

    wheel(e) {
        this.isScrollFromWheel = true;

        if( this._options.useWheel ){
            this._stopDecceleration();
            return;
        }
    }

};




for( let k in EventDispatcherMixin){
  ScrollBehaviour.prototype[k] = EventDispatcherMixin[k];
}

module.exports = ScrollBehaviour;
