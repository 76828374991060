import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class ListProducts extends Component {

    get events() {
        return {

        }
    }

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            data:    { type: 'json',   value: null },

            onAdd: {type:'fn', value: function(){}},
            onDelete: {type:'fn', value: function(){}},
        }
    }

    _onListReady(data) {
        this.props.data = data;
    }

    _onAdd(post){
        this.props.onAdd(post);
    }

    _onDelete(post){
        this.props.onDelete(post.content);
    }

}

register('list-products', ListProducts);