import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class StripeConfirmation extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
        }
    }

}

register('stripe-confirmation', StripeConfirmation);
