import {Component} from "light";
import {register} from "light";

class AppSlide extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            index : {type: 'int', value : 0},
            selectedIndex : {type: 'int', value : 0},
        }
    }

    _onIndexChange(index, selectedIndex) {        
        this.props.index = index;
    }

    _onSelectedIndexChange(selectedIndex) {
        this.props.selectedIndex = selectedIndex;
        this._focusSlide();
    }
    
    _focusSlide() {            
        this.setAttribute('index', (this.props.index==1?1: (this.props.index+1)/2))
        this.setAttribute('selected-index', this.props.selectedIndex);
        if ((this.props.index==1?0: ((this.props.index+1)/2)-1)> this.props.selectedIndex) {
            this.classList.add('is-next');
            this.classList.remove('is-prev');
            this.classList.remove('is-active');
        }
        else if ((this.props.index==1?0: ((this.props.index+1)/2)-1) < this.props.selectedIndex) {
            this.classList.add('is-prev');
            this.classList.remove('is-next');
            this.classList.remove('is-active');
        }
        else {
            this.classList.add('is-active');
            this.classList.remove('is-prev');
            this.classList.remove('is-next');
        }
    }

}

register("app-slide", AppSlide);

