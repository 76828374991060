import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';



class AddMultiupload extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goto': 'onGoToSlide',
            'click .js-add-field': '_addMediafield',
            'click .js-delete': '_deleteMedia',
        }
    }

    get defaultProps(){
        return {
            name:  "",
            placeholder:  "",
            value: {type: 'arr', value: null},
            activeSlide: { type: 'int', value: 0 },
            errorMessage: {type: 'string', value: '' },
            inputs: {type: 'arr', value: [
                {
                    active: true,
                    src: null,
                    newSrc: null,
                    value: null,
                    VideonewSrc:null
                }
            ]}
        }
    }

    ready() {
       
        if (this.props.value !== null && this.props.value.length) {

            this.props.inputs.splice(0, this.props.inputs.length );
            for (let i=0; i<this.props.value.length; i++) {
                this.props.inputs.push({
                    active: true,
                    src: this.props.value[i].src,
                    value: this.props.value[i].value,
                    newSrc: null,
                    VideonewSrc:null
                });
            }

        }
    }

    _onFileError() {
        this.props.errorMessage = 'Format Error. Please read instrcutions';
    }

   

    _onFilePreviewChange(preview, node, type) {
       
        this.props.errorMessage = '';

        let index = Number(node.getAttribute('index'));

        if (preview == null) {
            this.props.inputs[ index ].active = false;
            //this.props.inputs.splice( index, 1 );
            this.props.activeSlide = 0;
        }
        else {
            if (type === "1") {
                this.props.inputs[index].VideonewSrc = preview;
                this.props.inputs[index].newSrc = '';
            } else {
                this.props.inputs[index].newSrc = preview;
                this.props.inputs[index].VideonewSrc = '';
            }
            
        }

        if (this.props.inputs.length == 0) {
            this.props.inputs.push({
                active: true,
                src: null,
                newSrc: null,
                value: null,
                VideonewSrc:null
            });
            this.props.activeSlide = 0;
        }

    }

    _deleteMedia(event) {


        let index = Number(event.currentTarget.getAttribute('data-index'));
        // this.props.inputs.splice( index, 1 );
        // this.props.activeSlide = 0;
        
        if (this.props.inputs[ index ].src == null &&
             (this.props.inputs[ index ].newSrc == null || this.props.inputs[ index ].VideonewSrc == null) ) {
            return;
        }

        this.props.inputs[ index ].active = false;

        //find the first active slide and focus on it.
        for (let i=0;i<this.props.inputs.length; i++) {
            if (this.props.inputs[ i ].active) {
                this.props.activeSlide = i;
                break;
            }
        }

    }

    _addMediafield() {
        
        if (
             this.props.inputs[ this.props.inputs.length-1 ].src == null &&
             (this.props.inputs[ this.props.inputs.length-1 ].newSrc == null || this.props.inputs[ this.props.inputs.length-1 ].VideonewSrc == null)) {
            
            return;
        }

        this.props.inputs.push({
            active: true,
            src: null,
            newSrc: null,
            VideonewSrc:null,
            value: null
        });

        this.props.activeSlide = this.props.inputs.length -1;

    }

    onGoToSlide( event ){
        if(event){
            this.props.activeSlide = Number(event.currentTarget.getAttribute('data-target'));
        }
    }

}

register('app-multi-upload', AddMultiupload);
