import {Component} from 'light';
import {register} from 'light';

class AdminProducts extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    ready() {
        console.log('__ADMIN PRODUCTS__', this.context);
    }

}

register('admin-products', AdminProducts);
