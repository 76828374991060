import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

class PreviewMedia extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
        }
    }

}

register('preview-media', PreviewMedia);
