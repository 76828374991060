import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";

class LightCoreStateButton extends Component {

    get events(){
        return {
            'pointerup': 'triggerBtn'
        }
    }

    get defaultProps(){
        return {
            state: null,
            params: { type: 'json', value: null },
            active: { type: 'bool', value: true, change: 'onActiveChange' }
        }
    }

    created(){
        
    }

    onActiveChange(){
        console.log('change' , this.props.active);
    }

    triggerBtn(){
        if(this.props.active){
            EventDispatcher.fire('statechange', [this.props.state, this.props.params]);
        }
    }

}

register('light-core-state-button', LightCoreStateButton);