import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';


class AdminInformations extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-delete': 'onDelete'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            submitDelete: { type: 'bool', value: false },
            isPasswordActive: { type: 'bool', value: false },
            errorMessage: null
        }
    }

    created() {
        this.bindMethods(['_onConfirmDelete', '_onCancelDelete', '_onPasswordFocus']);
    }

    attached(){
       // EventDispatcher.on('user:delete', this.onDeleteUser, this);
    }

    ready() {
        setTimeout(()=>{
            this.querySelector('.js-password').addEventListener('focus', this._onPasswordFocus, false);
        }, 1000);
    }

    _onPasswordFocus(e) {
        e.currentTarget.value = '';
        this.props.isPasswordActive = true;
        this.querySelector('.js-password').removeEventListener('focus', this._onPasswordFocus, false);
    }

    // Edit account
    onEditSuccess( data ){
        if (data.body.success) {
            this.props.errorMessage = null;
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.onEditError(data);    
        }
    }

    onEditError( data ){
        this.props.errorMessage = data.body.response.body;
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    // Delete account
    onDelete(){
        EventDispatcher.fire('confirm', [{
            title: 'Are you sure?',
            body: 'This action is irreversible, your staff member will be deleted',
            confirmLabel: "delete",
            cancelLabel: "cancel",
            onConfirm: this._onConfirmDelete,
            onCancel:  this._onCancelDelete
        }]);
    }

    _onConfirmDelete() {
        this.props.submitDelete = false;
        requestAnimationFrame( () => {
            this.props.submitDelete = true;
        });
    }

    _onCancelDelete() {

    }

    onDeleteSuccess( data ){
        if (data.body.success) {
            EventDispatcher.fire('state:change', [ 'page', {'url': data.body.redirect} ]);
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.onDeleteError(data);
        }
    }

    onDeleteError( data ){
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
    }

    detached(){
        //EventDispatcher.off('user:delete', this.onDeleteUser);
        this.querySelector('.js-password').removeEventListener('focus', this._onPasswordFocus, false);
    }

}

register('admin-informations', AdminInformations);
