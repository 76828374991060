/**
 * JavaScript port of Webkit implementation of CSS cubic-bezier(p1x.p1y,p2x,p2y) by http://mck.me
 * http://svn.webkit.org/repository/webkit/trunk/Source/WebCore/platform/graphics/UnitBezier.h
 */
module.exports = (function(){

  var DEFAULT_DURATION = 400;//ms
 
  var solveEpsilon = function(duration) {
    return 1.0 / (200.0 * duration);
  };
 
  var unitBezier = function(p1x, p1y, p2x, p2y) {
  

    var cx = 3.0 * p1x;
    var bx = 3.0 * (p2x - p1x) - cx;
    var ax = 1.0 - cx -bx;
    var cy = 3.0 * p1y;
    var by = 3.0 * (p2y - p1y) - cy;
    var ay = 1.0 - cy - by;
 
    var sampleCurveX = function(t) {
      return ((ax * t + bx) * t + cx) * t;
    };
 
    var sampleCurveY = function(t) {
      return ((ay * t + by) * t + cy) * t;
    };

    var sampleCurveDerivativeX = function(t) {
      return (3.0 * ax * t + 2.0 * bx) * t + cx;
    };
 
    var solveCurveX = function(x, epsilon) {

      var t0,t1,t2,x2,d2,i;

      for (t2 = x, i = 0; i < 8; i++) {
        x2 = sampleCurveX(t2) - x;
        if (Math.abs (x2) < epsilon) {
          return t2;
        }
        d2 = sampleCurveDerivativeX(t2);
        if (Math.abs(d2) < 1e-6) {
          break;
        }
        t2 = t2 - x2 / d2;
      }
 
      t0 = 0.0;
      t1 = 1.0;
      t2 = x;
 
      if (t2 < t0) 
        return t0;
      
      if (t2 > t1) 
        return t1;
 
      while (t0 < t1) {
        x2 = sampleCurveX(t2);
        if (Math.abs(x2 - x) < epsilon) {
          return t2;
        }
        if (x > x2) {
          t0 = t2;
        } else {
          t1 = t2;
        }
        t2 = (t1 - t0) * 0.5 + t0;
      }
 
      // Failure.
      return t2;
    };
 
    var solve = function(x, epsilon) {
      return sampleCurveY(solveCurveX(x, epsilon));
    };
 
    return function(x, duration) {
      return solve(x, solveEpsilon(+duration || DEFAULT_DURATION));
    };
  };
 
  return function(p1x, p1y, p2x, p2y, x, duration) {
    return unitBezier(p1x, p1y, p2x, p2y)(x, duration);
  };

})();