import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {utils} from 'light';

class StoreSearch extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
        	src: null,
        	data:     { type: 'json', value: {
                'posts':  [],
                'total':  0,
                'rest':   0,
            }},
            params: {type: 'json',  value: {
                tags: [],
                category: '',
                keywords: '',
                date: '',
                todate: '',
                place: '',
                page: 0,
                num: 10,
            }},
            onReady: {type:'fn',value: function(){} }
        }
    }

    detached() {
        EventDispatcher.off('search', this._onSearch, this);
    }

    _onSearch(params) {
        for (let k in params) {
            if (this.props.params[k] !== void 0) {
                this.props.params[k] = params[k];
            }
        }
    }

    ready() {        
        EventDispatcher.on('search', this._onSearch, this);
        EventDispatcher.setHandler('search:store', (callback)=>{
            if (callback !== void 0) {
                callback( this.props.data );
            }
            return this.props.data;
        }, this);
        this.props.onReady();
    }

    _onSuccess(data) {        
        this.props.data.rest  = data.body.rest;
        this.props.data.total = data.body.total;
        if (this.props.params.page == 0) {
            this.props.data.posts.splice(0, this.props.data.posts.length);
        }
        this.props.data.posts.push.apply( this.props.data.posts, data.body.posts );
        EventDispatcher.fire('searchcompleted',[data.body.norecordfoundmessage]);
    }

    _onError() {
        this.props.data = data.body;
    }

}

register('store-search', StoreSearch);