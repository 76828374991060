import {register} from "light";
import {Component} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');

import './CSS3DObject';

class LightEngineMeshCSS3D extends Component {

	get defaultProps(){
		return {
			position:      { type: "array",  value: [0,0,0]     },
			rotation:      { type: "array",  value: [0,0,0]     },
			scale:         { type: "array",  value: [1,1,1]     },
			crossOrigin:   { type: "string", value: "anonymous" },
			lookAt:        { type: "array", value: null },
			quaternion:    { type: "json", value: null },
			halfWidth:  { type: "float", value: 0 },
			halfHeight: { type: "float", value: 0 },
			frustrumCulled:  { type: "bool", value: true },
		}
	}

	created(){

	}

	detached(){

	}

	ready(){

		//this.transform = mat4.create();
		
		this.mesh = new THREE.CSS3DObject(this);
		this.addToScene();

		this.meshTarget = new THREE.Vector3(0,0,0);

		this.mesh.geometry = {};
		this.mesh.geometry.boundingSphere = new THREE.Sphere();

	}

	resize() {

		let boundingRect      = this.getBoundingClientRect();
		this.props.halfWidth  = boundingRect.width  * .5;
		this.props.halfHeight = boundingRect.height * .5;
		this.props.maxSize    = Math.max( this.props.halfWidth, this.props.halfHeight );
		
		this.mesh.geometry.boundingSphere.radius = this.props.maxSize;

	}

	addToScene(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-SCENE" //may merge both CSS-SCENE and SCENE into one component using attribute
				|| node.tagName == "LIGHT-ENGINE-SCENE-CSS"){
				this.scene = node
				node.add( this.mesh );
				break;
			}
			node = node.parentNode;
		}
	}

	update(){

		// if (this.transform) {
		// 	mat4.identity(this.transform);
		// 	mat4.translate(this.transform, this.transform, [
		// 		this.props.position[0],
		// 		this.props.position[1],
		// 		this.props.position[2]
		// 	]);
		// 	mat4.rotateX(this.transform, this.transform, this.props.rotation[0]);
		// 	mat4.rotateY(this.transform, this.transform, this.props.rotation[1]);
		// 	mat4.rotateZ(this.transform, this.transform, this.props.rotation[2]);
		// }

		if (this.mesh) {

			this.mesh.scale.x = this.props.scale[0];
			this.mesh.scale.y = this.props.scale[1];
			this.mesh.scale.z = this.props.scale[2];

			this.mesh.position.x = this.props.position[0];
			this.mesh.position.y = this.props.position[1];
			this.mesh.position.z = this.props.position[2];

			if (this.props.rotation[0] !== 0) {
				this.mesh.rotation.x = -this.props.rotation[0] * Math.PI / 180;
			}

			if (this.props.rotation[1] !== 0) {
				this.mesh.rotation.y = -this.props.rotation[1] * Math.PI / 180;
			}

			if (this.props.rotation[2] !== 0) {
				this.mesh.rotation.z = -this.props.rotation[2] * Math.PI / 180;
			}

			if (this.props.lookAt !== null) {
				this.meshTarget.x = this.props.lookAt[0];
				this.meshTarget.y = this.props.lookAt[1];
				this.meshTarget.z = this.props.lookAt[2];
				this.mesh.lookAt( this.meshTarget );
			}

			if (this.props.quaternion !== null) {
				this.mesh.quaternion.copy( this.props.quaternion );
			}

			if (this.scene
				&& this.props.frustrumCulled) {
				if (this.scene.inFrustrum( this.mesh ) ) {
					this.classList.remove('u-release-gpu');
				}
				else {
					this.classList.add('u-release-gpu');
				}
			}

		}
		
	}

}

register('light-engine-mesh-css', LightEngineMeshCSS3D);