import {register} from "light";
import {Component} from "light";
import {utils} from 'light';
import request from 'superagent';
import {EventDispatcher} from 'light';
import jstz from 'jstz';

class LightCoreAjax extends Component {

    get defaultProps(){
        return {
            src: { value: null, change: 'onSrcChange' },
            method: 'get',
            data: { type: 'json', value: null, change: 'onDataChange' },
            onSuccess: { type: 'func', value: function(){} },
            onError: { type: 'func', value: function(){} },
            contentType: { type:"string", value: 'application/json' },
            auto: { type: 'bool', value: true },
            headers: { type: 'json', value: {} },
            reload: { type: 'bool', value: false, change: '_onReload' },
            sendOnSrcChange: {type:'bool', value:true},
            sendOnDataChange: {type:'bool', value: true}
        }
    }

    _onReload() {

      if (this.props.reload) {
        this.load();
      }
    }

    ready(){
      if(this.props.auto){
        if(this.props.src != null && this.props.src != "null"){
          this.load();
        }
      }
    }

    onSrcChange(){
      if(this.props.sendOnSrcChange){
        this.load();
      }
    }

    onDataChange(){
      if(this.props.sendOnDataChange){
        this.load();
      }
    }
    
    load() {
        
        this.props.headers.timezoneoffset = new Date().getTimezoneOffset();

        //ON IE10, the .pop() call fails
        let timezoneOffsetArr = new Date().toString().match(/\((.*)\)/);
        this.props.headers.windowstimezoneoffsetname = timezoneOffsetArr === void 0 || timezoneOffsetArr === null ? '' : timezoneOffsetArr.pop();    

        this.props.headers.timezoneoffsetname = jstz.determine().name();        
        this.props.headers.Token = EventDispatcher.request('token:get');

//console.log('Load ajax', this.props.src, this.props.headers.Token)

        if(this.props.src && this.props.method.toLowerCase() == 'get') {

            var q = request.get(this.props.src);

            for(let k in this.props.data) {

              if ( this.props.data.hasOwnProperty(k) ) {

                if ( Object.prototype.toString.call(this.props.data[k]) === '[object Array]' ){

                  for (let i=0; i<this.props.data[k].length; i++) {
                    let queryObject = {};
                    queryObject[k+'[]'] = this.props.data[k][i];
                    q.query(queryObject);
                    //console.log('Query', k+'[]', this.props.data[k][i]);
                  }

                }
                else {
                  let queryObject = {};
                  queryObject[k] = this.props.data[k];
                  q.query(queryObject);
                }

              }

            }

            q.set( this.props.headers )
              .end( (err, resp) => {

                  if (resp.body){

                      if(resp.body.response == "LoggedOut"){                          
                          SetSignalRAfterLogout();
                      }

                    if (resp.body.token !== '' && resp.body.token !== null && resp.body.token !== false
                        && resp.body.token !== void 0) {

                      //console.log('APPLY TOKEN', resp.body.token)
                      EventDispatcher.fire('token:set', [resp.body.token]);
                    }
                    else {
                      //console.log('APPLY EMPTY TOKEN')
                      EventDispatcher.fire('token:set', ['']);
                    }
                  }
                  else {
                    //console.log('AJAX ERROR')
                  }

                  requestAnimationFrame(()=>{
                    if(err) {
                      this.props.onError( err );
                    }
                    else {
                      this.props.onSuccess( resp );
                    }
                  });

              });

        }
        else {

            if (!this.props.src) {
              return;
            }

            let q = request.post(this.props.src);

            if (this.props.contentType !== 'multipart/form-data') {
              q.type(this.props.contentType);
              q.send(this.props.data);
            }
            else {
              for (var k in this.props.data) {
                if (this.props.data[k] instanceof File) {
                  q.attach(k, this.props.data[k], this.props.data[k].name);
                }
                else {
                  q.field(k, this.props.data[k]);
                }
              }
            }

            q.set(this.props.headers)
              .end( (err, resp) => {

                  if (resp.body){
                    if (resp.body.token !== '' && resp.body.token !== null && resp.body.token !== false
                        && resp.body.token !== void 0) {

                      //console.log('APPLY TOKEN', resp.body.token)
                      EventDispatcher.fire('token:set', [resp.body.token]);
                    }
                    else {
                      //console.log('APPLY EMPTY TOKEN')
                      EventDispatcher.fire('token:set', ['']);
                    }
                  }
                  else {
                    //console.log('AJAX ERROR')
                  }



                if (!resp.body) {
                  try {
                    JSON.parse(resp.text);
                  }
                  catch(err){
                    this.props.onError( 'internal server error' );
                    return;
                  }
                }

                requestAnimationFrame(()=>{
                  if(err) {
                    this.props.onError( err );
                  }
                  else {
                    this.props.onSuccess( resp );
                  }
                });
             });
        }
    }
}

register('light-core-ajax', LightCoreAjax);