import {register} from "light";
import {Component} from "light";
import {EventDispatcher} from "light";
// import threejs from "three-js";
// const THREE = threejs();

const THREE = require('three');
import "./OBJLoader";


class LightEngineGeometry extends Component {

	get defaultProps() {
		return {

			src:           { type: 'string', value: null        },
			crossOrigin:   { type: "string", value: "anonymous" },
			anchor:        { 'type': "array", value: [0.5, 0.5] },
			faceNormalsHelper:    { type: "bool", value: false },
			vertexNormalsHelper: { type: "bool", value: false },
			wireframeHelper:     { type: "bool", value: false  },

			vertices: { type: 'array', value: null},
			uvs:      { type: 'array', value: null},
			normals:  { type: 'array', value: null},
			indices:  { type: 'array', value: null},

			width:   { type: 'int', value: 1},
			height:  { type: 'int', value: 1},

		}
	}

	ready(){

		if (this.props.src) {
			this.loader = new THREE.OBJLoader();
			//this.loader.crossOrigin = this.props.crossOrigin;
			this.loader.load(
				this.props.src,
				function(object){
					this.geometry = object.children[0].geometry;
					this._addToMesh();
				}.bind(this),

				function(e) {
					EventDispatcher.fire('loader:progress', [0, e.loaded/1495202]);
				}
			);
		}
		else {

			this.geometry = new THREE.BufferGeometry();
			this._createGeometry();
			this._addToMesh();

		}

	}

	_createGeometry() {

	
		if ( this.props.vertices !== null && this.props.vertices.length > 0 ) {

			let vertices = this.props.vertices.slice(0, this.props.vertices.length);
			let halfWidth  = this.props.width * 0.5;
			let halfHeight = this.props.height * 0.5;

			for (let i=0; i<vertices.length; i+=3) {
				vertices[i+0] *= halfWidth;
				vertices[i+1] *= halfHeight;
			}

			this.geometry.addAttribute( 'position', new THREE.BufferAttribute( new Float32Array( vertices ), 3 ) );

		}
		
		if ( this.props.normals !== null && this.props.normals.length > 0 ) {
			this.geometry.addAttribute( 'normal', new THREE.BufferAttribute( new Float32Array( this.props.normals ), 3 ) );
		}

		if ( this.props.uvs !== null && this.props.uvs.length > 0 ) {
			this.geometry.addAttribute( 'uv', new THREE.BufferAttribute( new Float32Array( this.props.uvs ), 2 ) );
		}

		if ( this.props.indices !== null && this.props.indices.length > 0 ) {
			// this.geometry.addAttribute( 'index', new THREE.BufferAttribute( new Uint16Array( this.props.indices ), 1 ) );
        	this.geometry.setIndex( new THREE.BufferAttribute( new Uint16Array( this.props.indices ) , 1 ) );
		}


		if(this.props.anchor){
            let translateX = (this.props.anchor[0] - 0.5) * 2 * (this.props.width/2) * -1; //(this.props.width  ) - halfWidth;
            let translateY = (this.props.anchor[1] - 0.5) * 2 * (this.props.height/2);
            this.geometry.applyMatrix( new THREE.Matrix4().makeTranslation( translateX, translateY, 0 ) );
        }


	}

	_addToMesh(){
		var node = this.parentNode;
		while(node){
			if(node.tagName == "LIGHT-ENGINE-MESH"){
				this.mesh = node.mesh;
				node.mesh.geometry = this.geometry;

				if (this.props.vertexNormalsHelper) {
					this.vertexNormalsHelper = new THREE.VertexNormalsHelper( this.mesh, 10, 0xffff00, 1 );
					node.scene.add(this.vertexNormalsHelper);
				}
				
				if (this.props.wireframeHelper) {
					this.wireframeHelper = new THREE.WireframeHelper( this.mesh, 0x00ffff );
					node.scene.add(this.wireframeHelper);
				}

				break;
			}
			node = node.parentNode;
		}
	}

	update () {

		// if (this.vertexNormalsHelper) {
		// 	this.vertexNormalsHelper.update();
		// }
		// if (this.faceNormalsHelper) {
		// 	this.faceNormalsHelper.update();
		// }
	}

	
}

register('light-engine-geometry', LightEngineGeometry);
