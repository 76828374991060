import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';
import {EventDispatcher} from 'light';

class AppBreadcrumb extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps() {
        return {
            content: { type: 'json', value: null  }
        }
    }

}

register('app-breadcrumb', AppBreadcrumb);