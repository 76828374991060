import {Component} from 'light';
import {register} from 'light';
import Loader from 'resource-loader';

class CoreLoader extends Component {

    get defaultProps(){
        return {
            'manifest': { type: 'json', value: null, change: 'load' },
            'onStart': { type: 'func', value: function(){} },
            'onProgress': { type: 'func', value: function(){} },
            'onComplete': { type: 'func', value: function(){} },
            'onError': { type: 'func', value: function(){} }
        }
    }

    destroy(){
        if(this.loader) {
            this.loader.removeAllListeners();
            this.loader.reset();
            this.loader = null;
        }
    }

    attached(){
        this.load();
    }

    load(){
        if(!this.props.manifest) return

        if(this.props.manifest.length == 0){
            this.props.onComplete();
            return;
        }

        if(this.loader){
            this.destroy();
        }

        this.loader = new Loader();
        this.loader.on('error', this.props.onError);
        this.loader.on('progress', this.props.onProgress);
        this.loader.on('complete', this.props.onComplete);

        this.props.manifest.forEach( (file) => {
            this.loader.add(file, file);
        });

        this.loader.load();
        this.props.onStart();
    }
}

register('core-loader', CoreLoader);
