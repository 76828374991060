import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

function getAbsoluteBoundingRect(el) {
    var doc  = document,
        win  = window,
        body = doc.body,

        // pageXOffset and pageYOffset work everywhere except IE <9.
        offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
            (doc.documentElement || body.parentNode || body).scrollLeft,
        offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
            (doc.documentElement || body.parentNode || body).scrollTop,

        rect = el.getBoundingClientRect();

    if (el !== body) {
        var parent = el.parentNode;

        // The element's rect will be affected by the scroll positions of
        // *all* of its scrollable parents, not just the window, so we have
        // to walk up the tree and collect every scroll offset. Good times.
        while (parent !== body) {
            offsetX += parent.scrollLeft;
            offsetY += parent.scrollTop;
            parent   = parent.parentNode;
        }
    }

    return {
        bottom: rect.bottom + offsetY,
        height: rect.height,
        left  : rect.left + offsetX,
        right : rect.right + offsetX,
        top   : rect.top + offsetY,
        width : rect.width,
        screenTop: rect.top,
        screenLeft: rect.left,
        screenBottom: rect.bottom,
        screenRight: rect.right,
    };

}


class ScrollObject extends Component {

	get events() {
		return {
			
		}
	}

    get defaultProps(){
        return {
            scrollX: { type: 'float', value: 0   },
            scrollY: { type: 'float', value: 0   },
            deltaY: { type: 'float', value: 0   },
            ratio:   { type: 'float', value: 0   },
            culled:  { type: 'bool', value: true },
        }
    }

    created() {
        this._boundRect = null;
        this._position = [0,0];
    }

    ready() {
        setTimeout(()=>{
            this.resize();
        }, 1000);
    }

    resize() {
        this._boundRect = getAbsoluteBoundingRect(this);
    }

    update() {
        this._position[0] = 0;
        this._position[1] =  (this._boundRect.top - ((this._boundRect.top - this.props.deltaY)) - this.props.scrollY) * this.props.ratio;
        this.style[support.transform] = 'translate('+this._position[0]+'px, '+this._position[1]+'px)' + support.translateZ; 

       // console.log( this.props.deltaY );


    }

}

register('scroll-object', ScrollObject);
