import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class AdminReports extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return{
            'click .js-form-submit' : 'onFormSubmit',
            'click .js-btn-collapse': 'onToggleCollapse',
            'change .js-btn-social': 'onToggleCollapse'
        }
    }


    get defaultProps(){
        return {

            content: { type: 'json', value: null },

            params: {type: 'json', value: {
                settings:[]
            }},
            collapseState: { type: 'bool', value: true },
            forceReload: { type: 'bool', value: false },
            reporthtml: { type: 'text', value: '' },
            src:{ type: 'text', value: '' },
            reload: {type: 'bool', value: false}
        }
    }

    onToggleCollapse(event){
        // this.props.collapseState = event.currentTarget.checked;
        console.log(this.props.collapseState);
        if(!this.props.collapseState){
            this.props.collapseState = true;
        }
        else {
            this.props.collapseState = false;
        }
    }

    onFormSubmit(event){
        
        var query = {};        

       // var Selectquery='';
        this.$selectTag = this.querySelectorAll('.js-select');
        for (var i = 0, len = this.$selectTag.length; i < len; i++) {
            var key = this.$selectTag[i].name;
            var val = this.$selectTag[i].value;
           // Selectquery = '&'+key+'=' +val;
            query[key] = val;
        }

        this.$InputText = this.querySelectorAll('.js-input');
        for (var j = 0, len = this.$InputText.length; j < len; j++) {
            var key = this.$InputText[j].name;
            var val = this.$InputText[j].value;
            //hiddenquery = '&'+key+'=' +val;
            query[key] = val;
        }

        // var hiddenquery='';
        this.$hidden = this.querySelectorAll('.js-hidden');
        for (var j = 0, len = this.$hidden.length; j < len; j++) {
            var key = this.$hidden[j].name;
            var val = this.$hidden[j].value;
            //hiddenquery = '&'+key+'=' +val;
            query[key] = val;
        }
              
        //var FinalQuery ='';
        //if(Selectquery!='')
        //    FinalQuery = Selectquery;

        //if(hiddenquery!='')
        //    FinalQuery = FinalQuery + hiddenquery;
        //console.log(FinalQuery);

        //this.props.src = this.props.content.action ;//+ '?'+FinalQuery;

        this.props.params = query;

        this.props.reload = true;
        requestAnimationFrame(()=>{
            this.props.reload = false;
        });

    }
    attached(){
        this.$selectTag = this.querySelector('.js-select');
        // this.onSelectChange(this.$selectTag, this.$selectTag.value);
    }

    _onReportSuccess( data ){
        console.log('data');
        this.props.reporthtml = data.text;
        //console.log(this.props.reporthtml);

    }

    _onReportError( data ){
        console.log('Error in ajax form');
    }

    //onSelectChange( el, value ){
        
    //    this.$hidden = this.querySelector('.js-hidden');
    //    this.props.src = this.props.content.action + '/' + value + '/' + this.$hidden.value + '?UserID=' + this.$hidden.value;
    //    console.log(this.props.src);
    //}
}

register('admin-reports', AdminReports);
