import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';

class Unsubscribe_mail extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            "change .js-input-event": 'onInputChange'
        }
    }

    get defaultProps(){

        return {

            content: { type: 'json', value: null },

            params: {type: 'json', value: {
                events:[]
            }},

            forceReload: { type: 'bool', value: false },

        }
    }

    onSuccess( data ){
        if (data.body.success) {
            this.props.errorMessage = null;
            EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);
        }
        else {
            this.onError(data);
        }

    }

    onError( data ){
        this.props.errorMessage = data.body.response.body;
        EventDispatcher.fire('notify', [{title: data.body.response.title, body: data.body.response.body }]);

       
    }
    onInputChange(e){

        let templateid   = e.currentTarget.getAttribute('name');
        let checked = e.currentTarget.checked;
        let userid = e.currentTarget.getAttribute('user-id');

        let params = {
            userid: userid,
            templateid: templateid,
            assign: checked
        };

        this.props.params = params;

    }
}

register('unsubscribe-mail', Unsubscribe_mail);
