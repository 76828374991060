import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import {support} from 'light';



class CoreVideoNative extends Component {

    get template() {
        return require('./template');
    }


    get events(){
        return {
            'click .js-video-big-play': 'togglePlay',
            'click .js-video-mute': 'toggleSound',
            'click .js-video-play': 'togglePlay',
            'click .js-video-fullscreen': 'toggleFullScreen',
        }
    }

    get defaultProps() {
        return {
            active: { type: 'bool', value: true, change: 'onActiveChange' },

            src : { type: 'string', value: null, change: 'onSrcChange' },
            poster: { type: 'string', value: '' },
            controls: { type: 'bool',  value: true },
            autoplay: { type: 'bool', value: false },
            mute: { type: 'bool', value: false },
            loop: { type: 'bool', value: false },
            seekTo: { 'type': 'float', value: -1, change: 'onSeekTo' },

            callback: { type: 'func', value: function(){} },

            forceStop: { type: 'bool', value: false, change: 'onForceStop' },
            timelineValue: { type: 'float', value: null },
            onEnded: { type: 'fn', value: function(){ return; } },

            currentTime: '',
            duration: '',
            reloadnew: { type: 'bool', value: false},
            action: { type: 'string', value: ''}
        }
    }

    
    

    created(){
        this.paused = null;

        this.classList.add('c-video-player');

        // init values
        this.paused           = true;
        this.muted            = false;
        this.seeksliding      = false;
        this.duration         = 0;
        this.currentTime      = 0;
        this.isFullScreen     = false;
        this.progressTimer    = null;
        this.isFirstPlay      = true;

    }

    disable(){
        this.pause();
    }

    enable(){
        if( this.props.autoplay ){
            this.play();
        }
    }

    ready() {        
        setTimeout( () => {
            this.player = this.querySelector('.js-native-video');

            if(this.player){
                if( support.requestFullScreen  ){
                    this.classList.add( 'is-support-fullscreen' );
                }

                this.onSrcChange();
                this.toggleControls();
                this.onActiveChange();
            }
            else {
                this.ready();
            }

        }, 100);
    }

    resize(){
        if( support.ipad ){
            this.classList.add( 'is-ipad' );
        }

        if( support.iphone ){
            this.classList.add( 'is-iphone' );
        }

        if( support.touch ){
            this.classList.add( 'is-touch');
        }
    }

    onSrcChange(){
        if(this.props.src !== null ){
            this.player.src = this.props.src;
            this.addEvents();
        }
        else {
            console.warn("Sorry, props.src key must be specified");
        }
    }

    addEvents() {
        this.player.addEventListener('loadedmetadata', this.onPlayerReady.bind(this), false);
        this.player.addEventListener('play', this.onPlayerStateChange.bind(this), false);
        this.player.addEventListener('pause', this.onPlayerStateChange.bind(this), false);
        this.player.addEventListener('ended', this.onPlayerStateChange.bind(this), false);
        this.player.addEventListener('progress', this.onPlayerStateChange.bind(this), false);
        this.player.addEventListener('error', this.onPlayerError.bind(this), false);

        if( this.props.controls ){
            EventDispatcher.on('key:escape', this.cancelFullScreen, this);
        }

    }

    onPlayerReady() {
        this.classList.add('is-ready');
        this.duration = this.player.duration;
        this.props.callback(this);
        this.fire('ready');

        if( (support.touch && support.smallscreen) || support.ipad ){

        }
        else {
            if( this.props.autoplay && this.paused !== false && this.props.active ){
                this.play();
            }
        }
    }

    onPlayerError( e ) {
        console.warn('ERROR VIDEO:', e);
        this.fire('error');
    }

    onPlayerStateChange( event ) {

        if( this.props.mute == true ){
            this.mute();
        }

        if( event.type == "ended" ||  event.data == 0 ){ // ended
            this.onPlayerEnded();
        }
        if( event.type == "play" || event.data == 1 ){ // play
            this.classList.remove('is-loading');
            this.onPlayerPlay();
        }
        if( event.type == "pause" || event.data == 2 ){ // pause
            this.onPlayerPause();
        }
        if( event.type == "progress"){ //buffer
            this.onPlayerBuffer();
        }

    }

    onPlayerBuffer() {
        this.fire('buffer');
        this.classList.add('is-loading');
    }

    onPlayerPlay( node ){        
        this.classList.add('is-playing');
        this.classList.remove('is-paused');

        this.paused = false;
        this.startProgress();        
        if( this.isFirstPlay ){                        
            this.props.reloadnew = true;
            requestAnimationFrame(()=>{
                this.props.reloadnew = false;                                
            });
            this.classList.add('has-started');
            this.isFirstPlay = false;
            this.duration = this.player.duration;
        }

        this.fire('play');
    }

    onPlayerPause() {
        this.classList.remove('is-playing');
        this.classList.add('is-paused');
        this.paused = true;
        this.stopProgress();
        this.fire('pause');
    }

    onPlayerEnded() {
        this.fire('ended');
        this.props.onEnded();

        // setTimeout( () => {
        //     this.onPlayerPause();
        // }, 40)
    }

    toggleControls() {
        if( !this.props.controls ) return;

        if( this.isControlsHidden ){
            this.isControlsHidden = false;
            this.classList.remove('is-no-controls');
        }
        else {
            this.isControlsHidden = true;
            this.classList.add('is-no-controls');
        }
    }

    toggleSound( e ) {
        if( !this.player ) return;

        if( this.muted ){
            this.player.muted = false;
            this.muted = false;
            this.classList.remove('is-muted');
        } else {
            this.player.muted = true;
            this.muted = true;
            this.classList.add('is-muted');
        }
    }

    togglePlay() {
        this[ this.paused ? 'play' : 'pause' ]();
    }

    play() {
        if( !this.paused && !this.player ) return;

        if (this.player) {
            this.player.play();
        }
    }

    pause(){
        if( this.paused && !this.player ) return;

        this.stopProgress();
        if (this.player) {
            this.player.pause();
        }
    }

    stop(){
        if( !this.player ) return;

        this.seek(0, true);
    }

    onForceStop(){
        if( !this.props.forceStop ) return;
        this.stop();
    }

    onActiveChange(){
        if(this.props.active && this.props.autoplay){
            this.play();
        }
        else {
            this.pause();
        }
    }

    onSeekTo(){
        this.seek(this.props.seekTo);
    }

    seek( targetTime, pause ) {
        if (this.player) {
            this.player.currentTime = targetTime;
        }
    }


    onSliderSlide( ratio ) {
        this.stopProgress();
    }

    onSliderStop( ratio ) {
        this.seek( ratio * this.duration );
        this.startProgress();
    }

    startProgress() {
        clearTimeout( this.progressTimer );
        this.updateTime();
    }

    stopProgress() {
        clearTimeout(this.progressTimer);
        this.progressTimer = null;
    }

    updateTime( e, time ) {

        this.currentTime = this.player.currentTime;

        if( this.props.controls ){
            this.props.timelineValue = this.currentTime/this.duration;
        }

        this.progressTimer = setTimeout( () => {
            this.updateTime();
        }, 100);

        if( this.props.loop && this.currentTime >= this.duration - 0.5 ){
            this.seek(0);
        }

    }

    setTimeCount() {
        this._curr = this.formatTime( this.currentTime * 1000 );
        this._dur  = this.formatTime( this.duration * 1000 ) ;

        this.props.currentTime = curr;
        this.props.duration = dur;
    }

    formatTime( ms ) {

        let min = (ms / 1000 / 60) << 0,
            sec = ((ms / 1000) % 60) << 0,
            ret = "";

        ret += min < 10 ? "0" : "";
        ret += min + ":";
        ret += sec < 10 ? "0" : "";
        ret += sec;

        return ret;
    }


    // fullscreen

    toggleFullScreen() {
        if( !this.isFullScreen ){
            this.requestFullScreen();
        } else {
            this.cancelFullScreen();
        }
    }

    requestFullScreen () {

        this.isFullScreen = true;
        this.isPausedBeforeFullScreen = this.paused;

        this.pause();
        this.classList.add('is-fullscreen');

        EventDispatcher.fire('video:enterfullscreen');

        var node = this;
        while( node.parentNode && node.parentNode.getAttribute){
            node.parentNode.classList.add('is-branch')
            node = node.parentNode;
        }

        if( support.requestFullScreen ){
            this[ support.requestFullScreen ]();
            document.addEventListener(support.fullScreenChange, this.onFullScrenChange.bind(this) );
        } else {
            setTimeout( () => {
                if( this.isPausedBeforeFullScreen == true ){
                    this.pause();
                } else {
                    this.play();
                }
            }, 0);
        }

        document.getElementsByTagName('body')[0].classList.add( 'is-fullscreen' );

    }

    onFullScrenChange() {

        if( !document[ support.isFullScreen ] ){
            document.removeEventListener(support.fullScreenChange , this.onFullScrenChange.bind(this) );

            if( this.isFullScreen ){
                this.cancelFullScreen();
            }
        }

        setTimeout( () => {
            if( this.isPausedBeforeFullScreen == true ){
                this.pause();
            } else {
                this.play();
            }
        }, 500);

    }

    cancelFullScreen(){

        this.isFullScreen = false;
        this.isPausedBeforeFullScreen = this.paused;

        this.pause();
        this.classList.remove('is-fullscreen');

        var node = this;
        while( node.parentNode && node.parentNode.getAttribute){
            node.parentNode.classList.remove( 'is-branch' );
            node = node.parentNode;
        }

        if( support.cancelFullScreen ){
            document[ support.cancelFullScreen ]();
        } else {
            setTimeout( () => {
                if( this.isPausedBeforeFullScreen == true ){
                    this.pause();
                } else {
                    this.play();
                }
            }, 0);
        }

        document.getElementsByTagName('body')[0].classList.remove( 'is-fullscreen' );

        EventDispatcher.fire('video:leavefullscreen');

    }

}

register('core-video-native', CoreVideoNative);

