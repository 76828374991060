import {Component} from 'light';
import {register} from 'light';
import {support} from 'light';

import moment from 'moment-timezone';


class MyLiveTimeline extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'pointerdown .js-next': 'onNextThumb',
            'pointerdown .js-prev': 'onPrevThumb'
        }
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null }, //{ type: 'json', value: null },

            labelHours: { type: 'array', value: [] },

            containerSize: { type: 'array', value: [0,0] },
            contentSize: { type: 'array', value: [0,0] },

            activeTab: { type: 'int', value: 0 },
            dragToVal: { type: 'array', value: [0,0] },
            jumpVal: { type: 'array', value: [0,0] },

            active: { type: 'bool', value: false }
        }
    }

    created(){
        this.componentReady = false;
        this.x = 0;
        this.moreSizeDrag = 250;
        this.firstUpdate = true;
        this.overlayWidth = 0;        
    }
        
    attached(){        
        
    }

    ready(){

        this.initTimeSlot();

        setTimeout( () => {

            this.$overlay = this.querySelector('.js-timeline-overlay');
            this.$btnTimeline = this.querySelectorAll('.js-btn-timeline');

            this.$dragContainer = this.querySelector('.js-drag-container');
            this.$next = this.querySelector('.js-next');
            this.$prev = this.querySelector('.js-prev');


            if(this.$dragContainer){
                this.$childrens = this.$dragContainer.querySelectorAll(".js-drag-children");
            }

            if(this.$overlay && this.$btnTimeline && (this.$btnTimeline.length == this.props.content.timeline.length*2) && this.$dragContainer && this.$next && this.$prev && this.$childrens && (this.$childrens.length == this.props.labelHours.length)){
                this.componentReady = true;

                this.initDrag();

                this.$menuitemLinks = this.querySelectorAll('.js-btn-timeline');    
                for ( let i = 0; i < this.$menuitemLinks.length; i++ ){   
                    let $tipNode = this.querySelector("#tip"+ this.$menuitemLinks[i].getAttribute('data-id'));
                    if ($tipNode) {
                        $(this.$menuitemLinks[i]).qtip({
                            content: $tipNode.innerHTML,
                            hide: {
                                fixed: true,
                                delay: 300
                            },
                            style: {
                                classes: 'qtip-light qtip-shadow'
                            }
                        });
                    }                                                                
                }

                
            }
            else {
                this.ready();
            }

        }, 100);

    }

    initDrag(){

        // container Size
        this.props.containerSize[0] = this.$dragContainer.offsetWidth;
        this.props.containerSize[1] = this.$dragContainer.offsetHeight;

        // content Size
        this.maxTop = 0;
        this.maxLeft = 0;

        for ( let i = 0, length = this.$childrens.length; i < length; i++ ){

            let offset = this.$childrens[i].getBoundingClientRect();

            let computedTop = this.$childrens[i].clientHeight;
            let computedLeft = offset.left + this.$childrens[i].clientWidth;

            if (this.maxTop < computedTop ) {
                this.maxTop = computedTop;
            }

            if( this.maxLeft < computedLeft ) {
                this.maxLeft = computedLeft;
            }
        }

        this.props.contentSize[0] = this.maxLeft - this.$dragContainer.getBoundingClientRect().left;
        this.props.contentSize[1] = this.maxTop;

        this.overlayWidth = this.props.contentSize[0];
        this.$overlay.style.width = this.props.contentSize[0] + 'px';

        this.adjustBtnPosition();
    }

    resize(){
        if(this.componentReady){
            this.initDrag();
        }
    }

    adjustBtnPosition(){
        Array.prototype.forEach.call( this.$btnTimeline, (el, i) => {
            let currentTime = el.getAttribute('data-time');

            let currentHours = Number(currentTime.split(':')[0]);
            let currentMinutes = Number(currentTime.split(':')[1]);

            currentTime = (currentHours * 60) + currentMinutes;

            let btnPosition = (currentTime - this.startTimeline) / ( this.endTimeline - this.startTimeline);

            el.style[support.transform] = 'translateX(' + (btnPosition * this.overlayWidth) + 'px) ' + support.translateZ;
        });
    }

    initTimeSlot(){        
        let startHours = Number(this.props.content.start.split(':')[0]);
        let startMinutes = Number(this.props.content.start.split(':')[1]);

        let endHours = Number(this.props.content.end.split(':')[0]);
        let endMinutes = Number(this.props.content.end.split(':')[1]);

        let startTime = (startHours * 60) + startMinutes;
        let endTime = (endHours * 60) + endMinutes;

        for(let i = startTime; i <= endTime; i+=30 ){            
            let convertTime = i / 60;
            let hours = convertTime>>0;
            let rest = convertTime - hours;

            let minutes = rest * 60;

            if(minutes == 0){
                minutes = '00';
            }

            if(hours < 10){
                hours = '0'+hours;
            }

            let time = hours+':'+minutes;

            this.props.labelHours.push( {
                value: i,
                label: time,
                active: ''
            } );
        }

        this.nbTimeSlot = this.props.labelHours.length;

        this.startTimeline = (startHours * 60) + startMinutes - 15;
        this.endTimeline = (endHours * 60) + endMinutes + 15;

    }

    convertTime12( time ) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? 'am' : 'pm'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(''); // return adjusted time or original string
    }

    update(){

        if(this.$overlay && this.props.active && this.overlayWidth){
      
            //if( moment(this.props.content.date, "MM-DD-YYYY").tz(this.props.content.timezone).format('MM-DD-YYYY') == moment.tz(this.props.content.timezone).format('MM-DD-YYYY') ){
              if( moment(this.props.content.date, "MM-DD-YYYY").format('MM-DD-YYYY') == moment(new Date(), "MM-DD-YYYY").format('MM-DD-YYYY') ){
                this.time = new Date();
                this.hours = this.time.getHours();
                this.minutes = this.time.getMinutes();               
                this.currentTime = (this.hours * 60) + this.minutes;               

                if(this.currentTime >= this.startTimeline && this.currentTime <= this.endTimeline){
                    this.$overlay.classList.remove('u-hide');
                    this.percent = 100 * (this.currentTime - this.startTimeline) / ( this.endTimeline - this.startTimeline);
                    this.$overlay.style[support.transform] = 'translateX(' + (-100 + this.percent) + '%) ' + support.translateZ;

                    if(this.firstUpdate){
                        this.firstUpdate = false;
                        let calcul = (this.percent/100 * this.props.contentSize[0]) - (this.props.containerSize[0]/2);

                        if(calcul > ( this.props.contentSize[0] - this.props.containerSize[0] )){
                            calcul = ( this.props.contentSize[0] - this.props.containerSize[0] );
                        }

                        this.props.jumpVal[0] = calcul;
                    }
                }
                else {
                    this.$overlay.classList.add('u-hide');
                    //if(this.currentTime < this.startTimeline){
                    //    this.$overlay.style[support.transform] = 'translateX(-100%) ' + support.translateZ;
                    //}
                    //else {
                    //    this.$overlay.style[support.transform] = 'translateX(0%) ' + support.translateZ;
                    //}
                }

                for( let i = 0; i < this.nbTimeSlot; i++){
                    if(this.currentTime >= (this.props.labelHours[i].value - 15) && this.props.labelHours[(i+1)] && this.currentTime < (this.props.labelHours[(i+1)].value - 15) ){
                        this.props.labelHours[i].active = 'is-active';
                    }
                    else {
                        this.props.labelHours[i].active = '';
                    }
                }
            }
            else {

                if(this.firstUpdate && moment(this.props.content.date, "MM-DD-YYYY").format('x') < moment(new Date()).format('x') ){
                    this.$overlay.style[support.transform] = 'translateX(0%) ' + support.translateZ;

                    this.firstUpdate = false;
                    this.props.jumpVal[0] = ( this.props.contentSize[0] - this.props.containerSize[0] );
                }
                this.$overlay.classList.add('u-hide');
            }

        }

    }

    onMove(x, y){

        this.x = x;

        if( this.x > (this.props.contentSize[0] - this.props.containerSize[0]) - this.moreSizeDrag/2 ){
            if(this.$next){
                this.$next.classList.add('is-disabled');
            }
        }
        else {
            if(this.$next){
                this.$next.classList.remove('is-disabled');
            }
        }

        if( this.x < this.moreSizeDrag/2 ){
            if(this.$prev){
                this.$prev.classList.add('is-disabled');
            }
        }
        else {
            if(this.$prev){
                this.$prev.classList.remove('is-disabled');
            }
        }

        this.$dragContainer.style[support.transform] = 'translate('+ (-x) +'px,'+ (-y) +'px) '+ support.translateZ;
    }

    onDrag(){
        if(!this.pointerNone){
            this.pointerNone = true;
            this.$dragContainer.classList.add('u-force-pointer-none');
        }
    }

    onEnd(){
         if(this.pointerNone){
            this.pointerNone = false;
            this.$dragContainer.classList.remove('u-force-pointer-none');
        }
    }

    onNextThumb() {
        let target = this.x + this.moreSizeDrag;

        if( target > ( this.props.contentSize[0] - this.props.containerSize[0] ) ){
            target = this.props.contentSize[0] - this.props.containerSize[0];
        }

        this.props.dragToVal[0] = target;
    }

    onPrevThumb(){

        let target = this.x - this.moreSizeDrag;

        if( target < 0){
            target = 0;
        }

        this.props.dragToVal[0] = target;
    }

}

register('my-live-timeline', MyLiveTimeline);

