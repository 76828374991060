import {Component} from 'light';
import {register} from 'light';

class NavTabs extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goToTab': 'onTabChange'
        }
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null },
            activeTab: { type: 'int', value: 0 },
            theme: 'default',
        }
    }

    created(){
        this.componentReady = false;
    }

    ready(){
        setTimeout( () => {
            this.$goToLinks = this.querySelectorAll('.js-goToTab');

            if(this.$goToLinks && this.$goToLinks.length){
                this.componentReady = true;
            }
            else {
                this.ready();
            }
        }, 100);
    }

    onTabChange(event){

        if(event){
            this.props.activeTab = Number(event.currentTarget.getAttribute('data-target'));
        }

        Array.prototype.forEach.call(this.$goToLinks, (el, i) => {

            let index = el.getAttribute('data-target');

            if( index == this.props.activeTab ) {
                el.classList.add('is-active');
            }
            else {
                el.classList.remove('is-active');
            }

        });

    }

}

register('nav-tabs', NavTabs);
