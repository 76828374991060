import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';



class AddSingleupload extends Component {

    get template() {
        return require('./template');
    }

    get events(){
        return {
            'click .js-goto': 'onGoToSlide',
            'click .js-add-field': '_addMediafield',
            'click .js-delete': '_deleteMedia',
        }
    }

    get defaultProps(){
        return {
            name:  "",
            placeholder:  "",
            value: {type: 'arr', value: null},
            activeSlide: { type: 'int', value: 0 },
            errorMessage: {type: 'string', value: '' },
            inputs: {type: 'arr', value: [
                {
                    src: null,
                }
            ]}
        }
    }

    ready() {
        if (this.props.value !== null && this.props.value.length) {
            this.props.inputs.splice(0, this.props.inputs.length );
            for (let i=0; i<this.props.value.length; i++) {
                this.props.inputs.push({
                    src: this.props.value[i].src
                });
            }
        }
    }

    _onFileError() {
        this.props.errorMessage = 'Format Error. Please read instrcutions';
    }

    _onFilePreviewChange(preview, node) {

        this.props.errorMessage = '';

        let index = Number(node.getAttribute('index'));

        if (preview == null) {
            this.props.inputs.splice( index, 1 );
            this.props.activeSlide = 0;
        }
        else {
            this.props.inputs[index].src = preview;
        }

        if (this.props.inputs.length == 0) {
            this.props.inputs.push({
                src: null
            });
            this.props.activeSlide = 0;
        }

    }

    _deleteMedia(event) {
        let index = Number(event.currentTarget.getAttribute('data-index'));
        this.props.inputs.splice( index, 1 );
        this.props.activeSlide = 0;
    }

    _addMediafield() {

        if ( this.props.inputs[ this.props.inputs.length-1 ].src == null  ) {
            return;
        }

        this.props.inputs.push({
            src: null,
        });

        this.props.activeSlide = this.props.inputs.length -1;

    }

    onGoToSlide( event ){
        if(event){
            this.props.activeSlide = Number(event.currentTarget.getAttribute('data-target'));
        }
    }

}

register('app-single-upload', AddSingleupload);
