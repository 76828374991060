import {register} from "light";
import {Component} from "light";

class LightUiInputText extends Component {

    get template() {
        return require('./template');
    }

    get styles() {
        return require('./style');
    }

    get defaultProps() {
        return {
            value: {type: 'string', value: '', change: '_onValueChange'},
            name: '',
            inlinecss: '',
            placeholder: '',
            type: "text",
            required: {type: 'bool', value: false},
            onValueChange: {type: 'fn', value: function(){}},
            inputClass: '',
            placeholderClass: '',
            isPlaceholderActive: {type: 'bool', value: true},
            multiline: {type: 'bool', value: false},
            autoHeight: {type: 'bool', value: false}
        }
    }

    get events() {
        return {
            "focus .js-input": '_onInputFocus',
            "keyup .js-input": '_onInputChange',
            "copy .js-input": '_onInputChange',
            "cut .js-input": '_onInputChange',
            "paste .js-input": '_onInputChange',
        }
    }

    ready(){

        this.lastValue = '';
        this.bindMethods(["_onInputFocus"]);
        
        if ( this.querySelector('.js-input') !== null ) {
            this.querySelector('.js-input').addEventListener('focus', this._onInputFocus, false);
        }

        if( this.props.type == "date" ){
            this.querySelector('.js-helper').innerText = "JJ/MM/AAAA";
        }

    }

    _onValueChange() {
        this.querySelector('.js-input').value = this.props.value;
        this._onInputChange({
            currentTarget: this.querySelector('.js-input')
        });
    }

    destroy(){
        this.querySelector('.js-input').removeEventListener('focus', this._onInputFocus);
    }

    _onInputFocus(){
        if( this.props.type == "date" ){
            this.classList.add('is-filled');
        }
    }

    _onInputChange(e){

        this.props.isPlaceholderActive = e.currentTarget.value === '';

        if (this.props.autoHeight) {
            e.currentTarget.style.height = '0px';//reset the scrollHeight
            e.currentTarget.style.height = (e.currentTarget.scrollHeight)+"px";
        }

        this.props.onValueChange(e.currentTarget.value);

        if( this.props.type == "email" ){

        }

        setTimeout(()=>{
            if( e.currentTarget.value === '' ){
                this.classList.remove('is-filled');
            }else{
                this.classList.add('is-filled');
            }
            if( this.props.type == "date" ){
                //this.classList.add();
                //e.currentTarget.value = e.currentTarget.value.replace(/\//g,'');
                
                if( this.lastValue.length < e.currentTarget.value.length ){
                    if( e.currentTarget.value.length == 2 ){
                        e.currentTarget.value += '/';
                    }
                    if( e.currentTarget.value.length == 3 && e.currentTarget.value.charAt(2) != "/"){
                        e.currentTarget.value = e.currentTarget.value.slice(0, -1) + '/' + e.currentTarget.value.charAt(2);
                    }
                    if( e.currentTarget.value.length == 5 ){
                        e.currentTarget.value += '/';
                    }
                }

                if( this.lastValue.length > e.currentTarget.value.length ){
                    if( e.currentTarget.value.length == 3 && e.currentTarget.value.charAt(2) == "/"){
                        e.currentTarget.value = e.currentTarget.value.slice(0, -1);
                    }
                }

                this.querySelector('.js-helper').innerText = e.currentTarget.value + "JJ/MM/AAAA".substr( e.currentTarget.value.length );
            }

            this.lastValue = e.currentTarget.value;

        }, 0);

    }

}

register('light-ui-input-text', LightUiInputText);