import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';


class signIn extends Component {

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: {type: 'json', value: null },
            errorMessage: {type: 'string', value: '' }
        }
    }


    onSuccess( data ){        
        SetSignalRAfterLogin();
        if(data.body.success){
            if (data.body.redirect) {
                if( data.body.type == 'popin' ){
                    EventDispatcher.fire('state:change', ['popin', { url: data.body.redirect, "save": true,"size":"small"}] );
                }
                else{                
                    EventDispatcher.fire('state:change', ['page', {url: data.body.redirect}]);
                }
            }
            
            
            this.props.errorMessage = '';
        }
        else {
            this.props.errorMessage = data.body.response;
        }
        
    }

    onError(data){
        console.log('error', data.body);
    }

}


register('sign-in', signIn);