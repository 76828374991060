import {register} from 'light';
import {Component} from 'light';
import {support} from 'light';

class CoreInputRepeater extends Component {

    get template() {
        return require('./template');
    }

    get events() {
        return {
            'click .js-add': '_onAdd',
        }
    }

    get defaultProps() {
        return {
            repeat: { type: 'arr', value: [] },
            fields: { type: 'arr', value: [] },
        }
    }

    ready () {
        if (this.props.repeat.length == 0) {
            this.props.repeat.push( JSON.parse(JSON.stringify( this.props.fields )) );
        }
    }

    _removeField(e) {
        if (this.props.repeat.length > 1) {
            let index = Number(e.currentTarget.getAttribute('data-index') );
            this.props.repeat.splice(index, 1);
        }
    }

    _onAdd() {
        this.props.repeat.push( JSON.parse(JSON.stringify( this.props.fields )) );
    }

}

register('core-input-repeater', CoreInputRepeater);