import {Component} from 'light';
import {register} from 'light';
import {EventDispatcher} from 'light';
import getParameter from 'utils/getParameter';

function getAbsoluteBoundingRect(el) {
    var doc  = document,
        win  = window,
        body = doc.body,

        // pageXOffset and pageYOffset work everywhere except IE <9.
        offsetX = win.pageXOffset !== undefined ? win.pageXOffset :
            (doc.documentElement || body.parentNode || body).scrollLeft,
        offsetY = win.pageYOffset !== undefined ? win.pageYOffset :
            (doc.documentElement || body.parentNode || body).scrollTop,

        rect = el.getBoundingClientRect();

    if (el !== body) {
        var parent = el.parentNode;

        // The element's rect will be affected by the scroll positions of
        // *all* of its scrollable parents, not just the window, so we have
        // to walk up the tree and collect every scroll offset. Good times.
        while (parent && parent !== body) {
            offsetX += parent.scrollLeft;
            offsetY += parent.scrollTop;
            parent   = parent.parentNode;
        }
    }

    return {
        bottom: rect.bottom + offsetY,
        height: rect.height,
        left  : rect.left + offsetX,
        right : rect.right + offsetX,
        top   : rect.top + offsetY,
        width : rect.width,
        screenTop: rect.top,
        screenLeft: rect.left,
        screenBottom: rect.bottom,
        screenRight: rect.right,
    };

}




class HubXpo extends Component {

	get events() {
		return {
			'click .js-open-hub': '_openHub'
		}
	}

    get template() {
        return require('./template');
    }

    get defaultProps(){
        return {
            content: { type: 'json', value: null }
        }
    }

    _openHub() {

    	// if (getParameter('hub') == '1') {
            this._position = getAbsoluteBoundingRect(this);
            EventDispatcher.fire('hub:open', [this._position]);
        // }
        // else {
           // EventDispatcher.fire('state:change', ['page', {url: '/?hub=1'}]);
           // setTimeout(()=>{
            // this._openHub();
           // }, 100)
        // }

    }

    ready() {
        // if (getParameter('hub') == '1') {
            // this._openHub();
        // }
    }

}

register('hub-xpo', HubXpo);
