import {register} from "light";
import {Component} from "light";
import {support} from "light";

import ScrollBehaviour from './scroll.js';

var UUID = 0;

class LightUiDrag extends Component {

    get defaultProps(){
        return {
            enable: { type: 'bool', value: true, change: 'onActiveChange' },
            useDocument: { type: 'bool', value: false },
            useScroll: { type: 'bool', value: false },
            wheelDirection: { type: 'array', value: ['x','y'] },
            multiplyWheel: { type: 'float', value: 1 },

            onMove: { type: 'func', value: function(){} },
            onShift: { type: 'func', value: function(){} },
            onEnd: { type: 'func', value: function(){} },
            onScrollEnd: { type: 'func', value: function(){} },

            containerSize: { type: 'array', value: [0,0], change: 'onSizeChange'  },
            contentSize: { type: 'array', value: [0,0], change: 'onSizeChange'  },

            scrollTo: { type: 'array', value: [0,0], change: '_scrollTo' },
            jumpTo: { type: 'array', value: [0,0], change: 'jumpTo' },
            duration: { type: 'float', value: 500 }
        }
    }

    detached(){

        if(this.props.useScroll){
            document.removeEventListener('wheel', this._onWheel);
        }
        
        if(this._scroll != undefined){
            this._scroll.off('scroll', this._onScroll, this);
            this._scroll.off('move', this._onDrag, this );
            this._scroll.off('end', this._onEnd, this );
            this._scroll.off('scrollend', this._onScrollend, this);
            this._scroll.destroy();
        }
    }


    created(){
        this._scrollX = 0;
        this._scrollY = 0;

        this._PIXEL_STEP  = 10;
        this._LINE_HEIGHT = 40;
        this._PAGE_HEIGHT = 800;

        this.uuid = UUID;
        UUID++;
    }

    ready() {
        this._scroll = new ScrollBehaviour({
            target: (this.props.useDocument) ? document : this,
            direction: this.props.direction
        });

        this._scroll.on('scroll', this._onScroll, this);
        this._scroll.on('move', this._onDrag, this );
        this._scroll.on('end', this._onEnd, this );
        this._scroll.on('scrollend', this._onScrollend, this);

        if (!this.props.enable) {
            this._scroll._disableScroll();
        }

        if(this.props.useScroll){
            this.bindMethods(['_onWheel']);
            document.addEventListener('wheel', this._onWheel);
        }
    }

    enable(){
        if(this._scroll){
            this._scroll._enableScroll();
            this.resize();
        }
    }

    disable() {
        if(this._scroll){
            this._scroll._disableScroll();
        }
    }

    resize(){
        if( this._scroll &&  this.props.containerSize && this.props.contentSize){
            this._scroll.updateScrollSize( this.props.containerSize[0], this.props.containerSize[1], this.props.contentSize[0], this.props.contentSize[1]);
        }
    }

    _scrollTo(){
        if (this.props.scrollTo[0] !== null
            && this.props.scrollTo[1] !== null) {
            this._scroll.scrollTo(this.props.scrollTo[0], this.props.scrollTo[1], this.props.duration);
        }
    }

    jumpTo(){
        if (this.props.jumpTo[0] !== null
            && this.props.jumpTo[1] !== null) {
            this._scroll.jumpTo(this.props.jumpTo[0], this.props.jumpTo[1]);
        }
    }

    onActiveChange(){
        if (this.props.enable) {
            this.enable();
            this.resize();
        }
        else {
            this.disable();
        }
    }

    onSizeChange(){
        this.resize();
    }

    _onScroll(x, y){
        this._x = x;
        this._y = y;

        this.props.onMove(this._x, this._y);


        if(this.props.useScroll){
            this._scrollX = this._x;
            this._scrollY = this._y;
        }
    }

    _onEnd(state){
        this.props.onEnd(state);
    }

    _onDrag(){
        this.props.onShift();
    }

    _onScrollend(x, y){
        this.props.onScrollEnd(x, y);
    }

    update(){
        if(!this.props.enable) return;

        if( this.props.contentSize[0] != this._scroll._contentWidth
            || this.props.contentSize[1] != this._scroll._contentHeight
            || this.props.containerSize[0] != this._scroll.wrapperWidth
            || this.props.containerSize[1] != this._scroll.wrapperHeight){
            this.resize();
        }
    }

    _normalizeWheel( event ) {
        // Thanks Facebook !

        this._sX = 0;
        this._sY = 0; // spinX, spinY

        this._pX = 0;
        this._pY = 0; // pixelX, pixelY

        // Legacy
        if ('detail'      in event) { this._sY = event.detail; }
        if ('wheelDelta'  in event) { this._sY = -event.wheelDelta / 120; }
        if ('wheelDeltaY' in event) { this._sY = -event.wheelDeltaY / 120; }
        if ('wheelDeltaX' in event) { this._sX = -event.wheelDeltaX / 120; }

        // side scrolling on FF with DOMMouseScroll
        if ( 'axis' in event && event.axis === event.HORIZONTAL_AXIS ) {
            this._sX = this._sY;
            this._sY = 0;
        }

        this._pX = this._sX * this._PIXEL_STEP;
        this._pY = this._sY * this._PIXEL_STEP;

        if ('deltaY' in event) { this._pY = event.deltaY; }
        if ('deltaX' in event) { this._pX = event.deltaX; }


        if ((this._pX || this._pY) && event.deltaMode) {
            if (event.deltaMode == 1) {          // delta in LINE units
                this._pX *= this._LINE_HEIGHT;
                this._pY *= this._LINE_HEIGHT;
            } else {                             // delta in PAGE units
                this._pX *= this._PAGE_HEIGHT;
                this._pY *= this._PAGE_HEIGHT;
            }
        }

        // Fall-back if spin cannot be determined
        if (this._pX && !this._sX) { this._sX = (this._pX < 1) ? -1 : 1; }
        if (this._pY && !this._sY) { this._sY = (this._pY < 1) ? -1 : 1; }

        return { spinX  : this._sX,
                 spinY  : this._sY,
                 pixelX : this._pX,
                 pixelY : this._pY };
    }

    _onWheel( event ) {
        if (!this.props.enable) return;

        event.preventDefault();
        event.stopPropagation();

        this._wheelVal = this._normalizeWheel(event);

        this._scrollX += (this.props.wheelDirection[0] == 'x') ?  this._wheelVal.pixelX*this.props.multiplyWheel : this._wheelVal.pixelY*this.props.multiplyWheel;

        if(this._scrollX > this.props.contentSize[0] - this.props.containerSize[0]){
            this._scrollX = this.props.contentSize[0] - this.props.containerSize[0];
        }
        if(this._scrollX < 0){
            this._scrollX = 0;
        }

        this._scrollY += (this.props.wheelDirection[1] == 'y') ?  this._wheelVal.pixelY*this.props.multiplyWheel :  this._wheelVal.pixelX*this.props.multiplyWheel;

        if(this._scrollY > this.props.contentSize[1] - this.props.containerSize[1]){
            this._scrollY = this.props.contentSize[1] - this.props.containerSize[1];
        }
        if(this._scrollY < 0){
            this._scrollY = 0;
        }

        this._scroll.jumpTo(this._scrollX, this._scrollY);
    }



}


register('light-ui-drag', LightUiDrag);
